import axios from "axios";

const TaskServices = () => {
  const queryparams = window.config.spectedJWT
    ? Object.keys(window.config.spectedJWT)?.reduce(
        (acc, k) =>
          acc +
          "&" +
          k.toString() +
          "=" +
          window.config.spectedJWT[k].toString(),
        ""
      )
    : null;

  const getTasks = (jwt: string) =>
    axios.get(
      window.config.apiBase.buc +
        `tramites/tareas?userOnly=false` +
        (queryparams ? queryparams : ""),
      { headers: { Authorization: `Bearer ${jwt}` } }
    );
  const requestTask = (taskId: string, jwt: string) =>
    axios.put(
      window.config.apiBase.buc +
        `tramites/` +
        taskId +
        "/solicitar" +
        (queryparams ? "?" + queryparams : ""),
      "",
      { headers: { Authorization: `Bearer ${jwt}` } }
    );

  return {
    getTasks,
    requestTask,
  };
};
export default TaskServices;
