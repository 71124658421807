import React from 'react';
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {Link} from 'react-router-dom';
import ShareIcon from '@material-ui/icons/Share';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { tramiteType,tramiteIcon,EContactType, tramiteColor} from '../../../../models/ITramites';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
      margin:'10px auto',
      position:'relative',
      paddingBottom:'53px'
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    cardActions:{
        position:'absolute',
        bottom:'0',
        width:'100%',
        background:'#f7f7f7',
        justifyContent:'flex-end'
    },
    link:{
      textDecoration:'none',
      '&:hover':{
        textDecoration:'none',

      }
    }
  }),
);
const Tramite = (props) =>{
const classes = useStyles();



return (
  <Card className={classes.root}>
    <CardHeader
      avatar={
        <Avatar aria-label="recipe" className={classes.avatar} style={{backgroundColor:tramiteColor[EContactType[props.obj.type]]}}>
          <Icon>  { tramiteIcon[EContactType[props.obj.type]]}</Icon>
        </Avatar>
      }
      title={props.obj.title}
      subheader={ tramiteType[EContactType[props.obj.type]]}
    />
 
    <CardContent>
      <Typography variant="body2" color="textSecondary" component="p">
      {props.obj.description}
      </Typography>
    </CardContent>
    <CardActions className={classes.cardActions}>
   <Link to={'/login'} className={classes.link}> <Button variant='text'>
        Ingresar
    </Button>
    </Link>
    </CardActions>
   
  </Card>
);

    }
export default Tramite;


