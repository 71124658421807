export interface IContact {
    id: string;
    area: string;
    tipo: string;
    recepciono: string;
    descripcion: string;
    archivo: string;
    fechaInicio: string;
    origen: string;
    estado: string;
    leido:boolean;
    fechaInteraccion: string;

}

export enum EContact {
    id = "id",
    area = "area",
    tipo = "tipo",
    recepciono = "recepciono",
    descripcion = "descripcion",
    archivo = "archivo",
    fechaInicio = "fechaInicio",
    origen = "origen",
    estado = "estado",
    leido = '',
    fechaInteraccion = 'fechaInteraccion'
}


export const contactType = {
    1: 'mensaje',
    2: 'notificacion',
    3: 'telefono',
    4: 'DFE'
}

export enum EContactType {
    mensaje = 1,
    notificacion,
    telefono,
    DFE
}

