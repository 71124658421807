import React, { useEffect } from "react";
import Button from "../../Inputs/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import RenderInputs from "../RenderInputs";
import { connect } from "react-redux";
import { useField } from "react-final-form";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { InputGroup } from "../../models/IDynamicForm";
import { FieldArray, useFieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Eye from "@material-ui/icons/Visibility";
import TableInput from "../RenderInputs/TableInput";
import Axios, { Method } from "axios";
import { AppContextConsumer } from "../../DynamicFormContext";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import {
  getUrl,
  getDependenciasByUrl,
  getValuesFromOtherGroup,
} from "../../Inputs/Operations";
import { useContext } from "react";
import { ctxts } from "../../DynamicFormContext";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
    maxWidth: "100%",
    padding: "5px 0",
  },
  LessPadding: {
    padding: "0 5px 0 5px",
  },
  tableHeader: {
    background: "#eee",
  },
  deleteAction: {},
  tableContainer: {
    position: "relative",
    padding: "5px 0",
    overflowY: "hidden",
    margin: "15px 0",
  },
  addButton: {
    background: "white",
    border: " solid #ddd",
    "&:hover": {
      background: "#eee",
    },
  },
  textView: {
    textAlign: "center",
  },
  addButtonContainer: {
    textAlign: "center",
    margin: "30px",
    position: "relative",

    "&:before": {
      content: "''",
      borderBottom: "solid 2px #ddd",
      width: "100%",
      display: "block",
      position: "absolute",
      top: "50%",
    },
  },
  groupH: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  groupV: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    "& .field-input": {
      margin: "15px",
    },
  },
});

type Props = {
  values: any;
  group: InputGroup;
  push: any;
  pop: any;
  initial?: any;
};

const ModalView = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { values, group, push, pop } = props;
  let aux = 0;
  const fieldes = useFieldArray(group.name, undefined).fields;
  const context = useContext(ctxts);
  const [index, setIndex] = React.useState(0);
  const [url, setUrl] = React.useState("");
  const field = useField(group.name);
  const [isLoading, setIsLoading] = React.useState(false);

  let dependencias = [];
  if (group.asyncData)
    getDependenciasByUrl(group.name, group.asyncConf.url, null, dependencias);

  useEffect(() => {
    if (group.asyncData) {
      setUrl(
        getUrl(
          group.name,
          group.asyncConf.url,
          props.cuit,
          values,
          group.asyncConf.JsonFieldsName,
          null,
          group.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [
    ...dependencias.map((d) => {
      const dependenciaValue = _.get(values, d);
      return dependenciaValue ? dependenciaValue : "";
    }),
  ]);

  const [initialValue, setInitialValue] = React.useState(values[group.name]);
  // let initialValue = null;
  //!TODO GET VALUES FROM OTHER GROUP
  useEffect(() => {
    getValuesFromOtherGroup(group, values, field);
  }, [_.get(values, group.otherGroupValues)]);

  //use efect para traer datos asincronos
  useEffect(() => {
    if (!props.isLastPaso) return;
    // TIENE DEPENDENCIA EN URL
    if (group.asyncData && url && !(url.includes("[") || url.includes("{"))) {
      setIsLoading(true);
      Axios({
        method: group.asyncConf.method as Method,
        url: url,
        headers: { Authorization: `Bearer ${props.jwt}` },
      })
        .then((response) => {
          setIsLoading(false);
          field.input.onChange(_.get(response, group.asyncConf.dataKey));
          // setInitialValue(response.data);
        })
        .catch(function (error) {
          console.log(error.message);
          setIsLoading(false);
        });
    }
  }, [url]); //se ejecuta solo si la url cambia

  const handleClickOpen = () => {
    setOpen(true);

    setIndex(fieldes.length);
    push(group.name, undefined);
  };
  const handleCancel = () => {
    setOpen(false);

   pop(group.name);
  };
  const handleAcept = () => {
    setOpen(false);
  };
  const edit = (index) => {
    setOpen(true);
    setIndex(index);
  };

  const disableForce = (appContext, name) => {
    const grupo = name.split("[")[0];
    const index = name.split("[")[1].split("]")[0];
    return appContext.initialSnapshot && group.initialValuesInmutables
      ? parseInt(index) <= appContext.initialSnapshot[grupo]?.length - 1
      : false;
  };
  const requiredArray = (value) =>
    value && value.length > 0 ? undefined : "Required";

  const isRequired = (group) => {
    return group.inputs.some((i) => i.required);
  };
  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.LessPadding}></TableCell>
              {group.inputs.map((inp, index) => (
                <React.Fragment key={"groupRf" + index}>
                  {!inp.hideInModalview ? (
                    <TableCell key={index} align="left">
                      {inp.label}
                    </TableCell>
                  ) : null}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray
              defaultValue={initialValue}
              name={group.name}
              validate={isRequired(group) ? requiredArray : null}
            >
              {({ fields }) =>
                // fields of group
                fields.map((name, index) => (
                  <React.Fragment key={"rf-" + index}>
                    {group.inputs && group.inputs.length > 0 ? (
                      <TableRow key={index}>
                        <TableCell className={classes.deleteAction} key={index}>
                          <AppContextConsumer>
                            {(appContext) =>
                              appContext &&
                              !appContext.viewOnly && (
                                <>
                                  {!group.initialValuesInmutables && (
                                    <IconButton
                                      disabled={disableForce(appContext, name)}
                                      aria-label="delete"
                                      onClick={() => fields.remove(index)}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    aria-label="edit"
                                    disabled={disableForce(appContext, name)}
                                    onClick={() => edit(index)}
                                  >
                                    {!group.initialValuesInmutables ? (
                                      <EditIcon fontSize="small" />
                                    ) : (
                                      <Eye fontSize="small" />
                                    )}
                                  </IconButton>
                                </>
                              )
                            }
                          </AppContextConsumer>
                        </TableCell>
                        <AppContextConsumer>
                          {(appContext) =>
                            group.inputs.map((input, index) => (
                              <React.Fragment key={"modalInput" + index}>
                                {!input.hideInModalview ? (
                                  <TableInput
                                    key={index}
                                    name={name}
                                    input={input}
                                    isModal
                                    loadingAsyncData={isLoading}
                                    values={values}
                                    initialValuesInmutables={disableForce(
                                      appContext,
                                      name
                                    )}
                                  />
                                ) : null}
                              </React.Fragment>
                            ))
                          }
                        </AppContextConsumer>
                      </TableRow>
                    ) : (
                      <Alert variant="outlined" severity="warning">
                        El grupo no tiene ningun input
                      </Alert>
                    )}
                  </React.Fragment>
                ))
              }
            </FieldArray>
          </TableBody>
        </Table>
      </TableContainer>
      <AppContextConsumer>
        {(appContext) =>
          appContext &&
          !appContext.viewOnly &&
          !group.initialValuesInmutables && (
            <div className={classes.addButtonContainer}>
              <IconButton
                className={classes.addButton}
                aria-label="delete"
                onClick={handleClickOpen}
              >
                <Add fontSize="large" />
              </IconButton>
            </div>
          )
        }
      </AppContextConsumer>
      <Dialog
        open={open}
        maxWidth={"md"}
        fullWidth
        onClose={handleAcept}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{group.name}</DialogTitle>
        <DialogContent>
          <div
            key={index}
            className={
              group.show === "groupHorizontal" ? classes.groupH : classes.groupV
            }
          >
            <RenderInputs
              values={values}
              loadingAsyncData={isLoading}
              group={group.name + "[" + index + "]"}
              inputs={group.inputs}
              name={group.name + "[" + index + "]"}
            />
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleCancel} color="primary">
            Cancelar
          </Button> */}
          <Button onClick={handleAcept} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(ModalView);
