import React from "react";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const BreadcrumbsCustom = (props) => {
  const { inIframe, dispatch, ...rest } = props;
  const history = useHistory();
  if (props.inIframe) return <></>;
  return <Breadcrumbs {...rest}></Breadcrumbs>;
};

const mapState = (state) => ({
  inIframe: state.auth.inIframe,
});

export default connect(mapState, null)(BreadcrumbsCustom);
