export default {
    state: {
        tags: []
    },
    reducers: {
        setTags(state, tagsList){
            return Object.assign({}, {...state, tags:tagsList})
        },
    },
    effects: {},
}