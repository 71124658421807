import React from 'react';
import ObligationListElement from '../ObligationListElement/ObligationListElement';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormattedMessage } from 'react-intl';
import 'moment/locale/es';
import {connect} from 'react-redux';
import { cloneNode } from '@babel/types';
import PaymentPlansServices from "../../../services/PaymentPlansServices";
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
    Link: {
        "&:hover": {
            color: "white"
        },
        color: "white"
    },
    row: {
        "&:hover": {
            background: "#eee",
        }
    },
    cell: {
        background: "transparent"
    },
    cellLarge: {
        width: '75%',
    },
    table: {
        margin: 'auto',
        //  width: '70%',
        background: '#f9f9f9',
    },
    header: {
        background: "#ddd"
    },
    stepsStyle: {
        fontWeight: 400,
        textAlign: 'center',
        fontSize: '12px',

    },cellBorrar:{
        textAlign:'right'
    }
}
);


const ObligationList = (props) => {
    const classes = useStyles();
    let obligations = props.obligationList;
    const [actualizar, setActualizar] = React.useState(false);
    function removeItemFromArr ( arr, item ) {
        var i = arr.indexOf( item );
     
        if ( i !== -1 ) {
            arr.splice( i, 1 );
        }
    }


    const handleDelete =(obligation)=>{
        if(props.handleDelete) {props.handleDelete()};
        let i = obligations.indexOf(obligation);
        if ( i !== -1 ) {
            obligations.splice( i, 1 );
            setActualizar(true);
        }
        setActualizar(false)
        props.modifyObligation(obligation)
        if(!props.simulation){
            setTimeout(function () {
                window.location.reload()
            }, 1500);
        } else {
            PaymentPlansServices()
            .simulate(
                props.data.filter((o) => o.id !== obligation.id).map((o) => {
                let saldo = o.saldo;
                let saldoConRecargo = o.saldoConRecargo;
                if (o.exenta) {
                    saldo = (o.saldo * (100 - o.porcentaje_exencion)) / 100;
                    saldoConRecargo =
                    (o.saldoConRecargo * (100 - o.porcentaje_exencion)) / 100;
                }
                return {
                    periodo: o.periodo,
                    cuota: o.cuota,
                    impuesto: o.impuesto,
                    concepto: o.concepto,
                    vencimiento: o.vencimiento,
                    prorroga: o.prorroga,
                    saldo,
                    saldoConRecargo,
                    id: o.id,
                    sujeto_id: o.sujeto_id,
                    objeto_id: o.objeto_id,
                    objeto_tipo: o.objeto_tipo,
                };
            }), props.jwt)
              .then((response) =>{
                props.setSimulation(response.data);
              })
        }
    }
    return (
        <Table size="small" className={classes.table} aria-label="simple table">
            <TableHead className={classes.header}>
                <TableRow>
                    <TableCell><FormattedMessage id="payments.Object" defaultMessage="Objeto" /></TableCell>
                    <TableCell><FormattedMessage id="payments.Year" defaultMessage="Año" /></TableCell>
                    <TableCell><FormattedMessage id="payments.Cuota" defaultMessage="Cuota" /></TableCell>
                    <TableCell><FormattedMessage id="payments.Expire" defaultMessage="Vence" /></TableCell>
                    <TableCell><FormattedMessage id="payments.Price" defaultMessage="Saldo" /></TableCell>
                   {props.eliminable && <TableCell className={classes.cellBorrar}><FormattedMessage id="payments.Delete" defaultMessage="Borrar" /></TableCell>}
                    {props.agregable ? (
                        <TableCell> <FormattedMessage id="payments.Include" defaultMessage="Incluir" /></TableCell>
                    ) :null}


                 </TableRow>
            </TableHead>
            <TableBody>
                {obligations.map((o) => {
                    return (<ObligationListElement deudaOriginal={props.deudaOriginal} agregable={props.agregable} editable={props.eliminable} onDelete={handleDelete} key={o.id} obligation={o} juicios={props.juicios}/>)
                })}
            </TableBody>
        </Table>
    )
}

const mapDispatch = dispatch => ({
    modifyObligation: (checkedObligation, isCheked) => dispatch.payments.modifyObligation(checkedObligation, isCheked),
    setSimulation: (simulation) => dispatch.paymentPlans.setSimulation(simulation)
})

const mapState = (state) => ({
    config: state.auth.config,
    jwt: state.auth.jwt,
    data: state.payments.selectedObligations,
    allObjects: state.payments.allObjects,
    cuit: state.auth.cuit,
    denominacion: state.auth.denominacion,
    locale: state.auth.locale,
    simulation: state.paymentPlans.simulation,
  });

export default connect(mapState, mapDispatch)(ObligationList);