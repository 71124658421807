import React, {useState} from 'react';
import { Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';

const ObligationCheckbox = (props) => {
    const [isChecked, setIsCheked] = useState(props.selectedObligations.some(elem => props.obligation.id === elem.id));
    const handleCheckboxChange = (event) => {
        if(props.obligation.objeto_tipo == 'J' || props.obligation.estado == 'JUDICIAL')
        {
          if(!props.trialsConfig.feesTaxConcept.feesTaxConcept.includes(parseInt(props.obligation.impuesto.toString().concat(props.obligation.concepto.toString()))))
          {
            // Si es una obligacion en juicio, NO HONORARIO, entro aca
            if(props.trialsConfig.partialPay && props.trialsConfig.includeFees){
              // Si ademas hay partialPay y se deben incluir honorarios, entro aca
              let honorarios = props.allObjects.find( obj => obj.id == props.obligation.objeto_id).obligaciones.filter(obl => props.trialsConfig.feesTaxConcept.feesTaxConcept.includes(parseInt(obl.impuesto.toString().concat(obl.concepto.toString()))))
              if(event.target.checked){
                // estoy agregando
                honorarios.forEach(honorario => {
                  if(props.selectedObligations.includes(honorario)) {} else {props.modifyObligation(honorario, true)}
                })
              } else {
                // estoy sacando
                let selectedJuiOb = props.selectedObligations.filter(ob => ob.objeto_id == props.obligation.objeto_id && ob.id != props.obligation.id).filter(ob => !props.trialsConfig.feesTaxConcept.feesTaxConcept.includes(parseInt(ob.impuesto.toString().concat(ob.concepto.toString()))))
                console.log(selectedJuiOb)
                console.log(honorarios)
                if(selectedJuiOb.length == 0){
                  honorarios.forEach(honorario => {
                    console.log("borroHonorario")
                    props.modifyObligation(honorario,false)
                  })
                }
              } 
            }
          }
        }

        props.modifyObligation(props.obligation, event.target.checked);
        setIsCheked(!isChecked)
    }
    return(
        <Checkbox
        disabled={props.disabled}
        checked={isChecked}
        color="primary"
        onChange={handleCheckboxChange}
        inputProps={{ 'aria-label': 'Checkbox ' + props.obligation.objeto_id }}
      />
    )
}

const mapDispatch = dispatch => ({
    modifyObligation: (checkedObligation, isCheked) => dispatch.payments.modifyObligation(checkedObligation, isCheked),
  })

const mapState = state => ({
    selectedObligations: state.payments.selectedObligations,
    trialsConfig: state.payments.trialsConfig,
    allObjects: state.payments.allObjects
})

export default connect(mapState,mapDispatch)(ObligationCheckbox);