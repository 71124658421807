import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import ButtonBase from "@material-ui/core/ButtonBase";

import UserData from "./UserData";
import UserMenuItems from "./UserMenuItems";

const useStyles = makeStyles((theme) =>
  createStyles({
    userMenuContainer: {
      background: theme.userMenu.background,
    },
    lista:{
      padding:0
    }
  })
);

const MenuUsuario = (props) => {
  const classes = useStyles();

  const [openUserMenu, setOpenuserMenu] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleToggle = () => {
    setOpenuserMenu((prevOpen) => !prevOpen);
  };

  const handleCloseUserMenu = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenuserMenu(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenuserMenu(false);
    }
  }

  return (
    <>
      <ButtonBase
        aria-controls={openUserMenu ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={props.mobile ? () => props.onClick() : handleToggle}
        ref={anchorRef}
      >
        <UserData mobile={props.mobile || props.enAtencion}  />
      </ButtonBase>
      {!props.mobile && (
        <Popper
          open={openUserMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper  className={classes.userMenuContainer}>
                <ClickAwayListener onClickAway={handleCloseUserMenu}>
                  <MenuList
                  onClick={handleCloseUserMenu}
                  className={classes.lista}
                    autoFocusItem={openUserMenu}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <UserMenuItems />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default MenuUsuario;
