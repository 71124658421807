import React, { useEffect, useState } from "react";
import DynamicForm from "../../components/Applications/DinamycForm";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import LoadingComponent from "../../components/common/LoadingComponent";
import { Paper } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import apiApplication from "../../services/ApplicationServices";
import { connect } from "react-redux";
import Button from "../../components/Applications/DinamycForm/Inputs/Button";
import { ReactComponent as ErrorImg } from "../../assets/iconos/error.tdr.svg";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: "40px",
      marginBottom: "80px",
    },
    marginLoading: {
      marginTop: "40px",
      marginBottom: "80px",
      display: "block",
    },
    loading: {
      minHeight: "500px",
      background: "#eeeeee",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    superficie: {
      border: theme.superficies.border,
      borderRadius: theme.superficies.borderRadius,
    },
  })
);

const ApplicationSteps = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [jsonForm, setJsonForm] = useState(null);
  const [title, setTitle] = useState(null);
  const [pasoId, setPasoId] = useState(null);
  const [paramTramiteTipo, setParamTramiteTipo] = useState(null);
  const [tramiteId, setTramiteId] = useState(null);
  const [loadingApplications, setLoadingApplications] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);
  const [urlExterna, setUrlExterna] = React.useState<any>("");
  const [enriquecerValues, setEnriquecerValues] = React.useState(null);
  const [disabledFooter, setDisabledFooter] = React.useState(false);
  useEffect(() => {
    if (props.esEnriquecer) {
      loadEnriquecerForm();
      // TODO: Denifir traduccion
      setTitle("Enriquecer");
      // setLoadingApplications(false);
    } else {
      if (props.pasoId) {
        loadFormByPaso(props.paramTramiteId, props.pasoId);
      } else if (props.paramTramiteId) {
        loadFormById(props.paramTramiteId);
      }
    }
  }, [props.paramTramiteId, props.pasoId, props.esEnriquecer]);

  const loadEnriquecerValues = () => {
    if (
      props.tramite.datos_adicionales &&
      props.tramite.datos_adicionales.datos_extra
    ) {
      setEnriquecerValues(
        JSON.parse(props.tramite.datos_adicionales.datos_extra)
      );
    }
  };
  const loadEnriquecerForm = () => {
    if (!props.jwt) {
      return;
    }
    apiApplication.setToken(props.jwt);
    setHasError(false);
    setLoadingApplications(true);
    apiApplication.services
      .getEnriquecerForm(props.jwt, props.formularioEnriquecidoId)
      .then((response) => {
        setJsonForm(JSON.parse(response.data.formulario));
        setTitle(response.data.nombre);
        setLoadingApplications(false);
        setUrlExterna(response.data.urlExterna);
        setDisabledFooter(response.data.readOnly);
        loadEnriquecerValues();
      })
      .catch((error) => {
        setLoadingApplications(false);
        setHasError(true);
        setJsonForm(null);
        // TODO: define an error message
        // console.error(
        //   "Este mensaje indica que el backend genero un error y para fines de de desarrollo se mockea una respuesta."
        // );
        //history.push("/nuevo-tramite");
      });
  };

  const loadFormById = (id) => {
    if (!props.jwt) {
      return;
    }
    apiApplication.setToken(props.jwt);
    setLoadingApplications(true);
    setHasError(false);
    apiApplication.services
      .getApplicationForm(id)
      .then((response) => {
        setJsonForm(JSON.parse(response.data.formulario));
        setTitle(response.data.nombre);
        setUrlExterna(response.data.urlExterna);
        setLoadingApplications(false);
        setParamTramiteTipo(response.data.tipo);

        setDisabledFooter(response.data.readOnly);
      })
      .catch((error) => {
        setLoadingApplications(false);
        setJsonForm(null);
        setHasError(true);
        // TODO: define an error message
        // console.error(
        //   "Este mensaje indica que el backend genero un error y para fines de de desarrollo se mockea una respuesta."
        // );
        //history.push("/nuevo-tramite");
      });
  };
  const goBack = () => {
    if (props.urlToGoBack) {
      window.top.location.href = props.urlToGoBack;
    } else {
      window.history.back();
    }
  };
  const loadFormByPaso = (id, fnPasoId) => {
    if (!props.jwt) {
      return;
    }
    apiApplication.setToken(props.jwt);
    setHasError(false);
    setLoadingApplications(true);
    id &&
      apiApplication.services
        .getApplicationFormPasosByTramite(id, fnPasoId)
        .then((response) => {
          if (response?.data?.urlExterna) {
            window.location.replace(response.data.urlExterna);
          }
          setDisabledFooter(response.data.readOnly);
          setTitle(response.data.descripcion);
          setJsonForm(JSON.parse(response.data.formulario));
          setUrlExterna(response.data.urlExterna);
          setParamTramiteTipo(response.data.tipo);

          setLoadingApplications(false);
        })
        .catch((error) => {
          // TODO: define an error message
          setLoadingApplications(false);
          console.log(error);
          setJsonForm(null);
          setHasError(true);
          // console.error(
          //   "Este mensaje indica que el backend genero un error y para fines de de desarrollo se mockea una respuesta."
          // );
          // history.push("/nuevo-tramite");
        });
  };

  return urlExterna ? (
    <Paper
      className={classes.superficie}
      elevation={theme.superficies.elevation}
    >
      <iframe
        id="inlineFrameExample"
        title="Inline Frame Example"
        width="100%"
        frameBorder="0"
        height="600px"
        src={
          Object.keys(props.initial).length > 0
            ? urlExterna.replace("{{access_token}}", props.accessToken) +
              "?data=" +
              JSON.stringify(props.initial)
            : urlExterna.replace("{{access_token}}", props.accessToken)

          //   urlExterna.replace("{{access_token}}", props.accessToken) +
          //   "?data=" +
          //   (props.esEnriquecer ? enriquecerValues : props.initial)
        }
      />
    </Paper>
  ) : !loadingApplications ? (
    jsonForm ? (
      <DynamicForm
        disabledFooter={disabledFooter}
        initial={props.esEnriquecer ? enriquecerValues : props.initial}
        viewOnly={props.viewOnly}
        paramTramiteId={props.paramTramiteId}
        jsonform={jsonForm}
        pasoId={props.pasoId}
        pasos={props.steps}
        lastPaso={
          props.steps
            ? props.steps[props.steps.length - 1]?.proceso
            : props.pasoId
        }
        tramiteId={props.tramite.id}
        title={title}
        handleVolver={props.handleClose}
        enriquecer={props.esEnriquecer}
        paramTramiteTipo={paramTramiteTipo}
      />
    ) : (
      <>
        {hasError ? (
          <>
            <div className="text-center p-5">
              <ErrorImg />
              <h6 className="mt-2">
                {`No encontramos el formulario ${
                  props.pasoId && "para el paso " + props.pasoId
                }`}
              </h6>
            </div>

            <div className="d-flex justify-content-center mt-2">
              <Button
                variant={"contained"}
                color="primary"
                onClick={props.handleClose ? props.handleClose : goBack}
              >
                Volver
              </Button>
            </div>
          </>
        ) : null}
      </>
    )
  ) : (
    <LoadingComponent isLoading>
      <Paper className={classes.loading}>
        <h2 className="text-center"> Cargando...</h2>
      </Paper>
    </LoadingComponent>
  );
};

const mapState = (state) => ({
  jwt: state.auth.jwt,
  cuit: state.auth.cuit,
  formList: state.applications.formList,
  urlToGoBack: state.auth.urlToGoBack,
});

const mapDispatch = (dispatch) => ({
  setFormList: (formList) => dispatch.applications.setFormList(formList),
});

export default connect(mapState, mapDispatch)(ApplicationSteps);
