import React, { useEffect, Children, useState } from "react";
import Container from "@material-ui/core/Container";
import {connect} from 'react-redux';
import SeccionHeader from "../../common/SeccionHeader";
import { FormattedMessage } from "react-intl";
import ObjectButton from "../Components/ObjectsButtons";
import apiCMS from '../../../services/CMSServices';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';



const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        margin: "5px auto",
        justifyContent: 'center', flexWrap: 'wrap'     
       
    },
});

const SectionPayments = (props) => {
  const [taxes, setTaxes] = React.useState([]);
    const classes= useStyles();


    useEffect(() => {
      apiCMS.TaxesServices.getPublicTaxes()
          .then((response) => {
            setTaxes(response.data);
          });
  }, []);

  return (
    <Container maxWidth="xl">
      <SeccionHeader
        title={
          <FormattedMessage
            id="public.payments.title"
            defaultMessage="Consultá y pagá"
          />
        }
      ></SeccionHeader>
      <div className={classes.container}>
        {taxes.map(el=>  <ObjectButton obj={el} key={el.Description} />)}
      </div>
      
    </Container>
  );
};

const mapDispatch = dispatch => ({
  addTaxesTypes: (taxesList) => dispatch.taxes.addTaxesTypes(taxesList),
})

const mapState = state => ({
  taxesTypes: state.taxes.taxesTypes
})

export default connect(mapDispatch, mapState)(SectionPayments);
