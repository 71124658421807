import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Container } from "@material-ui/core";
import { makeStyles,createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import {FormattedMessage} from 'react-intl'
const useStyles = makeStyles((theme) =>
  createStyles({
  welcome: {
    fontWeight: 400,
    padding: "25px",
    color: theme.colores.contrast,
  },
  toolbar: {
    background: theme.colores.main,
    borderTop:`solid 1px ${theme.colores.contrast +'55'}`

  },
})
);


const GetDayMoment = () => {
  const now = moment();
  const currentHour = now.hour();
  if (currentHour >= 12 && currentHour <= 17) return <FormattedMessage id='taxProfile.Afternoon' defaultMessage="¡Buenas tardes, "/>;
  else if (currentHour <= 18) return <FormattedMessage id='taxProfile.Morning' defaultMessage="¡Buenas noches, "/>;
  else return <FormattedMessage id='taxProfile.Evening' defaultMessage="¡Buen día, "/>
};



const HeaderBar = (props) => {
  const classes = useStyles();

  return (
    <AppBar className={classes.toolbar} position="static" elevation={0}>
      <Container>
        <Toolbar>
          
          <Typography variant='h4' className={classes.welcome}>
           {GetDayMoment()} {props.denominacion.split(" ")[0]}!
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapState = (state) => ({
  denominacion: state.auth.denominacion,
});

export default connect(mapState, null)(HeaderBar);
