import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ObligationList from "../../ObligationList";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import NumberCurrency from "../../../common/NumberCurrency";
import { connect } from "react-redux";
import SeccionHeader from "../../../common/SeccionHeader";
import { FormattedMessage } from "react-intl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LoadingComponent, { FalsoTexto } from "../../../common/LoadingComponent";

const useStyles = makeStyles({
  Link: {
    "&:hover": {
      color: "white",
    },
    color: "white",
  },
  icon: {
    fontSize: "30px",
    color: "green",
    textAlign: "center",
  },
  iconerror: {
    fontSize: "30px",
    color: "red",
    textAlign: "center",
  },
  subMsj: {
    display: "flex",
    fontWeight: 300,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  red: {
    color: "red",
    fontWeight: 500,
  },
  load: { flex: 1, margin: "30px 20px", borderRadius: "10px", width: "30%" },
});

function createData(
  id: number,
  tipo: string,
  inicio,
  ultimoMov,
  estado: string
) {
  return { id, tipo, inicio, ultimoMov, estado };
}

const AddObligations = (props) => {
  const classes = useStyles();
  const [deudaOriginal, setDeudaOriginal] = React.useState(false);
  
  const handleChange = () => {
    setDeudaOriginal(!deudaOriginal);
  };

  let totalAvailable = 0;
  if(props.ObAgregables){
  props.ObAgregables.forEach( ob => {totalAvailable += ob.saldoConRecargo} )
  }

  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={props.handleClose}
    >
      <DialogTitle id="responsive-dialog-title">
        <FormattedMessage
          id="paymentsplans.Addobligationstitle"
          defaultMessage="Agregar obligaciones"
        />

        {totalAvailable > 0 && (
          <div className={classes.subMsj}>
            <span>
              <FormattedMessage
                id="paymentsplans.AddobligationsStill"
                defaultMessage=" Todavia le quedan "
              />{" "}
              <span className={classes.red}>
                <NumberCurrency
                  value={totalAvailable.toFixed(props.config.decimals)}
                />
              </span>
            </span>
            <FormControlLabel
              value={deudaOriginal}
              control={
                <Switch
                  checked={deudaOriginal}
                  onChange={handleChange}
                  color="primary"
                  name="deudaOriginal"
                  inputProps={{ "aria-label": "ver deuda original" }}
                />
              }
              label="Ver deuda original"
              labelPlacement="start"
            />
          </div>
        )}
      </DialogTitle>

      {props.loading ? (
        <LoadingComponent className={classes.load} isLoading>
          <DialogContent>
          <ObligationList
            deudaOriginal={deudaOriginal}fde
            agregable
            obligationList={[,]}
          />

          </DialogContent>
        </LoadingComponent>
      ) : (
        <DialogContent>
          <ObligationList
            deudaOriginal={deudaOriginal}
            agregable
            obligationList={props.ObAgregables}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={props.handleClose}
          variant="outlined"
          color="primary"
          autoFocus
        >
          Cancelar
        </Button>
        <Button
          disabled={props.loading}
          onClick={props.handleAdd}
          variant="contained"
          color="primary"
          autoFocus
        >
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapState = (state) => ({
  config: state.auth.config,
  obligationStates: state.payments.obligationStates,
  obligationsForSimulation: state.payments.obligationsForSimulation
});


const mapDispatch = dispatch => ({
  clearObligationsForSimulation: () => dispatch.payments.clearObligationsForSimulation()
})

export default connect(mapState, mapDispatch)(AddObligations);
