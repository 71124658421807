export const validar = (input) => {
  return (val) =>
    (input.required
      ? validaciones["required"]?.(val, input.validatorParams)
      : undefined) ||
    (Array.isArray(input.validators) &&
      input.validators?.reduce((err, s) => {
        return err || validaciones[s]?.(val, input.validatorParams);
      }, undefined));
};

const validaciones = {
  required: (value, params) =>
    value || typeof value === "number" ? undefined : "Requerido",
  number: (value, params) =>
    value && isNaN(Number(value)) ? "Debe ser un valor numerico" : undefined,
  email: (value, params) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "Ingrese un mail valido"
      : undefined,
  phoneNumber: (value, params) =>
    value && !/^(0|[1-9][0-9]{9})$/i.test(value)
      ? "Ingrese un telefono válido, debe tener 10 digitos"
      : undefined,
  alphaNumeric: (value, params) =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
      ? "Solo caracteres alfanumericos"
      : undefined,
  maxLength: (value, params) => {
    if (!params.maxLength) {
      return "No hay largo máximo cargado";
    } else
      return value && value.toString().length > params.maxLength
        ? `Debe tener como máximo ${params.maxLength} caracteres`
        : undefined;
  },
  minLength: (value, params) => {
    if (!params.minLength) {
      return "No hay largo minimo cargado";
    } else
      return value && value.toString().length < params.minLength
        ? `Debe tener al menos ${params.minLength} caracteres`
        : undefined;
  },
  maxValue: (value, params) => {
    if (!params.maxValue) {
      return "No hay valor máximo cargado";
    } else
      return value &&
        !isNaN(Number(value)) &&
        Number(value) > Number(params.maxValue)
        ? `El valor máximo es ${params.maxValue}`
        : undefined;
  },
  minValue: (value, params) => {
    if (!params.minValue) {
      return "No hay valor minimo cargado";
    } else
      return value &&
        !isNaN(Number(value)) &&
        Number(value) < Number(params.minValue)
        ? `El valor minimo es ${params.minValue}`
        : undefined;
  },

  matchRegEx: (value, params) => {
    if (!params.matchRegEx) {
      return "No hay expresion regular cargada";
    } else {
      const exp = new RegExp(params.matchRegEx);
      return !exp.test(value)
        ? `El valor no cumple con el formato ${params.matchRegEx}`
        : undefined;
    }
  },
};

export const required = (value) =>
  value || typeof value === "number" ? undefined : "Requerido";
export const maxLength = (max) => (value) =>
  value && value.length > max
    ? `Puede tener hasta ${max} caracteres`
    : undefined;
export const maxLength15 = maxLength(15);
export const minLength = (min) => (value) =>
  value && value.length < min
    ? `Debe tener al menos ${min} caracteres`
    : undefined;
export const minLength2 = minLength(2);
export const number = (value) =>
  value && isNaN(Number(value)) ? "Debe ser un valor numerico" : undefined;
export const minValue = (min) => (value) =>
  value && value < min ? `Debe ser mayor que ${min}` : undefined;
export const minValue13 = minValue(13);
export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Ingrese un mail valido"
    : undefined;
export const tooYoung = (value) =>
  value && value < 13
    ? "You do not meet the minimum age requirement!"
    : undefined;
export const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? "Solo caracteres alfanumericos"
    : undefined;
export const phoneNumber = (value) =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? "Ingrese un telefono válido, debe tener 10 digitos"
    : undefined;
