import { inputType } from "../models/IDynamicForm";
import TextSimpleField from "./TextSimpleField";
import NumberField from "./NumberField";
import SwitchField from "./SwitchField";
import PasswordField from "./PasswordField";
import SelectSimpleField from "./SelectSimpleField";

import SelectSimpleSearchField from "./SelectSimpleSearchField";
import TagsField from "./TagsField";
import SelectSearchedField from "./SelectSearchField";

import DateField from "./DateField";
import TextAreaField from "./TextAreaField";
import RadioButtons from "./RadioButtons";
import CheckBoxes from "./CheckBoxes";
import DropDowns from "./DropDowns";
import MoneyField from "./MoneyField";
import UploadFile from "./UploadFile";
import ModalInput from "./ModalInput";
import LinkComponent from "./LinkComponent";
import TextSimpleOutput from "./TextSimpleOutput";
import ImageRender from "./ImageRender";
import CollapseField from "./Collapse";

import { Text, Number } from "./Inputs2";

import {
  // ReduxTextSimpleField as TextSimpleField2,
  //ReduxPasswordField as PasswordField,
  // ReduxTagsField as TagsField,
  //ReduxNumberField as NumberFields,
  // ReduxSelectSimpleField as SelectSimpleField,
  // ReduxSelectSearchedField as SelectSearchedField,
  //ReduxDateField as DateField,
  //ReduxSwitchField as SwitchField,
  ReduxSubmitButton as SubmitButton,
  // ReduxTextAreaField as TextAreaField,
  ReduxDateRangeField as DateRangeField,
  ReduxSliderField as SliderField,
} from "./Inputs";

import {
  PasswordField as PassOutput,
  TextSimpleField as TextOutput,
  TagsField as TagsOutput,
  ImageField as ImageOutput,
} from "./Outputs";
import ParagraphComponent from "./ParagraphComponent";
import DateTimeAuditField from "./DateTimeAuditField";

const getType = (type) => {
  switch (type) {
    case inputType.collapse:
      return CollapseField;
    case inputType.link:
      return LinkComponent;
      break;
    case inputType.paragraph:
      return ParagraphComponent;
      break;
    case inputType.date:
      return DateField;
      break;
    case inputType.dateTimeAudit:
      return DateTimeAuditField;
      break;
    case inputType.select:
      return SelectSimpleSearchField;
      break;
    case inputType.selectMultiple:
      return TextSimpleField;
      break;
    case inputType.number:
      return NumberField;
      break;
    case inputType.money:
      return MoneyField;
      break;
    case inputType.imageRender:
      return ImageRender;
      break;
    case inputType.text:
      return TextSimpleField;
      break;
    case inputType.password:
      return PasswordField;
      break;
    case inputType.checkbox:
      return CheckBoxes;
      break;
    case inputType.radiobutton:
      return RadioButtons;
      break;
    case inputType.slider:
      return TextSimpleField;
      break;
    case inputType.switch:
      return SwitchField;
      break;
    case inputType.time:
      return TextSimpleField;
      break;
    case inputType.dateTime:
      return TextSimpleField;
      break;
    case inputType.dateRange:
      return TextSimpleField;
      break;
    case inputType.file:
      return UploadFile;
      break;
    case inputType.avatar:
      return TextSimpleField;
      break;
    case inputType.tags:
      return TagsField;
      break;
    case inputType.selectSearch:
      return SelectSearchedField;
      break;
    case inputType.textArea:
      return TextAreaField;
    case inputType.dropDown:
      return DropDowns;
      break;

    //viewsOnly
    case inputType.modalInput:
      return ModalInput;
      break;
    case inputType.date_viewOnly:
      return TextSimpleOutput;
      break;
    case inputType.select_viewOnly:
      return TextSimpleOutput;
      break;
    case inputType.selectMultiple_viewOnly:
      return TextSimpleField;
      break;
    case inputType.number_viewOnly:
      return TextSimpleOutput;
      break;
    case inputType.text_viewOnly:
      return TextSimpleOutput;
      break;
    case inputType.password_viewOnly:
      return PassOutput;
      break;
    case inputType.checkbox_viewOnly:
      return TextSimpleField;
      break;
    case inputType.radiobutton_viewOnly:
      return TextSimpleField;
      break;
    case inputType.slider_viewOnly:
      return TextSimpleField;
      break;
    case inputType.switch_viewOnly:
      return TextSimpleField;
      break;
    case inputType.time_viewOnly:
      return TextSimpleField;
      break;
    case inputType.dateTime_viewOnly:
      return TextSimpleField;
      break;
    case inputType.dateRange_viewOnly:
      return TextSimpleField;
      break;
    case inputType.file_viewOnly:
      return TextSimpleField;
      break;
    case inputType.avatar_viewOnly:
      return TextSimpleField;
      break;
    case inputType.tags_viewOnly:
      return TextSimpleField;
      break;
    case inputType.selectSearch_viewOnly:
      return TextSimpleField;
      break;
    case inputType.textArea_viewOnly:
      return TextSimpleField;
      break;
    //endViewsOnly
    case inputType.button:
      return TextSimpleField;
      break;

    default:
      return TextSimpleField;
  }
};
export default getType;
