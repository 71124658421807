import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import apiCMS from "../../services/CMSServices";
import PublicRouter from "./MainRouter/PublicRouter";
import MainRouter from "./MainRouter";
import Header from "../PublicPage/Header";
import SectionFooter from "../PublicPage/SectionFooter";
import apiBUC from "../../services/BUCServices";
import apiPayment from "../../services/PaymentServices";
import apiApplication from "../../services/ApplicationServices";
import { useHistory } from "react-router";
import LoadingContainer from "../../containers/Loading";
const b64DecodeUnicode = (str) => {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};
const parseJwt = (token) => {
  try {
    return JSON.parse(b64DecodeUnicode(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthContext = (props) => {
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const [firstLoad, setFirstLoad] = useState(true);

  const loginCallback = (
    username,
    jwt,
    cuit,
    taxRole,
    name,
    lastName,
    id,
    accessToken,
    urlToRedirect,
    urlSearchParams
  ) => {
    props.clearObligations();
    props.loginSuccess(
      username,
      jwt,
      cuit,
      name,
      lastName,
      id,
      taxRole,
      accessToken
    );
    props.setPublicLogin(false);
    apiCMS.UserMenuServices.getRepresentedUsers(cuit)
      .then((res) => {
        if (!res.data.some((e) => e.cuit === cuit)) {
          res.data.push({
            username: username,
            cuit: cuit,
            denominacion: name + " " + lastName,
          });
        }
        props.setRepresentedList(res.data);
      })
      .catch((err) =>
        console.log(
          "No se pudo obtener el menú del usuario. ERROR: ",
          err.message
        )
      );

    apiCMS.UserMenuServices.getUserMenu(taxRole)
      .then((res) =>
        props.setUserMenu(res.data.map((item) => item["user_menu"]))
      )
      .catch((err) =>
        console.log(
          "No se pudo obtener el menú del usuario. ERROR: ",
          err.message
        )
      );

    props.setAuthenticatedUser(
      jwt,
      username,
      cuit,
      name + " " + lastName,
      accessToken
    );
    apiCMS.setToken(jwt);
    apiBUC.setToken(jwt);
    apiPayment.setToken(jwt);
    apiApplication.setToken(props.jwt);

    apiCMS.AuthServices.getAgentActions().then((res) => {
      props.setAgentActions(res.data);
    });

    apiCMS.AuthServices.getAgentLinks().then((res) => {
      props.setAgentLinks(res.data);
    });

    apiCMS.AuthServices.getContactTypes().then((res) => {
      props.setContactTypes(res.data);
    });

    apiCMS.AuthServices.getSubContactTypes().then((res) => {
      props.setSubContactTypes(res.data);
    });

    apiCMS.AuthServices.getMenuItems(taxRole)
      .then((res) => {
        const orderedMenu = res.data
          .map((item) => {
            return item.action;
          })
          .sort((a, b) => (a.order > b.order ? 1 : -1));

        props.setMenu(orderedMenu);

        apiCMS.ConfigServices.getConfigs()
          .then((response) => {
            props.setConfig(response.data.find((t) => t.id === 1));

            apiCMS.LanguagesServices.getLanguages().then((response) => {
              props.setLanguages(response.data);

              apiCMS.TagsServices.getTags().then((response) => {
                props.setTags(response.data);

                apiCMS.ObjectsActionsServices.getActions().then((response) => {
                  props.setObjectsActions(response.data);

                  apiCMS.TaxesServices.getObligationStates().then((states) => {
                    apiCMS.TaxesServices.getObligationStatesActions().then(
                      (stateActions) => {
                        props.setObligationStates(states.data);
                        props.setObligationStateActions(stateActions.data);
                        props.history.push(urlSearchParams);
                      }
                    );
                  });
                });
              });
            });
          })
          .catch((err) => {
            alert(err);
          });
      })
      .catch((err) => {
        alert(err);
      });
  };

  const getData = async (datos, jwt, urlSearchParams) => {
    const res = await apiCMS.AuthServices.getTaxRoleByCode(datos.usuarioTipoId);
    loginCallback(
      datos.username,
      jwt,
      datos.cuitRepresentado,
      res.data[0].id,
      datos.nombre,
      datos.apellido,
      datos.userId,
      res.data.access_token,
      props.location.pathname,
      urlSearchParams
    );
  };
  const [jwt, setJwt] = useState(urlSearchParams.get("sso"));
  const [accessToken, setAccessToken] = useState(
    urlSearchParams.get("access_token")
  );
  const [urlToGoBack, setUrlToGoBack] = useState(urlSearchParams.get("backTo"));
  useEffect(() => {
    props.setInIframe(window.top !== window.self);
    // console.log("insideIframe", insideIframe);
    if (jwt && accessToken) {
      localStorage.clear();

      apiCMS.setToken(jwt);
      apiBUC.setToken(jwt);
      apiPayment.setToken(jwt);
      apiApplication.setToken(jwt);
      urlSearchParams.delete("sso");
      urlSearchParams.delete("access_token");
      const datos = parseJwt(jwt);
      props.setAccessToken(accessToken);
      props.setUrlToGoBack(urlToGoBack);
      getData(datos, jwt, urlSearchParams);
      setFirstLoad(false);
    }
  }, [jwt, accessToken, urlToGoBack]);

  if (jwt && accessToken) {
    if (props.jwt && !firstLoad && jwt === props.jwt) {
      return <MainRouter />;
    } else {
      return <LoadingContainer noRedirect />;
    }
  } else {
    if (props.jwt == null) {
      return (
        <div
          style={{
            minHeight: "100vh",
            position: "relative",
            marginBottom: "138px",
          }}
        >
          <Header />
          <PublicRouter />
          <SectionFooter />
        </div>
      );
    } else {
      apiCMS.setToken(props.jwt);
      apiBUC.setToken(props.jwt);
      apiPayment.setToken(props.jwt);
      apiApplication.setToken(props.jwt);

      const menu = [...props.menu, ...props.userMenu];
      const links = [
        ...menu.map((m) => m.link.split("/")[1]),
        "login",
        "public-login",
      ];
      const parsePath = props.history.location.pathname.split("/")[1];

      if (links.includes(parsePath)) {
        return <MainRouter />;
      } else {
        // return  <MainRouter />
        localStorage.clear();
        window.location.reload();
        return null;
      }
    }
  }
};

const mapState = (state) => ({
  jwt: state.auth.jwt,
  menu: state.auth.menu,
  userMenu: state.auth.userMenu,
});

const mapDispatch = (dispatch) => ({
  loginSuccess: (
    username,
    jwt,
    cuit,
    name,
    lastName,
    id,
    taxRole,
    accessToken
  ) =>
    dispatch.auth.loginSuccess(username, {
      jwt,
      cuit,
      name,
      lastName,
      id,
      taxRole,
      accessToken,
    }),
  setConfig: (config) => dispatch.auth.setConfig(config),
  setInIframe: (inIframe) => dispatch.auth.setInIframe(inIframe),
  setMenu: (menu) => dispatch.auth.setMenu(menu),
  setUserMenu: (menu) => dispatch.auth.setUserMenu(menu),
  setLanguages: (languagesList) =>
    dispatch.languages.setLanguages(languagesList),
  setTags: (tagsList) => dispatch.tags.setTags(tagsList),
  setObjectsActions: (actionsList) =>
    dispatch.objectsActions.setObjectsActions(actionsList),
  setAuthenticatedUser: (jwt, username, cuit, denominacion, accessToken) =>
    dispatch.auth.setAuthenticatedUser(jwt, {
      username,
      cuit,
      denominacion,
      accessToken,
    }),
  setRepresentedList: (representedList) =>
    dispatch.auth.setRepresentedList(representedList),
  setObligationStates: (states) =>
    dispatch.payments.setObligationStates(states),
  setObligationStateActions: (statesActions) =>
    dispatch.payments.setObligationStateActions(statesActions),
  setAgentActions: (agentActions) =>
    dispatch.auth.setAgentActions(agentActions),
  setAgentLinks: (agentLinks) => dispatch.auth.setAgentLinks(agentLinks),
  setContactTypes: (contactTypes) =>
    dispatch.contacts.setContactTypes(contactTypes),
  setSubContactTypes: (subContactTypes) =>
    dispatch.contacts.setSubContactTypes(subContactTypes),
  clearObligations: () => dispatch.payments.clearObligations(),
  setPublicLogin: (publicLogin) => dispatch.auth.setPublicLogin(publicLogin),
  setAccessToken: (accessToken) => dispatch.auth.setAccessToken(accessToken),
  setUrlToGoBack: (url) => dispatch.auth.setUrlToGoBack(url),
});

export default withRouter(connect(mapState, mapDispatch)(AuthContext));
