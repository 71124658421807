import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { useField } from "react-final-form";
const ModalInput = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    meta: { touched, invalid, error },
    name,
    input,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;

  const showField = useField(input.name + "_selectedOption");

  return (
    <div className={classes.textView}>
      {inputelement.type === "switch"
        ? input.value
          ? input?.value?.toString()
          : "false"
        : inputelement.type === "select"
        ? showField.input?.value?.text?.toString()
        : input?.value?.toString()}
      {props.meta.touched && error && (
        <span style={{ color: "red" }}>{error}</span>
      )}
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(ModalInput);
