import React from 'react';
import TaxItem from '../TaxItem/TaxItem';
import { Grid, Checkbox } from "@material-ui/core";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '../../../common/Accoderon';
import MoreButton from '../MoreButton';
import TagsArray from '../TagsArray';
import { connect } from 'react-redux';
import './styles.css';

import moment from 'moment';
import 'moment-timezone';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import NumberCurrency from '../../../common/NumberCurrency';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { FormattedMessage } from 'react-intl';
import JuicioTags from '../JuicioTags';
import JuicioItem from '../JuicioItem/JuicioItem';
const rowStyleMoreButton = {
    marginRight: 20,
}

const rowStyleTags = {
    paddingLeft: 12,
    paddingTop: 10,
}


export function JuicioItemsMapper(props) {
    return (
        props.objects.map((o, i) =>
            <JuicioItem key={i} o={o}/>
        )
    )
}

const mapState = state => ({
    config: state.auth.config,
    cuit: state.auth.cuit,
    showCapitalDebt: state.payments.showCapitalDebt,
    juicios: state.payments.allJudgments
})

export default connect(mapState, null)(JuicioItemsMapper);