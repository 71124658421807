import React from "react";
import { connect } from "react-redux";
import DefineRepresentedListElement from "../DefineRepresentedListElement";
import DefineRepresentedModal from "../DefineRepresentedModal";
import { useHistory } from "react-router-dom";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import Table from "../../common/Table";
import { FormattedMessage } from "react-intl";
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';import CheckIcon from "@material-ui/icons/Check";
import apiCMS from "../../../services/CMSServices";
const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  fullWidth: {
    width: "100%",
  },
});


const columnas = [
  {
    title: (
      <FormattedMessage
        id="defineRepresented.columns.name"
        defaultMessage="Nombre"
      />
    ),
    field: "name",
    render: (rowData) => <span>{rowData.denominacion} </span>,
  },
  {
    title: (
      <FormattedMessage
        id="defineRepresented.columns.cuit"
        defaultMessage="Cuit"
      />
    ),
    field: "cuit",
  },
];

const DefineRepresentedList = (props) => {
  let history = useHistory();
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  const selectRepresented = (r) => {
    if (r.username != props.username) {
      setSelected(r);
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setSelected(null);
  };
  const SetRepresentated = () => {
    props.clearObligations();
    apiCMS.AuthServices.impersonate(selected.cuit, props.jwt)
      .then((res) => {
        props.impersonateUser(res.token, selected);
        setOpen(false);
        history.push('/mis-impuestos')
      })
      .catch((err) =>
        console.log(
          "No se pudo obtener el menú del usuario. ERROR: ",
          err.message
        )
      );
  };

  return (
    <div>
      {props.TableView ? (
        <Table
          className={classes.fullWidth}
          columnas={columnas}
          Data={props.representedList}
          filter={props.filter}
          disabled={(rowData) => rowData.username === props.username}
          mensajeVacio={
            <FormattedMessage
              id="defineRepresented.emptyMessage"
              defaultMessage="No hay sujetos para mostrar"
            />
          }
          rowClick={(rowData) =>
            rowData.username != props.username
              ? selectRepresented(rowData)
              : null
          }
          acciones={[
            (rowData) => ({
              disabled: rowData.username === props.username,
              icon: (r) => (r.disabled ? <CheckIcon /> : <SwapHorizIcon />),
              tooltip: (
                <FormattedMessage
                  id="defineRepresented.define"
                  defaultMessage="Representar"
                />
              ),
              onClick: (e, r) => selectRepresented(r),
            }),
          ]}
        />
      ) : (
        <div className={classes.container}>
          {props.representedList
            // .filter((elem) => elem.username != props.username)
            .map((elem, i) => (
              <DefineRepresentedListElement
                key={i}
                elem={elem}
                onClick={(r)=>selectRepresented(r)}
                disabled={elem.username === props.username}
              />
            ))}
        </div>
      )}
      <DefineRepresentedModal
        representar={selected}
        isOpen={isOpen}
        handleClose={handleClose}
        handleSubmit={SetRepresentated}
      />
    </div>
  );
};

const mapState = (state) => ({
  jwt: state.auth.jwt,
  username: state.auth.username,
  representedList: state.auth.representedList,
});
const mapDispatch = (dispatch) => ({
  impersonateUser: (jwt, user) => dispatch.auth.impersonateUser(jwt, user),
  clearObligations: () => dispatch.payments.clearObligations(),
});

export default connect(mapState, mapDispatch)(DefineRepresentedList);
