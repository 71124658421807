import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { Container } from "@material-ui/core";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Icon } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useHistory } from "react-router-dom";
import AgentHeaderMobile from "./AgentHeaderMobile";
import IconButton from "@material-ui/core/IconButton";
import StopAppointmentButton from "./StopAppointmentButton";
const useStyles = makeStyles((theme) =>
  createStyles({
    welcome: {
      fontWeight: 400,
      padding: "25px",
    },
    toolbar: {
      background: "#7979ff",
    },
    formContainer: {
      padding: "20px",
    },
    textInput: {
      color: "white !important",
      width: "100%",
      padding: "0 10px",
      "& fieldset": {
        borderColor: "white !important",
      },
    },
    gridBscar: {
      display: "flex",
    },
    BtnBuscar: { color: "white", padding: "10px 30px", borderColor: "white" },
    formControl: {
      borderColor: theme.agentHeader.color,
      width: "100%",
      marginBottom: "-6px",
    },
    selectLabel: {
      color: `${theme.agentHeader.color}  !important`,
      background: theme.agentHeader.background,
      padding: "0 3px",
    },
    select: {
      "& fieldset": {
        borderColor: `${theme.agentHeader.color}  !important`,
      },
      color: theme.agentHeader.color,
      "& svg": {
        color: `${theme.agentHeader.color}  !important`,
      },
      "& span": {
        fontSize: "0.8rem",
      },
    },
    AgentToolbar: {
      width: "100%",
      background: theme.agentHeader.background,
      minHeight: "auto !important",
    },
    AgentToolbarAtendiendo: {
      width: "100%",
      minHeight: "auto !important",
      background: theme.agentHeader.background,
      // opacity: ".3",
      color: theme.agentHeader.color,
      transition: "all 300ms",
      "&:hover": {
        opacity: "1",
        //padding: "10px 24px",
        transition: "all 300ms",
      },
    },
    datosAtendiendo: {
      display: "flex",
      flexDirection: "row",
      color: theme.agentHeader.color,
      justifyContent: "space-between",
      margin: "5px 0 0 ",
    },
    controles: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down('sm')]: {
      
        flexDirection: "column",
      }
    },
    noWrap: { flexWrap: "nowrap",
    display: "flex",
    justifyContent: "space-between", },
    iconButton: {
      color: theme.agentHeader.color,
      marginTop: "-7px",
    },
    BtnDetener: {
      color: theme.agentHeader.color,
      margin: "5px 20px 5px 0",
      [theme.breakpoints.down('sm')]: {
      
        margin: "0",
        padding:'0'
      
      }

    },
    Clock: {
      padding: "4px 12px",
      margin: "5px 0px",
    },
    flexCol: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    hideOnMobile:{

      [theme.breakpoints.down('sm')]: {
      display:'none',
      }
    },
    menu: {
      padding: "0px 0px",
      
    },
  })
);

const BigTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 16,
  },
}))(Tooltip);
const HeaderForAgent = (props) => {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(false);
  const history = useHistory();

  const renderLanguages = () => {
    return props.languages.map((language) => {
      return (
        <MenuItem key={language.prefix} value={language.prefix}>
          <Icon>language</Icon> {language["name_" + props.locale.toUpperCase()]}
        </MenuItem>
      );
    });
  };

  const handleLocaleChange = (event) => {
    props.setLocale(event.target.value);
  };

  const HandleOpen = () => {
    setOpen(true);
  };

  const HandleClose = () => {
    setOpen(false);
  };

  const changeRepresented = () => {
    history.push({
      pathname: "/agent-dashboard",
      state: { changeRep: true },
    });
  };

  return (
    <AgentHeaderMobile
      mobile={false}
      onClose={HandleClose}
      open={isOpen}
      onOpen={HandleOpen}
    >
      <Toolbar
        className={
          props.atencion ? classes.AgentToolbarAtendiendo : classes.AgentToolbar
        }
      >
          <Container maxWidth="lg" className={classes.menu}>
        <Grid container className={classes.noWrap}>
          <Grid item xs={12} md={11}>
            {props.atencion ? (
              <div className={classes.datosAtendiendo}>
                <div>
                  <i className={classes.hideOnMobile}> {"Estas atendiendo a "}</i>
                  {props.atencion.turno ? (
                    <span className={classes.hideOnMobile}>{`turno: ${props.atencion.turno}`}</span>
                  ) : null}
                  <BigTooltip
                    title={props.atencion.Persona.cuit}
                    aria-label="cuit"
                  >
                    <b> {props.atencion.Persona.denominacion}</b>
                  </BigTooltip>

                  {props.atencion.enRepresentacion && (
                    <>
                      <i className={classes.hideOnMobile}> {"como representante de "}</i>
                      <BigTooltip
                        title={props.atencion.Representado.cuit}
                        aria-label="cuit"
                      >
                        <b>{props.atencion.Representado.denominacion}</b>
                      </BigTooltip>
                    </>
                  )}
                  <IconButton
                    className={classes.iconButton}
                    onClick={changeRepresented}
                  >
                    <Icon>sync_alt</Icon>
                  </IconButton>
                </div>

                <div className={classes.controles}>
                    HeaderForAgent
                  <StopAppointmentButton
                      classes={classes}
                      {...props}
                      bold={false} />
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </Grid>

          <Grid item className={`${classes.flexCol} ${classes.hideOnMobile}`}>
            <FormControl className={classes.formControl}>
              <Select
                className={classes.select}
                id="selLocale"
                value={props.locale}
                onChange={handleLocaleChange}
              >
                {renderLanguages()}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        </Container>
      </Toolbar>
    </AgentHeaderMobile>
  );
};

const mapDispatch = (dispatch) => ({
  setLocale: (locale) => dispatch.auth.setLocale(locale),
  setMenu: (menu) => dispatch.auth.setMenu(menu),
  setUserMenu: (menu) => dispatch.auth.setUserMenu(menu),
  resetAtencion: () => dispatch.auth.resetAtencion(),
  setCurrentContactId: (contactId) =>
    dispatch.contacts.setCurrentContactId(contactId),
});

const mapState = (state) => ({
  locale: state.auth.locale,
  languages: state.languages.languages,
  atencion: state.auth.atencion,
  taxRole: state.auth.taxRole,
  currentContactId: state.contacts.currentContactId,
});

export default connect(mapState, mapDispatch)(HeaderForAgent);
