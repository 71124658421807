import React, {useEffect, useState} from 'react'
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TaxItemsMapper from "../TaxItemsMapper/TaxItemsMapper";
import TaxIcon from "../TaxIcon/TaxIcon";
import {Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {first} from "../../../../utils";
import _ from "lodash"
import ShowMoreButton from "../ShowMoreButton";

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: `100%`
    },
}));

function subjectTabsGenerator(subjects, distinctTypes, isSelectedGroup) {

    return distinctTypes.map((t, i) => {
        if (subjects.length === 1) {
            i = i + 3;
        }
        if (isSelectedGroup) {
            i = i + 4;
        }
        const icon = <TaxIcon type={t}/>;
        return (<Tab key={i} label={icon} {...a11yProps(i)} />)
    })
}

const TaxTypeTabs = (props) => {

    const [cant, setCant] = useState(Array(props.distinctTypes.length).fill(props.config.taxesPageSize))
    const subjectObjects = _.groupBy(props.subjects, 'tipo');
    const [renderObjects, setRenderObjects] = useState(_.map(subjectObjects, (arr) => first(arr, props.config.taxesPageSize)));

    const classes = useStyles({});
    const theme = useTheme();

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    /**
     *  Increase cant of elements for Tab with index recived
     * @param number i Index of Tab
     */
    const showMore = (i) => {
        let j = 0;
        const newObcts = []
        _.each(subjectObjects, (arr) => {
                if (i === j) {
                    newObcts.push(first(arr, cant[j] + props.config.taxesPageSize))
                } else {
                    newObcts.push(renderObjects[j])
                }
                j++;
            }
        )
        setCant(cant.map((val, j) => i === j ? val + props.config.taxesPageSize : val));
        setRenderObjects(newObcts);
    }

    /**
     * Generate the corresponding elements for each category (distinctTypes)
     */
    const contentTabGeneraton = () => {
        return props.distinctTypes.map((t, i) => {
            if(i >= renderObjects.length){
                return null;
            }
            return (
                <TabPanel key={i} value={value} index={i} dir={theme.direction}>
                    <Grid container spacing={3}>
                        <TaxItemsMapper objects={renderObjects[i]}/>
                    </Grid>
                    <ShowMoreButton cant={cant[i]} elements={subjectObjects[t]} showMore={showMore} index={i} text={'Ver Mas'}/>
                </TabPanel>
            )
        })
    }

    useEffect(() => {
        setCant(Array(props.distinctTypes.length).fill(props.config.taxesPageSize));
        setRenderObjects(_.map(subjectObjects, (arr) => first(arr, props.config.taxesPageSize)));
        setValue(0);
    }, [props.subjects, props.isSelectedGroup, props.distinctTypes]);

    return (
        <Paper className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example">
                    {subjectTabsGenerator(props.subjects, props.distinctTypes, props.isSelectedGroup)}
                </Tabs>
            </AppBar>
            {contentTabGeneraton()}
        </Paper>
    )
}

const mapDispatch = dispatch => ({
    clearObligations: () => dispatch.payments.clearObligations(),
})
const mapState = state => ({
    config: state.auth.config,
})

export default connect(mapState, mapDispatch)(TaxTypeTabs);