import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    MsjContainer: {
        textAlign: 'center',
        padding: '3px',
        display: 'flex',
        borderRadius: '5px',
        backgroundColor: '#b9b9b9',
        flexDirection: 'column',
    },
    MsjTx: {    
        color: '#353535',
        margin: 0,
        padding: '5px'

    },
});



const PlanMsj = (props) => {
    const classes = useStyles();
    return (
        <Grid item xs={12}>
            <div className={classes.MsjContainer}>
                <p className={classes.MsjTx}> {props.Msj}</p>
            </div>
        </Grid>
    );
}
export default PlanMsj;