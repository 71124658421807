import axios from "axios";
import {invokeAPI } from "../utils/Tools";

export type ImpersonateResponse = {
    token: string;
    cuit: string;
    parsedCuit: string;
  };

const CMSAuthServices = (instance) => {

    const getProvider = () => instance.get('login-providers/1');
    const login = (identifier, password) => instance.post('auth/local', {identifier, password});
    const loginTDR = (identifier, password, url, data) => { data.username = identifier; data.password = password; return axios.post(url, data)};
    const getMenuItems = (taxRoleId) => instance.get('tax-role-menus?tax_role.id=' + taxRoleId);
    const getTaxRoleByCode = (code) => instance.get('tax-roles?code=' + code);
    const getAgentActions = () => instance.get('agent-actions');
    const getAgentLinks = () => instance.get('agent-links');
    const getContactTypes = () => instance.get('contact-types');
    const getSubContactTypes = () => instance.get('sub-contact-types')

    const impersonate = (cuit : any, jwt : any):Promise<ImpersonateResponse> => {
        return new Promise((resolve, reject) => {
            const data = {parsedCuit: cuit, cuit: String(cuit).replace(/-/g, '') };
            getProvider().then((res) =>
            {
                const config = res.data;
                invokeAPI({
                    url:config.urlImpersonate,
                    method: config.methodImpersonate,
                    sendAuthHeader:config.sendAuthHeaderImpersonate,
                    jsonRequest: config.jsonRequestImpersonate,
                    jsonResponse: config.jsonResponseImpersonate,
                }, data, jwt)
                .then((r) => resolve(r as ImpersonateResponse))
                .catch((err) => reject(err));
            });
        })
    }
       
    return {
        login,
        getMenuItems,
        getProvider,
        loginTDR,
        getAgentActions,
        getAgentLinks,
        impersonate,
        getTaxRoleByCode,
        getContactTypes,
        getSubContactTypes
    };
};
export default CMSAuthServices;