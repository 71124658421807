import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  Grid, Container,
  CardActions
} from '@material-ui/core/'
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'; 
import BoxButton from '../BoxButtons';
import { FormattedMessage } from 'react-intl';
import styled from "styled-components";
import EndMessage from '../EndMessage';
import { Link } from "react-router-dom";
import { FormasDePago, ElectronicMethods, CashMethods, CashData } from '../../../mock/data';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  }
}));


const BoxContainer = styled(Container)`
  transition: all .2s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap:wrap;
  @media(max-width: 991px) {
    flex-direction: column;
  }

  .boxCol {
    position:relative;
    padding:15px;
    flex: 1;
    min-width: 25%;
    transition: all .2s ease-in-out;
    @media(max-width: 991px) {
      &:not(:last-of-type) {
        margin: 0 0 20px;
      }
    }
    &.disabled:not(.disabledImage) {
      .boxHover {
        background: transparent;
        border: solid 1px #eee;
        box-shadow: none !important;
        transform: none !important;
        pointer-events: none;
        .boxTitle {
          color:#ddd !important;
        }
        .boxDescription {
          color:#ddd;
          li {
            color: #ddd;
          }
        }
      }
    }
    &.disabledImage {
      .boxHover {
        pointer-events: none;
        transform: none !important;
        .boxImage {
          filter: grayscale(100%);
        }
      }
    }
  }
  &:hover {
    .boxCol {
      opacity: .5;
      .boxHover {
        box-shadow: none;
      }
      &:hover {
        opacity: 1;
        .boxHover {
          box-shadow: 10px 20px 30px 0 rgba(51, 51, 51, 0.2);
          cursor: pointer;
          transform: scale(1.1,1.1);
          .boxTitle {
            color: blue;
          }
        }
      }
    }
  }
`;

export function loadFooter(elem) {
  if (elem.hasFooter === 'one') {
    return (
      <CardActions>
        <Button size="large" color="primary" style={
          {
            width: '100%',
            backgroundColor: elem.footerColor,
            color: elem.footerTextColor
          }
        }>
          <small>¡Hasta 12 cuotas con Tarjeta Cabal!</small>
        </Button>
      </CardActions>)

  }
  if (elem.hasFooter === 'two') {
    return (
      <CardActions>
        <Button size="large" color="primary" style={
          {
            width: '100%',
            backgroundColor: elem.footerColor,
            color: elem.footerTextColor
          }
        }>
          <small>¡10% de descuento!</small>
        </Button>
      </CardActions>)

  }
}

const PaymentMethodBox = (props) => {
  const [Estado, setEstado] = React.useState("");
  const [Loading, setLoading] = React.useState(false);
  const [Mensaje, setMensaje] = React.useState({
    msj: 'Estamos preparando el pago',
    subMsj: `Sera redireccionado a la pagina seleccionada`,
    type: 'loading',
    action:[],
    
    link: null
  });
  let history = useHistory();

  const handleClick = (e) => {
    setEstado(e.action);
    if (e.action == '/plan-payments') {
      history.push(e.action);
      setMensaje({
        msj: 'Estamos generando el cupon de pago',
        subMsj: `Puede abonarlo en ${[...CashMethods]}`,
        type: 'loading',
        action:[],
        link: null
      });
    }else
    if (e.action == '/cash-payments') {
      setLoading(true);
      setMensaje({
        msj: 'Estamos generando el cupon de pago',
        subMsj: `Puede abonarlo en ${[...CashMethods]}`,
        type: 'loading',
        action:[],
        link: null
      }
      )
      setTimeout(() => {
        setMensaje({
          msj: 'Se genero el cupon de pago',
          subMsj: `Puede abonarlo en ${[...CashMethods]}`,
          type: 'succes',
          action:[{
            action: 'pdf.pdf',
            actionTxt: 'Descargar',
            type:'primary'},
            {
              action: '/my-taxes',
              actionTxt: 'Volver',
              type:'secondary'}],
          
          link: null
        }
        )
        setLoading(false);
        // window.open(e.link, "_blank") 
      }, 2000);
    }
  }
  const handleClickElectronic = (e) => {
    setLoading(true);
    setEstado('message');
    setMensaje({
      msj: 'Estamos preparando el pago',
      subMsj: `Sera redireccionado a la pagina de  ${e.title}`,
      type: 'loading',
      action: [], 
      link: null
    }
    )
    setTimeout(() => {
      setMensaje({
        msj: `Te redireccionamos a la pagina de  ${e.title}`,
        subMsj: ``,
        type: 'succes',
        action:[
          {
            action: '/my-taxes',
            actionTxt: 'Volver a mis impuestos',
            type:'secondary'}],
       
        link: `${e.link}`
      }
      )
      setLoading(false);
      window.open(e.link, "_blank")
    }, 2000);



  }

  const classes = useStyles({});
  let total = 0;

  props.obligationList.forEach(obl => {
    if (obl.exenta) {
      total = total + (obl.saldoConRecargo * (100 - obl.porcentaje_exencion)) / 100;
    } else {
      total = total + obl.saldoConRecargo;
    }
  });

  return (
    <div className="boxHoverLinks">
      {Estado == '/e-payments' ? <BoxContainer>
        {ElectronicMethods.map((elem, i) => (
          <BoxButton key={i} elevation={2} onClickFc={() => handleClickElectronic(elem)} btnProps={elem} total={total}>  {loadFooter(elem)}</BoxButton>

        ))}
      </BoxContainer> : (
          Estado == 'message' || Estado == '/cash-payments' ? <EndMessage msj={{
            msj: 'Estamos preparando el pago',
            subMsj: `Sera redireccionado a la pagina de  `,
            type: 'loading',
           
          }} loading={Loading} />
            :
            <BoxContainer>
              {FormasDePago.map((elem, i) => (
                <BoxButton key={i} elevation={2} onClickFc={() => handleClick(elem)} btnProps={elem} total={total}>  {loadFooter(elem)}</BoxButton>

              ))}
            </BoxContainer>)}

    </div>);
}

const mapState = state => ({
  config: state.auth.config
})

export default connect(mapState, null)(PaymentMethodBox);