export enum obligationStates {
    DEB = 1,
    PP,
    PSV,
    JUDICIAL,
    NONE,
    PRESENTADA,
    SIN_PRESENTAR,
    INACTIVA,
    RECTIFICADA
}

export const obligationStateCodes = {
    1: 'DEB',
    2: 'PP',
    3: 'PSV',
    4: 'JUDICIAL',
    5: 'NONE',
    6: 'presentada',
    7: 'sinPresentar',
    8: 'inactiva',
    9: 'rectificada'
}