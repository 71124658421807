import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    border:theme.superficies.border,
    boxShadow:theme.shadows[theme.superficies.elevation]
  },


}));

const ApplicationListMobile = (props) => {
  const classes = useStyles();
 


  return (
    <ExpansionPanel
    className={classes.root}
      expanded={props.expanded}
      onChange={props.Change}

    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={props.title}
        id={props.id}
      >
        <Typography variant='subtitle1'>{props.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{props.children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ApplicationListMobile;
