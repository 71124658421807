import { styled } from '@material-ui/core/styles';

import  Btn from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';

const Button = styled(Btn)({
borderRadius:'50px'})





export default Button;
export { IconButton, Fab };
