// import { Accordion} from "@material-ui/core/Accordion";
// import AccordionDetails from "@material-ui/core/AccordionDetails";
// import AccordionSummary from "@material-ui/core/AccordionSummary";
import {
  ExpansionPanel as Accordion,
  ExpansionPanelDetails as AccordionDetails,
  ExpansionPanelSummary as AccordionSummary,
} from "@material-ui/core";

import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Axios, { Method } from "axios";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getDependenciesCollapse, getUrl, getAsyncData } from "./Operations";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import { DialogActions } from "@material-ui/core";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
const formats = {
  number: (val) => {
    return val;
  },
  date: (val) => {
    return moment(val).format("DD/MM/YYYY");
  },
  money: (val) => {
    return "$" + val.toFixed(2);
  },
  text: (val) => {
    return val;
  },
  bold: (val) => {
    return <b>{val}</b>;
  },
};
const CollapseField = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    meta: { touched, invalid, error },
    name,
    input,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const theme = useTheme();
  const context = useContext(ctxts);
  //#region //! actualizar  urls
  //? con estas dependencias y useEffect recalculo las urls
  //? solo cuando cambien los valores de los inputs que la url requiere
  const mountedRef = useRef(true);
  const [dataUrl, setDataUrl] = React.useState("");
  const [collapseData, setCollapseData] = React.useState(null);
  const dependencias = getDependenciesCollapse(input, inputelement);

  const [loading, setLoading] = React.useState(false);
  const [errorResponse, setErrorResponse] = React.useState(null);
  const [waitingResponse, setWaitingResponse] = React.useState(false);
  useEffect(() => {
    if (inputelement.collapse.urlApi) {
      setDataUrl(
        getUrl(
          input.name,
          inputelement.collapse.urlApi,
          props.cuit,
          values,
          null,
          props.tramiteId,
          inputelement.collapse.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [
    ...dependencias.map((d) => {
      const dependenciaValue = _.get(values, d);
      return dependenciaValue ? dependenciaValue : "";
    }),
  ]);
  //#endregion
  //#region //?traer datos asincronos
  useEffect(() => {
    if (
      dataUrl &&
      dataUrl !== "" &&
      !(dataUrl.includes("[") || dataUrl.includes("{"))
    ) {
      setLoading(true);
      Axios({
        method: "get" as Method,
        url: dataUrl,
        headers: { Authorization: `Bearer ${props.jwt}` },
      })
        .then((response) => {
          if (!mountedRef.current) return null;
          setCollapseData(
            _.get(response, inputelement.collapse.nodoRaizAcordions)
          );
          setLoading(false);
        })
        .catch(function (error) {
          if (!mountedRef.current) return null;
          setLoading(false);
          console.log(error.message);
          // return null;
        });
    }
  }, [dataUrl]); //se ejecuta solo si la url cambia
  //#endregion
  const [expanded, setExpanded] = React.useState(null);
  const showActionBtn = inputelement.collapse.hasAction;
  const nodoTitle = inputelement.collapse.nodoTitle || "";
  const subtitule = inputelement.collapse.subtitule || {
    nodoSubtitle: "",
    format: "text",
  };
  const actionLabel = inputelement.collapse.actionLabel || "pagar";
  const nodoTableData =
    inputelement.collapse.table.nodoTableData || "obligaciones";
  const tableColumns = inputelement.collapse.table.tableColumns || [];
  // const accordions = _.get(response, nodoRaizAcordions);
  const actionMethod = inputelement.collapse?.action?.method
    ? inputelement.collapse.action.method
    : "get";
  const actionUrl = inputelement.collapse?.action?.url
    ? inputelement.collapse.action.url
    : "";

  const actionBaseUrl = inputelement.collapse?.action?.baseUrl
    ? inputelement.collapse.action.baseUrl
    : "";
  const thenUrl = inputelement.collapse?.action?.then?.url
    ? inputelement.collapse?.action?.then?.url
    : "";
  const thenBaseUrl = inputelement.collapse?.action?.then?.baseUrl
    ? inputelement.collapse?.action?.then?.baseUrl
    : "";
  const handleChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const doAction = (e, data, idx) => {
    e.stopPropagation();
    setErrorResponse(null);
    setWaitingResponse(true);
    Axios({
      method: actionMethod as Method,
      url: getUrl(
        input.name,
        actionUrl,
        props.cuit,
        values,
        null,
        props.tramiteId,
        actionBaseUrl,
        context.BaseUrls
      ),
      headers: { Authorization: `Bearer ${props.jwt}` },
      data: data,
    })
      .then((response) => {
        if (!mountedRef.current) return null;
        window.open(
          getUrl(
            input.name,
            thenUrl,
            props.cuit,
            response,
            null,
            props.tramiteId,
            thenBaseUrl,
            context.BaseUrls
          ),
          "_blank"
        );
        setWaitingResponse(false);
      })
      .catch(function (error) {
        if (!mountedRef.current) return null;
        console.log({ [idx]: error.message });
        setErrorResponse({ [idx]: error.message });
        setWaitingResponse(false);
      });
  };
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (collapse) => {
    if (inputelement.collapse?.action?.body) {
      return inputelement.collapse?.action?.body.reduce((acc, k) => {
        return {
          ...acc,
          [k.key]: k.data === "null" ? null : _.get(collapse, k.data),
        };
      }, {});
    } else {
      return {};
    }
  };
  if (loading) {
    return <p>Buscando información...</p>;
  }
  return (
    <div>
      {Array.isArray(collapseData) && collapseData.length > 0 ? (
        collapseData?.map((accordion, idx) => (
          <Accordion
            key={"acc" + idx}
            expanded={expanded === "panel" + idx}
            onChange={handleChange("panel" + idx)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"panel" + idx + "bh-content"}
              id={"panel" + idx + "bh-header"}
            >
              <div className={classes.collapseHeader}>
                <Typography className={classes.heading}>
                  {accordion[nodoTitle] ? accordion[nodoTitle] : "Titulo"}
                  <br />
                  <span className={classes.secondaryHeading}>
                    {accordion[subtitule.nodoSubtitle] &&
                    formats[subtitule.format]
                      ? formats[subtitule.format]?.(
                          accordion[subtitule.nodoSubtitle]
                        )
                      : accordion[subtitule.nodoSubtitle]}
                  </span>
                </Typography>
                <div className={"text-right"}>
                  {showActionBtn && (
                    <Button
                      disabled={waitingResponse}
                      onClick={(e) => doAction(e, getData(accordion), idx)}
                    >
                      {actionLabel ? actionLabel : "Accion"}
                    </Button>
                  )}
                  {errorResponse && errorResponse[idx] && (
                    <small className="text-danger d-block">
                      {errorResponse[idx]}
                    </small>
                  )}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ maxWidth: "100%", margin: "auto" }}>
                <TableContainer>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        {tableColumns.map((column) => (
                          <TableCell key={column.title}>
                            {column.title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {accordion[nodoTableData]?.map((row, i) => (
                        <TableRow key={row.name + "-" + i}>
                          {tableColumns.map((column) => (
                            <TableCell
                              component="th"
                              scope="row"
                              key={"data-" + column.title}
                            >
                              {row[column.field] !== null &&
                              row[column.field] !== undefined
                                ? formats[column.type]
                                  ? formats[column.type]?.(row[column.field])
                                  : row[column.field]
                                : "undefined"}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>{" "}
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <p>
          {inputelement?.collapse?.noInformationMsg
            ? inputelement?.collapse?.noInformationMsg
            : "No encontramos datos"}
        </p>
      )}
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = () => ({});

export default connect(mapState, mapDispatch)(CollapseField);
