export default {
    state: {
        procedureForm: {}
    },
    reducers: {
        setProcedureValue(state, procedureField, procedureValue){
            let newProcedureForm = {...state.procedureForm}
            newProcedureForm[procedureField] = procedureValue;
            return Object.assign({}, {...state, procedureForm:newProcedureForm})
        },
        resetProcedureForm(state){
            return Object.assign({}, {...state, procedureForm:{}})
        }
    },
    effects: {},
}