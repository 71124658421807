const ChatService = (instance) => {

    const createChat = (entidad: string, clave: string) => instance.post(`/mensajes`, {entidad: entidad, clave: clave});
    const closeChat = (entidad: string, clave: string) => instance.delete(`/mensajes/${clave}/${entidad}`);
    const getChat = (entidad: string, clave: string) => instance.get(`/mensajes/${clave}/${entidad}`);
    const saveMessages = (entidad: string, clave: string, mensajes: Array<any>) => instance.put(`/mensajes/${clave}/${entidad}`, {mensajes: mensajes});

    return {
        createChat,
        closeChat,
        saveMessages,
        getChat
    };

};
export default ChatService;
