import React from 'react';
import ObligationCheckbox from "../ObligationCheckbox/ObligationCheckbox";
import { connect } from 'react-redux';
import NumberCurrency from '../../../common/NumberCurrency';
import './styles.css';
import moment from 'moment';
import 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { Checkbox, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import JuicioTags from '../JuicioTags';
import ModalJuicio from '../ModalJuicio';
import TaxItem from '../TaxItem/TaxItem';
import { Grid } from "@material-ui/core";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '../../../common/Accoderon';
import MoreButton from '../MoreButton';
import TagsArray from '../TagsArray';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';


const getTotalCapital = (obligations) => {
    let total = 0;
    obligations.forEach(obligation => {
        if(obligation.cuota != '0'){
            if(obligation.exenta){
                total = total + ((obligation.saldo  * (100 - obligation.porcentaje_exencion)) / 100);
            } else {
                total = total + obligation.saldo;
            }
        }
    })
    return total;
}

const JuicioItem = (props) => {
    const [showModal, setShowModal] = React.useState(false);

    const getTotal = (obligations) => {
        let total = 0;
        obligations.forEach( ob => {
                if(ob.exenta){
                    total = total + (ob.saldoConRecargo * ((100 - ob.porcentaje_exencion)) / 100);
                } else {
                    total = total + ob.saldoConRecargo;
                }
        })
        return total;
    }

    let juicioToRender;
    let fechaDemanda = moment(props.juicios.filter( j => j.id == props.o.id).inicio_demanda).format('DD/MM/YYYY');
    props.juicios.forEach( (juicio) => {if(juicio.id == props.o.id){ juicioToRender = juicio }})

    let totalToPay = 0;
    props.o.obligaciones.forEach( ob => {
        if(ob.exenta){
            totalToPay = totalToPay + (ob.saldoConRecargo * ((100 - ob.porcentaje_exencion)) / 100);
        } else {
            totalToPay = totalToPay + ob.saldoConRecargo;
        }
    })
    
    if(juicioToRender){
    totalToPay = totalToPay + juicioToRender.gastos;
    totalToPay = totalToPay + juicioToRender.honorarios;
    fechaDemanda = moment(juicioToRender.inicio_demanda).format('DD/MM/YYYY');
    }

    const doShowModal = () => {
        setShowModal(true);
    }

    const hideModal = () => {
        setShowModal(false);
    }

    return (
        <Grid item xs={12} key={props.key}>
                <ExpansionPanel elevation={0} className={'SubAcordion ' + 'SubAcrodionTipo_'+props.o.tipo}>
                    <ExpansionPanelSummary  className='SubAcordionHeader'>
                        <Grid item xs={12} className='SubAcordionTitle'> 
                            <div className="row justify-content-between">
                            <div className="col-auto columnExpand">
                                   <ChevronRightOutlinedIcon className='expandIcon'/>
                                                               </div>
                                <div className="col-auto">
                                    <b>Juicio {props.o.objeto_id ? props.o.objeto_id : props.o.id}</b><IconButton size='small' onClick={doShowModal}><SearchIcon/></IconButton>
                                    <small><br/>Fecha de demanda: {fechaDemanda}</small>
                                </div>
                                <div className="col">
                                         {props.cuit === props.o.sujeto_id ? <TagsArray object={props.o} /> : null}
                                </div>
                                <div className="col-auto">
                                    <div className="row justify-content-end">
                                        <b>Subtotal:</b><div style={{paddingLeft: "5px"}}>{<NumberCurrency value={getTotal(props.o.obligaciones).toFixed(props.config.decimals)}/>}</div><br/>
                                    </div>
                                    <div className="row justify-content-end">
                                        {props.showCapitalDebt ? <small><NumberCurrency value={getTotalCapital(props.o.obligaciones).toFixed(props.config.decimals)} /></small> : null}
                                    </div>
                                </div>
                                 <div className="col-auto">                  
                                </div>
                            </div>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="row justify-content-end">
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                {<ModalJuicio Juicio={juicioToRender} Obligations={props.o.obligaciones} ToPay={getTotal(props.o.obligaciones)}
                open={showModal}
                handleClose={hideModal}
                onClose={hideModal}
                alredyFiltered>
                </ModalJuicio>}
            </Grid>
    )
}
const mapState = state => ({
    config: state.auth.config,
    showCapitalDebt: state.payments.showCapitalDebt,
    juicios: state.payments.allJudgments,
    trialsConfig: state.payments.trialsConfig
})

export default connect(mapState, null)(JuicioItem);