import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';


const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
    container: {
      marginBottom: '50px'
    },
    tableCell: {
      padding: '5px 16px'
    },
    tableHead: {
      background: '#efefef'
    },
    falsoTexto: {
  
      width: '63%',
      height: '15px',
      borderRadius: '15px',
      display: 'inline-block',
      background: '#dadada',
  
    },
    tabs:{
      background:'white'
    },
    tableRow: {
      cursor: 'pointer',
      '&:hover': {
        background: '#f3f3f3'
      }
    }
    ,tabsContainer:{
          boxShadow:' inset 3px -1px 2px 0px #dddddd8c',
      background:' #f7f7f7'}
    
  });


interface ContactsTableProps {
    children?: React.ReactNode;
    index: any;
   
    value: any;
    
  }
  
  

  function ContactTabPanel(props:ContactsTableProps) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const { children, value, index, ...other } = props;
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    const classes= useStyles();
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={0}>
        {children}
          </Box>}
      </div>
    );
  }
  export default ContactTabPanel;