import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import './styles.css';
import {obligationStateCodes, obligationStates} from "../../../../constants/obligationStates";

function ObligationTags(props) {
    const expirationDate = moment(props.obligation.vencimiento).add(3, 'hours');
    const isExpired = moment(expirationDate.format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD'));
    const isExent = props.obligation.exenta;
    const renderTags = () => {
        const tagsToRender = [];
        props.tags.map( (tag, i) => { // PRESET TAGS FILTERS
            const tagConditions = [
                tag.isExpired && isExpired,
                tag.isNotExpired && !isExpired,
                tag.isExent && isExent,
                tag.isInDebit && props.obligation.estado === obligationStateCodes[obligationStates.DEB]
            ]
            if( tagConditions.includes(true)) {
                tagsToRender.push(<span key={i} className={"estadoCuota"} style={{background: tag.color}}>{tag['name_' + props.locale.toUpperCase()]}</span>)
            }
        })

        return tagsToRender;
    }

    return(<div>{renderTags()}</div>);
}

const mapState = state => ({
    config: state.auth.config,
    tags: state.tags.tags,
    locale: state.auth.locale,
})

export default connect(mapState, null)(ObligationTags);