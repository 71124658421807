import React from "react";

const Badge = (props) => {
    return <span style={{
            background: '#ff4925',
            color: 'white',
            margin: '0px 3px',
            padding: '0 6px',
            borderRadius: '10px',
            display: 'inline-flex',
            fontSize: '12px',
            lineHeight: '12px',
            height: '20px',
            flexDirection: 'column',
            justifyContent: 'center',
        }}>{props.text}</span>;
}

export default Badge;