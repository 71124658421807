import React from 'react';
import Contacts from '../../components/MyContacts/';
import Breadcrumbs from "../../components/common/MaterialComponents/Breadcrumbs";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
const MyContacts = props => {
    return (
        <div >
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/dashboard">Inicio</Link>
  
          <Typography color="textPrimary"> Mis Contactos</Typography>
        </Breadcrumbs>
    
    <Contacts ></Contacts>
    </div>)
}

export default MyContacts