export default {
    state: {
        languages: []
    },
    reducers: {
        setLanguages(state, languagesList){
            return Object.assign({}, {...state, languages:languagesList})
        },
    },
    effects: {},
}