import {useField} from "react-final-form";
import React, {useEffect, useRef} from "react";
import {useStyles} from "./styles";
import {InputElement} from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import {connect} from 'react-redux';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useTheme} from '@material-ui/core/styles';
const fullWidthOp = false;

const Dropdowns = (props) => {
    const classes = useStyles();
    const inputelement: InputElement = props.inputelement;
    const {
        label,
        input,
        meta: {touched, invalid, error},
        name,
        meta,
        values,
        ...custom
    } = props;



    const theme=useTheme();


    const [value, setValue] = React.useState(meta.initial ? meta.initial : "");
   // const [options, setOptions] = React.useState([]);


    const handleChange = (event) => {
       // console.log("handleCHange" + event.target.value);
      //  setValue(event.target.value);
        setValue(event.target.value as string);
        input.onChange(event.target.value as string);
    };

    const onChange = (event : any) => {
       // props.setProcedureValue(input.label,event.target.value);
        setValue(event as string);
    }

    const select_handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
        input.onChange(event.target.value as string);
    };

    return (
        <FormControl
            variant="outlined"
            component="fieldset"
            {...custom}
            fullWidth={fullWidthOp}
            error={touched && invalid}
            className={classes.formcontrol}
        >
            <FormLabel component="legend">{inputelement.label}</FormLabel>
            <Select 
             variant={theme.inputs.variant?theme.inputs.variant : 'standard' }    className={classes.selects} labelId="demo-simple-select-outlined-label" onChange={handleChange}>
                {inputelement.options.map(option => (
                    <MenuItem key={option.id} value={option.value} id={option.id}>
                        {option.text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );



};

const mapState = state => ({
    cuit: state.auth.cuit
});

const mapDispatch = dispatch => ({})


export default connect(mapState, mapDispatch)(Dropdowns);