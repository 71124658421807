import React, { useEffect, useState } from 'react';
import PaymentMethodBox from "../../components/PaymentMethods/PaymentMethodBox";
import ObligationList from "../../components/PaymentMethods/ObligationList";
import { Divider } from '@material-ui/core';
import GoBackButton from '../../components/PaymentMethods/GoBackButton';
import AddMoreButton from '../../components/PaymentMethods/AddMoreButton';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import  EndMessage  from '../../components/PaymentMethods/EndMessage';

const ElectronicPayment = (props) => {
    const getObligationsToPay = () => {
        let obligationsList = [];
        props.allObjects.forEach(object => obligationsList = [...obligationsList, ...object.obligaciones])
        return obligationsList;
    }
    if (props.data === null || props.data.length === 0) {
        return (
            <div>
                <p></p>
                <h3>No has seleccionado ninguna obligacion para pagar</h3>
                <p></p>
                <h4><small>Vuelve a "Mis Impuestos" para seleccionar al menos una obligacion</small></h4>
                <p></p>
                <GoBackButton to="mis-impuestos" />
            </div>
        )
    } else {
        return (
            <div style={{ marginBottom: '30px' }}>
                <div className="col col-title">
                    <h4 className='taxesTituloMiga'><FormattedMessage id="paymentMethod.select" defaultMessage="Seleccioná el medio de pago electronico" /></h4>
                </div>
                    <PaymentMethodBox obligationList={props.data} />
                <div className="taxesScreen">
                    <div className="taxesHeader">
                        <div className="row">
                            <div className="col col-title">
                                <h4 className='taxesTituloMiga'><FormattedMessage id="paymentMethod.youArePaying" defaultMessage="Obligaciones a pagar" /></h4>
                            </div>
                            <div className="col  col-title">
                                <div className='verdeudalabel'>
                                    {
                                        props.data.length === getObligationsToPay().length ?
                                            null
                                            :
                                            <AddMoreButton to="mis-impuestos" />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <ObligationList obligationList={props.data} />
                    <Divider />
                    <GoBackButton to="mis-impuestos" />
                </div>
                
                    
            </div>);
    }
}
const mapState = state => ({
    data: state.payments.selectedObligations,
    allObjects: state.payments.allObjects
})



export default connect(mapState, null)(ElectronicPayment);
