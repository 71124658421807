export enum objectTypes {
    inmobiliario = 1,
    automotor,
    ingresosBrutos,
    multas,
    embarcaciones
}

export const objectTypeCodes = {
    1: 'I',
    2: 'A',
    3: 'E',
    4: 'M',
    5: 'EMq'
}