import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TaskServices from "../../services/TaskServices";
import SeccionHeader from "../../components/common/SeccionHeader";
import MyTasksList from "../../components/MyTasksList";
import { FormattedMessage } from "react-intl";
import TableSkeleton from "../../components/common/TableSkeleton";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "../../components/common/MaterialComponents/Breadcrumbs";
import { Link } from "react-router-dom";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import FormControl from "@material-ui/core/FormControl";
import { IconButton } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
// import IconButton from '@mui/material/IconButton';
// import PhotoCamera from '@mui/icons-material/PhotoCamera';
const MyTasks = (props) => {
  const [allTasks, setAllTasks] = useState([]);
  const [filterValue, setfilterValue] = useState("");
  const [loadingApplications, setLoadingApplications] = React.useState(true);
  const [parsedJwt, setParsedJwt] = useState(null);
  const [searcheables, setSearcheables] = React.useState([
    "sujeto_id",
    "estado.proceso",
    "cuit",
    "fechaInicio",
    "id",
    "tipo",
    "datos_adicionales.asignado",
    "datos_adicionales.grupo",
    "datos_adicionales.asignadoNombre",
    "paramTramite.nombre",
  ]);
  const [loadingText, setLoadingText] = React.useState(
    <FormattedMessage
      id={"contacts.loadingText"}
      defaultMessage={"Cargando..."}
    />
  );

  const handleChange = (e) => {
    setfilterValue(e.target.value);
  };
  const getTasks = async () => {
    setParsedJwt(parseJwt(props.jwt));

    setLoadingApplications(true);
    TaskServices()
      .getTasks(props.jwt)
      //.getTasks('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTUsImdydXBvIjpbInNpc3RlbWFzIiwic2VjcmV0YXJpYSJdLCJpYXQiOjE1OTM2OTM2NjUsImV4cCI6MTU5NjI4NTY2NX0.FmvGX84C0S6-nrhfdPvEVZy5lnp-a64HlyDVmByL1-g')
      .then((response) => {
        setAllTasks(
          response.data.map((el) => {
            return {
              id: el.tramiteId,
              estado: el.estado[el.estado.length - 1],
              fechaInicio: el.fechaCreacion,
              datos_adicionales: el.datosAdicionales,

              tipo: el.tipoTramiteInfo.tipo,

              sujeto_id: el.sujetoId,
              paramTramite: el.tipoTramiteInfo,
            };
          })
        );

        setLoadingApplications(false);
      })
      .catch(() => {
        return true;
      })
      .finally(() => {
        return true;
      });
  };
  useEffect(() => {
    getTasks();
  }, []);

  // const search=(alltask)=>{
  //     return alltask.filter((t)=> JSON.stringify(t).)

  // }
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const search = (data) => {
    if (!filterValue) {
      return data;
    }
    return data.filter((el) => {
      let exist = false;
      searcheables.forEach((searchKey) => {
        let filterObject = _.get(el, searchKey);
        if (searchKey === "id") {
          filterObject = filterObject.slice(-6, filterObject.length);
        }
        if (
          filterObject &&
          JSON.stringify(filterObject)
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) > -1
        )
          exist = true;
        if (exist) return exist;
      });

      return exist;
    });
  };

  if (loadingApplications) {
    return (
      <div>
        <SeccionHeader
          title={
            <FormattedMessage id="Tasks.title" defaultMessage="Mis Tareas" />
          }
        ></SeccionHeader>
        <TableSkeleton
          loadingApplications={loadingApplications}
          loadingText={loadingText}
          cantCols={6}
          cantRows={3}
        />
      </div>
    );
  } else {
    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/agent-dashboard">Dashboard</Link>
          <Typography color="textPrimary">Mis tareas</Typography>
        </Breadcrumbs>

        <SeccionHeader
          title={
            <FormattedMessage id="Tasks.title" defaultMessage="Mis Tareas" />
          }
          refresh={getTasks}
          refreshing={loadingApplications}
        >
          <FormControl variant="outlined">
            <TextField
              label="Filtrar"
              id="outlined-size-small"
              onChange={handleChange}
              style={{ marginBottom: "5px" }}
              variant="outlined"
              size="small"
            />
          </FormControl>
        </SeccionHeader>
        {parsedJwt && parsedJwt?.grupo?.length <= 0 ? (
          <div className="my-2">
            <Alert severity="error">En el token no hay grupo asignado</Alert>
          </div>
        ) : null}
        <MyTasksList
          // setCompleting={setCompleting}
          reload={() => getTasks()}
          data={search(allTasks)}
        />
      </div>
    );
  }
};

const mapState = (state) => ({
  jwt: state.auth.jwt,
});

export default connect(mapState, null)(MyTasks);
