import React, { useEffect } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import PaymentSearch from "../Components/PaymentSearch";
import { automotorMock, IBMock } from "../../../mock/publicInfo";
import { FormattedMessage } from "react-intl";
import SeccionHeader from "../../common/SeccionHeader";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link, useParams } from "react-router-dom";
import apiBUC from "../../../services/BUCServices";
import { connect } from "react-redux";
import { obligationSatesMock, publicObjectMock } from "../../../mock/data";
import PaymentsBody from "../PaymentsBody";
import { Grid } from "@material-ui/core";
import NumberCurrency from "../../common/NumberCurrency/NumberCurrency";
import PayButton from "../../MyTaxes/Taxes/PayButton/PayButton";
import PayAllButton from "../../MyTaxes/Taxes/PayAllButton/PayAllButton";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "5px auto",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
  taxesFooter: {
    color: "white",
    position: "sticky",

    bottom: "0",
    width: "100%",
    zIndex: 99,
    left: "0",
    padding: "30px",
    background: "#0584ce",
  },
  test: {
    minHeight: "70vh",
    marginBottom: "80px",
  },
});

const PaymentsPublic = (props) => {
  let { type, code } = useParams();
  const classes = useStyles();
  const [objeto, setObjeto] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [TextsConfig, setTextsConfig] = React.useState({
    FormatedMsj: "public.payments.default",
    SearchLabel: "Identificador",
    friendlyType: "",
    SearchPlaceholder: "",
  });

  const handleSearch = (objetoId) => {
    setIsLoading(true);
    setInterval(() => setIsLoading(false), 600);

    apiBUC.SubjectServices.getPublicObjectObligations(objetoId.toUpperCase())
      .then((response) => {
        if (response.data.tipo == code) {
          setObjeto(response.data);
          props.setAllObjects([response.data]);
        } else {
          setObjeto(null);
          props.setAllObjects([]);
        }
      })
      .catch(() => setObjeto(null));
  };

  useEffect(() => {
    props.setObligationStates(obligationSatesMock);

    switch (type.toString()) {
      case "automotor":
        setTextsConfig({
          FormatedMsj: "public.payments.automotor",
          SearchLabel: "Patente",
          friendlyType: "Automotor",
          SearchPlaceholder: "ABC123,  AB123CF, ...",
        });
        break;
      case "inmobiliario":
        setTextsConfig({
          FormatedMsj: "public.payments.inmobiliario",
          SearchLabel: "Partida",
          friendlyType: "Inmobiliario",
          SearchPlaceholder: "1345675,  842695, ...",
        });
        break;
      case "embarcaciones":
        setTextsConfig({
          FormatedMsj: "public.payments.embarcaciones",
          SearchLabel: "Partida",
          friendlyType: "Embarcaciones",
          SearchPlaceholder: "1345675,  842695, ...",
        });
        break;
      case "ingresosbrutos":
        setTextsConfig({
          FormatedMsj: "public.payments.ingresosbrutos",
          SearchLabel: "Cuit",
          friendlyType: "Ingresos brutos",
          SearchPlaceholder: "20-35609264-5,  30-25236883-1, ...",
        });
        break;
      default:
        setTextsConfig({
          FormatedMsj: "public.payments.default",
          SearchLabel: "Identificador",
          friendlyType: "",
          SearchPlaceholder: "",
        });
        break;
    }
  }, [type]);
  useEffect(() => {
    props.setConfig({
      decimals: 2,
      currencySymbol: "$",
    });
    props.clearObligations();
    props.toogleShowPayAll(true);
  }, []);
  const totalDebt = () => {
    let total = 0;
    if (objeto) {
      objeto.obligaciones.forEach((obligation) => {
        if (obligation.cuota != "0") {
          if (obligation.exenta) {
            total =
              total +
              (obligation.saldoConRecargo *
                (100 - obligation.porcentaje_exencion)) /
                100;
          } else {
            total = total + obligation.saldoConRecargo;
          }
        }
      });
    }
    return total;
  };

  const stickyFotter = () => {
    if (props.showPayAll && objeto && objeto.obligaciones.length > 0) {
      return (
        <div className={classes.taxesFooter}>
          <div className="row justify-content-between">
            <div
              className="row justify-content-start"
              style={{ marginLeft: "25px" }}
            >
              <b>
                <FormattedMessage
                  id="myTaxes.totalDebt"
                  defaultMessage="Deuda total"
                />
                :
              </b>
              <div style={{ paddingLeft: "5px" }}>
                <NumberCurrency
                  value={totalDebt().toFixed(props.config.decimals)}
                />
              </div>
            </div>
            <div
              className="row justify-content-end"
              style={{ marginRight: "10px" }}
            >
              <div className="col-auto">
                {props.totalToPay > 0 ? (
                  <PayButton linkTo="/public-login" amount={props.totalToPay} />
                ) : null}
              </div>
              <div className="col-auto">
                <PayAllButton
                  linkTo="/public-login"
                  size="small"
                  buttonText={
                    <FormattedMessage
                      id="myTaxes.payAllButton.link"
                      defaultMessage="Pagar todas las obligaciones"
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div>
        <SeccionHeader
          title={
            <FormattedMessage
              id={TextsConfig.FormatedMsj + ".title"}
              defaultMessage={
                "Consulte y pague su impuesto " + TextsConfig.friendlyType
              }
            />
          }
          leftIcon={
            <Link className={classes.link} to={"/home"}>
              <IconButton aria-label="volver">
                <ArrowBackIcon />
              </IconButton>
            </Link>
          }
        />
        <Container className={classes.test} maxWidth="md">
          <PaymentSearch
            label={
              <FormattedMessage
                id={TextsConfig.FormatedMsj + ".searchLabel"}
                defaultMessage={TextsConfig.SearchLabel}
              />
            }
            onClick={handleSearch}
            placeholder={TextsConfig.SearchPlaceholder}
          />
          <PaymentsBody isLoading={isLoading} objeto={objeto}></PaymentsBody>
        </Container>
        {stickyFotter()}
      </div>
    </>
  );
};

const mapDispatch = (dispatch) => ({
  setObligationStates: (states) =>
    dispatch.payments.setObligationStates(states),
  toogleShowPayAll: (value) => dispatch.payments.toogleShowPayAll(value),
  clearObligations: () => dispatch.payments.clearObligations(),
  setConfig: (config) => dispatch.auth.setConfig(config),
  setAllObjects: (objectList) => dispatch.payments.setAllObjects(objectList),
});

const mapState = (state) => ({
  totalToPay: state.payments.totalToPay,
  config: state.auth.config,
  showPayAll: state.payments.showPayAll,
});

export default connect(mapState, mapDispatch)(PaymentsPublic);
