export default {
    state: {
        selectedObligations: [],
        allObjects: [],
        renderedObjects:[],
        renderedThirdPartObjects:[],
        allDeclarations:[],
        allJudgments:[],
        totalToPay: 0,
        showAlert: true,
        showPayAll: false,
        showCapitalDebt: false,
        thirdPartObjects: [],
        loadingObjects: true,
        filteringObjects: false,
        obligationStates: [],
        obligationStateActions: [],
        trialsConfig: [],
        tagsInUse: [],
        obligationsForSimulation: []
    }, // initial state
    reducers: {
        clearObligationsForSimulation(state){
            return Object.assign({}, {...state, obligationsForSimulation: []})
        },
        setObligationsForSimulation(state, objects, isChecked){
            const newObligations = state.obligationsForSimulation;
            if(isChecked){
                newObligations.push(objects)
                return Object.assign({}, {...state, obligationsForSimulation: newObligations})
            } else {
                return Object.assign({}, {...state, obligationsForSimulation: newObligations.filter(x => x.id !== objects.id)})
            }
        },
        setThirdPartObjects(state, objects){
            return Object.assign({}, {...state, thirdPartObjects:objects});
        },
        setTrialsConfig(state, config){
            return Object.assign({}, {...state, trialsConfig:config});
        },
        setTagsInUse(state, tags){
            return Object.assign({}, {...state, tagsInUse:tags})
        },
        addJudgment(state, judgment){
            let newAllJudgments = state.allJudgments;
            const found = newAllJudgments.some(ob => ob.id === judgment.id);
            if (!found) newAllJudgments.push(judgment);
            return Object.assign({}, {...state, allJudgments:newAllJudgments})
        },
        clearObligations(state) {
            return Object.assign({}, {...state, selectedObligations:[], selectedObjects:[], totalToPay: 0, showAlert: true})
        },
        modifyObligation(state, checkedObligation, isCheked) {
            const newSelectedObligations = state.selectedObligations;
            let totalToPay = 0;
            if(checkedObligation.exenta){
                totalToPay = (checkedObligation.saldoConRecargo * (100 - checkedObligation.porcentaje_exencion)) / 100
            } else {
                totalToPay = checkedObligation.saldoConRecargo;
            }
            if(isCheked){
                newSelectedObligations.push(checkedObligation)
                return Object.assign({}, {...state, selectedObligations: newSelectedObligations, totalToPay: state.totalToPay += totalToPay})
            } else {
                return Object.assign({}, {...state, selectedObligations: newSelectedObligations.filter(x => x.id !== checkedObligation.id), totalToPay: state.totalToPay -= totalToPay})
            }
        },
        sumAllObligations(state){
            return Object.assign({}, {...state, totalToPay: state.selectedObligations.reduce((a, b) => a + (b.saldoConRecargo || 0), 0)})
        },
        toogleShowAlert(state){
            return Object.assign({}, {...state, showAlert: !state.showAlert})
        },
        toggleShowCapitalDebt(state){
            return Object.assign({}, {...state, showCapitalDebt: !state.showCapitalDebt})
        },
        toogleShowPayAll(state, value){
            return Object.assign({}, {...state, showPayAll: value})
        },
        setAllObligations(state, obligationList){
            return Object.assign({}, {...state, selectedObligations: obligationList})
        },
        setAllObjects(state, objectList){
            return Object.assign({}, {...state, allObjects: objectList})
        },
        setAllDeclarations(state, declarationList){
            return Object.assign({}, {...state, allDeclarations: declarationList})
        },
        setAllJudgments(state, judgmentList){
            return Object.assign({}, {...state, allJudgments: judgmentList})
        },
        setRenderedObjects(state, selectedObject){
            return Object.assign({}, {...state, renderedObjects: selectedObject})
        },
        setRenderedThirdPartObjects(state, selectedObject){
            return Object.assign({}, {...state, renderedThirdPartObjects: selectedObject})
        },
        setLoadingObjects(state, loading){
            return Object.assign({}, {...state, loadingObjects: loading})
        },
        setFilteringObjects(state, loading){
            return Object.assign({}, {...state, filteringObjects: loading})
        },
        setObligationStates(state, states){
            return Object.assign({}, {...state, obligationStates: states})
        },
        setObligationStateActions(state, statesActions){
            return Object.assign({}, {...state, obligationStateActions: statesActions})
        }
    },
    effects: {},
}