import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Table from "../../common/Table";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import MyAffidavitModal from "../MyAffidavitModal";
import Button from "@material-ui/core/Button";
import { format } from "date-fns";
import NumberCurrency from "../../common/NumberCurrency";
import { useHistory } from "react-router-dom";
import { affidavitStates } from "../../../constants/applications";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  fullWidth: {
    width: "100%",
  },
});

const MyAffidavitdList = (props) => {
  const classes = useStyles();
  const [isOpen, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(null);
  props.data.map((affidavit) => (affidavit.cuota = parseInt(affidavit.cuota)));
  const history = useHistory();

  const handlerSelect = (r) => {
    setSelected(r);
    setOpen(true);
  };

  const handleClose = () => {
    setSelected(null);
    setOpen(false);
  };

  //Defino columnas de la tabla y como quiero que se rendericen
  //TODO HACER TRADUCCIONES
  const columnas = [
    {
      title: (
        <FormattedMessage id="myAffidavits.form.year" defaultMessage="Año" />
      ),
      field: "periodo",
      width: 50,
    },
    {
      title: (
        <FormattedMessage
          id="myAffidavits.form.installment"
          defaultMessage="Cuota"
        />
      ),
      field: "cuota",
      width: 50,
    },
    {
      title: (
        <FormattedMessage id="myAffidavits.form.type" defaultMessage="Tipo" />
      ),
      field: "tipo",
      width: 150,
      render: (data) => <strong>{data.tipo}</strong>,
    },
    {
      title: (
        <FormattedMessage
          id="myAffidavits.form.state"
          defaultMessage="Estado"
        />
      ),
      width: 80,
      field: "estado",
      render: (data) => (
        <span
          className={"estadoCuota"}
          style={
            data.estado === affidavitStates.presentada
              ? { background: "green" }
              : { background: "red" }
          }
        >
          {data.estado}
        </span>
      ),
    },
    {
      title: (
        <FormattedMessage
          id="myAffidavits.form.expiracy"
          defaultMessage="Vencimiento"
        />
      ),
      field: "vencimiento",
      render: (rowData) => (
        <span> {format(new Date(rowData.vencimiento), "dd/MM/yyyy")} </span>
      ),
    },

    {
      title: (
        <FormattedMessage
          id="myAffidavits.form.declaredIncome"
          defaultMessage="Ingresos declarados"
        />
      ),
      field: "total",
      render: (rowData) => (
        <NumberCurrency value={rowData.total.toFixed(props.config.decimals)} />
      ),
    },
    //TODO ver el valor de la obligacion segun los datos que ingresen (hay que hacer sumatoria?).
    {
      title: (
        <FormattedMessage
          id="myAffidavits.form.tribute"
          defaultMessage="Tributo"
        />
      ),
      field: "impuestoDeterminado",
      render: (rowData) => (
        <NumberCurrency
          value={rowData.impuesto_determinado.toFixed(props.config.decimals)}
        />
      ),
    },
    //TODO ver las acciones de los botones.
    {
      title: (
        <FormattedMessage
          id="myAffidavits.form.actions"
          defaultMessage="Acciones"
        />
      ),
      field: "Acciones",
      headerStyle: { textAlign: "right" },
      cellStyle: { textAlign: "right" },
      render: (rowData) => {
        const handleClickAction = () => {
          history.push({
            pathname:
              "/detalle-tramite/" +
              JSON.stringify({ tramiteId: rowData.tramiteId, readOnly: true }),
          });
        };
        const rectifyAction = () => {
          history.push({
            pathname:
              "/detalle-tramite/" +
              JSON.stringify({
                tramiteId: rowData.tramiteId,
                readOnly: false,
                rectify: true,
              }),
          });
        };
        return rowData.estado !== affidavitStates.inactiva ? (
          <>
            <Button color="primary" onClick={handleClickAction}>
              <FormattedMessage
                id="myAffidavits.showDj"
                defaultMessage="Ver Dj"
              />
            </Button>
            <Button color="primary" onClick={rectifyAction}>
              <FormattedMessage
                id="myAffidavits.rectify"
                defaultMessage="RECTIFICAR"
              />
            </Button>
          </>
        ) : null;
      },
    },
  ];

  return (
    <div>
      <Table
        className={classes.fullWidth}
        columnas={columnas}
        Data={props.data}
        filter={props.filter}
        disabled={(rowData) => true}
        mensajeVacio={
          <FormattedMessage
            id="myAffidavits.emptyMessage"
            defaultMessage="No hay DDJJ para mostrar"
          />
        }
      />
      <MyAffidavitModal
        affidavit={selected}
        isOpen={isOpen}
        handleClose={handleClose}
      />
    </div>
  );
};
const mapState = (state) => ({
  config: state.auth.config,
});

export default connect(mapState, null)(MyAffidavitdList);
