import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from "react-intl";
import NumberCurrency from '../../../common/NumberCurrency';
import { makeStyles } from '@material-ui/core/styles';

interface PlanInfoProps {
    Anticipo: number;
    Cuotas: number;
    ValorCuotas: number;
    Valor: number;
    Incluye: any;
}

const useStyles = makeStyles({
    Title: {
        color: '#8b8b8b',
        textAlign: 'left',
    },
    Valor: {
        color: '#424242',
        fontSize: '35px',
        marginTop: "-12px",
    },
    PlanInfo: {
        textAlign: 'left',
        padding: '8px 60px',
        borderLeft:'solid 1px #d8d8d8'

    },
    msjExtra: {
        color: '#a9a9a9',
        fontSize: '15px',
        fontWeight: 300,
        fontStyle: 'italic',
    }, Block: {
        marginBottom: '10px',
    }
});

const PlanInfo = (props: PlanInfoProps) => {
    const classes = useStyles();
    return (
        <Grid className={classes.PlanInfo} container spacing={2} alignItems="center">
            <Grid className={classes.Block} item xs={12}>
                <div className={classes.Title}><FormattedMessage id={"paymentsPlan.Anticipo"} defaultMessage="Anticipo" /></div>
                <div className={classes.Valor}><NumberCurrency value={props.Anticipo.toFixed(2)} /></div>
            </Grid>
            <Grid className={classes.Block} item xs={12}>
                <div className={classes.Title}>{props.Cuotas}<FormattedMessage id={"paymentsPlan.Cuotas"} defaultMessage=" cuotas de " /> </div>
                <div className={classes.Valor}><NumberCurrency value={props.ValorCuotas.toFixed(2)} /></div>
            </Grid>
            <Grid className={classes.Block} item xs={12}>
                <div className={classes.Title}><FormattedMessage id={"paymentsPlan.Total"} defaultMessage="Total" /></div>
                <div className={classes.Valor}><NumberCurrency value={props.Valor.toFixed(2)} /></div>
                {props.Incluye.map((elem,index) => <div key={index} className={classes.msjExtra}>{elem}</div>)}
            </Grid>
        </Grid>
    );
}
export default PlanInfo;