import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import useLoginForm from "./LoginHooks";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import apiCMS from "../../services/CMSServices";
import { LoginProviders } from "../../constants/loginProviders";
import { mockRepresented } from "../../mock/DefineRepresentedMock";
import apiBUC from "../../services/BUCServices";
import apiPayment from "../../services/PaymentServices";
import apiApplication from "../../services/ApplicationServices";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props) => {
  const classes = useStyles({});
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    apiCMS.AuthServices.getProvider()
      .then((res) => {
        setProvider(res.data);
      })
      .catch((err) => {
        alert(err);
      });

    apiCMS.TaxesServices.getTaxes()
      .then((response) => props.addTaxesTypes(response.data))
      .catch((error) =>
        console.error("can't load taxesTypes: ", error.message)
      );
  }, []);

  const handleLogin = () => {
    switch (provider ? provider.type : null) {
      case LoginProviders.JWT:
        apiCMS.AuthServices.login(inputs.username, inputs.password)
          .then((res) => {
            loginCallback(
              res.data.user.username,
              res.data.jwt,
              res.data.user.cuit,
              res.data.user.tax_role.id,
              res.data.user.name,
              res.data.user.lastName,
              res.data.user.id
            );
          })
          .catch((err) => {
            alert("Usuario o Password incorrecto");
          });
        break;
      case LoginProviders.TDR:
        apiCMS.AuthServices.loginTDR(
          inputs.username,
          inputs.password,
          provider.url,
          provider.data
        )
          .then((res) => {
            const token = res.data.token;
            const datos = parseJwt(token);
            apiCMS.setToken(token);
            props.setAccessToken(res.data.access_token);

            apiCMS.AuthServices.getTaxRoleByCode(datos.usuarioTipoId)
              .then((res) => {
                loginCallback(
                  datos.username,
                  token,
                  datos.cuitRepresentado,
                  res.data[0].id,
                  datos.nombre,
                  datos.apellido,
                  datos.userId,
                  res.data.access_token
                );
              })
              .catch((err) => {
                alert("Usuario o Password incorrecto");
              });
          })
          .catch((err) => {
            alert("Usuario o Password incorrecto");
          });
        break;
        case null:
            alert("No se encontro el login provider");
            break;
        default:
          alert("No se encontro el login provider. El servicio puede no estar disponible");
            break;
    }
  };

  const b64DecodeUnicode = (str) => {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(b64DecodeUnicode(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const loginCallback = (username, jwt, cuit, taxRole, name, lastName, id, accessToken?) => {
    props.clearObligations();
    props.loginSuccess(username, jwt, cuit, name, lastName, id, taxRole, accessToken);
    props.setPublicLogin(false);
    apiCMS.UserMenuServices.getRepresentedUsers(cuit)
      .then((res) => {
        if (!res.data.some((e) => e.cuit === cuit)) {
          res.data.push({
            username: username,
            cuit: cuit,
            denominacion: name + " " + lastName,
          });
        }
        props.setRepresentedList(res.data);
      })
      .catch((err) =>
        console.log(
          "No se pudo obtener el menú del usuario. ERROR: ",
          err.message
        )
      );

    apiCMS.UserMenuServices.getUserMenu(taxRole)
      .then((res) =>
        props.setUserMenu(res.data.map((item) => item["user_menu"]))
      )
      .catch((err) =>
        console.log(
          "No se pudo obtener el menú del usuario. ERROR: ",
          err.message
        )
      );

    props.setAuthenticatedUser(jwt, username, cuit, name + " " + lastName, accessToken);
    apiCMS.setToken(jwt);
    apiBUC.setToken(jwt);
    apiPayment.setToken(jwt);
    apiApplication.setToken(props.jwt);

    apiCMS.AuthServices.getAgentActions().then((res) => {
      props.setAgentActions(res.data);
    });

    apiCMS.AuthServices.getAgentLinks().then((res) => {
      props.setAgentLinks(res.data);
    });

    apiCMS.AuthServices.getContactTypes().then((res) => {
      props.setContactTypes(res.data);
    });

    apiCMS.AuthServices.getSubContactTypes().then((res) => {
      props.setSubContactTypes(res.data);
    });

    apiCMS.AuthServices.getMenuItems(taxRole)
      .then((res) => {
        const orderedMenu = res.data
          .map((item) => {
            return item.action;
          })
          .sort((a, b) => (a.order > b.order ? 1 : -1));
        const defaultAction = orderedMenu[0].link;
        props.setMenu(orderedMenu);

        apiCMS.ConfigServices.getConfigs()
          .then((response) => {
            props.setConfig(response.data.find((t) => t.id === 1));

            apiCMS.LanguagesServices.getLanguages().then((response) => {
              props.setLanguages(response.data);

              apiCMS.TagsServices.getTags().then((response) => {
                props.setTags(response.data);

                apiCMS.ObjectsActionsServices.getActions().then((response) => {
                  props.setObjectsActions(response.data);

                  apiCMS.TaxesServices.getObligationStates().then((states) => {
                    apiCMS.TaxesServices.getObligationStatesActions().then(
                      (stateActions) => {
                        props.setObligationStates(states.data);
                        props.setObligationStateActions(stateActions.data);

                        props.history.push(defaultAction);
                      }
                    );
                  });
                });
              });
            });
          })
          .catch((err) => {
            alert(err);
          });
      })
      .catch((err) => {
        alert(err);
      });
  };

  const { inputs, handleInputChange, handleSubmit } = useLoginForm(handleLogin);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <small>Ingrese sus datos para iniciar sesión</small>
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Usuario"
            name="username"
            autoComplete="username"
            autoFocus
            onChange={handleInputChange}
            value={inputs.username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleInputChange}
            value={inputs.password}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recordarme"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Iniciar sesión
          </Button>
        </form>
      </div>
      <Box mt={8}></Box>
    </Container>
  );
};

const mapDispatch = (dispatch) => ({
  loginSuccess: (username, jwt, cuit, name, lastName, id, taxRole, accessToken) =>
    dispatch.auth.loginSuccess(username, {
      jwt,
      cuit,
      name,
      lastName,
      id,
      taxRole,
      accessToken
    }),
  setConfig: (config) => dispatch.auth.setConfig(config),
  setMenu: (menu) => dispatch.auth.setMenu(menu),
  setUserMenu: (menu) => dispatch.auth.setUserMenu(menu),
  setLanguages: (languagesList) =>
    dispatch.languages.setLanguages(languagesList),
  setTags: (tagsList) => dispatch.tags.setTags(tagsList),
  setObjectsActions: (actionsList) =>
    dispatch.objectsActions.setObjectsActions(actionsList),
  setAuthenticatedUser: (jwt, username, cuit, denominacion, accessToken) =>
    dispatch.auth.setAuthenticatedUser(jwt, { username, cuit, denominacion, accessToken }),
  setRepresentedList: (representedList) =>
    dispatch.auth.setRepresentedList(representedList),
  setObligationStates: (states) =>
    dispatch.payments.setObligationStates(states),
  setObligationStateActions: (statesActions) =>
    dispatch.payments.setObligationStateActions(statesActions),
  addTaxesTypes: (taxesList) => dispatch.taxes.addTaxesTypes(taxesList),
  setAgentActions: (agentActions) =>
    dispatch.auth.setAgentActions(agentActions),
  setAgentLinks: (agentLinks) => dispatch.auth.setAgentLinks(agentLinks),
  setContactTypes: (contactTypes) =>
    dispatch.contacts.setContactTypes(contactTypes),
  setSubContactTypes: (subContactTypes) =>
    dispatch.contacts.setSubContactTypes(subContactTypes),
  setTrialsConfig: (config) => dispatch.payments.setTrialsConfig(config),
  clearObligations: () => dispatch.payments.clearObligations(),
  setPublicLogin: (publicLogin) => dispatch.auth.setPublicLogin(publicLogin),
  setAccessToken: (accessToken) => dispatch.auth.setAccessToken(accessToken),
});

export default withRouter(connect(null, mapDispatch)(Login));
