import React, { useEffect, useRef } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getUrl,
  applyDependencies,
  applyInitialDependencies,
  applySimpleCondition,
  getDependencies,
  getMask,
  validarDato,
  dependenciaCondicional,
  getAsyncData,
  getOptionsFromGroup,
} from "./Operations";
import _ from "lodash";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import DateFnsUtils from "@date-io/date-fns";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
const fullWidthOp = false;

const RadioButtons = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    label,
    input,
    meta: { touched, invalid, error },
    name,
    meta,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const theme = useTheme();
  const mountedRef = useRef(true);
  const [value, setValue] = React.useState(
    _.get(values, input.name)
      ? _.get(values, input.name)
      : meta.initial
      ? meta.initial
      : ""
  );
  const context = useContext(ctxts);
  //#region  //? Condicional  visible o no config
  const [visible, setVisible] = React.useState(
    inputelement.isConditional ? false : true
  );
  const [urlCondition, setUrlCondition] = React.useState("");
  useEffect(() => {
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      dependenciaCondicional(inputelement, urlCondition, setVisible, props);
    }
  }, [urlCondition]);
  //#endregion
  //#region //! actualizar  urls
  //? con estas dependencias y useEffect recalculo las urls
  //? solo cuando cambien los valores de los inputs que la url requiere

  const [url, setUrl] = React.useState("");
  const getArrayDependecies = () => {
    const dependencias = getDependencies(input, inputelement);
    return [
      dependencias.map((d) => {
        const dependenciaValue = _.get(values, d);
        return dependenciaValue ? dependenciaValue : "";
      }),
    ];
  };
  useEffect(() => {
    if (inputelement.asyncData) {
      setUrl(
        getUrl(
          input.name,
          inputelement.asyncConf.url,
          props.cuit,
          values,
          inputelement.asyncConf.JsonFieldsName,
          null,
          inputelement.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      setUrlCondition(
        getUrl(
          input.name,
          inputelement.conditional.asyncConf.url,
          props.cuit,
          values,
          inputelement.conditional.asyncConf.JsonFieldsName,
          null,
          inputelement.conditional.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [...getArrayDependecies()]);
  //#endregion
  //#region //? validaciones asyncronas
  const [msj, setMsj] = React.useState({
    show: false,
    type: "error",
    msj: inputelement.asyncValidationConf?.errorMsg,
  });

  const validar = () =>
    validarDato(inputelement, input, props, values, setMsj, context.BaseUrls);
  //#endregion
  //#region  //? calculos entre campos
  useEffect(() => {
    applyDependencies(props);
    if (inputelement.isConditional && !inputelement.conditional.asyncData) {
      setVisible(applySimpleCondition(props));
    }
  });
  //#endregion

  useEffect(() => {
    if (inputelement.optionsFromGroup) {
      const response = getOptionsFromGroup(
        inputelement.optionsConfig.groupName,
        values
      );
      if (response) {
        setOptions(
          Array.isArray(response)
            ? response.map((elem) => {
                return {
                  text: _.get(elem, inputelement.optionsConfig.propertyText),
                  value: _.get(elem, inputelement.optionsConfig.propertyValue),
                };
              })
            : []
        );
      }
    }
  }, [_.get(values, inputelement?.optionsConfig?.groupName)]); //se ejecuta solo si la url cambia

  //#region //?traer datos asincronos
  const dependencias = getDependencies(input, inputelement);
  const focusedInput = props.appContext.inputFocus;
  useEffect(() => {
    if (
      inputelement.asyncData &&
      !dependencias.some((d) => d === props.appContext.inputFocus)
    ) {
      const response = getAsyncData(inputelement, url, props, input);
      response.then((res) => {
        if (!mountedRef.current) return null;
        if (res) {
          const data = _.get(
            res,
            inputelement.asyncConf.baseNode
              ? inputelement.asyncConf.baseNode
              : "data"
          );

          setOptions(
            Array.isArray(data)
              ? data.map((elem) => {
                  return {
                    text: _.get(elem, inputelement.asyncConf.propertyText),
                    value: _.get(elem, inputelement.asyncConf.propertyValue),
                  };
                })
              : []
          );
        }
      });
    }
  }, [url, focusedInput]); //se ejecuta solo si la url cambia
  //#endregion
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#region //? inicializar el componente

  const [options, setOptions] = React.useState(
    inputelement.options
      ? inputelement.options
      : [
          {
            text: "No hay opciones configuradas",
            value: "No hay opciones configuradas",
          },
        ]
  );
  const handleChange = (event) => {
    console.log("handleCHange" + event.target.value);
    //  setValue(event.target.value);
    setValue(event.target.value as string);
    input.onChange(event.target.value as string);
  };

  const onChange = (event: any) => {
    // props.setProcedureValue(input.label,event.target.value);
    setValue(event as string);
  };

  const select_handleChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setValue(event.target.value as string);
    input.onChange(event.target.value as string);
  }; //use efect para inicializar el componente

  if (visible) {
    return (
      <FormControl
        {...custom}
        fullWidth={fullWidthOp}
        error={touched && invalid}
        className={classes.formcontrol}
      >
        <RadioGroup
          aria-label={inputelement.label}
          name={inputelement.label}
          // value={value}
          {...input}
          onChange={handleChange}
        >
          <FormLabel component="legend">{inputelement.label}</FormLabel>

          {options ? (
            options.map((option) => (
              <FormControlLabel
                key={option.value}
                name={option.id}
                value={option.value}
                control={<Radio color="primary" />}
                label={option.text}
                labelPlacement="end"
              />
            ))
          ) : (
            <FormControlLabel
              value={" "}
              control={<Radio color="primary" />}
              label={"No hay configuracion cargada"}
              labelPlacement="end"
            />
          )}
        </RadioGroup>
        {props.meta.touched && error && (
          <span style={{ color: "red" }}>{error}</span>
        )}
      </FormControl>
    );
  } else {
    return null;
  }
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(RadioButtons);
