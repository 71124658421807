
import React from 'react';
import {
  createMuiTheme,
  createStyles,
  withStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';



const CPaper = withStyles((theme: Theme) => ({
    root: {
        boxShadow:theme.shadows[theme.superficies.elevation],
        border:theme.superficies.border,
        borderRadius:theme.superficies.borderRadius
    },
   
  })
  )(Paper);


export default CPaper;