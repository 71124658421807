import React from 'react';
import {  makeStyles } from '@material-ui/core/styles';
import  Button from '../../common/MaterialComponents/Button';
import {FormattedMessage} from "react-intl";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { format} from 'date-fns'
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
interface ContactsDetailProps {
    isOpen: boolean;
    handleClose: any;
    data:any;
    
}

const useStyles = makeStyles({
   title:{
       display:'flex',
       justifyContent:'space-between'
   },
   button: {
       margin: '3px 10px 3px 0',

       borderRadius: '20px',
       color: '#525252',
       fontSize: '7px',
       padding: '0px 10px',
   },
}
);

const ContactsDetail = (props:ContactsDetailProps) => {
    const classes = useStyles();
    return (
        <Dialog fullWidth={true}
            maxWidth={'sm'} open={props.isOpen} onClose={props.handleClose}>
           <DialogTitle id="responsive-dialog-title">
                  <div className={classes.title}>  <span>  <FormattedMessage id="contacts.detail.Tipo" defaultMessage="Tipo" />  {props.data.tipo} 
                  </span> <span>  <FormattedMessage id="contacts.tableHead.date" defaultMessage="Fecha:" /> {format(new Date(props.data.fechaInicio), "dd/MM/yyyy")} </span>
                    </div></DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                       {props.data.descripcion}<br/>
                          <br/>
                          {props.data.archivo && <Button
                                                                variant="outlined"
                                                                color="default"
                                                                className={classes.button}
                                                                startIcon={<AttachmentOutlinedIcon />}
                                                            >
       <FormattedMessage id="contacts.table.Adjunto" defaultMessage="Adjunto" />
                                                             </Button>}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={props.handleClose}
                            variant="outlined"
                            color="primary"
                            autoFocus
                        > <FormattedMessage id="contacts.detail.Btn" defaultMessage="Aceptar" />
                            
                    </Button>
                    </DialogActions>
        </Dialog>
    );
}
export default ContactsDetail;

