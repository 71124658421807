import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {Container} from "@material-ui/core";
import { Grid } from '@material-ui/core';


const useStyles = makeStyles({
    footer: {
      position:'sticky',
      bottom:'20px',
           color: 'white',
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
    
      padding: '30px',
      background: '#0584ce'
    },
    TextRight: {
       textAlign:'right'
       ,flex: 1
    },
});





const Footer = (props) => {
    const classes = useStyles();

return(
<div className={classes.footer}>
    <Container>
<Grid container spacing={2}  direction="row"
  justify="flex-end"
  alignItems="center">
<Grid item>
        <Button onClick={props.onClickVolver} variant='contained' > Volver</Button>
</Grid>
<Grid item>
        <Button disabled={props.disabled} onClick={props.onClick} variant='contained' > Continuar</Button>
</Grid>
</Grid></Container>
</div>);
}
export default Footer