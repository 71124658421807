import { useField } from "react-final-form";
import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTheme } from "@material-ui/core/styles";
import {
  applyDependencies,
  applySimpleCondition,
  applyInitialDependencies,
} from "./Operations";
import _ from "lodash";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const fullWidthOp = false;

const PasswordField = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    label,
    input,
    meta: { touched, invalid, error },
    name,
    meta,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;

  const [showPassword, setShowPassword] = React.useState(false);
  const [visible, setVisible] = React.useState(
    inputelement.isConditional ? false : true
  );
  const _handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const _handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const theme = useTheme();

  //use efect para calculos entre campos
  useEffect(() => {
    applyDependencies(props);
    if (inputelement.isConditional && !inputelement.conditional.asyncData) {
      setVisible(applySimpleCondition(props));
    }
  });
  //use efect para inicializar el componente
  useEffect(() => {
    applyInitialDependencies(props);
  }, [
    inputelement.dependsOn ? _.get(values, inputelement.dependsOn[0]) : null,
  ]);
  if (visible) {
    return (
      <FormControl
        {...custom}
        fullWidth={fullWidthOp}
        error={touched && invalid}
        className={classes.formcontrol}
        variant={theme.inputs.variant ? theme.inputs.variant : "standard"}
      >
        <InputLabel htmlFor={input.name + "outlined-adornment-password"}>
          {" "}
          {inputelement.label}
        </InputLabel>
        <OutlinedInput
          id={input.name + "outlined-adornment-password"}
          {...input}
          fullWidth
          type={showPassword ? "text" : "password"}
          //  value={values.password}
          // onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={_handleClickShowPassword}
                onMouseDown={_handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
        {props.meta.touched && error && (
          <span style={{ color: "red" }}>{error}</span>
        )}
      </FormControl>
    );
  } else {
    return null;
  }
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(PasswordField);
