import axios from "axios";

const instance = axios.create({
  baseURL: window.config.apiBase.tramite,
});

const setToken = (jwt) => {
  instance.defaults.headers.common["Authorization"] = "Bearer " + jwt;
};

const ApplicationServices = (instance) => {
  const getApplicationForm = (idForm) => {
    return instance.get("param-tramites/" + idForm);
  };
  const getApplicationFormPasos = () => instance.get("param-tramite-pasos");
  const getApplicationFormPasosByTramite = (id, procesoId) =>
    instance.get(`/param-tramite-pasos/${procesoId}/param-tramites/${id}`);
  const getApplicationFormPaso = (id) =>
    instance.get("param-tramite-pasos/" + id);
  const getApplications = () => instance.get("param-tramites");
  const getApplication = (idApp) => instance.get("userapplications/" + idApp);
  const getUserApplications = () => instance.get("userapplications");
  const postUserApplications = (json) =>
    instance.post("userapplications", json);
  const getEnriquecerForm = (jwt, idForm) =>
    instance.get("formulario-enriquecer/" + idForm);
  const postTramite = (id, accion, json, paramTramiteId, tramiteId?) => {
    if (tramiteId) json.tramiteId = tramiteId;
    json.paramTramiteId = paramTramiteId;
    return instance.post("sujetos/" + id + "/tramites/" + accion, json);
  };

  return {
    getApplicationForm,
    getApplications,
    getUserApplications,
    postUserApplications,
    getApplication,
    postTramite,
    getEnriquecerForm,
    getApplicationFormPasos,
    getApplicationFormPaso,
    getApplicationFormPasosByTramite,
  };
};

const apiApplication = {
  services: ApplicationServices(instance),
  setToken: setToken,
};
export default apiApplication;
