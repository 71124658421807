import React, {useEffect} from 'react';
import {FormattedMessage} from "react-intl";
import SeccionHeader from '../common/SeccionHeader';
import {makeStyles} from '@material-ui/core/styles';
import {VouchersData} from '../../mock/data';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import {format} from "date-fns";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Table from '../../components/common/Table';
import Chip from '@material-ui/core/Chip';
import VoucherDetail from './VoucherDetail';
import {voucherEstadoCodigo} from "../../models/IVouchers";
import NumberCurrency from '../common/NumberCurrency';
import TableSkeleton from "../common/TableSkeleton";
import apiBUC from '../../services/BUCServices';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    cell: {
        backgroundColor: '20px'
    },
    container: {
        marginBottom: '50px'
    },
    tableCell: {
        padding: '5px 16px'
    },
    tableHead: {
        background: '#efefef'
    },
    falsoTexto: {

        width: '63%',
        height: '15px',
        borderRadius: '15px',
        display: 'inline-block',
        background: '#dadada',

    },
    tabs: {
        background: 'white',
        flexDirection: 'row',
        '& span': {
            flexDirection: 'row',
        }
    },
    tableRow: {
        cursor: 'pointer',
        '&:hover': {
            background: '#f3f3f3'
        }
    }
    , tabsContainer: {
        boxShadow: ' inset 3px -1px 2px 0px #dddddd8c',
        background: ' #f7f7f7'
    }
    , buscar: {
        marginTop: '-16px',
        marginBottom: '16px',
    },
    marginTop:{
        marginTop:'-20px'
    }

});


const Vouchers = (props) => {
    const classes = useStyles();
    const [filter, setFilter] = React.useState('');
    const [searcheables, setSearcheables] = React.useState([]);
    const [isOpen, setisOpen] = React.useState(false);
    const [selectedVoucher, setVoucher] = React.useState();
    const [voucherList, setVoucherList] = React.useState([]);
    const [loadingApplications, setLoadingApplications] = React.useState(true);
    const [loadingText, setLoadingText] = React.useState(<FormattedMessage id={'contacts.loadingText'}
                                                                           defaultMessage={"Cargando..."}/>);

    /*mapeo de columnas que se van a mostrar en la tabla
    cada columna tiene title (titulo visible), field (campo del response a renderizar),
    searcheable (si false no se busca por esa columna)
    render (forma de renderizado del dato, acepta componentes react)*/

    const columnas = [
        {
            title: <FormattedMessage id="Vouchers.columns.id" defaultMessage="#"/>,
            field: 'numero'
        },
        {
            title: <FormattedMessage id="Vouchers.columns.Fecha" defaultMessage="Fecha"/>,
            searchable: false, field: 'fecha',
            render: rowData => <span>
                            {format(new Date(rowData.fecha), "dd/MM/yyyy").toString()}
                             </span>
        },
        {
            title: <FormattedMessage id="Vouchers.columns.Estado" defaultMessage="Estado"/>,
            field: 'estado_descripcion',
            render: rowData => <Chip variant="outlined" size="small"
                                     label={rowData.estado_descripcion}/>
        },
        {
            title: <FormattedMessage id="Vouchers.columns.Importe" defaultMessage="Importe"/>,
            field: 'importe',
            render: rowData => <NumberCurrency value={parseFloat(rowData.importe).toFixed(props.config.decimals)}/>
        },
    ]

    const handleFilter = (event) => {
        setFilter(event.target.value);
    };

    const handleClose = () => {
        setisOpen(false)
    }
    const selectVoucher = (voucher) => {
        setisOpen(true)
        setVoucher(voucher)

    }

    useEffect(() => {
        apiBUC.SubjectServices.getVouchers(props.cuit)
            .then((response) => {
                setVoucherList(response.data.filter( voucher => {return voucher.medio_pago != 'undefined'} ));
                setLoadingApplications(false);
            })
            .catch((e) => {
                console.error("fallo la comunicacion con el back: ", e.message)
                setLoadingApplications(false);
            });
    }, [])

    if (loadingApplications) {
        return (
            <div>
                <SeccionHeader title={<FormattedMessage id="Vouchers.title" defaultMessage="Mis Boletas"/>}>
                </SeccionHeader>
                <TableSkeleton
                    loadingApplications={loadingApplications}
                    loadingText={loadingText}
                    cantCols={6}
                    cantRows={3}
                />
            </div>
        );
    } else {
        return (
            <div className={classes.container}>
                <SeccionHeader title={<FormattedMessage id="Vouchers.title" defaultMessage="Mis boletas"/>}>
                <TextField className={classes.marginTop}
                        onChange={(e) => handleFilter(e)}
                        id="Filtrar"
                        label="Buscar"
                    />
                </SeccionHeader>
                <Table
                    columnas={columnas}
                    Data={voucherList}
                    filter={filter}
                    rowClick={(r) => selectVoucher(r)}
                    mensajeVacio={<FormattedMessage id="Vouchers.emptyMessage" defaultMessage="No hay Boletas para mostrar"/>}
                    acciones={[{
                        icon: () => <VisibilityIcon/>,
                        tooltip: <FormattedMessage id="Vouchers.showDetails" defaultMessage="Ver detalle"/>,
                        onClick: (e, r) => selectVoucher(r)
                    }
                    ]}
                />
                {isOpen && <VoucherDetail data={selectedVoucher} isOpen={isOpen} handleClose={handleClose}/>}


            </div>
        );
    }
}

const mapState = state => ({
    cuit: state.auth.cuit,
    config: state.auth.config
});

const mapDispatch = dispatch => ({})

export default connect(mapState, mapDispatch)(Vouchers);

