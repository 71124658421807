export const alertICon = {
    1:require("../assets/iconos/center-error@1x.png"),
    2:require("../assets/iconos/center-success@1x.png"),
    3:require("../assets/iconos/center-alert@1x.png"),
 4:require("../assets/iconos/center-alert@1x.png"),

}


export enum EAlertType {
    error=1,
    success=2,
    warning=3,
    info=4

}
