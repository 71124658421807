import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Alert, AlertTitle } from "@material-ui/lab";
import styled from "styled-components";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Container } from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import PaymentServices from "../../../../services/PaymentServices";
import PaymentMethodsLoading from "../../../PaymentMethods/PaymentMethodsLoading";
import BoxButtonElectronic from "../../../PaymentMethods/BoxButtonElectronic";
import { CardActions } from "@material-ui/core";
import EndMessage from "../../../PaymentMethods/EndMessage";
import LoadingComponent from "../../../common/LoadingComponent";
import apiBUC from "../../../../services/BUCServices";
import apiPayment from "../../../../services/PaymentServices";

let icon = require("../../../../assets/iconos/center-error@1x.png");
let icontwo = require("../../../../assets/iconos/center-alert@1x.png");

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  })
);

const BoxContainer = styled(Container)`
  transition: all 0.2s ease-in-out;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  flex-wrap: wrap;

  .boxCol {
    position: relative;
    padding: 15px;
    width: 25%;

    transition: all 0.2s ease-in-out;

    @media (max-width: 991px) {
      &:not(:last-of-type) {
        margin: 0 0 20px;
      }
    }
    &.disabled:not(.disabledImage) {
      .boxHover {
        background: transparent;
        border: solid 1px #eee;
        box-shadow: none !important;
        transform: none !important;
        pointer-events: none;
        .boxTitle {
          color: #ddd !important;
        }
        .boxDescription {
          color: #ddd;
          li {
            color: #ddd;
          }
        }
      }
    }
    &.disabledImage {
      .boxHover {
        pointer-events: none;
        transform: none !important;
        .boxImage {
          filter: grayscale(100%);
        }
      }
    }
  }
  &:hover {
    .boxCol {
      &:hover {
        opacity: 1;
        .boxHover {
          box-shadow: 10px 20px 30px 0 rgba(51, 51, 51, 0.2);
          cursor: pointer;
          transform: scale(1.1, 1.1);
        }
      }
    }
  }
`;

const DebitoDialog = (props) => {
  const [value, setValue] = React.useState(null);
  const [ClienteId, setClienteId] = React.useState("30709110078");
  const [FormasDePago, setFormasDePago] = React.useState(null);
  const [Estado, setEstado] = React.useState("");
  const [Loading, setLoading] = React.useState(false);
  const [ReadyToPay, setReadyToPay] = React.useState(false);
  const [Editable, setEditable] = React.useState(true);
  const [FlagDebito, setFlagDebito] = React.useState(false);
  const [Mensaje, setMensaje] = React.useState({
    msj: "Estamos preparando el pago",
    subMsj: `Sera redireccionado a la pagina seleccionada`,
    type: "loading",
    action: [],

    link: null,
  });

  useEffect(() => {
    if ("obligaciones" in props.obj) {
      let jsonClaves = {
        claves: [],
      };

      props.obj.obligaciones.forEach((obl) => {
        let claveToPush = "";
        claveToPush = obl.concepto.toString();
        claveToPush = claveToPush + obl.impuesto.toString();
        if (jsonClaves.claves.includes(claveToPush)) {
        } else {
          if (obl.cuota != "0") {
            jsonClaves.claves.push(claveToPush);
          } else {
          }
        }
      });

      apiPayment.services
        .getPaymentTypes(ClienteId, jsonClaves)
        .then((response) => {
          let flagDebito = false;
          response.data.forEach((medio) => {
            if (medio.nombre == "debito") {
              setFormasDePago(medio.medios);
              setFlagDebito(true);
            }
          });
          setReadyToPay(true);
        });
    }
  }, []);

  function loadFooter(elem) {
    if (elem.hasFooter === "one") {
      return <CardActions></CardActions>;
    }
    if (elem.hasFooter === "two") {
      return <CardActions></CardActions>;
    }
  }

  const handleClose = () => {
    props.callbackFromParent();
    setTimeout(function () {
      setLoading(false);
      setEstado("");
      setEditable(true);
    }, 1000);
  };

  const onChangeValue = (event: any) => {
    setValue(event.target.value);
  };

  const handleAdd = () => {
    let aux = null;
    if (props.obj.etiquetas !== null) {
      aux = props.obj.etiquetas + "," + value;
    } else {
      aux = value;
    }
    apiBUC.SubjectServices
      .putObjectTags(props.obj.id, {
        sujeto_id: props.cuit,
        tipo_objeto: props.obj.tipo,
        etiqueta: aux,
      })
      .then((response) => {
        window.location.reload();
      });

    props.callbackFromParent();
  };

  const handleClickDebito = (e) => {
    setLoading(true);
    setEstado("message");
    setEditable(false);

    // TODO implementar logica con debito automatico PAGOS cuando este disponible

    setTimeout(function () {
      window.open(e.url_redireccion, "_blank");
      handleClose();
    }, 3000);
  };

if (ReadyToPay) {
    if(FlagDebito){
      return (
        <div>
          {Estado == "message" ? (
            <Dialog
              open={props.show}
              fullWidth={true}
              maxWidth={"lg"}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <DialogContent>
                <EndMessage msj={Mensaje} loading={Loading} />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            <Dialog
              open={props.show}
              onClose={handleClose}
              fullWidth={true}
              maxWidth={"lg"}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">
                Agregar a Debito Automatico
              </DialogTitle>
              <DialogContent>
                <BoxContainer>
                  {FormasDePago.map((elem, i) => (
                    <BoxButtonElectronic
                      key={i}
                      elevation={2}
                      onClickFc={() => handleClickDebito(elem)}
                      btnProps={elem}
                      total={0}
                    ></BoxButtonElectronic>
                  ))}
                </BoxContainer>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      );
    } else {
    return (
      <div>
        <Dialog
          open={props.show}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Agregar a Debito Automatico
          </DialogTitle>
          <DialogContent>
            <Alert
              icon={<img src={icon} />}
              variant="outlined"
              severity="error"
            >
              <AlertTitle>
                No se encontraron medios de Debito Automático disponibles.
              </AlertTitle>
              Intenta nuevamente mas tarde.
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
} else {
  return(<div>
    <Dialog
      open={props.show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Agregar a Debito Automatico
      </DialogTitle>
      <DialogContent>
      <Alert
              icon={<img src={icontwo} />}
              variant="outlined"
              severity="info"
            >
              <AlertTitle>
                Estamos cargando los medios de Debito Automático disponibles.
              </AlertTitle>
              Aguarde unos segundos por favor.
            </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  </div>)
}
};

const mapState = (state) => ({
  objectsActions: state.objectsActions.objectsActions,
  locale: state.auth.locale,
  cuit: state.auth.cuit,
});

export default connect(mapState, null)(DebitoDialog);
