import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getUrl,
  applyDependencies,
  applyInitialDependencies,
  applySimpleCondition,
  getDependencies,
  getMask,
  validarDato,
  dependenciaCondicional,
  getAsyncData,
  getDataFromResponse,
} from "./Operations";
import _ from "lodash";

import FormLabel from "@material-ui/core/FormLabel";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
const uploadBtnStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.botones.borderRadius,
  },
  outlinedPrimary: {
    borderColor: theme.botones.color,
    color: theme.botones.color,
    "&:hover": {
      borderColor: theme.botones.color,
      backgroundColor: theme.botones.color + "33",
    },
  },
  textPrimary: {
    color: theme.botones.color,
  },
  containedPrimary: {
    backgroundColor: theme.botones.color,
    color: theme.botones.colorContraste,
    "&:hover": {
      backgroundColor: theme.botones.color,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: theme.botones.color,
      },
    },
  },
}));

const UploadFile = (props) => {
  const classes = useStyles();
  const uploadBtn = uploadBtnStyles();
  const inputelement: InputElement = props.inputelement;
  const mimes = inputelement.mimeTypes ? inputelement.mimeTypes.join(", ") : "";
  const {
    label,
    input,
    meta: { touched, invalid, error },
    name,
    meta,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const theme = useTheme();
  //#region  //? Condicional  visible o no config
  const [visible, setVisible] = React.useState(
    inputelement.isConditional ? false : true
  );
  const context = useContext(ctxts);
  const [urlCondition, setUrlCondition] = React.useState("");
  useEffect(() => {
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      dependenciaCondicional(inputelement, urlCondition, setVisible, props);
    }
  }, [urlCondition]);
  //#endregion
  //#region //! actualizar  urls
  //? con estas dependencias y useEffect recalculo las urls
  //? solo cuando cambien los valores de los inputs que la url requiere

  const [url, setUrl] = React.useState("");
  const getArrayDependecies = () => {
    const dependencias = getDependencies(input, inputelement);
    return [
      dependencias.map((d) => {
        const dependenciaValue = _.get(values, d);
        return dependenciaValue ? dependenciaValue : "";
      }),
    ];
  };
  useEffect(() => {
    if (inputelement.asyncData) {
      setUrl(
        getUrl(
          input.name,
          inputelement.asyncConf.url,
          props.cuit,
          values,
          inputelement.asyncConf.JsonFieldsName,
          null,
          inputelement.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      setUrlCondition(
        getUrl(
          input.name,
          inputelement.conditional.asyncConf.url,
          props.cuit,
          values,
          inputelement.conditional.asyncConf.JsonFieldsName,
          null,
          inputelement.conditional.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [...getArrayDependecies()]);
  //#endregion
  //#region //? inicializar el componente
  useEffect(() => {
    applyInitialDependencies(props);
  }, [
    inputelement.dependsOn ? _.get(values, inputelement.dependsOn[0]) : null,
  ]);
  //#endregion
  //#region  //? calculos entre campos
  useEffect(() => {
    applyDependencies(props);
    if (inputelement.isConditional && !inputelement.conditional.asyncData) {
      setVisible(applySimpleCondition(props));
    }
  });
  //#endregion
  const [readingFile, setReadingFile] = React.useState(false);
  const [fileB64, setfileB64] = React.useState(
    meta.initial ? meta.initial : ""
  );
  const [files, setFiles] = React.useState(meta.initial ? meta.initial : "");
  const [fileListB64, setFileListB64] = React.useState(
    meta.initial ? meta.initial : ""
  );
  const [errMsgArray, setErrMsgArray] = React.useState(null);

  const [validationErrors, setValidationErrors] = React.useState([]);

  const [errMsgText, setErrMsgText] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!Array.isArray(files)) {
      converBase64();
    }
  }, [files]);

  const handleClickView = (file, index) => {
    let archivo = file;

    if (archivo.url && archivo.url !== "") {
      window.open(archivo.url, "_blank");
    } else {
      if (archivo.base64.indexOf("image") >= 0) {
        setfileB64(archivo.base64);
        setOpen(true);
      } else {
        window.open(archivo.base64, "_blank");
      }
    }
  };

  const handleClickOpen = (file, index) => {
    let archivo = file;
    let reader = new FileReader();
    let fileBase64;
    reader.readAsDataURL(archivo);
    reader.onloadend = () => {
      setfileB64(reader.result);
      fileBase64 = reader.result;
      setOpen(true);
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteFile = (file, ind) => {
    let archivos = input.value;
    let newValues = [...archivos];
    const index = newValues.indexOf(file);

    if (input.value[index].url && input.value[index].url !== "") {
      newValues[index].borrar = true;
    } else {
      newValues = archivos.filter((item, i) => {
        return i !== index;
      });
    }
    setErrMsgText("");
    input.onChange(newValues);
  };

  const renderArchivoSeleccionado = (archivoB64) => {
    let result = (
      <div>
        <img
          alt=""
          src={archivoB64}
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
    if (archivoB64 !== "") {
      return (
        <div>
          <div>{result}</div>
        </div>
      );
    } else {
      return null;
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const checkMimeType = (event, tipos) => {
    let files = event.target.files;

    let err2 = [];
    const types = tipos;
    // loop access array
    for (let x = 0; x < files.length; x++) {
      // compare file type find doesn't matach

      if (
        types &&
        types
          .reduce((types, t) => {
            return [...types, ...t.split(",")];
          }, [])
          .every((type) => files[x].type !== type)
      ) {
        // create error message and assign to container

        err2.push("Formato no soportado\n");
        // err = +files[x].type + " Formato no soportado\n";
      }
    }
    if (Array.isArray(err2) && err2.length) {
      // if message not same old that mean has error

      setValidationErrors((prev) => [...prev, ...err2]);
      event.target.value = null; // discard selected file
      // console.log(err);
      setErrMsgArray(err2);
      return false;
    } else {
      // setErrMsgArray(null);
    }
    return true;
  };

  const checkName = (event) => {
    let files = [...event.target.files];
    let err = "";
    let err2 = [];
    let combFiles = [...input.value];

    const filesFiltered = combFiles.filter((f) => !f.borrar);
    filesFiltered.map((arch) => {
      files.map((f) => {
        if (arch.fileName === f.name) {
          err2.push(" archivo repetido: " + f.name + "\n");
          // err = +f.name + " archivo repetido\n";
        }
      });
    });

    if (Array.isArray(err2) && err2.length) {
      setValidationErrors((prev) => [...prev, ...err2]);
      // if message not same old that mean has error
      event.target.value = null; // discard selected file
      // console.log(err);
      setErrMsgArray(err2);
      return false;
    } else {
      // setErrMsgArray(null);
    }
    return true;
  };

  const maxSelectedFiles = (event) => {
    let files = event.target.files;
    let combFiles = [...input.value];
    const filesCount = combFiles.filter((f) => !f.borrar).length;
    const totalArchivos = filesCount + event.target.files.length;
    if (
      files.length > inputelement.maxFiles ||
      totalArchivos > inputelement.maxFiles
    ) {
      //const msg = 'Solo se permite subir de a 10 archivos';
      setValidationErrors((prev) => [
        ...prev,
        "No puede subir mas de " + inputelement.maxFiles + " Archivos",
      ]);
      event.target.value = null;
      // setErrMsgText(
      //   "No puede subir mas de " + inputelement.maxFiles + " Archivos"
      // );
      return false;
    } else {
      return true;
    }
  };

  const CheckMaxSizeFile = (event) => {
    let files = event.target.files;
    let err3 = [];
    for (let x = 0; x < files.length; x++) {
      console.log("max permitido: " + inputelement.maxSize);
      // compare file type find doesn't matach

      if (files[x].size > parseInt(inputelement.maxSize, 10)) {
        // create error message and assign to container
        setValidationErrors((prev) => [
          ...prev,
          files[x].name + " El archivo supera el tamaño permitido.",
        ]);
        err3.push(files[x].name + " El archivo supera el tamaño permitido."); //esto iria en constants??
      }
    }
    if (Array.isArray(err3) && err3.length) {
      setErrMsgArray(err3);
      return false;
    } else {
      // setErrMsgArray([]);
      return true;
    }
  };

  const checkMaxFiles = () => {
    let combFiles = [...input.value];
    const cantidadFiles = combFiles.filter((f) => !f.borrar).length;
    if (cantidadFiles >= inputelement.maxFiles) {
      return true;
    } else {
      return false;
    }
  };

  const renderFiles = (archivos) => {
    let resultado;
    let resultado2 = [];
    let maxFiles;
    const files2 = [...archivos];
    let archivo = input.value;
    //if values and disabled...
    if (files2 && files2.length >= 1 && archivo) {
      //  console.log("vengo bien"+JSON.stringify(archivo));
      let files2array = files2.map((file, index) => {
        resultado = <div> {file.name} </div>;
        /*     return <div key={Math.random()} className="col-12"><label id={file.name} >{file.name} ({formatBytes(file.size)}) <span onClick={(e) => handleClickOpen(file, index)}> {fileB64?<VisibilityIcon/>:<VisibilityOffIcon/> } </span> <span onClick={(e)=>{deleteFile(e, index)}}> <DeleteIcon/> </span>
                     </label></div>*/
      });
      let filesLoadedArray = archivo.map((f, index) => {
        if (f.base64 && f.base64 !== "") {
          //aca muestro en modal..
          //no lo muestro por que se muestra arriba.
          return (
            <div key={Math.random()} className="col-12">
              <label id={f.fileName}>
                {f.fileName}
                {(f.base64 && f.base64.indexOf("image") > 0) || f.url ? (
                  <Button onClick={() => handleClickView(f, index)}>
                    {<VisibilityIcon />}
                  </Button>
                ) : null}
                {/* <span onClick={(e) => handleClickView(f, index)}>
                  {<VisibilityIcon />}
                </span> */}
              </label>
            </div>
          );
        } else {
          // aca muestro redirect a url.
          //se muestran los que ya estaban cargados.
          return (
            <div key={Math.random()} className="col-12">
              <label id={f.fileName}>
                {f.fileName}
                {(f.base64 && f.base64.indexOf("image") > 0) || f.url ? (
                  <Button onClick={() => handleClickView(f, index)}>
                    {<VisibilityIcon />}
                  </Button>
                ) : null}
                {/* <span onClick={(e) => handleClickView(f, index)}>
                  {<VisibilityIcon />}
                </span> */}
              </label>
            </div>
          );
        }
      });
      let combinedFiles = [...files2array, ...filesLoadedArray];
      return combinedFiles;
    } else if (props.disabled) {
      //aca de value..
      //recorrer   url y filename
      archivo.map((file, index) => {
        return resultado2.push(
          <div key={Math.random()} className="col-12">
            <label id={file.fileName}>
              {file.fileName}
              {(file.base64 && file.base64.indexOf("image") > 0) || file.url ? (
                <Button onClick={() => handleClickView(file, index)}>
                  {<VisibilityIcon />}
                </Button>
              ) : null}
              {/* <span onClick={(e) => handleClickView(file, index)}>
                {<VisibilityIcon />}
              </span> */}
            </label>
          </div>
        );
      });
    } else if (archivo && !props.disabled) {
      //aca viene si tiene un value... y no esta deshabilitado....
      // como ser el cambio de pestaña.
      //armar un render aparte para no repetir lineas-----...
      archivo.map((file, index) => {
        if (file.base64 && file.base64 !== "") {
        } else {
          return resultado2.push(
            <div key={Math.random()} className="col-12">
              <label id={file.fileName}>
                {file.fileName}
                {(file.base64 && file.base64.indexOf("image") > 0) ||
                file.url ? (
                  <Button onClick={() => handleClickView(file, index)}>
                    {<VisibilityIcon />}
                  </Button>
                ) : null}
                {/* <span onClick={(e) => handleClickView(file, index)}>
                  {<VisibilityIcon />}
                </span> */}
              </label>
            </div>
          );
        }
      });
      //aca  se mostraria con botones para editar.
    } else {
      if (files2 && files2.length >= 1) {
        files2.map((file, index) => {
          console.log("item: " + file);
          resultado = <div> {file.name} </div>;
          return resultado2.push(
            <div key={Math.random()} className="col-12">
              <label id={file.name}>
                {file.name} ({formatBytes(file.size)})
                <Button onClick={(e) => handleClickOpen(file, index)}>
                  {fileB64 ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Button>
                <Button
                  onClick={() => {
                    deleteFile(file, index);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </label>
            </div>
          );
        });
      } else {
        resultado2 = null;
      }
    }
    return resultado2;
  };

  const formatFileListButtons = (file, index) => {
    let result;
    if (props.disabled) {
      result = (
        <div key={Math.random()} className="col-12">
          <label id={file.fileName}>
            {file.fileName}
            {(file.base64 && file.base64.indexOf("image") > 0) || file.url ? (
              <Button onClick={() => handleClickView(file, index)}>
                {<VisibilityIcon />}
              </Button>
            ) : null}

            {/* <span onClick={() => handleClickView(file, index)}>
              {<VisibilityIcon />}
            </span> */}
          </label>
        </div>
      );
    } else {
      result = (
        <div key={Math.random()} className="col-12">
          <label id={file.fileName}>
            {file.fileName}
            {(file.base64 && file.base64.indexOf("image") > 0) || file.url ? (
              <Button onClick={() => handleClickView(file, index)}>
                {<VisibilityIcon />}
              </Button>
            ) : null}
            <Button
              onClick={() => {
                deleteFile(file, index);
              }}
            >
              <DeleteIcon />
            </Button>
          </label>
        </div>
      );
    }
    return result;
  };

  const formatFileList = (archs) => {
    let combFiles = [...input.value];
    combFiles = combFiles.filter((f) => !f.borrar);
    return combFiles.map((a, index) => {
      return formatFileListButtons(a, index);
    });
  };

  const converBase64 = () => {
    setReadingFile(true);
    let reader = new FileReader();
    let result = [];
    // let filesArray = [...files];
    let archivos = files;
    for (let file of archivos) {
      let reader = new FileReader();
      reader.onload = function (e) {};
      // Read in the image file as a data URL.
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        result.push({
          fileName: file.name,
          base64: reader.result,
          name: inputelement.name,
          size: file.size,
          borrar: false,
        });
        setFileListB64(result);
        setReadingFile(false);
        input.onChange([...input.value, ...result]); //posible solucion
      };
    }
  };

  const onChange = (e: any) => {
    setValidationErrors([]);
    // setErrMsgArray([]);
    setErrMsgText("");
    if (
      checkName(e) &&
      CheckMaxSizeFile(e) &&
      maxSelectedFiles(e) &&
      checkMimeType(e, inputelement.mimeTypes)
    ) {
      setFiles(e.target.files);
    }
  };

  let fileInput;

  const renderError = (error) => {
    return error.map((dato, i) => {
      return (
        <div key={i} className="col-12">
          {dato.fileName}
        </div>
      );
    });
  };

  const renderUploadButton = (vals) => {
    let result;
    let max = checkMaxFiles();
    let mockpepe = { archivo: input.value };
    let { archivo } = mockpepe;
    if (max) {
      result = (
        <>
          {/* // <Button
        //   className={`
        //         ${uploadBtn.root}
        //         ${uploadBtn.outlinedPrimary}
        //         ${uploadBtn.textPrimary}
        //         ${uploadBtn.containedPrimary}`}
        //   variant="contained"
        //   component="span"
        //   size="large"
        //   color="primary"
        //   disabled
        // >
        //   <small> Subir archivo</small>
        // </Button> */}
        </>
      );
    } else if (props.disabled) {
      result = null;
    } else if (!props.disabled && archivo.length >= 1) {
      result = (
        <Button
          className={`${uploadBtn.root}
                ${uploadBtn.outlinedPrimary}
                ${uploadBtn.textPrimary}
                ${uploadBtn.containedPrimary}`}
          variant="contained"
          component="span"
          size="large"
          color="primary"
        >
          <small> Agregar Archivo</small>
        </Button>
      );
    } else {
      result = (
        <Button
          className={`${uploadBtn.root}
                ${uploadBtn.outlinedPrimary}
                ${uploadBtn.textPrimary}
                ${uploadBtn.containedPrimary}`}
          variant="contained"
          component="span"
          size="large"
          color="primary"
        >
          <small> Subir archivo</small>
        </Button>
      );
    }
    return result;
  };
  if (visible) {
    return (
      <FormControl component="fieldset" className={classes.formcontrol}>
        <FormLabel component="legend">
          {inputelement.label ? inputelement.label : ""}
        </FormLabel>
        <div>
          <input
            ref={(input) => {
              fileInput = input;
            }}
            color="primary"
            accept={mimes}
            type="file"
            onChange={onChange}
            disabled={checkMaxFiles()}
            id={input.name}
            style={{ display: "none" }}
            multiple={inputelement.maxFiles > 1}
          />
          <label htmlFor={input.name}>{renderUploadButton(files)}</label>
          <div className="err">
            {validationErrors?.map((e) => (
              <span key={e} className="text-danger">
                {e}
              </span>
            ))}

            {errMsgArray && errMsgArray.length > 0
              ? renderError(errMsgArray)
              : null}
            {errMsgText && errMsgText.length >= 1 ? errMsgText : null}
          </div>
        </div>
        <div className="pepe">{formatFileList(files)}</div>
        <div className="dialog">
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {renderArchivoSeleccionado(fileB64)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {props.meta.touched && error && (
          <span style={{ color: "red" }}>{error}</span>
        )}
      </FormControl>
    );
  } else return null;
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(UploadFile);
