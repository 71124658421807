import axios from 'axios';
import SubjectServices from './SubjectServices';
import AffidavitServices from './AffidavitServices';
import ProceduresServices from './ProceduresServices';
import ChatServices from './ChatServices';
const instance = axios.create({
    baseURL: window.config.apiBase.buc
    });

const setToken = (jwt) => {instance.defaults.headers.common['Authorization'] =  'Bearer ' + jwt};    

const apiBUC = {
    SubjectServices: SubjectServices(instance),
    AffidavitServices: AffidavitServices(instance),
    ProceduresServices:ProceduresServices(instance),
    ChatService: ChatServices(instance),
    setToken: setToken,
}
export default apiBUC;