import React from "react";
import { connect } from "react-redux";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon';
import {RepresentedType, RepresentedColor} from '../../../models/IRepresented';
const useStyles = makeStyles(theme=>({
    link: {
        textDecoration: "none",
        backgroundColor: "transparent",
        margin: "5px 10px",
        '&:hover':{
            textDecoration:'none',
            

        }
    },
    Btn: {
    margin:theme.spacing(2)
       
    },
    Paper: {
        display:"flex",
    minHeight:"180px",
    minWidth:"182px",
    textAlign:"center",
    flexDirection:"column",
    flex:"1",
    justifyContent:"center",
    margin:"auto",
    
    },
    icon: {

       fontSize:'40px',
       margin: '0 auto 20px',


    },
    disabled:{margin:theme.spacing(2),
      opacity:'.3'
    }
}));

const DefineRepresentedListElement = (props) => {
    const classes = useStyles();
    const colorSelect =RepresentedColor[props.elem.cuit.slice(0,2).toString()]?RepresentedColor[props.elem.cuit.slice(0,2).toString()]:'#444';
    const iconSelect =RepresentedType[props.elem.cuit.slice(0,2).toString()]?RepresentedType[props.elem.cuit.slice(0,2).toString()]:'domainIcon';
 
    return (
    <ButtonBase  disabled={props.disabled} onClick={() => props.onClick(props.elem)} focusRipple
     className={  props.disabled ? classes.disabled:classes.Btn}>
    <Paper elevation={1} className={classes.Paper} >
        <Icon style={{color:colorSelect}} className={classes.icon}>
        {iconSelect}
           </Icon>   
        
        <Typography variant='subtitle2'> {props.elem.denominacion} </Typography>
      <Typography variant='subtitle1'>{props.elem.cuit} </Typography>
    </Paper >

 </ButtonBase>
  );
};

export default DefineRepresentedListElement;
