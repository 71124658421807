export default {
    state: {
        chats: [],
        flagRefresh: 0
    },
    reducers: {
        createChat(state, entidad, clave) {
            const index = state.chats.findIndex((chat) => {
                return chat.entidad == entidad && chat.clave == clave
            })
            if (index === -1) {
                const chats = state.chats;
                chats.push({
                        entidad: entidad,
                        clave: clave,
                        mensajes: []
                    })
                    return Object.assign({}, {...state, chats: chats, flagRefresh: chats.length})
            }
            return Object.assign({}, {...state })
        },
        closeChat(state, entidad, clave) {
            const chats = state.chats.filter((chat)=> !(chat.entidad == entidad && chat.clave == clave))
            return Object.assign({}, {
                ...state,
                chats: chats,
                flagRefresh: chats.length
            })
        }
    },
    effects: (dispatch) => ({}),
}