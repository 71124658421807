import React, { useEffect } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import ApplicationSteps from "../ApplicationSteps";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import StepsMenu from "./StepsMenu";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme: Theme) => ({
  stepsButtons: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  timeline: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "&::before": {
      top: "20px",
      width: "2px",
      bottom: "20px",
      content: "''",
      right: "24px",
      position: "absolute",
      background: "#c7c7c7",
    },
  },
  stepButton: {
    textTransform: "none",
    fontWeight: 400,
    color: "#9a9a9a",
    textAlign: "right",
    justifyContent: "flex-end",
  },
  active: {
    color: theme.palette.primary.main,
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  icon: { margin: "5px", borderRadius: "100%", background: "#ebebeb" },
}));

const TramiteFormContainer = (props) => {
  const [muestroPaso, setMuestroPaso] = React.useState(true);
  const classes = useStyles();
  const [pasoID, setPasoID] = React.useState(props.pasoIdToShow);
  const [active, setActive] = React.useState(props.pasoIdToShow);
  const [isOpen, setOpen] = React.useState(null);
  const enriquecer = "enriquecer";
  const primero = "primero";
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const handleClick = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const mostrarPaso = (pasoID) => {
    setOpen(null);
    setPasoID(pasoID);
    setActive(pasoID);
    setMuestroPaso(props.pasoId !== pasoID);
  };

  const mostrarInicio = () => {
    setOpen(null);
    setActive(primero);
    setPasoID(null);
    setMuestroPaso(props.pasoId !== null);
  };

  const mostrarEnriquecer = () => {
    setOpen(null);
    setMuestroPaso(true);
    setActive(enriquecer);
  };
  const b64DecodeUnicode = (str) => {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(b64DecodeUnicode(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const handleVolver = () => {
    if (props.pasoId === active || primero === active) {
      if (props.urlToGoBack) {
        window.top.location.href = props.urlToGoBack;
      } else {
        const user = parseJwt(props.jwt);

        if (user.isBackOffice) {
          history.goBack();
          // window.top.location.href = "/misTareas/";
        } else {
          window.top.location.href = "/tramites/";
        }
        //
      }
      // history.goBack();
      // history.push("/mis-tramites");
    }

    setActive(props.pasoId ? props.pasoId : primero);
    setMuestroPaso(false);
    setPasoID(props.pasoId ? props.pasoId : null);
    //setMuestroPaso(props.pasoId ? true : false);
  };

  useEffect(() => {
    setActive(props.pasoId ? props.pasoId : primero);
    setMuestroPaso(props.muestroPaso);
    setPasoID(props.pasoId ? props.pasoId : null);
  }, [props.pasoId, props.muestroPaso]);

  return (
    <div>
      <Grid container>
        <Grid item md={2} sm={12}>
          <StepsMenu
            open={isOpen}
            handleClose={handleClose}
            handleOpen={(e) => handleClick(e)}
            mobile={mobile}
            primero={primero}
            active={active}
            mostrarInicio={mostrarInicio}
            steps={props.steps}
            viewOnly={props.viewOnly}
            mostrarPaso={(proceso) => mostrarPaso(proceso)}
            formularioEnriquecidoId={props.formularioEnriquecidoId}
            tramite={props.tramite}
            enriquecer={enriquecer}
            mostrarEnriquecer={mostrarEnriquecer}
          ></StepsMenu>
        </Grid>

        <Grid item xs={12} md={10}>
          <ApplicationSteps
            initial={props.initial}
            paramTramiteId={props.paramTramiteId}
            formularioEnriquecidoId={props.formularioEnriquecidoId}
            esEnriquecer={active === enriquecer}
            viewOnly={
              active === enriquecer
                ? false
                : props.initialPasoId === pasoID
                ? props.viewOnly
                : true
            }
            activo={active}
            steps={props.steps}
            initialPAso={props.initialPasoId}
            pasoId={pasoID}
            handleClose={handleVolver}
            tramite={props.tramite}
            tramiteId={props.tramiteId}
          />
        </Grid>
      </Grid>
    </div>
  );
};
const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
  urlToGoBack: state.auth.urlToGoBack,
});

export default connect(mapState, null)(TramiteFormContainer);
