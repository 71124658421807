import React, { useEffect } from "react";
import Button from "../../common/MaterialComponents/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles,createStyles } from "@material-ui/core/styles";
import { format } from "date-fns";

import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClearIcon from "@material-ui/icons/Clear";
import AgentError from "../AgentError";
import apiBUC from "../../../services/BUCServices";
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme) =>
  createStyles({
  contribuyenteEncontrado: {
    display: "flex",
    flexDirection: "row",
  },
  content: { padding: " 8px 64px",
  [theme.breakpoints.down('sm')]: {
    padding: "8px 0",
  },


},
  icono: { display: "flex", flexDirection: "column", justifyContent: "center" },
  texto: { display: "flex", flexDirection: "column" },
  nombre: { margin: "0 10px",
  [theme.breakpoints.down('sm')]: {
    fontSize: "1.2rem",
  },
},
  cuit: {
    margin: "0px 15px",
  },
  formBuscarTercero: {
    display: "flex",
    flexDirection: "row",
    padding: " 8px 64px",
    [theme.breakpoints.down('sm')]: {
      padding: " 8px 5px",
    },
  },
  btnBuscar: {
    margin: "10px 0 0 10px",
  },
  iconoChange: {},
  btnChange: {
    borderRadius: "100%",
    width: "60px",
    color: "#444",
    height: "63px",
    display: "inline-block",
  },
  turnoData: {
 
    padding: "30px 10px 0",
  },
  turnoProp: { display: "block" },
  turnoPropTitle: { fontWeight: 500 },
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
  fecha: {
    fontSize: "13px",
    fontWeight: 200,
    display: "flex",
    justifyContent: "center",
    textAlign:'right',
    flexDirection: "column",
  },
})
);
const AgentModal = (props) => {
  const classes = useStyles();
  const [Loading, setLoading] = React.useState(false);
  const [cuit, setCuit] = React.useState("");
  const [error, setError] = React.useState(null);
  const [userFound, setUserFound] = React.useState(null);
  const [enRepresentacion, setEnRepresentacion] = React.useState(props.enRepresentacion);
  const { turno } = props;
  useEffect(()=>{
    setEnRepresentacion(props.enRepresentacion)
  },[props.enRepresentacion]);


  const handleRepresentacion = () => {
    setEnRepresentacion(!enRepresentacion);
  };

  const handleTextChange = (event) => {
    setCuit(event.target.value as string);
  };
  const keyPress = (event) => {
    if (event.which == 13 && cuit.length >= 10) {
      handleSearch(cuit);
    }
  };
  const handleSearch = (cuit) => {
    setLoading(true);
    setError(null);
    let parseCuit = String(cuit);
    parseCuit =
      parseCuit.slice(0, 2) +
      "-" +
      parseCuit.slice(2, 10) +
      "-" +
      parseCuit.slice(10);
    apiBUC.SubjectServices
      .getSubject(parseCuit)
      .then((response) => {
        const userFound = {
          cuit: parseCuit,
          denominacion: response.data.denominacion,
          username: cuit,
        };
        setUserFound(userFound);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        console.log("No encontramos el usuario. ERROR: ", err.message);
      });
  };
  const handleChange = () => {
    setUserFound(null);
  };

  const handleSubmit = () => {
    props.SetAtencion(enRepresentacion, userFound);
  };

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {turno ? (
          <div className={classes.title}>
            <div>Turno {turno["turno"]}</div>
            <div className={classes.fecha}>{format(new Date(turno["turnoDate"]), "dd/MM/yyyy")
            }<br/>{format(new Date(turno["turnoDate"]), "hh:mm")+' hs'} 
            </div>
          </div>
        ) : (
          <span>Contribuyente encontrado:</span>
        )}
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          {props.user && !props.error ? (
            <>
              <div className={classes.contribuyenteEncontrado}>
                <div className={classes.icono}>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </div>
                <div className={classes.texto}>
                  <Typography variant='h5' className={classes.nombre}>{props.user.denominacion}</Typography>
                  <Typography variant='h6' className={classes.cuit}>{props.user.cuit}</Typography>
                </div>
              </div>
              {turno && (
                <Typography className={classes.turnoData}>
                  {turno["procedencia"] && (
                    <span className={classes.turnoProp}>
                      <span className={classes.turnoPropTitle}>
                        Derivado por:
                      </span>
                      <span>{turno["procedencia"]}</span>
                    </span>
                  )}
                  {turno["descripcion"] && (
                    <span className={classes.turnoProp}>
                      <span className={classes.turnoPropTitle}>Motivo: </span>
                      <span>{turno["descripcion"]}</span>
                    </span>
                  )}
                </Typography>
              )}
            </>
          ) : (
            <AgentError />
          )}
        </div>

        {props.user && !props.error ? (
          <FormGroup row>
            <FormControlLabel
              label="Actuar en representacion"
              labelPlacement="start"
              control={
                <Switch
                  checked={enRepresentacion}
                  onChange={handleRepresentacion}
                  name="checkedB"
                  color="primary"
                />
              }
            />
          </FormGroup>
        ) : null}

        {enRepresentacion && (
          <>
            {!userFound ? (
              <>
                <div className={classes.formBuscarTercero}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Cuit"
                    value={cuit}
                    fullWidth
                    onChange={handleTextChange}
                    onKeyDown={keyPress}
                    disabled={Loading}
                  />
                  <Button
                    color="primary"
                    disabled={Loading || cuit.length < 10}
                    className={classes.btnBuscar}
                    variant="contained"
                    onClick={() => cuit.length >= 10 && handleSearch(cuit)}
                  >
                    {Loading ? (
                      <>
                        <CircularProgress size={24} />
                        Buscando
                      </>
                    ) : (
                      <>Buscar</>
                    )}
                  </Button>{" "}
                </div>
                {!Loading && error ? <AgentError /> : null}
              </>
            ) : null}
            {!Loading && userFound && !error ? (
              <div className={classes.content}>
                <div className={classes.contribuyenteEncontrado}>
                  <div className={classes.icono}>
                    <Avatar>
                      <PersonIcon />
                    </Avatar>
                  </div>
                  <div className={classes.texto}>
                  <Typography variant='h5' className={classes.nombre}>{userFound.denominacion}</Typography>
                  <Typography variant='h6' className={classes.cuit}>{userFound.cuit}</Typography>
                  </div>
                  <div className={classes.iconoChange}>
                    <Button
                      className={classes.btnChange}
                      onClick={handleChange}
                    >
                      <ClearIcon />
                    </Button>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={Loading || (enRepresentacion && !userFound) || props.error}
          variant="contained"
          color="primary"
        >
          Iniciar atención
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgentModal;
