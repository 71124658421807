import React from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Badge } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { NavLink, useRouteMatch } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    active: {
      textDecoration: "none",
      "&:after": {
        content: "''",
        position: "absolute",
        background: theme.header.active,
        width: "100%",
        height: "3px",
        bottom: 0,
        left: 0,
      },
    },
    text: {
      fontSize: theme.header.fontSize,
      textDecoration: "none",
      color: theme.header.color,
    },

    link: {
      position: "relative",
      color: theme.header.color,
      padding: "14px 10px",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
    mobile: {
      color: theme.userMenu.color,
      padding: "14px 20px 14px 10px",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
      "& $text": {
        color: theme.userMenu.color,
        padding: "0 30px",
      },
    },
  })
);

const MenuButton = (props) => {
  const classes = useStyles();
  const match = useRouteMatch({
    path: props.option.link,
    exact: false,
  });
  return (
    <>
      {props.contactos ? (
        <Badge color="secondary" badgeContent={props.contactos}>
          <NavLink
            onClick={()=>props.close}
            to={props.option.link}
            className={`${props.mobile ? classes.mobile : classes.link}  ${
              match ? classes.active : null
            }`}
          >
            <Typography className={classes.text}>
              {" "}
              {props.option["name_" + props.locale.toUpperCase()]}
            </Typography>
          </NavLink>
        </Badge>
      ) : (
        <NavLink
        onClick={()=>props.close}
          to={props.option.link}
          className={`${props.mobile ? classes.mobile : classes.link}  ${
            match ? classes.active : null
          }`}
        >
          <Typography className={classes.text}>
            {" "}
            {props.option["name_" + props.locale.toUpperCase()]}
          </Typography>
        </NavLink>
      )}
    </>
  );
};

export default MenuButton;
