import React, { useEffect } from "react";
import { InputGroup } from "../../models/IDynamicForm";
import TableView from "./TableView";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ModalView from "./ModalView";
import SimpleView from "./SimpleView";
import Grupo from "./Grupo";
// import { percepcionMock } from "../../../../../mock/Tramites.mock";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import { LogicalOperationsArray } from "../../Inputs/Operations";
import { AppContextConsumer } from "../../DynamicFormContext";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupH: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
    groupV: {
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      "& .field-input": {
        margin: "15px",
      },
    },
    buttonsContainer: {},
    button: {
      margin: "10px",
    },
  })
);

type Props = {
  values: any;
  groups: InputGroup[];
  push: any;
  pop: any;
};
const Grupos = (props: Props) => {
  const classes = useStyles();
  const { values, groups, push, pop } = props;

  return (
    <AppContextConsumer>
      {(appContext) =>
        appContext && (
          <>
            {groups && groups.length > 0 ? (
              groups.map((group, index) =>
                !group.isConditional ? (
                  <div key={index}>
                    {group.iterable ? ( //grupos  iterables
                      <>
                        {group.show === "tableRow" ? (
                          <TableView
                            values={values}
                            push={push}
                            pop={pop}
                            group={group}
                            isLastPaso={
                              appContext.lastPasoId === appContext.pasoId
                            }
                          />
                        ) : group.show === "modalView" ? (
                          <ModalView
                            values={values}
                            push={push}
                            pop={pop}
                            group={group}
                            isLastPaso={
                              appContext.lastPasoId === appContext.pasoId
                            }
                          />
                        ) : (
                          //grupo horizontal y vertical iterables.
                          <SimpleView
                            values={values}
                            push={push}
                            pop={pop}
                            group={group}
                            isLastPaso={
                              appContext.lastPasoId === appContext.pasoId
                            }
                          />
                        )}
                      </>
                    ) : (
                      //grupos no iterables vertical y horizontal
                      <div key={index}>
                        <Grupo
                          values={values}
                          push={push}
                          pop={pop}
                          group={group}
                          isLastPaso={
                            appContext.lastPasoId === appContext.pasoId
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : group.isConditional &&
                  LogicalOperationsArray[
                    group.condition?.operation
                      ? group.condition?.operation
                      : "same"
                  ]?.(
                    _.get(values, group.condition?.observed),
                    group.condition?.value
                  ) ? (
                  <div key={index}>
                    {group.iterable ? ( //grupos  iterables
                      <>
                        {group.show === "tableRow" ? (
                          <TableView
                            values={values}
                            push={push}
                            pop={pop}
                            group={group}
                            isLastPaso={
                              appContext.lastPasoId === appContext.pasoId
                            }
                          />
                        ) : group.show === "modalView" ? (
                          <ModalView
                            values={values}
                            push={push}
                            pop={pop}
                            group={group}
                            isLastPaso={
                              appContext.lastPasoId === appContext.pasoId
                            }
                          />
                        ) : (
                          //grupo horizontal y vertical iterables.
                          <SimpleView
                            values={values}
                            push={push}
                            pop={pop}
                            group={group}
                            isLastPaso={
                              appContext.lastPasoId === appContext.pasoId
                            }
                          />
                        )}
                      </>
                    ) : (
                      //grupos no iterables vertical y horizontal
                      <div key={index}>
                        <Grupo
                          values={values}
                          push={push}
                          pop={pop}
                          group={group}
                          isLastPaso={
                            appContext.lastPasoId === appContext.pasoId
                          }
                        />
                      </div>
                    )}
                  </div>
                ) : null
              )
            ) : (
              <Alert variant="outlined" severity="warning">
                El paso no tiene ningun grupo
              </Alert>
            )}
          </>
        )
      }
    </AppContextConsumer>
  );
};

export default Grupos;
