import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  paper:{
  maxWidth:'90%',
  
  minWidth:'50%'
  }
});

const DrawerMobile = (props) => {
  const classes = useStyles();
  return (<>
  {props.mobile? 
    <Drawer anchor={"right"} PaperProps={{className:classes.paper}} open={props.open} onClick={props.onClose} onClose={props.onClose}>
     {props.children}
    </Drawer>
    :props.children}
    </>
  );
};

export default DrawerMobile;


