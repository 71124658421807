import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import SeccionHeader from "../../common/SeccionHeader";
import { connect } from 'react-redux';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ObligationList from "../../PaymentMethods/ObligationList";
import   NumberCurrency from '../../common/NumberCurrency'
import DialogActions from "@material-ui/core/DialogActions";
import { format } from "date-fns";
import Chip from "@material-ui/core/Chip";
import { voucherEstadoCodigo, EVoucherType } from "../../../models/IVouchers";

import Table from '../../common/Table';
const useStyles = makeStyles({
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
  titulo: {
    fontWeight: 300,
  },
  button: {
    margin: "3px 10px 3px 0",

    borderRadius: "20px",
    color: "#525252",
    fontSize: "7px",
    padding: "0px 10px",
  },
  descripcion: {
    marginLeft: "5px",
    padding: "3px 5px",
    fontSize: "18px",
    fontWeight: 300,
  },
});
const VoucherDetail = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={props.isOpen}
      onClose={props.handleClose}
    >
      <DialogTitle id="responsive-dialog-title">
        <div className={classes.title}>
          <span>
            <FormattedMessage
              id="Vouchers.detail.title"
              defaultMessage="Boleta # "
            />
            <span className={classes.titulo}>{props.data.numero} </span>
          </span>
          <span>
            <FormattedMessage
              id="Vouchers.detail.date"
              defaultMessage="Fecha:"
            /> <span className={classes.titulo}>   {format(new Date(props.data.fecha), "dd/MM/yyyy")}</span>
         
          </span>
        </div><br/>
        <FormattedMessage
              id="Vouchers.detail.Estado"
              defaultMessage="Estado: "
            />{" "}
            <span className={classes.descripcion}>
              <Chip
                variant="outlined"
                size="small"
                label={props.data.estado_descripcion}
              />
            </span>
            <br/>
            <FormattedMessage
              id="Vouchers.detail.Total"
              defaultMessage="Total: "
            />{" "}
            <span className={classes.descripcion}>
            <NumberCurrency value={parseFloat(props.data.importe).toFixed(props.config.decimals)} />
          
            </span>
      </DialogTitle>
      <DialogContent>
      <h5 className={classes.titulo}> 
            <FormattedMessage
              id="Vouchers.detail.obligaciones"
              defaultMessage="Obligaciones incluidas"
            />
        </h5>
        {<ObligationList obligationList={props.data.obligaciones} />}
   
 
    <br/>
      </DialogContent>
      <DialogActions> 
        {props.data.archivo && (
          <Button
            onClick={props.handleClose}
            variant="contained"
            color="primary"
            
          >
            <FormattedMessage
              id="Vouchers.detail.descargar"
              defaultMessage="Descargar"
            />
          </Button>
        )}
        <Button
          onClick={props.handleClose}
          variant="outlined"
          color="primary"
          autoFocus
        >
          <FormattedMessage id="Vouchers.detail.btnCerrar"
           defaultMessage="Cerrar" />
        </Button>
       
      </DialogActions>
    </Dialog>
  );
};
const mapState = state => ({
  config: state.auth.config
})

export default connect(mapState, null)(VoucherDetail);