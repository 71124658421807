import React, {useEffect, useState} from 'react';
import TaxIcon from '../TaxIcon/TaxIcon';
import TaxItemsMapper from '../TaxItemsMapper/TaxItemsMapper';
import {Grid} from '@material-ui/core';
import {first} from "../../../../utils";
import {connect} from 'react-redux';
import ShowMoreButton from "../ShowMoreButton";
import NumberCurrency from '../../../common/NumberCurrency'
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '../../../common/Accoderon'
//import './styles.css';
import { FormattedMessage } from 'react-intl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { makeStyles } from '@material-ui/core/styles';
import JuicioItemsMapper from '../JuicioItemsMapper/JuicioItemsMapper';
export const rowStyleTaxIcon = {
    marginLeft: 30,
    paddingBottom: '0px'
};

export const rowStyleTaxTotal = {
    marginRight: 30,
    paddingBottom: '0px'
};




const useStyles = makeStyles({
    panel:{
        padding:0
    },
AcordionHeader :{
    border: 'none',
    borderRadius: 0,
},

AcordionTitle: {
    border: 'none',
    borderRadius: 0,
    padding: 0,
    margin: 0,
    minHeight: 'auto',
    borderBottom: 'solid 1px #e6e6e6',
},

AcordionGrid: {
    margin: 0,
},

taxIconText : {
    margin: 0,
},

AcordionHeaderType_A: {
    color: '#6ebe64',
},

AcordionHeaderType_E: {
    color: '#75c4c6',
},

AcordionHeaderType_I: {
    color: '#e67864',
}
});






export function TaxTypeAccordion(props) {
    const classes = useStyles();
    const [cant, setCant] = useState(props.config.taxesPageSize);
    const [subjectObjects, setSubjectObjects] = useState(first(props.objects, cant));
    let total = 0;
    let totalCapital = 0;

    subjectObjects.forEach( object => {
        object.obligaciones.forEach( ob => {    
                if(ob.exenta){
                    total = total + (ob.saldoConRecargo * ((100 - ob.porcentaje_exencion)) / 100);
                } else {
                    total = total + ob.saldoConRecargo;
                }
        })
    })
    subjectObjects.forEach( object => {
        object.obligaciones.forEach( obligation => {
            if(obligation.cuota != '0'){
                if(obligation.exenta){
                    totalCapital += ((obligation.saldo * (100 - obligation.porcentaje_exencion)) / 100);
                } else {
                    totalCapital += obligation.saldo;
                }
            }
        })
    })

    const showMore = () => {
        setCant(cant + props.config.taxesPageSize)
        setSubjectObjects(first(props.objects, cant + props.config.taxesPageSize))
    }

    useEffect(() => {
        setCant(props.config.taxesPageSize);
        setSubjectObjects(first(props.objects, props.config.taxesPageSize));
    }, [props.objects, props.isSelectedGroup]);

    if(props.type == 'J'){
        return (
            <div className=''>
                <ExpansionPanel elevation={0} className={'AcordionHeader ' + 'AcordionHeaderType_'+ props.type}
                 defaultExpanded={props.config.accordionDefaultExpanded}>
                    <ExpansionPanelSummary  
                     expandIcon={<ExpandMoreIcon /> } 
                     className={classes.AcordionTitle}
                      aria-controls="panel1d-content" 
                      id="panel1d-header">
                            <Grid  className={classes.AcordionGrid} item xs={12}>
                                    <div className="row justify-content-between">
                                        <div className="row justify-content-start taxIconText" style={rowStyleTaxIcon}>
                                            <TaxIcon type={props.type}/>
                                        </div>
                                        <div className="row justify-content-end" style={rowStyleTaxTotal}>
                                            <div className="col">
                                            <div className="row">
                                                <b>Total en juicio:</b><div style={{paddingLeft: "5px"}}><NumberCurrency value={total.toFixed(props.config.decimals)}/></div>
                                            </div>
                                            <div className="row justify-content-end">
                                                {props.showCapitalDebt ? <small><NumberCurrency value={totalCapital.toFixed(props.config.decimals)} /></small> : null}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                            </Grid>
                    </ExpansionPanelSummary>
    
                    <ExpansionPanelDetails className={classes.panel}>
                        <Grid container item xs={12}>
                            <JuicioItemsMapper objects={subjectObjects}/>
                        </Grid>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                        <ShowMoreButton cant={cant} elements={props.objects} showMore={showMore} text={'Ver Mas'}/>
                    </ExpansionPanelDetails>
                     
                </ExpansionPanel>
               
            </div>
        )
    } else {
        return (
            <div className=''>
                <ExpansionPanel elevation={0} className={'AcordionHeader ' + 'AcordionHeaderType_'+ props.type}
                 defaultExpanded={props.config.accordionDefaultExpanded}>
                    <ExpansionPanelSummary  
                     expandIcon={<ExpandMoreIcon /> } 
                     className={classes.AcordionTitle}
                      aria-controls="panel1d-content" 
                      id="panel1d-header">
                            <Grid  className={classes.AcordionGrid} item xs={12}>
                                    <div className="row justify-content-between">
                                        <div className="row justify-content-start taxIconText" style={rowStyleTaxIcon}>
                                            <TaxIcon type={props.type}/>
                                        </div>
                                        <div className="row justify-content-end" style={rowStyleTaxTotal}>
                                            <div className="col">
                                            <div className="row">
                                                <b><FormattedMessage id="myTaxes.totalTaxDebt" defaultMessage="Total de impuesto" />:</b><div style={{paddingLeft: "5px"}}><NumberCurrency value={total.toFixed(props.config.decimals)}/></div>
                                            </div>
                                            <div className="row justify-content-end">
                                                {props.showCapitalDebt ? <small><NumberCurrency value={totalCapital.toFixed(props.config.decimals)} /></small> : null}
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                            </Grid>
                    </ExpansionPanelSummary>
    
                    <ExpansionPanelDetails className={classes.panel}>
                        <Grid container item xs={12}>
                            <TaxItemsMapper objects={subjectObjects}/>
                        </Grid>
                    </ExpansionPanelDetails>
                    <ExpansionPanelDetails>
                        <ShowMoreButton cant={cant} elements={props.objects} showMore={showMore} text={'Ver Mas'}/>
                    </ExpansionPanelDetails>
                     
                </ExpansionPanel>
               
            </div>
        )
    }
}

const mapState = state => ({
    selectedObject: state.payments.selectedObject,
    allObjects: state.payments.allObjects,
    config: state.auth.config,
    showCapitalDebt: state.payments.showCapitalDebt,
    trialsConfig: state.payments.trialsConfig
})

export default connect(mapState, null)(TaxTypeAccordion);