import React from 'react';
import {connect} from 'react-redux';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import {useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) =>
  createStyles({
    link: {
        textDecoration: "none",
        backgroundColor: "transparent",
        margin: "0px 10px 10px 0",
        display: 'inline-block', transition:'all 300ms',
     
        '&:hover':{
            textDecoration:'none',
            boxShadow:theme.shadows[theme.superficies.elevationHover],
            transition:'all 300ms',
         }
    },
    Btn: {
       
    },
    Paper: {
        display:"flex",
    minHeight:"180px",
    minWidth:"182px",
    textAlign:"center",
    flexDirection:"column",
    flex:"1",
    justifyContent:"center",
    boxShadow:theme.shadows[theme.superficies.elevation],
    border:theme.superficies.border,
    margin:"auto",
    [theme.breakpoints.down('sm')]: {
        minWidth: '148px',
        minHeight: '130px',
      },
      
   

    },
    icon: {

       fontSize:'40px',
       margin: '0 auto 20px',


    },
})
);

const AgentAction = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [elevation, setElevation] = React.useState(1);
    const _onMouseOver= () =>{
        setElevation(10);
    }

    const _onMouseOut =() =>{
        setElevation(1);
    }
    const handleClick =()=>{
        if (props.action.to.split(':/').length>1){
              window.open(props.action.to);}
         else{
            history.push({
                pathname:props.action.to,
                state: props.action.state,
            })
         }   
    }


    return (
        <a onMouseOver={_onMouseOver}
        onMouseOut={_onMouseOut} className={classes.link} onClick={handleClick} >
        <ButtonBase focusRipple className={classes.Btn}>
           <Paper elevation={elevation} className={classes.Paper} >
              {/* <Doughnut data={data} options={settings}/> */}
               <Icon  className={classes.icon}>
               {props.action.icon}
               </Icon>
             <Typography>{props.action['description_' + props.locale.toUpperCase()]}</Typography>  
           </Paper >

        </ButtonBase></a>
    );
  };
  
const mapState = state => ({
    locale: state.auth.locale
})

export default connect(mapState, null)(AgentAction);