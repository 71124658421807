import React from 'react';

import EndMessage from '../../components/PaymentMethods/EndMessage';


const PaymentCash = (props) => {

    return (
       <EndMessage/>);
}
export default PaymentCash;
 