import React from "react";
import { Route, Switch } from "react-router";
import MyTaxes from "../../../containers/MyTaxes";
import MainPage from "../../MainPage";
import PaymentMethods from "../../../containers/PaymentMethods";
import Header from "../Header";
import Footer from "../Footer";
import { Container } from "@material-ui/core";
import Applications from "../../../containers/Applications";
import ElectronicPayment from "../../../containers/ElectronicPayment";
import PaymentsPlan from "../../../containers/PaymentsPlan";
import PlansConfirm from "../../../containers/PaymentsPlan/Confirm";
import PaymentCash from "../../../containers/PaymentCash";
import MyVouchers from "../../../containers/MyVouchers";
import MyContacts from "../../../containers/MyContacts";
import DefineRepresented from "../../../containers/DefineRepresented";
import MyAffidavit from "../../../containers/MyAffidavit";
import MyTasks from "../../../containers/MyTasks";
import MyIndex from "../../../containers/MyIndex";
import FormContainer from "../../../containers/Applications/FormContainer";
import TaxProfile from "../../../containers/TaxProfile";
import AgentDashboard from "../../../containers/AgentDashboard";
import LoadingContainer from "../../../containers/Loading";
import AdminTurnos from "../../../containers/AdminTurnos";
import Embedded from "../../../containers/Embedded";
import WidgetDashboard from "../../../containers/WidgetDashboard";
import Nps from "../../../containers/NPS";
import ChatContainer from "../../../containers/ChatContainer";
import { connect } from "react-redux";
const MainRouter = (props) => {
  return (
    <>
      <div style={{ flex: 1 }}>
        <Switch>
          {/* <Route exact path="/">
                
               <IndexComponent/>
            </Route>
            <Route exact path="/login">
                <Login/>
             
            </Route> */}
          <Route path="/Loading">
            <Header />
            <LoadingContainer />
          </Route>
          <Route path="/inicio">
            <Header />
            <Container>
              <MainPage title="mainPage.dashboard" />
            </Container>
          </Route>
          <Route path="/mis-impuestos">
            <Header />
            <Container>
              <MyTaxes />
            </Container>
          </Route>
          <Route path="/payment-methods">
            <Header />
            <Container>
              <PaymentMethods />
            </Container>
          </Route>
          <Route path="/e-payments">
            <Header />
            <Container>
              <ElectronicPayment />
            </Container>
          </Route>
          <Route path="/cash-payments">
            <Header />
            <Container>
              <PaymentCash />
            </Container>
          </Route>
          <Route path="/plan-payments">
            <Header /> <PaymentsPlan />
          </Route>
          <Route path="/confirm">
            <Header /> <PlansConfirm />
          </Route>
          <Route path="/mis-tramites">
            <Header />
            <Container>
              <MainPage title="mainPage.myProcedures" />
            </Container>
          </Route>
          <Route path="/nuevo-tramite">
            <Header />
            <Container>
              <Applications title="mainPage.applications" />
            </Container>
          </Route>
          {/* <Route path="/tramite-form/:params">
              <Header />
              <Container maxWidth={"lg"}>
                <FormContainer title="mainPage.applicationsForm" />
              </Container>
            </Route> */}
          <Route path="/detalle-tramite/:params">
            <Header />
            <Container maxWidth={"lg"}>
              <FormContainer title="mainPage.applicationsForm" />
            </Container>
          </Route>
          <Route path="/mis-contactos">
            <Header />
            <Container>
              <MyContacts title="applications.contacts"></MyContacts>
            </Container>
          </Route>
          <Route path="/mis-boletas">
            <Header />
            <Container>
              <MyVouchers title="applications.contacts"></MyVouchers>
            </Container>
          </Route>
          <Route path="/definir-representado">
            <Header />
            <Container>
              <DefineRepresented />
            </Container>
          </Route>
          <Route path="/mis-ddjj">
            <Header />
            <Container>
              <MyAffidavit />
            </Container>
          </Route>
          <Route path="/dashboard">
            <Header />
            <TaxProfile />
          </Route>
          <Route path="/agent-dashboard">
            <Header />
            <AgentDashboard />
          </Route>
          <Route path="/turnero">
            <Header />

            <Container>
              <AdminTurnos />
            </Container>
          </Route>
          <Route path="/nps">
            <Header />

            <Container>
              <Nps />
            </Container>
          </Route>
          <Route path="/mis-tareas">
            <Header />
            <Container>
              <MyTasks />
            </Container>
          </Route>
          <Route path="/graficos">
            <Header />
            <Container>
              <WidgetDashboard />
            </Container>
          </Route>
          <Route path="/embedded">
            <Header />
            <Container>
              {/* <WidgetDashboard /> */}
              <Embedded />
            </Container>
          </Route>
          <Route path="*">
            {/* <Header /> */}
            <Container>
              <LoadingContainer />
            </Container>
          </Route>
        </Switch>
      </div>
      <ChatContainer />
      {!props.inIframe && <Footer />}
    </>
  );
};
const mapState = (state) => ({
  inIframe: state.auth.inIframe,
});

export default connect(mapState, null)(MainRouter);
