import React from "react";
import TaxItem from "./TaxItem";
import { Grid } from "@material-ui/core";
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "../../../common/Accoderon";

import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import { FormattedMessage } from "react-intl";
import Alert from "../../../common/AlertsLabs";

const rowStyleMoreButton = {
  marginRight: 20,
};

const rowStyleTags = {
  paddingLeft: 12,
  paddingTop: 10,
};
const useStyles = makeStyles({
  alerta: {
    margin: "15px auto",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
});
const getTotal = (obligations) => {
  let total = 0;
  obligations.forEach((obligation) => {
    if (obligation.cuota != "0") {
      if (obligation.exenta) {
        total =
          total +
          (obligation.saldoConRecargo * (100 - obligation.porcentaje_exencion)) / 100;
      } else {
        total = total + obligation.saldoConRecargo;
      }
    }
  });
  return total;
};

const getTotalCapital = (obligations) => {
  let total = 0;
  obligations.forEach((obligation) => {
    total = total + obligation.saldo;
  });
  return total;
};

export function ObjectView(props) {
  const classes = useStyles();
  const obj = props.objeto;
  if(!obj)
  return null;
  else
  return (
    <div>
        <div>
          <div className={classes.alerta}>
            {obj.deuda ? (
              <Alert
                severity="error"
                title={ <FormattedMessage
                    id="public.objectDetail.hasDebtTitle"
                    defaultMessage="El objeto tiene deuda"
                  />}
                  message={  <FormattedMessage
                    id="public.objectDetail.hasDebtMessage"
                    defaultMessage="Pongase al dia, pague toda su deuda"
                  />}
                action={()=>{}}
               actionLabel={<FormattedMessage
                id="public.objectDetail.paybutton"
                defaultMessage="Pagar deuda"
              />}
              />
            ) : (
                <Alert
                severity="success"
                title={<FormattedMessage
                    id="public.objectDetail.hasntDebtTitle"
                    defaultMessage="El objeto está al dia"
                  />}
                  message={  <FormattedMessage
                    id="public.objectDetail.hasntDebtMessage"
                    defaultMessage="No tiene obligaciones que pagar"
                  />}               
              />
            
            )}
          </div>
          <div>
            <Grid item xs={12} key={obj.id}>
              <ExpansionPanel
                elevation={0}
                className={"SubAcordion " + "SubAcrodionTipo_" + obj.tipo}
              >
            
                <ExpansionPanelSummary className="SubAcordionHeader">
                  <Grid item xs={12} className="SubAcordionTitle">
                    <div className="row justify-content-between">
                      <div className="col-auto columnExpand">
                        <ChevronRightOutlinedIcon className="expandIcon" />
                      </div>
                      <div className="col-auto">
                        <b>{obj.id}</b>
                        {obj.base_imponible ? (
                          <small>
                           <FormattedMessage
                              id="myTaxes.taxBase"
                              defaultMessage="Base imponible"
                            />
                            : {obj.base_imponible} <br />
                            {obj.descripcion}
                          </small>
                        ) : (
                          <small>
                            <br />
                            {obj.descripcion}
                          </small>
                        )}
                      </div>
                      <div className="col"></div>
                      <div className="col-auto">
                        <div className="row justify-content-end">
                          <b>Subtotal:</b>
                          <div style={{ paddingLeft: "5px" }}>
                            {getTotal(obj.obligaciones)}
                          </div>
                          <br />
                        </div>
                      </div>
                      <div className="col-auto"></div>
                    </div>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className="row justify-content-end">
                  <div className="col">
                    {<TaxItem className="SubAcordionItem" object={obj} />}
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          </div>
        </div>
      )
    </div>
  );
}

export default ObjectView;
