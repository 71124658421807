import React, {useState} from 'react';
import { connect } from 'react-redux';
import NumberCurrency from '../../../common/NumberCurrency';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import moment from 'moment';
import 'moment-timezone';
import { Link } from "react-router-dom";
import ObligationList from '../../../PaymentMethods/ObligationList';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles({
    Link: {
        "&:hover": {
            color: "white"
        },
        color: "white"
    },
    icon: {
        fontSize: '30px',
        color: 'green',
        textAlign: 'center'
    },
    iconerror:{
        fontSize: '30px',
        color: 'red',
        textAlign: 'center'
    }
});

function createData(id: number, tipo: string, inicio, ultimoMov, estado: string) {
    return { id, tipo, inicio, ultimoMov, estado };
}


const ModalJuicio = (props) => {
    // Juicio y Obligations
    let obligationsToRender = [];
    let obligacionesJuicio = []; 
    let honorarios = []; 
    
    if(props.alredyFiltered){
        // Filtro honorarios
        obligacionesJuicio = props.Obligations.filter( obl => !props.trialsConfig.feesTaxConcept.feesTaxConcept.includes(parseInt(obl.impuesto.toString().concat(obl.concepto.toString()))))
        honorarios = props.Obligations.filter( obl => props.trialsConfig.feesTaxConcept.feesTaxConcept.includes(parseInt(obl.impuesto.toString().concat(obl.concepto.toString()))))
        
        obligationsToRender = props.Obligations
    } 

    if(props.Juicio !== undefined && obligationsToRender.length == 0)
        props.Obligations.forEach( ob => {if(ob.juicio_id == props.Juicio.id){obligationsToRender.push(ob)}});


    const [isChecked, setIsCheked] = useState(props.selectedObligations.some(elem => obligationsToRender[0].id === elem.id));

    const handleAddJuicio = () => {
        obligationsToRender.forEach( obl => props.modifyObligation(obl, true))
        setIsCheked(true)
        props.handleClose()
    }

    const handleRemoveJuicio = () => {
        obligationsToRender.forEach( obl => props.modifyObligation(obl, false))
        setIsCheked(false)
        props.handleClose()
    }

    const classes = useStyles();
    if(props.Juicio !== undefined)
    {
        if(props.trialsConfig.partialPay) {
            return (
                <Dialog open={props.open}   fullWidth={true}
                maxWidth={'md'} onClose={props.handleClose}>
                    <DialogTitle id="responsive-dialog-title">
                      Juicio {props.Juicio.id}
                    </DialogTitle>
                    <DialogContent>
                        <b>Capital:</b> {<NumberCurrency value={props.Juicio.capital.toFixed(props.config.decimals)} />} <br/>
                        <b>Gastos:</b> {<NumberCurrency value={props.Juicio.gastos.toFixed(props.config.decimals)} />} <br/>
                        <b>Gastos martillero:</b> {<NumberCurrency value={props.Juicio.gastos_martillero.toFixed(props.config.decimals)} />} <br/>
                        <b>Honorarios:</b> {<NumberCurrency value={props.Juicio.honorarios.toFixed(props.config.decimals)} />} <br/>
                        <b>Honorarios martillero:</b> {<NumberCurrency value={props.Juicio.honorarios_martillero.toFixed(props.config.decimals)} />} <br/>
                        <b>Inicio de demanda:</b> {moment(props.Juicio.inicio_demanda).format('DD/MM/YYYY')} <br/>
                        <b>Interes punitorio:</b> {<NumberCurrency value={props.Juicio.interes_punitorios.toFixed(props.config.decimals)} />} <br/>
                        <b>Interes resarcitorio:</b> {<NumberCurrency value={props.Juicio.interes_resarcitorio.toFixed(props.config.decimals)} />} <br/>
                        <b>Porcentaje Iva:</b> { props.Juicio.porcentaje_iva + '%'} <br/>
                        <b>Procurador:</b> {props.Juicio.procurador} <br/>
                        <b>Tipo:</b> {props.Juicio.tipo} <p/>
                        <b>Obligaciones incluidas:</b> <p/>
                         {<ObligationList  obligationList={obligationsToRender} agregable juicios/>}<br/>
                        <b>Total de juicio:</b> {<NumberCurrency value={props.ToPay.toFixed(props.config.decimals)} />} <br/>
                    </DialogContent>
                    <DialogActions>
        
                    <Button
                        onClick={props.handleClose}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        Volver
                    </Button>
           
                    
                    </DialogActions>
                </Dialog>
            );
        }
        else {
            return (
                <Dialog open={props.open}   fullWidth={true}
                maxWidth={'md'} onClose={props.handleClose}>
                    <DialogTitle id="responsive-dialog-title">
                      Juicio {props.Juicio.id}
                    </DialogTitle>
                    <DialogContent>
                        <b>Capital:</b> {<NumberCurrency value={props.Juicio.capital.toFixed(props.config.decimals)} />} <br/>
                        <b>Gastos:</b> {<NumberCurrency value={props.Juicio.gastos.toFixed(props.config.decimals)} />} <br/>
                        <b>Gastos martillero:</b> {<NumberCurrency value={props.Juicio.gastos_martillero.toFixed(props.config.decimals)} />} <br/>
                        <b>Honorarios:</b> {<NumberCurrency value={props.Juicio.honorarios.toFixed(props.config.decimals)} />} <br/>
                        <b>Honorarios martillero:</b> {<NumberCurrency value={props.Juicio.honorarios_martillero.toFixed(props.config.decimals)} />} <br/>
                        <b>Inicio de demanda:</b> {moment(props.Juicio.inicio_demanda).format('DD/MM/YYYY')} <br/>
                        <b>Interes punitorio:</b> {<NumberCurrency value={props.Juicio.interes_punitorios.toFixed(props.config.decimals)} />} <br/>
                        <b>Interes resarcitorio:</b> {<NumberCurrency value={props.Juicio.interes_resarcitorio.toFixed(props.config.decimals)} />} <br/>
                        <b>Porcentaje Iva:</b> { props.Juicio.porcentaje_iva + '%'} <br/>
                        <b>Procurador:</b> {props.Juicio.procurador} <br/>
                        <b>Tipo:</b> {props.Juicio.tipo} <p/>
                        <b>Obligaciones incluidas:</b> <p/>
                         {<ObligationList  obligationList={obligationsToRender}/>}<br/>
                        <b>Total de juicio:</b> {<NumberCurrency value={props.ToPay.toFixed(props.config.decimals)} />} <br/>
                    </DialogContent>
                    <DialogActions>
        
                    <Button
                        onClick={props.handleClose}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        Volver
                    </Button>
                    {!isChecked ? <Button
                        onClick={handleAddJuicio}
                        variant="contained"
                        color="primary"
                        autoFocus
                    >
                        Agregar
                    </Button> :
                    <Button
                    onClick={handleRemoveJuicio}
                    variant="contained"
                    color="primary"
                    autoFocus
                    >
                        Quitar
                    </Button>}
                    </DialogActions>
                </Dialog>
            );
        }
    }
    else
    {
        return null;
    }
}

const mapState = state => ({
    config: state.auth.config,
    obligationStates: state.payments.obligationStates,
    selectedObligations: state.payments.selectedObligations,
    trialsConfig: state.payments.trialsConfig
})

const mapDispatch = dispatch => ({
    modifyObligation: (checkedObligation, isCheked) => dispatch.payments.modifyObligation(checkedObligation, isCheked),
})

export default connect(mapState, mapDispatch)(ModalJuicio);