import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      color: theme.header.color,
      fontSize: "0.8em",
      textAlign: "right",
      lineHeight: "0.8em",
      fontWeight: 100,
    },
    padding: {
      padding: "6px 10px 0 0",
    },
    denominacion: {
      fontSize: "0.9em",
      color: theme.header.color,
      lineHeight: "1em",
      textAlign: "right",
      margin: "3px 0",
    },
    avatar: {
      width: "50px",
      height: "50px",
    },
    nowrap: {
      flexWrap: "nowrap",
    },
  })
);
const UserData = (props) => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.nowrap}>
        {!props.mobile && (
          <Grid item className={classes.padding}>
            <Typography className={classes.text}>
              <FormattedMessage
                id="menu.representing"
                defaultMessage="Estas representando a"
              />
            </Typography>
            <Typography className={classes.denominacion}>
              {props.denominacion}
            </Typography>
            {!props.publicLogin && (
              <Typography className={classes.text}>{props.cuit}</Typography>
            )}
          </Grid>
        )}
        <Grid item>
          <Avatar className={classes.avatar} variant="square">
            {props.denominacion
              .split(" ")
              .slice(0, 3)
              .map((elem) => elem[0])
              .join("")}
          </Avatar>
        </Grid>
      </Grid>
    </>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  publicLogin: state.auth.publicLogin,
  denominacion: state.auth.denominacion,
  atencion: state.auth.atencion,
});

export default connect(mapState, null)(UserData);
