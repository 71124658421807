import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import PlanTab from "../PlanTab";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import LoadingComponent, {
    FalsoTexto,
  } from "../../../common/LoadingComponent";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles({
  seccion: {
    margin: "25px 0 40px",
    padding: 0,
  },
  panel: {
    background: "white",
    padding: 0,
  },
  alignRight: {
    textAlign: "right",
  },
  title: {
    borderBottom: "solid 1px #ddd",
    marginBottom: "10px",
    color: "#444",
  },
  margBtnNeg: {
    marginBottom: "-15px",
  },
});

const AntTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&$selected": {
        color: "#1890ff",
        background: "white",
        borderRadius: "5px  5px 0 0",

        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

interface StyledTabProps {
  label: string;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const classes = useStyles();

  const { children, value, index, ...other } = props;
  return (
    <Paper
      elevation={1}
      component="div"
      className={classes.panel}
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Paper>
  );
}
const Partida = (props) => {
  const [valueTab, setValueTab] = useState(0);
  const [totalPlan, setTotalPlan] = useState(props.partida.totalPlan);
  const [montoAnticipo, setMontoAnticipo] = useState(
    props.partida.decretos[0].porcentajesInteres[0].anticipo_minimo
  );
  const [valorCuota, setValorCuota] = useState(
    _.round(
      props.partida.totalPlan -
        props.partida.decretos[0].porcentajesInteres[0].anticipo_minimo,
      2
    )
  );
  const [cantidadCuotas, setCantidadCuotas] = useState(1);
  const [fechaAnticipo, setFechaAnticipo] = useState(
    moment().format("DD/MM/YYYY")
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValueTab(newValue);
    setMontoAnticipo(
      props.partida.decretos[newValue].porcentajesInteres[0].anticipo_minimo
    );
    setCantidadCuotas(1);
    setValorCuota(
      totalPlan -
        props.partida.decretos[newValue].porcentajesInteres[0].anticipo_minimo
    );
    setFechaAnticipo(moment().format("DD/MM/YYYY"));
  };

  useEffect(() => {
    actualizarPlan();
  });

  const onCuotaChanged = (newCantidadCuotas) => {
    setCantidadCuotas(newCantidadCuotas);
    setValorCuota(_.round((totalPlan - montoAnticipo) / newCantidadCuotas, 2));
  };
  const onFechaAnticipoChanged = (newFechaAnticipo) => {
    setFechaAnticipo(moment(newFechaAnticipo).format("DD/MM/YYYY"));
  };
  const onMontoAnticipoChanged = (newMontoAnticipo) => {
    setMontoAnticipo(newMontoAnticipo);
    setValorCuota(_.round((totalPlan - newMontoAnticipo) / cantidadCuotas, 2));
  };
  const actualizarPlan = () => {
    const plan = {
      numeroPlan: props.partida.numeroPlan,
      totalPlan: totalPlan,
      decreto: props.partida.decretos[valueTab].id,
      descripcionDecreto: props.partida.decretos[valueTab].decreto,
      anticipo: montoAnticipo,
      valorCuota: valorCuota,
      cantidadCuotas: cantidadCuotas,
      fechaAnticipo: fechaAnticipo,
    };

    props.updatePlans([plan]);
  };
  const classes = useStyles();
  return (
    <div className={classes.seccion}>
      <Grid className={classes.title} container>
        <Grid item xs={6}>
          {" "}
          <h3>
            <FormattedMessage
              id="paymentsPlanPartida.title.plan"
              defaultMessage="Plan de Pago N°"
            />{" "}
            {props.partida.numeroPlan}
          </h3>
        </Grid>
        <Grid item className={classes.alignRight} xs={6}>
          <IconButton  
            disabled={props.isLoading} 
            className={classes.margBtnNeg} 
            onClick={props.handleDeletePlan} 
            aria-label="delete">
            <DeleteForeverIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <LoadingComponent isLoading={props.isLoading}>
      <Tabs
        value={valueTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="fullWidth"
        aria-label="Partida"
      >
        {props.partida.decretos.map((dec, index) => (
          <AntTab key={index} value={index} label={dec.decreto} />
        ))}
      </Tabs>
      {props.partida.decretos.map((dec, index) => (
        <TabPanel key={index} value={valueTab} index={index}>
          <PlanTab
            Decreto={dec}
            totalIntereses={(
              props.partida.totalPlan - props.partida.totalPlanCapital
            ).toFixed(2)}
            onMontoAnticipoChanged={onMontoAnticipoChanged}
            onFechaAnticipoChanged={onFechaAnticipoChanged}
            onCuotaChanged={onCuotaChanged}
            totalActualizado={props.partida.totalPlan}
            Incluidas={props.partida.incluidas}
            handleDelete={props.handleDelete}
          />
        </TabPanel>
      ))}
      </LoadingComponent>
    </div>
  );
};

const mapState = (state) => ({
  simulation: state.paymentPlans.simulation,
});

const mapDispatch = (dispatch) => ({
  updatePlans: (plans) => dispatch.paymentPlans.updatePlans(plans),
});

export default connect(mapState, mapDispatch)(Partida);
