import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import "moment/locale/es";
import moment from "moment";
import { useTheme } from "@material-ui/core/styles";
const DateTimeAuditField = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    meta: { touched, invalid, error },
    name,
    input,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const date = moment.utc().local().locale("es");

  const [visible, setVisible] = React.useState(
    inputelement.conditional ? false : true
  );

  let clockInterval = null;
  const theme = useTheme();
  useEffect(() => {
    if (input.value === "") {
      clockInterval = setInterval(
        () => input.onChange(date.add(1, "s").toISOString()),
        1000
      );
    } else {
      input.onChange(input.value);
    }
    return () => clearInterval(clockInterval);
  }, []);

  if (visible) {
    return (
      <FormControl
        {...custom}
        error={touched && invalid}
        className={classes.formcontrol}
      >
        <TextField
          inputVariant={
            theme.inputs.variant ? theme.inputs.variant : "standard"
          }
          readonly={true}
          fullWidth
          label={inputelement.label}
          placeholder={inputelement.label}
          error={touched && invalid}
          helperText={touched && error}
          value={
            moment(input.value).isValid()
              ? moment(input.value).format("DD/MM/YYYY HH:mm:ss")
              : date.format("DD/MM/YYYY HH:mm:ss")
          }
          {...custom}
        />
      </FormControl>
    );
  } else return null;
};

const mapState = (state) => ({});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(DateTimeAuditField);
