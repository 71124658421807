import React, { useEffect } from "react";

import { Calendar } from "@material-ui/pickers";
import VencimientosHorizontal from "../../../components/PublicPage/Components/Vencimientos/VencimientosHorizontal";
import apiCMS from '../../../services/CMSServices';
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
const ExpirationsContainer = (props) => {

  const [expirations, setExpirations] = React.useState([]);

  useEffect(() => {
    apiCMS.ExpirationsServices.getExpirationsData()
        .then((response) => {
          setExpirations(response.data);
        });
}, []);

  return (
      <Container maxWidth='lg'>
        <Grid container>
          <Grid item sm={6}>
            {expirations.map((el, i) => (
              <VencimientosHorizontal
                obj={el}
                key={el.fecha}
                last={i === expirations.length - 1}
              />
            ))}
          </Grid>
          </Grid>
        </Container>
  );
};

export default ExpirationsContainer;
