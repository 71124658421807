import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import {connect} from 'react-redux';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Link } from "react-router-dom";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import Icon from '@material-ui/core/Icon';
import Paper from "@material-ui/core/Paper";
import { FormattedMessage } from "react-intl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 752,
    },
    link:{
      textDecoration:'none !important',
      color:'#444'
    },
    demo: {
      margin: '0 20px',
      backgroundColor: theme.palette.background.paper,
      height: '100%',
      border: 'solid 1px #dedede',
      [theme.breakpoints.down('sm')]: {
        margin: "20px 0",
      },
    

    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    linkTitle: {
      padding: "10px 10px 5px 12px",
      background: '#e6e6e6',
      
      fontWeight: 400,
      marginBottom: "0",
    },
  })
);

function generate(element: React.ReactElement) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

const AgentLinks = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.demo}>
      <Typography className={classes.linkTitle}><FormattedMessage
                      id="agentDashboard.links"
                      defaultMessage="Links útiles"
                    /></Typography>
      <List dense={false}>
        {props.links.map((el, idx) => (
          <a className={classes.link} key={idx} href={el.to} target={el.target}>
            <ListItem button onClick={() => {}}>
         
              <ListItemIcon>
                <Icon>link</Icon>
              </ListItemIcon>
          



              <ListItemText
                primary={el['text_' + props.locale.toUpperCase()]}
              />
            </ListItem>
          </a>
        ))}
      </List>
    </Paper>
  );
};

const mapState = state => ({
  locale: state.auth.locale
})

export default connect(mapState, null)(AgentLinks);
