import React, { useEffect, useRef } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import {
  getUrl,
  getDataFromResponse,
  applyDependencies,
  applyInitialDependencies,
  getDependencies,
} from "./Operations";
import Axios, { Method } from "axios";
import _ from "lodash";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
const fullWidthOp = false;

const TextAreaField = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const theme = useTheme();
  const {
    meta: { touched, invalid, error },
    name,
    input,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const [valores, setValores] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const mountedRef = useRef(true);
  const context = useContext(ctxts);
  const [url, setUrl] = React.useState(
    // url para datos asincronos si cambia ejecuta useEffect
    inputelement.asyncData
      ? getUrl(
          input.name,
          inputelement.asyncConf.url.replace("[value]", input.value),
          props.cuit,
          values,
          null,
          null,
          inputelement.asyncConf.baseUrl,
          context.BaseUrls
        )
      : ""
  );

  //use efect para calculos entre campos
  useEffect(() => {
    applyDependencies(props);
  });

  //use efect para traer datos asincronos
  const dependencias = getDependencies(input, inputelement);
  const focusedInput = props.appContext.inputFocus;
  useEffect(() => {
    // TIENE DEPENDENCIA EN URL
    if (!props.isLastPaso) return;
    if (
      inputelement.asyncData &&
      !dependencias.some((d) => d === props.appContext.inputFocus)
    ) {
      setIsLoading(true);
      Axios({
        method: inputelement.asyncConf.method as Method,
        url: url,
        headers: { Authorization: `Bearer ${props.jwt}` },
      })
        .then((response) => {
          if (!mountedRef.current) return null;
          const res = response;
          const ValueToSet = getDataFromResponse(
            res,
            inputelement.asyncConf.propertyText
          );
          setIsLoading(false);
          input.onChange(ValueToSet); //esto setea el valor al input se usa en campos tipo text, number: En otros campos podrian ser las opciones del select o checkboxs
        })
        .catch(function (error) {
          if (!mountedRef.current) return null;
          setIsLoading(false);
          console.log(error.message);
        });
      //get data from api with newUrl
    }
  }, [url, focusedInput]); //se ejecuta solo si la url cambia
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //use efect para inicializar el componente
  useEffect(() => {
    applyInitialDependencies(props);
  }, [
    inputelement.dependsOn ? _.get(values, inputelement.dependsOn[0]) : null,
  ]);

  return (
    <div
      className={isLoading || props.loadingAsyncData ? classes.loading : null}
    >
      <FormControl
        {...custom}
        error={touched && invalid}
        className={classes.formcontrol}
      >
        <TextField
          variant={theme.inputs.variant ? theme.inputs.variant : "standard"}
          algoaca={valores}
          fullWidth
          label={inputelement.label}
          placeholder={inputelement.label}
          error={touched && invalid}
          helperText={touched && error}
          multiline
          {...input}
          {...custom}
        />
        {props.meta.touched && error && (
          <span style={{ color: "red" }}>{error}</span>
        )}
      </FormControl>
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(TextAreaField);
