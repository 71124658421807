import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import { useStyles } from "./styles";

const fullWidthOp = false;
const PasswordField = (props) => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false);

  const _handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const _handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl fullWidth={fullWidthOp} className={classes.formcontrol}>
      <InputLabel
        className={classes.outputField}
        htmlFor="standard-adornment-password"
      >
        {props.label}
      </InputLabel>
      <Input
        className={classes.outputField}
        disabled
        
        id="standard-adornment-password"
        type={showPassword ? "text" : "password"}
        value={props.value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={_handleClickShowPassword}
              onMouseDown={_handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

const TagsField = (props) => {
  const classes = useStyles();
  const { label, value } = props;

  const [val, setVal] = React.useState(props.value ? props.value : []);

  const setValue = (value, input) => {
    setVal(value);
    input.onChange(value); //update forn-redux value
  };

  return (
    <FormControl fullWidth={fullWidthOp} className={classes.formcontrol}>
      <Autocomplete
        className={classes.outputField}
        multiple
        id="tags-field"
        options={[].map((option) => option.title)}
        disabled
        onChange={(event, value, reason) => setValue(value, event)}
        value={val}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              className={classes.outputField}
              variant="outlined"
              label={option}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </FormControl>
  );
};

const TextSimpleField = (props) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formcontrol}>
      <TextField
        className={classes.outputField}
        disabled
        label={props.label}
        value={props.value ||props.meta.initial }
      />
    </FormControl>
  );
};

const ImageField = (props) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formcontrol}>
      <div className={classes.fileInputContainer}>
        {props.img && props.img != "undefined" ? (
          <img
            src={props.img}
            className={classes.fileInputImage}
            alt="Imagen Seleccionada"
          />
        ) : (
          <label className={classes.inputlabel}>
            <input
              disabled
              type="file"
              onChange={() => {}}
              name="inputfile"
              id="inputfile"
              accept="image/png, image/jpeg"
              className={classes.inputfield}
            />

            <span className={classes.fileInputLabel}>
              <WallpaperIcon />
              {props.label}
            </span>
          </label>
        )}
      </div>
    </FormControl>
  );
};


export {
  PasswordField,
  TextSimpleField,
  TagsField,
  ImageField,
 
};
