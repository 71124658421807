import React, {useState} from 'react';
import { Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';

const ObligationCheckbox = (props) => {
    const [isChecked, setIsCheked] = useState(false);
    const handleCheckboxChange = (event) => {
        //props.modifyObligation(props.obligation, event.target.checked);
        setIsCheked(!isChecked)
    }
    return(
        <Checkbox
        checked={isChecked}
        color="primary"
        onChange={handleCheckboxChange}
        inputProps={{ 'aria-label': 'Checkbox ' + props.obligation.objeto_id }}
      />
    )
}


export default ObligationCheckbox;