export default {
    state: {
        taxesTypes: []
    },
    reducers: {
        addTaxesTypes(state, taxesList){
            return Object.assign({}, {...state, taxesTypes:taxesList})
        },
    },
    effects: {},
}