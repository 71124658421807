import React from 'react';
import _ from 'lodash';
import ObligationAccordion from "./ObligationAccordion";
import {Grid, Divider} from "@material-ui/core";
import ObligationMapper from './ObligationMapper';
import moment from 'moment';
import 'moment-timezone';
//import './styles.css';
import { FormattedMessage } from 'react-intl';
const TaxItem = (props) => {
    const obligationsByYear = _.groupBy(props.object.obligaciones, "periodo");
    const keySort = _.reverse(_.keys(obligationsByYear));

    const calcTotalDebtTotal = (obligations) => {
        let total = 0;
        obligations.forEach( obligation => {
            if(obligation.cuota != '0'){
                if(obligation.exenta){
                    total = total + ((obligation.saldoConRecargo * (100 - obligation.porcentaje_exencion)) / 100);
                } else {
                    total = total + obligation.saldoConRecargo;
                }
            }
        })
        return total;
    }

    const calcTotalDebtCapital = (obligations) => {
        let total = 0;
        obligations.forEach((obl => total += obl.saldo))
        return total;
    }

    const currentPeriod = (obligations) => {
        const currentPeriod = _.sortBy(obligations, ['vencimiento']);
        const expired = [];
        const current = [];
        const next = [];
        const toDay = moment().format('YYYY-MM-DD');
        currentPeriod.forEach((obl) => {
            const expireDate = moment(obl.vencimiento).add(3, 'hours').format('YYYY-MM-DD');
            if (moment(toDay).isAfter(expireDate) && obl.cuota != 0) {
                expired.push(obl)
            } else {
                // TODO: change number of days to be dynamic and load it from backend
                if (moment(expireDate).isBetween(moment(toDay), moment(toDay).add('30', 'days')) || obl.cuota == 0) {
                    current.push(obl)
                } else {
                    next.push(obl)
                }
            }
        });
        return [current, next, expired]
    }

    const showTotalCurrentObligation = (current) => {
        if(current.length > 0)
            return (
                <div className="row justify-content-center Total-a-Vencer"
                 style={{paddingBottom: '15px', paddingTop: '15px'}}>
                <div className="col">
                    <strong><FormattedMessage id="myTaxes.totalDebtToBeDue" defaultMessage="Total de deuda a vencer" />: </strong>{calcTotalDebtTotal(current)}
                </div>
            </div>
            )
    }

    const showObligation = (year, obligations) => {
        if ((new Date()).getFullYear() <= year) {
            const [current, next, expired] = currentPeriod(obligations)
            return (
                <div key={year}>
                    <Grid item xs={12}>
                        <ObligationMapper obligations={current}/>
                        {showTotalCurrentObligation(current)}
                    </Grid>
                    {next.length > 0 ? <ObligationAccordion year={year} obligations={next} isExpired={false}/> : null}
                    {expired.length > 0 ? <ObligationAccordion year={year} obligations={expired} isExpired={true}/> : null}

                </div>
            )
        } else {
            return (<ObligationAccordion key={year} year={year} obligations={obligations} isExpired={true}/>)
        }
    }
    return (keySort.map((year) => showObligation(year, obligationsByYear[year])));
}



export default TaxItem;