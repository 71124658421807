import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import { connect } from "react-redux";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "../../../components/common/MaterialComponents/Button";
import LoadingAccordion from "../../MyTaxes/Taxes/LoadingAccordion/LoadingAccordion";
import Spinner from "../../common/Spinner";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { IApplication } from "../DinamycForm/models/IDynamicForm";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import ApplicationListMobile from "./ApplicationListMobile";
//import {applicationforms} from "../../../mock/Tramites.mock";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    marginTop: "15px",
  },
  Link: {
    "&:hover": {
      color: "white",
    },
    color: "white",
  },
  Menu: {
    borderRight: `1px solid ${theme.palette.divider}`,
    marginRight: "-15px",
    paddingRight: "15px",
    paddingTop: "10px",
    border: theme.superficies.border,
    textAlign: "center",
  },
  tabs: {
    height: "100%",
    marginRight: "-15px",
  },
  forms: {
    background: "#fff",
    width: "100%",
    marginRight: "15px",
    border: theme.superficies.border,
  },
  alignRight: {
    textAlign: "right",
    borderTop: theme.superficies.border,
    padding: "10px 0 0",
  },
  formulario: {
    padding: "10px 140px",
  },
  btns: {
    margin: "0 15px",
  },
  AppMenu: {
    "& $span": {
      alignItems: "flex-start",
      textAlign: "left",
    },
  },
  containerMobile: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    paddingBottom: "40px",
  },
  filter: {
    margin: "10px ",
    width: "100%",
  },
  filterMobile: {
    margin: "10px 0 20px",
    width: "100%",
  },
  col: {
    flex: "0 0 auto",
    width: "auto",
    maxWidth: "100%",
    position: "relative",

    paddingRight: "15px",
    paddingLeft: "15px",
  },
  formDescripcion: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "360px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "initial",
      borderTop: theme.superficies.border,
    },
  },
}));

class Application implements IApplication {
  id?: number;
  description?: string;
  descripcion?: string;
  requirements?: string;
  requirementsImage?: string;
  step?: any[];
  submit?: string;

  constructor(obj?: any) {
    this.id = (obj && obj.id) || 0;
    this.description = (obj && obj.description) || "";
    this.descripcion = (obj && obj.descripcion) || "";
    this.requirements = (obj && obj.requirements) || "";
    this.requirementsImage = (obj && obj.requirementsImage) || "";
    this.step = (obj && obj.step) || [];
    this.submit = (obj && obj.submit) || "";
  }
}

const ApplicationsList = (props) => {
  const classes = useStyles();

  const [application, setApplication] = React.useState(new Application());
  const [loadingText, setLoadingText] = React.useState(null);
  const [filter, setFilter] = React.useState("");

  const [value, setValue] = React.useState(
    props.mobile
      ? null
      : props.formList.length > 0
      ? props.formList[0].id
      : null
  );
  const [expanded, setExpanded] = React.useState(false);

  //   const handleChange = panel => (event, isExpanded) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };
  const handleChangeCollapse = (newValue: string) => {
    setValue(value === newValue ? false : newValue);
  };
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  useEffect(() => {
    setLoadingText(
      <FormattedMessage
        id={"applicationList.loadingForm"}
        defaultMessage="Estamos cargando el form..."
      />
    );
    if (value) {
      let appForm = props.formList.find((el) => el.id == value);
      setApplication(appForm);
    }
    setLoadingText("");
  }, [value]);

  const filterItems = (data) => {
    const datar = data.filter(
      (el) =>
        !el.usoInterno &&
        el.visualizarTramite !== false &&
        el.nombre?.toLowerCase().indexOf(filter.toLowerCase()) > -1
    );
    if (datar.some((d) => d.id === value)) {
    } else {
      if (datar.length > 0) {
        setValue(datar[0].id);
      } else {
        if (value) setValue(null);
      }
    }

    // filterItems(props.formList).
    return datar;
  };

  function renderRequerimentText(app) {
    if (!value) {
      return null;
    }
    return (
      <div className={classes.formDescripcion}>
        {application.descripcion ? (
          <div
            dangerouslySetInnerHTML={{ __html: application.descripcion }}
          ></div>
        ) : null}
        <br />
        {application.requirementsImage ? (
          <img src={application.requirementsImage} alt="Steps" width={"100%"} />
        ) : null}
        <br />
        <br />
        <div className={classes.alignRight}>
          {app.urlExterna ? (
            <a
              href={app.urlExterna.replace(
                "{{access_token}}",
                props.accessToken
              )}
            >
              <Button variant="contained" color="primary">
                <FormattedMessage
                  id="applicationList.startApplication"
                  defaultMessage="Iniciar Tramite"
                />
              </Button>
            </a>
          ) : (
            <Button
              onClick={() => {
                history.push({
                  // TODO: change this url to config param.
                  pathname:
                    "/detalle-tramite/" +
                    JSON.stringify({ paramTramiteId: application.id }),
                });
              }}
              variant="contained"
              color="primary"
            >
              <FormattedMessage
                id="applicationList.startApplication"
                defaultMessage="Iniciar Tramite"
              />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {props.mobile ? (
        <div className={classes.containerMobile}>
          <TextField
            value={filter}
            className={classes.filterMobile}
            onChange={handleFilterChange}
            id="outlined-basic"
            label={
              <FormattedMessage
                id="applicationList.list.filter"
                defaultMessage="Filtrar"
              />
            }
            variant="outlined"
          />

          {props.formList ? (
            <>
              {filterItems(props.formList).map((app) => (
                <ApplicationListMobile
                  expanded={value === app.id}
                  Change={() => handleChangeCollapse(app.id)}
                  id={app.id}
                  key={app.id}
                  title={app.nombre}
                >
                  {app.id === value && renderRequerimentText(app)}
                </ApplicationListMobile>
              ))}
            </>
          ) : null}
        </div>
      ) : (
        <>
          <div className={classes.col}>
            <div className={classes.Menu}>
              <TextField
                className={classes.filter}
                value={filter}
                onChange={handleFilterChange}
                id="outlined-basic"
                label={
                  <FormattedMessage
                    id="applicationList.list.filter"
                    defaultMessage="Filtrar"
                  />
                }
                variant="outlined"
              />

              {props.formList ? (
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  style={{ maxHeight: "400px" }}
                  className={classes.tabs}
                >
                  {filterItems(props.formList).map((app) => (
                    <Tab
                      className={classes.AppMenu}
                      label={app.nombre}
                      key={app.id}
                      value={app.id}
                      {...a11yProps(app.id)}
                    />
                  ))}
                </Tabs>
              ) : null}
            </div>
          </div>
          <div className={classes.forms}>
            {loadingText ? (
              <div>
                <Spinner />
              </div>
            ) : (
              props.formList &&
              props.formList.map((app) => (
                <TabPanel value={value} index={app.id} key={app.id}>
                  {app.id === value && renderRequerimentText(app)}
                </TabPanel>
              ))
            )}
          </div>
        </>
      )}
    </div>
  );
};

const mapState = (state) => ({
  procedureForm: state.procedures.procedureForm,
  cuit: state.auth.cuit,
  formList: _.orderBy(state.applications.formList, "nombre"),
  accessToken: state.auth.accessToken,
});

const mapDispatch = (dispatch) => ({
  resetProcedureForm: () => dispatch.procedures.resetProcedureForm(),
});

export default connect(mapState, mapDispatch)(ApplicationsList);
