import React, { useEffect } from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import UserCircle from "../../components/TaxProfile/UserCircle";
import { FormattedMessage } from "react-intl";
import HeaderBar from "../../components/TaxProfile/HeaderBar";
import UserCenteredLink from "../../components/TaxProfile/UserCenteredLink";
import apiCMS from "../../services/CMSServices";
import NumberCurrency from "../../components/common/NumberCurrency";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Theme,
  makeStyles,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import SectionExpirations from "../../components/TaxProfile/SectionExpirations";
import apiBUC from "../../services/BUCServices";

const useStyles = makeStyles((theme) =>
  createStyles({
    profile: {
      margin: "30px auto 0",
      position: "relative",
      padding: "0 80px 30px",
      [theme.breakpoints.down("sm")]: {
        padding: "0 0",
      },
      [theme.breakpoints.down("md")]: {
        padding: "0 20px",
      },
    },
    profmobile: {
      padding: "0 0px",
    },
    btnsContainer: {
      display: "flex",
      height: "300px",
      flexDirection: "column",
      justifyContent: "center",
    },
    btnLateral: {
      width: "100%",
      border: "solid #eee 4px",
      display: "flex",
      padding: "10px 30px",
      flex: "1",
      background: "#fff",
      transition: "all 300ms",
      flexDirection: "column",
      "&:hover": {
        margin: "0 -10px",
        transition: "all 300ms",
        textDecoration: "none",
      },
    },
    btnLateralDer: {
      padding: "10px 30px 10px 170px",
      transition: "all 300ms",
      "&:hover": {
        margin: "0 -10px 0 10px",
        transition: "all 300ms",
        textDecoration: "none",
      },
    },
    circuloCentral: {
      left: "50%",
      color: "white",
      width: "300px",
      height: "300px",
      top: "0",
      display: "flex",
      padding: "10px",
      position: "absolute",
      borderRadius: "50%",
      transform: "translate(-50%, 0px)",
      background: "#7979ff",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
    },
    txtSmall: {
      fontSize: "11px",
      color: "#6b6b6b",
    },
    txtBig: {
      fontSize: "34px",
      color: "#3684ca",
      lineHeight: "36px",
    },
    txtMd: {
      color: "#545454",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "21px",
    },
    btnsNoCentered: {
      marginTop: "80px",
      flexWrap: "wrap",
      justifyContent: "center",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
      },
      [theme.breakpoints.down("md")]: {
        marginTop: "0px",
      },
    },
    icon: { fontSize: "220px", lineHeight: "80px", marginBottom: "-20px" },
    userName: { fontSize: "22px", paddingBottom: "30px", lineHeight: "22px" },
    userCuit: { fontSize: "18px" },
    welcome: {
      fontWeight: 400,
      padding: "25px",
    },
    toolbar: {
      background: "#7979ff",
    },
    taxesFooter: {
      color: "white",
      position: "fixed",
      bottom: "20px",
      width: "100%",
      left: "0",
      padding: "30px",
      background: "#0584ce",
    },
  })
);

const TaxProfile = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const maxCenteredBtns = 6;
  const [noCenteredActions, setNoCenteredActions] = React.useState([]);
  const [leftSideActions, setLeftSideActions] = React.useState([]);
  const [rightSideActions, setRightSideActions] = React.useState([]);
  const [vencimientos, setVencimientos] = React.useState({
    objetos: [],
    objetosTerceros: [],
  });
  const [isLoading, setLoading] = React.useState(true);
  useEffect(() => {
    apiCMS.TaxesServices.getDashboard(props.taxRole).then((response) => {
      const parseActions = response.data
        .map((action) => action.dashboard)
        .sort(function (a, b) {
          return a.order - b.order;
        });
      let centeredBtns = parseActions.splice(0, maxCenteredBtns);
      const leftSideActions = centeredBtns.splice(
        0,
        Math.ceil(centeredBtns.length / 2)
      );
      setLeftSideActions(leftSideActions);
      setRightSideActions(centeredBtns);
      setNoCenteredActions(parseActions);
    });

    apiBUC.SubjectServices.getSubjectNextExpirations(props.cuit)
    .then((res) => {
      setVencimientos(res.data);
    })
    .catch(error => console.log('El sujeto no tiene objetos asociados. ' + error.message)
    );
  }, []);

  useEffect(() => {
    if (
      props.obligationStateActions.length > 0 &&
      props.obligationStates.length > 0
    ) {
      setLoading(false);
    }
  });

  return (
    <div>
      <HeaderBar />
      <Container
        maxWidth={mobile ? "xl" : "lg"}
        className={`${classes.profile}`}
      >
        <Grid container>
          <Grid item xs={12} sm={6} md={6}>
            <div className={classes.btnsContainer}>
              {leftSideActions.map((el, idx) => (
                <UserCenteredLink mobile={mobile} key={idx} element={el} />
              ))}
            </div>
          </Grid>
          {!mobile && <UserCircle />}
          <Grid item xs={12} sm={6} md={6}>
            <div className={classes.btnsContainer}>
              {rightSideActions.map((el, idx) => (
                <UserCenteredLink
                  mobile={mobile}
                  right
                  key={idx}
                  element={el}
                />
              ))}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.btnsNoCentered}>
              {noCenteredActions.map((el, idx) => (
                <UserCenteredLink
                  mobile={mobile}
                  noCentered={!mobile}
                  key={idx}
                  element={el}
                />
              ))}
            </div>
          </Grid>
        </Grid>

        {vencimientos.objetos.length > 0 && !isLoading ? (
          <Grid item xs={12}>
            {vencimientos.objetos ? (
              <SectionExpirations objects={vencimientos.objetos} />
            ) : null}
            {vencimientos.objetosTerceros ? (
              <SectionExpirations
                terceros
                objects={vencimientos.objetosTerceros}
              />
            ) : null}
          </Grid>
        ) : null}
      </Container>
    </div>
  );
};

const mapState = (state) => ({
  config: state.auth.config,
  cuit: state.auth.cuit,
  taxRole: state.auth.taxRole,
  totalToPay: state.payments.totalToPay,
  obligationStateActions: state.payments.obligationStateActions,
  obligationStates: state.payments.obligationStates,
});

export default connect(mapState, null)(TaxProfile);
