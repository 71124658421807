import React, { useEffect, useRef } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getUrl,
  applyDependencies,
  applyInitialDependencies,
  applySimpleCondition,
  getDependencies,
  getMask,
  validarDato,
  dependenciaCondicional,
  getDataFromResponse,
  getAsyncData,
} from "./Operations";
import _ from "lodash";
import { Button, TextField } from "@material-ui/core";
import InputMask from "./InputMask";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
const fullWidthOp = false;

//@todo probar deceimales
const NumberField = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    meta: { touched, invalid, error },
    name,
    input,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);
  const context = useContext(ctxts);
  //#region  //? Condicional  visible o no config
  const [visible, setVisible] = React.useState(
    inputelement.isConditional ? false : true
  );
  const mountedRef = useRef(true);
  const [urlCondition, setUrlCondition] = React.useState("");
  useEffect(() => {
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      dependenciaCondicional(inputelement, urlCondition, setVisible, props);
    }
  }, [urlCondition]);
  //#endregion
  //#region  //? mascara config
  const [reg, setReg] = React.useState([]);
  useEffect(() => {
    setReg(getMask(inputelement));
  }, [inputelement.mask]);
  //#endregion
  //#region //! actualizar  urls
  //? con estas dependencias y useEffect recalculo las urls
  //? solo cuando cambien los valores de los inputs que la url requiere

  const [url, setUrl] = React.useState("");
  const getArrayDependecies = () => {
    const dependencias = getDependencies(input, inputelement);
    return [
      dependencias.map((d) => {
        const dependenciaValue = _.get(values, d);
        return dependenciaValue ? dependenciaValue : "";
      }),
    ];
  };
  useEffect(() => {
    if (inputelement.asyncData) {
      setUrl(
        getUrl(
          input.name,
          inputelement.asyncConf.url,
          props.cuit,
          values,
          inputelement.asyncConf.JsonFieldsName,
          null,
          inputelement.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      setUrlCondition(
        getUrl(
          input.name,
          inputelement.conditional.asyncConf.url,
          props.cuit,
          values,
          inputelement.conditional.asyncConf.JsonFieldsName,
          null,
          inputelement.conditional.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [...getArrayDependecies()]);
  //#endregion
  //#region //? validaciones asyncronas
  const [msj, setMsj] = React.useState({
    show: false,
    type: "error",
    msj: inputelement.asyncValidationConf?.errorMsg,
  });

  const validar = () =>
    validarDato(inputelement, input, props, values, setMsj, context.BaseUrls);
  //#endregion
  //#region  //? calculos entre campos
  useEffect(() => {
    applyDependencies(props);
    if (inputelement.isConditional && !inputelement.conditional.asyncData) {
      setVisible(applySimpleCondition(props));
    }
  });
  //#endregion
  //#region //?traer datos asincronos
  const dependencias = getDependencies(input, inputelement);
  const focusedInput = props.appContext.inputFocus;
  useEffect(() => {
    if (!props.isLastPaso) return;
    if (
      inputelement.asyncData &&
      !dependencias.some((d) => d === props.appContext.inputFocus)
    ) {
      setIsLoading(true);
      const response = getAsyncData(inputelement, url, props, input);
      response.then((res) => {
        if (!mountedRef.current) return null;
        setIsLoading(false);
        if (res) {
          const ValueToSet = getDataFromResponse(
            res,
            inputelement.asyncConf.propertyText
          );
          input.onChange(ValueToSet);
        }
      });
    }
  }, [url, focusedInput]); //se ejecuta solo si la url cambia
  //#endregion
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#region //? inicializar el componente
  useEffect(() => {
    applyInitialDependencies(props);
  }, [
    inputelement.dependsOn ? _.get(values, inputelement.dependsOn[0]) : null,
  ]);
  //#endregion

  if (visible) {
    const op = reg.length > 0 ? { inputComponent: InputMask } : {};
    return (
      <div
        className={isLoading || props.loadingAsyncData ? classes.loading : null}
      >
        <FormControl
          {...custom}
          error={touched && invalid}
          className={classes.formcontrol}
        >
          <div className="d-flex">
            <TextField
              variant={theme.inputs.variant ? theme.inputs.variant : "standard"}
              fullWidth
              label={inputelement.label}
              placeholder={inputelement.label}
              error={touched && invalid}
              id={inputelement.name}
              {...input}
              {...custom}
              InputProps={{
                ...op,
              }}
              value={
                inputelement.extraProps?.decimales &&
                (props.disabled || inputelement.disabled)
                  ? parseFloat(input.value).toFixed(
                      inputelement.extraProps?.decimales
                    )
                  : input.value
              }
              // onChange={(e, val) => onChange(e, e.target.value)}
              // type="number"
              inputProps={{
                reg: reg,
                type: reg.length > 0 ? "text" : "number",
                min:
                  inputelement.extraProps?.min ||
                  inputelement.validatorParams?.minValue ||
                  null,
                max:
                  inputelement.extraProps?.max ||
                  inputelement.validatorParams?.maxValue ||
                  null,
                step: inputelement.extraProps?.decimales
                  ? parseFloat(
                      "0." +
                        "0".repeat(inputelement.extraProps?.decimales - 1) +
                        "1"
                    )
                  : null,
              }}
            />

            {inputelement.asyncValidation ? (
              <Button onClick={validar}>
                {inputelement.asyncValidationConf?.label}
              </Button>
            ) : null}
          </div>
          {msj.show ? (
            <span
              style={
                msj.type === "error" ? { color: "red" } : { color: "green" }
              }
            >
              {msj.msj}
            </span>
          ) : null}
          {(props.meta.touched || props.meta.modified) && error && (
            <span style={{ color: "red" }}>{error}</span>
          )}
        </FormControl>
      </div>
    );
  } else return null;
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(NumberField);
