import axios from 'axios';
const instance = axios.create({
    baseURL: window.config.apiBase.pagos
    });

const setToken = (jwt) => {instance.defaults.headers.common['Authorization'] =  'Bearer ' + jwt};    

const PaymentServices = (instance) => {
    const getPaymentTypes = (id: string, json: any) => instance.post(window.config.apiBase.pagos + 'medios-pagos/claves?cliente_id=' + id, json);
    const confirmPaymentType = (id: any, json: any) => instance.post(window.config.apiBase.pagos + 'boletas/', json);
    const generateReporte = (json: any, jwtStrapi: any) => axios.post(window.config.apiBase.reportes + 'reporte/' + 5, json, { headers: {"Authorization" : `Bearer ${jwtStrapi}`}});


    return {
        getPaymentTypes,
        confirmPaymentType,
        generateReporte
    };
}

const apiPayment = {
    services: PaymentServices(instance),
    setToken: setToken,
}
export default apiPayment;
