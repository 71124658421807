import React, { useEffect, useRef } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getUrl,
  applyDependencies,
  applyInitialDependencies,
  applySimpleCondition,
  getDependencies,
  getMask,
  validarDato,
  dependenciaCondicional,
  getAsyncData,
  getOptionsFromGroup,
} from "./Operations";
import Axios, { Method } from "axios";
import { Button, Input } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
// import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
import Select from "react-select";
import { useField } from "react-final-form";
const fullWidthOp = false;

const SelectSimpleField = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const inputelement: InputElement = props.inputelement;
  const {
    meta: { touched, invalid, error },
    name,
    input,
    meta,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;

  const customStyles = {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: isTableView ? "5px 14px" : "9.5px 14px",
    }),

    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 9999999999,
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "transparent",
      borderColor: "#000",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#000",
    }),
  };

  const showField = useField(input.name + "_selectedOption");
  const context = useContext(ctxts);
  const [value, setValue] = React.useState(
    input.value ? input.value : meta.initial ? meta.initial : ""
  );
  const mountedRef = useRef(true);
  //#region  //? Condicional  visible o no config
  const [visible, setVisible] = React.useState(
    inputelement.isConditional ? false : true
  );
  const [urlCondition, setUrlCondition] = React.useState("");
  useEffect(() => {
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      dependenciaCondicional(inputelement, urlCondition, setVisible, props);
    }
  }, [urlCondition]);
  //#endregion
  //#region  //? mascara config
  const [reg, setReg] = React.useState([]);
  useEffect(() => {
    setReg(getMask(inputelement));
  }, [inputelement.mask]);
  //#endregion
  //#region //! actualizar  urls
  //? con estas dependencias y useEffect recalculo las urls
  //? solo cuando cambien los valores de los inputs que la url requiere
  const [isLoading, setIsLoading] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const getArrayDependecies = () => {
    const dependencias = getDependencies(input, inputelement);
    return [
      dependencias.map((d) => {
        const dependenciaValue = _.get(values, d);
        return dependenciaValue ? dependenciaValue : "";
      }),
    ];
  };
  useEffect(() => {
    if (inputelement.asyncData) {
      setUrl(
        getUrl(
          input.name,
          inputelement.asyncConf.url,
          props.cuit,
          values,
          inputelement.asyncConf.JsonFieldsName,
          null,
          inputelement.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      setUrlCondition(
        getUrl(
          input.name,
          inputelement.conditional.asyncConf.url,
          props.cuit,
          values,
          inputelement.conditional.asyncConf.JsonFieldsName,
          null,
          inputelement.conditional.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [...getArrayDependecies()]);
  //#endregion
  //#region //? validaciones asyncronas
  const [msj, setMsj] = React.useState({
    show: false,
    type: "error",
    msj: inputelement.asyncValidationConf?.errorMsg,
  });

  const validar = () =>
    validarDato(inputelement, input, props, values, setMsj, context.BaseUrls);
  //#endregion
  //#region  //? calculos entre campos
  useEffect(() => {
    applyDependencies(props);
    if (
      inputelement.isConditional &&
      inputelement.conditional &&
      !inputelement.conditional.asyncData
    ) {
      setVisible(applySimpleCondition(props));
    }
  });
  //#endregion

  useEffect(() => {
    if (!props.isLastPaso) return;
    if (inputelement.optionsFromGroup) {
      const response = getOptionsFromGroup(
        inputelement.optionsConfig.groupName,
        values
      );

      if (response) {
        setOptions(
          Array.isArray(response)
            ? response.map((elem) => {
                return {
                  text: _.get(elem, inputelement.optionsConfig.propertyText)
                    ? _.get(elem, inputelement.optionsConfig.propertyText)
                    : "no hay opcion",
                  value: _.get(elem, inputelement.optionsConfig.propertyValue)
                    ? _.get(elem, inputelement.optionsConfig.propertyValue)
                    : "-1",
                };
              })
            : []
        );
      }
    }
  }, [_.get(values, inputelement?.optionsConfig?.groupName)]); //se ejecuta solo si la url cambia

  //#region //?traer datos asincronos
  const dependencias = getDependencies(input, inputelement);
  const focusedInput = props.appContext.inputFocus;
  useEffect(() => {
    if (!props.isLastPaso) return;
    if (
      inputelement.asyncData &&
      !dependencias.some((d) => d === props.appContext.inputFocus)
    ) {
      setIsLoading(true);
      const response = getAsyncData(inputelement, url, props, input);
      response.then((res) => {
        if (!mountedRef.current) return null;
        setIsLoading(false);
        if (res) {
          const data = _.get(
            res,
            inputelement.asyncConf.baseNode
              ? inputelement.asyncConf.baseNode
              : "data"
          );
          setOptions(
            Array.isArray(data)
              ? data.map((elem) => {
                  return {
                    text: _.get(elem, inputelement.asyncConf.propertyText)
                      ? _.get(elem, inputelement.asyncConf.propertyText)
                      : "no hay opcion",
                    value: _.get(elem, inputelement.asyncConf.propertyValue)
                      ? _.get(elem, inputelement.asyncConf.propertyValue)
                      : "-1",
                  };
                })
              : []
          );
        }
      });
    }
  }, [url, focusedInput]); //se ejecuta solo si la url cambia
  //#endregion
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#region //? inicializar el componente
  useEffect(() => {
    applyInitialDependencies(props);
  }, [
    inputelement.dependsOn ? _.get(values, inputelement.dependsOn[0]) : null,
  ]);
  //#endregion

  const [options, setOptions] = React.useState(
    inputelement.options?.length > 0
      ? inputelement.options
      : showField.input.value
      ? [showField.input.value]
      : [
          {
            text: "No hay opciones configuradas",
            value: "-1",
          },
        ]
  );

  const select_handleChange = (
    // event: React.ChangeEvent<{ value: unknown }>,
    data
  ) => {
    setValue(data.value as string);
    const valueToSet = data.value === "-1" ? null : data.value;
    input.onChange(valueToSet as string);
    if (data.value) {
      setShirnked(true);
    } else {
      setShirnked(false);
    }
    showField.input.onChange({
      value: valueToSet,
      text: data.label,
      disabled: true,
    });
  };
  const [shirnked, setShirnked] = React.useState(
    showField.value?.text?.length > 0 || value
  );
  if (visible && options) {
    return (
      <div
        className={isLoading || props.loadingAsyncData ? classes.loading : null}
      >
        <FormControl
          {...custom}
          fullWidth={fullWidthOp}
          error={touched && invalid}
          className={classes.formcontrol}
          variant={theme.inputs.variant ? theme.inputs.variant : "standard"}
        >
          <div
            className="d-flex"
            style={
              isTableView && {
                width:
                  options.find((op) => op.value === value)?.text.length * 8 +
                  90 +
                  "px",

                minWidth: "100px",
              }
            }
          >
            <div className="w-100">
              <InputLabel
                style={{ background: "white" }}
                shrink={shirnked}
                htmlFor={input.name + "SelectSearchSimple"}
              >
                {inputelement.label}
              </InputLabel>
              <Select
                aria-label={inputelement.label}
                id={input.name + "SelectSearchSimple"}
                styles={customStyles}
                isDisabled={props.disabled}
                // variant={
                //   theme.inputs.variant ? theme.inputs.variant : "standard"
                // }
                // fullWidth
                // {...custom}
                // labelId="IdLabel-selectField"
                // id={input.name + "SelectSimple"}
                onFocus={() => setShirnked(true)}
                menuPosition="fixed"
                options={options.map((op) => {
                  if (op?.value) return { label: op.text, value: op.value };
                })}
                value={{
                  label: options.find((op) => op.value === value)?.text,
                  value: options.find((op) => op.value === value)?.value,
                }}
                blurInputOnSelect
                // {...input}
                onChange={(data) => {
                  select_handleChange(data);
                }}
                //  required={custom.required}
              />
              {/* {options.map((opt, index) => {
                  if (opt?.value)
                    return (
                      <MenuItem
                        key={opt.value}
                        disabled={opt.disabled}
                        value={opt.value}
                      >
                        {opt.text ? opt.text : opt.value}
                      </MenuItem>
                    );
                })} */}
              {/* </Select> */}
            </div>
            {inputelement.asyncValidation ? (
              <Button onClick={validar}>
                {inputelement.asyncValidationConf?.label}
              </Button>
            ) : null}
          </div>
          {msj.show ? (
            <span
              style={
                msj.type === "error" ? { color: "red" } : { color: "green" }
              }
            >
              {msj.msj}
            </span>
          ) : null}
          {props.meta.touched && error && (
            <span style={{ color: "red" }}>{error}</span>
          )}
        </FormControl>
      </div>
    );
  } else return null;
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(SelectSimpleField);
