import React from 'react';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

interface SeccionHeaderProps {
    title: String;
}

const useStyles = makeStyles({
        opacity: {
            opacity: '.3',
            flex: 1
        },
        bkg: {
            background: '#fff',//'radial-gradient(circle, rgba(241,241,241,1) 0%, rgba(251,251,251,1) 33%, rgba(251,251,251,1) 59%, rgba(241,241,241,1) 100%)',
            //flex: 1,
            position: 'relative',
            textShadow: '0px 0px 5px rgb(0, 0, 0)',
            // height:'100px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            height: 'fit-content',
    /* flex-direction: column, */
    margin: '10px',
    borderRadius: '4px',
            '& p,h1,h2,h3,h4,h5,h6,span,div,*': {
               // color: 'transparent !important',
            }
        },
        msj: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            margin: 0,
            color: '#dcdcdc',
            fontSize: '40px'
        }, horizontal: {}, vertical: {}, falsoTexto: {

            width: '63%',
            height: '15px',
            borderRadius: '15px',
            display: 'inline-block',
            background: '#dadada',

        },
        bloquear: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 99,
            backgroundColor: 'transparent'

        },

        reflejo: {
            background: `linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(230,230,230,.4) 28%, rgba(230,230,230,.4) 71%, rgba(255,255,255,0) 100%)`,

            transform: 'rotate(28deg)',

            position: 'absolute',
            top: '-50%',
            height: '200%',
            width: '60px',
            left: '-30%', transition: 'all 3s',
            animationName: '$loading',
            animationDuration: '6s',

            animationIterationCount: 'infinite',

        }
        , '@keyframes loading': {
            from: {left: '-30%'},
            to: {left: '130%'}
        },


    }
);

export const FalsoTexto = () => {
    const classes = useStyles();
    return (<span className={classes.falsoTexto}></span>);
};


const LoadingComponent = (props) => {
    const classes = useStyles();

    if (props.isLoading) {
        return (<div  className={classes.bkg}>
            <div className={classes.opacity}>
                {props.children}
            </div>
            <div className={classes.reflejo}></div>
            <div className={classes.reflejo} style={{animationDelay: '2s'}}></div>
            <div className={classes.reflejo} style={{animationDelay: '4s'}}></div>
            <div className={classes.bloquear}></div>
        </div>);
    } else {
        return <div>  {props.children}</div>
    }
}
export default LoadingComponent;


