const CMSTaxesServices = (instance) => {

    const getTaxes = () => instance.get('taxes/');
    const getObligationStates = () => instance.get('obligation-states');
    const getObligationStatesActions = () => instance.get('obligation-state-actions');
    const getPublicTaxes = () => instance.get('taxes?showsInPublic=true');
    const getDashboard = (taxRoleId) => instance.get('tax-role-dashboards?tax_role.id=' + taxRoleId);
    const getTrialsConfig = () => instance.get('trials');

    return {
        getTaxes,
        getObligationStates,
        getObligationStatesActions,
        getPublicTaxes,
        getDashboard,
        getTrialsConfig
    };
};
export default CMSTaxesServices;