import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const PayAllButton = (props) => {
  const getObligationsToPay = () => {
    props.clearObligations();
    let obligationsList = [];
    props.allObjects.forEach((object) => {
      if (object.obligaciones.length !== 0) {
        object.obligaciones.forEach((obligation) => {
          if (obligation.cuota != "0") {
            obligationsList.push(obligation);
          }
        });
      }
    });
    props.setAllObligations(obligationsList);
    props.sumAllObligations();
  };
  return (
    <Button
      style={{ backgroundColor: "white" }}
      variant="outlined"
      size={props.size}
      color="primary"
      onClick={getObligationsToPay}
    >
      {/*TODO: Definir la ruta como un parametro dinamico.*/}
      <Link to={props.linkTo}>{props.buttonText}</Link>
    </Button>
  );
};

const mapDispatch = (dispatch) => ({
  clearObligations: () => dispatch.payments.clearObligations(),
  sumAllObligations: () => dispatch.payments.sumAllObligations(),
  setAllObligations: (obligationList) =>
    dispatch.payments.setAllObligations(obligationList),
});

const mapState = (state) => ({
  allObjects: state.payments.allObjects,
});

export default connect(mapState, mapDispatch)(PayAllButton);
