import _ from "lodash";
import Axios, { Method } from "axios";
import { getIn } from "final-form";

const fnSumArray = (values, dependencia, valor) => {
  const result =
    values[dependencia[0]].reduce((ac, item) => {
      if (item && item[dependencia[1]] !== undefined)
        return (
          ac +
          parseFloat(isNaN(item[dependencia[1]]) ? 0 : item[dependencia[1]])
        );
      else return ac;
    }, 0) ?? 0;

  valor += result;

  return valor;
};
const fnMinusArray = (values, dependencia, valor) => {
  const result =
    values[dependencia[0]].reduce((ac, item) => {
      if (item && item[dependencia[1]] !== undefined)
        return (
          ac +
          parseFloat(isNaN(item[dependencia[1]]) ? 0 : item[dependencia[1]])
        );
      else return ac;
    }, 0) ?? 0;
  if (valor === undefined) {
    valor = result;
  } else {
    valor -= result;
  }

  return valor;
};
const fnMaxArray = (values, dependencia, valor) => {
  const result =
    values[dependencia[0]].reduce((ac, item) => {
      if (item && item[dependencia[1]] !== undefined) {
        const parseValue = parseFloat(
          isNaN(item[dependencia[1]]) ? 0 : item[dependencia[1]]
        );
        if (ac === operationsInitialValue.maxArray) return parseValue;
        else return ac > parseValue ? ac : parseValue;
      } else return ac;
    }, operationsInitialValue.maxArray) ?? operationsInitialValue.maxArray;

  if (valor === operationsInitialValue.maxArray) valor = result;
  else valor = valor > result ? valor : result;

  return valor;
};

const fnMin = (values, dependencia, valor) => {
  const result =
    parseFloat(
      isNaN(values[dependencia[0]][dependencia[1]])
        ? 0
        : values[dependencia[0]][dependencia[1]]
    ) ?? 0;

  if (valor === operationsInitialValue.minArray) valor = result;
  else valor = valor < result ? valor : result;

  return valor;
};

const fnMinArray = (values, dependencia, valor) => {
  const result =
    values[dependencia[0]].reduce((ac, item) => {
      if (item && item[dependencia[1]] !== undefined) {
        const parseValue = parseFloat(
          isNaN(item[dependencia[1]]) ? 0 : item[dependencia[1]]
        );
        if (ac === operationsInitialValue.minArray) return parseValue;
        else return ac < parseValue ? ac : parseValue;
      } else return ac;
    }, operationsInitialValue.minArray) ?? operationsInitialValue.minArray;

  if (valor === operationsInitialValue.minArray) valor = result;
  else valor = valor < result ? valor : result;

  return valor;
};

const fnMax = (values, dependencia, valor) => {
  const result =
    parseFloat(
      isNaN(values[dependencia[0]][dependencia[1]])
        ? 0
        : values[dependencia[0]][dependencia[1]]
    ) ?? 0;

  if (valor === operationsInitialValue.maxArray) valor = result;
  else valor = valor > result ? valor : result;

  return valor;
};

const fnSum = (values, dependencia, valor) => {
  const result =
    parseFloat(
      isNaN(values[dependencia[0]][dependencia[1]])
        ? 0
        : values[dependencia[0]][dependencia[1]]
    ) ?? 0;
  valor += result;
  return valor;
};

const fnSameAs = (values, dependencia, valor, name) => {
  const index = getIndex(name);

  const valueName = index
    ? `${dependencia[0]}[${index}].${dependencia[1]}`
    : `${dependencia[0]}.${dependencia[1]}`;
  const result = _.get(values, valueName); //[dependencia[0]][dependencia[1]];
  valor = result;
  return valor;
};
const fnMinus = (values, dependencia, valor) => {
  const result =
    parseFloat(
      isNaN(values[dependencia[0]][dependencia[1]])
        ? 0
        : values[dependencia[0]][dependencia[1]]
    ) ?? 0;
  if (valor === undefined) {
    valor = result;
  } else {
    valor -= result;
  }
  return valor;
};

const fnMinusRow = (values, dependencia, valor, name) => {
  const index = getIndex(name);
  const result =
    values[dependencia[0]][index] !== undefined &&
    values[dependencia[0]][index][dependencia[1]] !== undefined
      ? parseFloat(
          isNaN(values[dependencia[0]][index][dependencia[1]])
            ? 0
            : values[dependencia[0]][index][dependencia[1]]
        ) ?? 0
      : 0;
  if (valor === undefined) {
    valor = result;
  } else {
    valor -= result;
  }
  return valor;
};

const fnSumRow = (values, dependencia, valor, name) => {
  const index = getIndex(name);
  const result =
    values[dependencia[0]][index] !== undefined &&
    values[dependencia[0]][index][dependencia[1]] !== undefined
      ? parseFloat(
          isNaN(values[dependencia[0]][index][dependencia[1]])
            ? 0
            : values[dependencia[0]][index][dependencia[1]]
        ) ?? 0
      : 0;

  valor += result;
  return valor;
};

const fnMultArray = (values, dependencia, valor) => {
  const result =
    values[dependencia[0]].reduce((ac, item) => {
      if (item && item[dependencia[1]] !== undefined)
        return (
          ac *
          parseFloat(isNaN(item[dependencia[1]]) ? 0 : item[dependencia[1]])
        );
      else return ac;
    }, 1) ?? 1;

  valor *= result;

  return valor;
};

const fnMult = (values, dependencia, valor) => {
  const result =
    parseFloat(
      isNaN(values[dependencia[0]][dependencia[1]])
        ? 0
        : values[dependencia[0]][dependencia[1]]
    ) ?? 0;

  valor *= result;

  return valor;
};

const fnMultRow = (values, dependencia, valor, name) => {
  const index = getIndex(name);
  const result =
    values[dependencia[0]][index] !== undefined &&
    values[dependencia[0]][index][dependencia[1]] !== undefined
      ? parseFloat(
          isNaN(values[dependencia[0]][index][dependencia[1]])
            ? 0
            : values[dependencia[0]][index][dependencia[1]]
        ) ?? 0
      : 0;

  valor *= result;

  return valor;
};

const getDependencies = (input, inputelement) => {
  const name = input.name;
  let Dependencies = [];

  const index = getIndex(name); //index del input si esta en un grupo repetitivo
  if (inputelement.asyncData) {
    getDependenciasByUrl(name, inputelement.asyncConf.url, index, Dependencies);
  }
  if (inputelement.conditional?.asyncData) {
    getDependenciasByUrl(
      name,
      inputelement.conditional.asyncConf.url,
      index,
      Dependencies
    );
  }

  return Dependencies;
};

const getDependenciesCollapse = (input, inputelement) => {
  const name = input.name;
  let Dependencies = [];

  const index = getIndex(name); //index del input si esta en un grupo repetitivo
  if (inputelement.collapse?.urlApi) {
    getDependenciasByUrl(
      name,
      inputelement.collapse?.urlApi,
      index,
      Dependencies
    );
  }
  if (inputelement.conditional?.asyncData) {
    getDependenciasByUrl(
      name,
      inputelement.conditional.asyncConf.url,
      index,
      Dependencies
    );
  }

  return Dependencies;
};

function getInputGroupsNames(groupInputs, groupName) {
  if (!groupInputs || !groupName) {
    return [];
  }
  const names = groupInputs.reduce(
    (acc, inpt) => [
      ...acc,
      groupName.toString() + inpt.namegroupName.toString(),
    ],
    []
  );
  return names;
}
function getValuesFromOtherGroup(group: any, values: any, field) {
  if (group.otherGroupValues) {
    const valuesOtherGroup = _.get(values, group.otherGroupValues);

    if (valuesOtherGroup) {
      if (Array.isArray(valuesOtherGroup) && group.iterable) {
        const val = valuesOtherGroup.map((valuesByIndex, index) => {
          if (valuesByIndex) {
            return group.inputs.reduce((acc, inp) => {
              if (!valuesByIndex[inp.name]) {
                return acc;
              } else
                return {
                  ...acc,
                  [inp.name]:
                    valuesByIndex[inp.name] && valuesByIndex[inp.name],
                  // : null,
                };
            }, {});
          }
        });

        field.input.onChange(val);
      } else {
        if (!Array.isArray(valuesOtherGroup) && !group.iterable) {
          const val = group.inputs.reduce((acc, inp) => {
            return {
              ...acc,
              [inp.name]:
                valuesOtherGroup[inp.name] && valuesOtherGroup[inp.name],
              // : null,
            };
          }, {});
          field.input.onChange(val);
        }
      }
    }
  }
}

function getOptionsFromGroup(group: any, values: any) {
  const valuesOtherGroup = _.get(values, group);
  if (valuesOtherGroup) {
    if (Array.isArray(valuesOtherGroup)) {
      return valuesOtherGroup;
    } else {
      return [];
    }
  }
}
function getDependenciasByUrl(
  name: any,
  url,
  index: string,
  Dependencies: any[]
) {
  url = url ? url?.replace("{{cuit}}", "").replace("[value]", "") : "";
  const inputsNames = new RegExp(/\[(.*?)\]/, "gm");
  const accVal = url.match(inputsNames) != null ? url.match(inputsNames) : [];

  accVal.forEach((el) => {
    const depend = el.replace("[", "").replace("]", "");
    // let valueToSet = _.get(values, depend);
    const splitedName = name.split("[");
    let dependUpdate = depend.split(".");
    if (
      /*si la dependencia esta adentro del mismo grupo*/
      index &&
      dependUpdate.length > 1 &&
      splitedName[0] === dependUpdate[0]
    ) {
      dependUpdate = dependUpdate[0] + "[" + index + "]." + dependUpdate[1];
      // valueToSet = _.get(values, dependUpdate);
      Dependencies.push(dependUpdate);
    } else {
      Dependencies.push(depend);
    }
  });
}

const getUrl = (
  name,
  url,
  cuit,
  values,
  JsonFieldsName?,
  tramiteId?,
  baseUrlName?,
  baseUrl?
) => {
  const index = getIndex(name);
  //index del input si esta en un grupo repetitivo
  if (!url) {
    console.log("no hay url para el campo" + name);
    return;
  }
  const base = baseUrl?.find((url) => url.name === baseUrlName)?.url ?? "";

  if (url?.toLowerCase().startsWith("http")) {
    url = url
      ? url
          ?.replace("{{cuit}}", cuit)
          .replace("{{tramiteId}}", tramiteId)
          .replace("[value]", _.get(values, name))
      : "";
  } else {
    url =
      base + url
        ? base +
          url
            ?.replace("{{cuit}}", cuit)
            .replace("{{tramiteId}}", tramiteId)
            .replace("[value]", _.get(values, name))
        : "";
  }

  const inputsNames = new RegExp(/\[(.*?)\]/, "gm");
  const accVal = url.match(inputsNames) != null ? url.match(inputsNames) : [];

  accVal.forEach((el) => {
    const depend = el.replace("[", "").replace("]", "");
    let valueToSet = _.get(values, depend);
    const splitedName = name.split("[");
    let dependUpdate = depend.split(".");

    if (
      /*si la dependencia esta adentro del mismo grupo*/
      index !== null &&
      dependUpdate.length > 1 &&
      splitedName[0] === dependUpdate[0]
    ) {
      dependUpdate = dependUpdate[0] + "[" + index + "]." + dependUpdate[1];
      valueToSet = _.get(values, dependUpdate);
    }

    let arrayToSet = [];
    let dependencyValueToUrl = valueToSet;
    if (Array.isArray(valueToSet)) {
      /*si el valor de la  dependencia tiene forma de array lo mapea a un json (ej: la dependencia es un grupo entero repetitivo )*/
      valueToSet.forEach((val, i) => {
        let auxObject = {};
        if (JsonFieldsName && JsonFieldsName.length > 0) {
          /*si en la configuracion viene mapeo de json lo mapea, sino manda el stringuify del valor de la dependencia entero*/

          JsonFieldsName.forEach((inptName) => {
            auxObject = {
              ...auxObject,
              [inptName.keyName]:
                val?.[inptName.iputName ? inptName.iputName : inptName.keyName],
            };
          });
          arrayToSet.push(auxObject);
        } else {
          arrayToSet = valueToSet;
        }
      });

      dependencyValueToUrl = JSON.stringify(arrayToSet);
    } else if (typeof valueToSet === "object" && valueToSet !== null) {
      /*si el valor de la  dependencia tiene forma de object  lo mapea a un json (ej: la dependencia es un grupo entero no repetitivo)*/

      let auxObject = {};
      if (JsonFieldsName) {
        /*si en la configuracion viene mapeo de json lo mapea, sino manda el stringuify del valor de la dependencia entero*/

        JsonFieldsName.forEach((inptName) => {
          auxObject = {
            ...auxObject,
            [inptName.keyName]:
              valueToSet?.[
                inptName.iputName ? inptName.iputName : inptName.keyName
              ],
          };
        });
      } else {
        auxObject = valueToSet;
      }
      dependencyValueToUrl = JSON.stringify(auxObject);
    }
    if (dependencyValueToUrl) {
      /*si la el valor de la dependencia cambia reemplazo la url, evita rerender en caso contrario*/
      url = url.replace(el, dependencyValueToUrl);
    }
  });

  return url;
};

function getMask(inputelement) {
  return inputelement.mask
    ? [
        ...inputelement.mask.split(",").map((s) => {
          let isRegular = true;
          let regular = null;
          if (s[0] !== '"') {
            try {
              regular = new RegExp(s);
            } catch (e) {
              isRegular = false;
            }
          } else {
            isRegular = false;
          }

          return isRegular ? regular : s.replace(/"/g, ""); //s.replaceAll('"', "");
        }),
      ]
    : [];
}

const applyDependencies = (props) => {
  const { inputelement, values, input } = props;

  if (inputelement.dependsOn) {
    if (values) {
      const dependencias = inputelement.dependsOn; //inputelement.dependsOn.split(","); //lista de dependencias
      let valor = operationsInitialValue[inputelement?.operation]; //valor inicial segun operacion de dependencia
      dependencias.forEach((el) => {
        //por cada dependencia
        const dependencia = el.split("."); // separo grupo [0] de nombre [1] de la dependencia
        if (Array.isArray(values[dependencia[0]])) {
          // la dependencia es iterable?
          if (
            values[dependencia[0]] && // si el grupo no tienen ningun tipo de valor
            values[dependencia[0]][0] !== undefined // el segundo indice no lo entinendo todavia
          ) {
            valor = operationsArray[inputelement?.operation]?.(
              //llamo funcion segun valor de operation
              values,
              dependencia,
              valor,
              input.name
            );
          }
        }
        // si la dependencia no es iterable
        else {
          if (values[dependencia[0]]) {
            if (values[dependencia[0]][dependencia[1]] !== undefined)
              valor = operationsSimple[inputelement.operation](
                //operaciones sobre campos no iterables
                values,
                dependencia,
                valor
              );
          }
        }
      });
      input.onChange(valor); //seteo el valor de la operacion al input
    }
  }
};
const applyInitialDependencies = (props) => {
  const { inputelement, input } = props;
  if (inputelement.dependsOn) {
    input.onChange(operationsInitialRender[inputelement.operation]); // inicializo el valor segun la operacion que se le va a hacer
  }
};
const getIndex = (name) => {
  const as = new RegExp(/\[(.*?)\]/, "gm");
  // const exp = /\d+(?!\.)/i;
  const index = as.exec(name);
  //
  if (index) {
    return index[0].replace("[", "").replace("]", "");
  } else {
    return null;
  }
};
const getDataFromResponse = (response, name) => {
  let indx;
  return name.split(".").reduce(function (a, b) {
    if (Array.isArray(a)) {
      const nameIdx = getIndex(b);
      const name = b.replace(`[${nameIdx}]`, "");

      const data = (a = a[indx]?.[name]);
      indx = nameIdx;
      return data;
    } else {
      indx = getIndex(b);
      const name = b.replace(`[${indx}]`, "");
      return (a = a[name]);
    }
  }, response);
};

const LogicalOperationsArray = {
  same: (valor, esperado) => {
    return String(valor).toLowerCase() === String(esperado).toLowerCase();
  },
  distinct: (valor, esperado) => {
    return String(valor).toLowerCase() !== String(esperado).toLowerCase();
  },
  grather: (valor, esperado) => {
    return parseInt(valor) > parseInt(esperado);
  },
  less: (valor, esperado) => {
    return parseInt(valor) < parseInt(esperado);
  },
  gratherEqual: (valor, esperado) => {
    return parseInt(valor) >= parseInt(esperado);
  },
  lessEqual: (valor, esperado) => {
    return parseInt(valor) <= parseInt(esperado);
  },
  in: (valor, esperado) => {
    return (
      esperado
        .split(",")
        .filter((v) => String(valor).toLowerCase() === v.toLowerCase()).length >
      0
    );
  },
  notin: (valor, esperado) => {
    return (
      esperado
        .split(",")
        .filter((v) => String(valor).toLowerCase() === v.toLowerCase())
        .length <= 0
    );
  },
  empty: (valor, esperado) => {
    return !valor;
  },
};

const applySimpleCondition = (props) => {
  const { inputelement, values, input } = props;
  let valor = true;
  if (
    inputelement.conditional &&
    !inputelement.conditional.asyncData &&
    values
  ) {
    const dependencia = inputelement.conditional.observed
      ? inputelement.conditional.observed.split(".")
      : []; // dependencia

    if (Array.isArray(values[dependencia[0]])) {
      // la dependencia es iterable?
      if (
        values[dependencia[0]] && // si el grupo no tienen ningun tipo de valor
        values[dependencia[0]][0] !== undefined
      ) {
        const index = getIndex(input.name);
        const data =
          values[dependencia[0]][index] !== undefined &&
          values[dependencia[0]][index][dependencia[1]] !== undefined
            ? values[dependencia[0]][index][dependencia[1]]
            : "";

        valor = LogicalOperationsArray[
          inputelement.conditional.operation
            ? inputelement.conditional.operation
            : "same"
        ]?.(data, inputelement.conditional.value);
      }
    }
    // si la dependencia no es iterable
    else {
      if (values[dependencia[0]]) {
        if (values[dependencia[0]][dependencia[1]] !== undefined)
          valor = LogicalOperationsArray[
            inputelement.conditional.operation
              ? inputelement.conditional.operation
              : "same"
          ]?.(
            values[dependencia[0]][dependencia[1]],
            inputelement.conditional.value
          );

        // valor =
        //   values[dependencia[0]][dependencia[1]] ==
        //   inputelement.conditional.value;
      } else {
        valor = false;
      }
    }
  }
  return valor;
};

const validarDato = async (
  inputelement,
  input,
  props,
  values,
  setMsj,
  BaseUrls
) => {
  let url = "";
  let respuesta = false;
  if (inputelement.asyncValidation && inputelement.asyncValidationConf) {
    url = getUrl(
      input.name,
      inputelement.asyncValidationConf.url,
      props.cuit,
      values,
      inputelement.asyncValidationConf.JsonFieldsName,
      null,
      inputelement.asyncValidationConf.baseUrl,
      BaseUrls
    );

    await Axios({
      method: inputelement.asyncValidationConf.method as Method,
      url: url,
      headers: { Authorization: `Bearer ${props.jwt}` },
    })
      .then((response) => {
        const res = response;
        const apiValue = _.get(
          res,
          inputelement.asyncValidationConf?.propertyText
        );
        //  getDataFromResponse(
        //   res,
        //   inputelement.asyncValidationConf?.propertyText
        // );
        if (
          (apiValue !== undefined &&
            !inputelement.asyncValidationConf?.propertyValue) ||
          String(apiValue) ===
            inputelement.asyncValidationConf?.propertyValue?.toString()
        ) {
          setMsj({
            show: true,
            type: "success",
            msj: inputelement.asyncValidationConf.successMsg
              ? inputelement.asyncValidationConf.successMsg
              : "correcto",
          });
          respuesta = true;
        } else {
          setMsj({
            show: true,
            type: "error",
            msj: inputelement.asyncValidationConf.errorMsg
              ? inputelement.asyncValidationConf.errorMsg
              : "Incorrecto",
          });
          respuesta = false;
        }
      })
      .catch(function (error) {
        setMsj({
          show: true,
          type: "error",
          msj: "Error en la configuracion API",
        });
        console.log(error.message);
        respuesta = false;
      });
    //get data from api with newUrl
  }
  return respuesta;
};

function dependenciaCondicional(
  inputelement,
  urlCondition,
  setVisible,
  props?
) {
  // debugger;
  // const isUrlBadFormated = setVisible.includes("[") || setVisible.includes("{");
  //aply logiacl operations
  Axios({
    method: inputelement.conditional.asyncConf.method as Method,
    url: urlCondition,
    headers: { Authorization: `Bearer ${props?.jwt}` },
  })
    .then((response) => {
      const res = response;
      const apiValue = getDataFromResponse(
        res,
        inputelement.conditional.asyncConf.propertyText
      );

      setVisible(
        LogicalOperationsArray[
          inputelement.conditional.asyncConf.operation
            ? inputelement.conditional.asyncConf.operation
            : "same"
        ]?.(inputelement.conditional.asyncConf.propertyValue, apiValue)
      );
      // setVisible(
      //   inputelement.conditional.asyncConf.propertyValue == String(apiValue)
      // );
    })
    .catch(function (error) {
      console.log(error.message);
    });
}
const getAsyncData = async (inputelement, url, props, input) => {
  let res = null;
  const isUrlBadFormated = url?.includes("[") || url?.includes("{");
  if (isUrlBadFormated) return null;
  url &&
    (await Axios({
      method: inputelement.asyncConf.method as Method,
      url: url,
      headers: { Authorization: `Bearer ${props.jwt}` },
    })
      .then((response) => {
        res = response;
        // const ValueToSet = getDataFromResponse(
        //   res,
        //   inputelement.asyncConf.propertyText
        // );
        // handleChange(ValueToSet);
        // input.onChange(ValueToSet); //esto setea el valor al input se usa en campos tipo text, number: En otros campos podrian ser las opciones del select o checkboxs
      })
      .catch(function (error) {
        console.log(error.message);
        // return null;
      }));
  return res;
};

const operationsArray = {
  sum: fnSumArray,
  sumRow: fnSumRow,
  same: fnSameAs,

  minus: fnMinusArray,
  minusRow: fnMinusRow,

  mult: fnMultArray,
  multRow: fnMultRow,

  max: fnMaxArray,
  min: fnMinArray,
};

const operationsSimple = {
  sum: fnSum,
  sumRow: fnSum,
  same: fnSameAs,
  minus: fnMinus,
  minusRow: fnMinus,

  mult: fnMult,
  multRow: fnMult,

  max: fnMax,
  min: fnMin,
};

const operationsInitialValue = {
  same: undefined,
  minus: undefined,
  minusRow: undefined,
  sum: 0,
  mult: 1,
  multRow: 1,
  sumRow: 0,
  maxArray: "",
  minArray: "",
};
const operationsInitialRender = {
  same: undefined,
  minus: 0,
  minusRow: 0,
  sum: 0,
  mult: 0,
  multRow: 0,
  sumRow: 0,
  maxArray: 0,
  minArray: 0,
};

export {
  getDataFromResponse,
  getUrl,
  applyDependencies,
  getDependencies,
  applyInitialDependencies,
  applySimpleCondition,
  getMask,
  validarDato,
  dependenciaCondicional,
  getAsyncData,
  getOptionsFromGroup,
  getValuesFromOtherGroup,
  getDependenciasByUrl,
  LogicalOperationsArray,
  getDependenciesCollapse,
};
