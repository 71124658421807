import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import moment from "moment";
import DoneAllIcon from "@material-ui/icons/DoneAll";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "300px",
    border: theme.superficies.border,
    borderRadius: 0,
    boxShadow: theme.shadows[theme.superficies.elevation],
  },
  chatRoot: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  msj: {
    padding: "5px 14px",
    background: "#ededed",
    borderRadius: "10px",
    margin: "10px auto 10px 10px",
    width: "fit-content",
    fontSize: "12px",
    maxWidth: "70%",
    position: "relative",
    "&:before": {
      content: "' '",
      position: "absolute",
      top: 0,
      left: "-6px",
      width: "20px",
      borderTop: "solid #ededed 30px",
      borderLeft: "solid transparent 20px",
    },
  },
  chatContent: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    padding: 0,
    borderRadius: 0,
  },
  propio: {
    margin: "10px 10px 10px auto",
    background: "#cde3f1",

    "&:before": {
      right: "-6px",
      left: "auto",
      borderLeft: "none",
      borderTopColor: "#cde3f1",
      borderRight: "solid transparent 20px",
    },
  },
  chatFooter: {
    padding: "10px ",
  },
  chatMensajes: {
    height: "330px",
    flexDirection: "column",
    padding: "0 5px",
    overflow: "auto",
  },
  chatHeader: {
    background: theme.colores.main,
    color: theme.colores.contrast,
    minHeight: "auto",
  },
  hora: {
    fontSize: "8px",
    display: "flex",
    justifyContent: "flex-end",
  },
  leido: { fontSize: "0.8rem", padding: "0 0 0 3px", color: "#0574b9" },
  dia: {
    fontSize: "8px",
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
    "&:before": {
      content: "' '",
      width: "100%",
      borderTop: "solid 1px #ededed",
      margin: "5px",
    },
    "&:after": {
      content: "' '",
      width: "100%",
      borderTop: "solid 1px #ededed",
      margin: "5px",
    },
  },

  user: {
    fontSize: "8px",
    display: "flex",
    textAlign: "left",

    whiteSpace: "nowrap",

    "&:after": {
      content: "' '",
      width: "100%",
      borderTop: "solid 1px #ededed",
      margin: "5px",
    },
  },
}));

const Messages = (props) => {
 
  const classes = useStyles();
  const ref = React.useRef(null);

  const handleFocus = () => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };


  useEffect(() => {
    ref.current.scrollIntoView({ behavior: "smooth" });
 }, [props.mensajes]);

  let lastDate = null;
  const getDatetoShow = (msjdate) => {
    const day = moment(msjdate).format("DD/MM/YYYY");

    if (day !== lastDate) {
      lastDate = day;
      return <div className={classes.dia}>{day}</div>;
    } else {
      return null;
    }
  };

  return (
  
            <div className={classes.chatMensajes}>
              {props.mensajes && props.mensajes.map((msj, index) => (
                <div key={index}>
                  {getDatetoShow(msj.fecha)}

                  <div
                    className={`${classes.msj} ${
                      msj.usuario === props.cuit ? classes.propio : null
                    } `}
                  >
                    <Typography variant="body2">
                      {msj.usuario !== props.cuit ? (
                        <span className={classes.user}>{msj.usuario}</span>
                      ) : null}
                      {msj.mensaje}
                      <span className={classes.hora}>
                        {moment(msj.fecha).format("HH:mm")}
                        {msj.usuario === props.cuit &&
                        msj.leidopor ? (
                          <DoneAllIcon className={classes.leido} />
                        ) : null}
                      </span>
                    </Typography>
                  </div>
                </div>
              ))}
              <span ref={ref}></span>
            </div>
           
  );
};

const mapState = (state) => ({
  cuit: state.auth.authenticatedUser.cuit,
});

export default connect(mapState, null)(Messages);
