import MoreVertIcon from '@material-ui/icons/MoreVert';import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { FormattedMessage } from 'react-intl';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import AddTagDialog from '../AddTagDialog/';
import { connect } from 'react-redux';
import FileServices from '../../../../services/FileServices';
import DebitoDialog from '../DebitoDialog';
import AffidavitDialog from '../AffidavitDialog/AffidavitDialog';
import { useHistory } from "react-router-dom";
import apiBUC from '../../../../services/BUCServices';

const MoreButton = (props) => {
    const [open, setOpen] = React.useState(false);
    const [showAddTagDialog, setShowAddTagDialog] = React.useState(false);
    const [showDebito, setShowDebito] = React.useState(false);
    const [showAffidavit, setShowAffidavit] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    let showDebitoButton = false;
    let showTagsButton = false;
    let showAffidavitButton = false;

    const history = useHistory();

    props.taxesTypes.forEach( taxType => {
      if(props.obj.asociado){
        if(taxType.code == props.obj.tipo){
          showDebitoButton = taxType.DebitOptions;
          showTagsButton = taxType.TagOptions;
          showAffidavitButton = taxType.hasAffidavit;
        }
      } else {
        if(taxType.code == props.obj.tipo){
          showDebitoButton = taxType.DebitOptions;
          showTagsButton = taxType.TagOptions;
          showAffidavitButton = taxType.hasAffidavit;
        }
      }
    })

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const callback = () => {
      setShowAddTagDialog(prevShowAddTagDialog => !prevShowAddTagDialog);
    }

    const callbackDebito = () => {
      setShowDebito(prevShowDebito => !prevShowDebito);
    }

    const callbackAffidavit = () => {
      setShowAffidavit(prevShowAffidavit => !prevShowAffidavit);
    }

    const handleToggleAddTagDialog = () => {
      setShowAddTagDialog(prevShowAddTagDialog => !prevShowAddTagDialog);
    }

    const handleToggleDebito = () => {
      setShowDebito(prevShowDebito => !prevShowDebito);
    }

    const handleToggleAffidavit = () => {
      setShowAffidavit(prevShowAffidavit => !prevShowAffidavit);
    }

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
        return;
        }

        setOpen(false);
    };

    const handleClickAddTag = (e) => {
      e.stopPropagation();
      handleToggleAddTagDialog();
      setOpen(false);
    }

    const handleClickDebito = (e) => {
      e.stopPropagation();
      handleToggleDebito();
      setOpen(false);
    }

    const handleClickRemove = (e) => {
      e.stopPropagation();
      apiBUC.SubjectServices.deleteThirdPartTax(props.cuit, props.obj.id, props.obj.tipo)
      .then((response) => {
        window.location.reload();
      });
      setOpen(false);
    }

    const handleClickAffidavit = (e) => {
        e.stopPropagation();
        history.push({
          pathname: '/mis-ddjj',
          state: { detail: props.obj.id }
        });
    }

    const handleClickReg = (action) => {
        FileServices().postFile(action, props.obj, props.locale, props.jwt);

        setOpen(false);
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const renderDynamicAction = () =>
        props.objectsActions.map((action) => {
            if(action.taxes.length == 0) {
                return <MenuItem onClick={() => handleClickReg(action)}>
                    {action['name_' + props.locale.toUpperCase()]}
                </MenuItem>
            } else {
                return  action.taxes.some(t => t.tax.code == props.obj.tipo) ?
                    <MenuItem onClick={() => handleClickReg(action)}>
                    {action['name_' + props.locale.toUpperCase()]}
                </MenuItem>: <></>
            }
        });

    return(
        <div>
            <Button ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true" color="primary" onClick={e => {handleToggle();
            e.stopPropagation()}}  
            onFocus={event => event.stopPropagation()}><MoreVertIcon/></Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    {props.obj.asociado ? <MenuItem onClick={handleClickRemove}>Desvincular</MenuItem> : null}
                    {showTagsButton? <MenuItem onClick={handleClickAddTag}><FormattedMessage id="myTaxes.addTag" defaultMessage="Añadir Etiqueta" /></MenuItem> : null}
                    {props.cuit === props.obj.sujeto_id && showDebitoButton? <MenuItem onClick={handleClickDebito}>Agregar a Debito Automatico</MenuItem> : null}
                    {props.cuit === props.obj.sujeto_id && showAffidavitButton? <MenuItem onClick={handleClickAffidavit}>Ver Declaracion Jurada</MenuItem> : null}
                    {props.noActions != true ? renderDynamicAction() : <div></div>}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <AddTagDialog show={showAddTagDialog} callbackFromParent={callback} obj={props.obj}/>
        {showDebito ? <DebitoDialog show={showDebito} callbackFromParent={callbackDebito} obj={props.obj}/> : null}
          {/*<AffidavitDialog show={showAffidavit} callbackFromParent={callbackAffidavit} obj={props.obj}/>*/}
        </div>
    )
}

const mapState = state => ({
    objectsActions: state.objectsActions.objectsActions,
    locale: state.auth.locale,
    cuit: state.auth.cuit,
    jwt: state.auth.jwt,
    taxesTypes: state.taxes.taxesTypes
})

export default connect(mapState, null)(MoreButton);