import React, { useEffect, useRef } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getUrl,
  applyDependencies,
  applyInitialDependencies,
  applySimpleCondition,
  getDependencies,
  getMask,
  validarDato,
  dependenciaCondicional,
  getAsyncData,
  getOptionsFromGroup,
} from "./Operations";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Axios, { Method } from "axios";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
const fullWidthOp = false;

const TagsField = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    meta: { touched, invalid, error, initial },
    name,
    input,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const theme = useTheme();
  const context = useContext(ctxts);
  //#region  //? Condicional  visible o no config
  const [visible, setVisible] = React.useState(
    inputelement.isConditional ? false : true
  );
  const mountedRef = useRef(true);
  const [urlCondition, setUrlCondition] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  useEffect(() => {
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      dependenciaCondicional(inputelement, urlCondition, setVisible, props);
    }
  }, [urlCondition]);
  //#endregion
  //#region  //? mascara config
  const [reg, setReg] = React.useState([]);
  useEffect(() => {
    setReg(getMask(inputelement));
  }, [inputelement.mask]);
  //#endregion
  //#region //! actualizar  urls
  //? con estas dependencias y useEffect recalculo las urls
  //? solo cuando cambien los valores de los inputs que la url requiere

  const [url, setUrl] = React.useState("");

  const getArrayDependecies = () => {
    const dependencias = getDependencies(input, inputelement);
    return [
      dependencias.map((d) => {
        const dependenciaValue = _.get(values, d);
        return dependenciaValue ? dependenciaValue : "";
      }),
    ];
  };

  useEffect(() => {
    if (inputelement.asyncData) {
      setUrl(
        getUrl(
          input.name,
          inputelement.asyncConf.url,
          props.cuit,
          values,
          inputelement.asyncConf.JsonFieldsName,
          null,
          inputelement.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      setUrlCondition(
        getUrl(
          input.name,
          inputelement.conditional.asyncConf.url,
          props.cuit,
          values,
          inputelement.conditional.asyncConf.JsonFieldsName,
          null,
          inputelement.conditional.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [...getArrayDependecies()]);
  //#endregion
  //#region //? validaciones asyncronas
  const [msj, setMsj] = React.useState({
    show: false,
    type: "error",
    msj: inputelement.asyncValidationConf?.errorMsg,
  });

  //#endregion
  //#region  //? calculos entre campos
  useEffect(() => {
    applyDependencies(props);
    if (inputelement.isConditional && !inputelement.conditional.asyncData) {
      setVisible(applySimpleCondition(props));
    }
  });
  //#endregion

  useEffect(() => {
    if (inputelement.optionsFromGroup) {
      const response = getOptionsFromGroup(
        inputelement.optionsConfig.groupName,
        values
      );
      if (response) {
        setOptions(
          Array.isArray(response)
            ? response.map((elem) => {
                return _.get(elem, inputelement.optionsConfig.propertyValue);
              })
            : []
        );
      }
    }
  }, [_.get(values, inputelement?.optionsConfig?.groupName)]); //se ejecuta solo si la url cambia
  const dependencias = getDependencies(input, inputelement);
  const focusedInput = props.appContext.inputFocus;
  //#region //?traer datos asincronos
  useEffect(() => {
    if (
      inputelement.asyncData &&
      !dependencias.some((d) => d === props.appContext.inputFocus)
    ) {
      setIsLoading(true);
      const response = getAsyncData(inputelement, url, props, input);

      response.then((res) => {
        if (!mountedRef.current) return null;
        setIsLoading(false);
        if (res) {
          const data = _.get(
            res,
            inputelement.asyncConf.baseNode
              ? inputelement.asyncConf.baseNode
              : "data"
          );

          setOptions(
            Array.isArray(data)
              ? data.map((elem) => {
                  return _.get(elem, inputelement.asyncConf.propertyValue);
                })
              : []
          );
        }
      });
    }
  }, [url, focusedInput]); //se ejecuta solo si la url cambia
  //#endregion
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#region //? inicializar el componente
  useEffect(() => {
    applyInitialDependencies(props);
  }, [
    inputelement.dependsOn ? _.get(values, inputelement.dependsOn[0]) : null,
  ]);
  //#endregion

  const [val, setVal] = React.useState(
    _.get(values, input.name)
      ? _.get(values, input.name)
      : initial
      ? initial
      : []
  );

  const [isValidating, setIsValidating] = React.useState(false);
  const [waiting, setWaiting] = React.useState(false);

  const setValue = async (value, input) => {
    if (inputelement.asyncValidation && val.length <= value.length) {
      setIsValidating(true);
      setWaiting(true);
      // setTimeout(() => {
      //   setWaiting(false);
      // }, 500);

      const validation = validarDato(
        {
          ...inputelement,
          asyncValidationConf: {
            ...inputelement.asyncValidationConf,
            url: inputelement.asyncValidationConf.url.replace(
              "[value]",
              value[value.length - 1]
            ),
          },
        },
        input,
        props,
        values,
        setMsj,
        context.BaseUrls
      );
      validation.then((res) => {
        if (res) {
          setVal(value);
          input.onChange(value);
          setIsValidating(false);
        } else {
          setIsValidating(false);
        }
      });

      // validar(value);
    } else {
      setIsValidating(false);
      setWaiting(false);
      setVal(value);
      input.onChange(value);
    } //update forn-redux value
  };
  const [options, setOptions] = React.useState(
    inputelement.options
      ? inputelement?.options?.map((option) => option.value)
      : []
  );

  if (visible) {
    return (
      <div
        className={isLoading || props.loadingAsyncData ? classes.loading : null}
      >
        <FormControl
          {...custom}
          fullWidth={fullWidthOp}
          error={touched && invalid}
          className={classes.formcontrol}
        >
          <Autocomplete
            {...input}
            {...custom}
            multiple={true}
            id={input.name + "tags-field"}
            options={options}
            disabled={isValidating || waiting || props.disabled}
            freeSolo={inputelement.allowNewTag}
            onChange={(event, value, reason) => {
              setValue(value, input);
            }}
            value={Array.isArray(input.value) ? input.value : []}
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  disabled={isValidating || waiting || props.disabled}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params, rest) => {
              return (
                <TextField
                  variant={
                    theme.inputs.variant ? theme.inputs.variant : "standard"
                  }
                  {...custom}
                  {...params}
                  disabled={isValidating || waiting || props.disabled}
                  label={inputelement.label}
                  onBlur={(event, value, reason) => {
                    // debugger;
                    if (
                      event.target.value &&
                      Array.isArray(input.value) &&
                      !input.value?.find((v) => v === event.target.value)
                    ) {
                      setValue([...input.value, event.target.value], input);
                    }
                    event.currentTarget.value = "";
                  }}
                  placeholder={
                    isValidating || waiting ? "validando" : custom.placeholder
                  }
                />
              );
            }}
          />
          {msj.show && !waiting ? (
            <span
              style={
                msj.type === "error" ? { color: "red" } : { color: "green" }
              }
            >
              {msj.msj}
            </span>
          ) : null}
          {props.meta.touched && error && (
            <span style={{ color: "red" }}>{error}</span>
          )}
        </FormControl>
      </div>
    );
  } else return null;
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(TagsField);
