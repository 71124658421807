import React,{useEffect} from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { format, formatDistance, formatRelative, subDays } from "date-fns";
import { es } from 'date-fns/locale'
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    backgroundColor: "transparent",
    margin: "5px 10px",
    "&:hover": {
      textDecoration: "none",
    },
  },
  Btn: {},
  Paper: {
    display: "flex",
    minHeight: "180px",
    minWidth: "182px",
    textAlign: "center",
    flexDirection: "column",
    flex: "1",
    justifyContent: "center",
    margin: "auto",
  },
  icon: {
    fontSize: "40px",
    margin: "0 auto 20px",
  },
  mes: { fontSize: "15px", textAlign: "left", display: "block" ,lineHeight:'18px',margin:'15px 5px 0'},
  fecha: { fontSize: "80px", textAlign: "right", display: "block",color:'#ff8b1e' },
  detalle: {
    textAlign: "center",
    fontSize: "15px",
    fontWeight: 100, 
     margin:'0 auto',
     maxWidth: '270px'

  },centered:{
justifyContent:'center'
  },
  nuevoVenc: {
    [theme.breakpoints.up("md")]: {
      borderRight:'solid 1px #d5d5d5',
      width: '27%'
    },

     
  },Venc:{
    [theme.breakpoints.up("md")]: {
          width: '27%'
    },
  },
  dateInfo:{
      justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  

  }
}));

const Vencimientos = (props) => {
  const classes = useStyles();

  const dt = new Date(props.obj.date);
  return (  
    <Fade in timeout={props.timeout}>
    <div className={props.last ? classes.Venc : classes.nuevoVenc}>
     
      <Grid container className={classes.centered}>
        <Grid item sm={6} >
          <span className={classes.fecha}>{dt.getDate()}</span>
        </Grid>
        <Grid item sm={6}className={classes.dateInfo}>
          <span className={classes.mes}>
            {format(dt, "iiii",{locale: es})}
            <br/>
            <strong>{format(dt, "MMMM",{locale: es})}</strong>   
          </span>
        </Grid>
        <Grid item xs={12}>
          <p className={classes.detalle}>
            {props.obj.expirations.split(',')}
          </p>
        </Grid>
      </Grid>
    </div>
   </Fade>
  );
};

export default Vencimientos;
