const CMSUserMenuServices = (instance) => {

    const getUserMenu = (taxRoleId) => instance.get('tax-role-user-menus?tax_role.id=' + taxRoleId);
    const getRepresentedUsers = (cuit) => instance.get(window.config.apiBase.buc + 'sujetos/' + cuit + '/representados');

    return {
        getUserMenu,
        getRepresentedUsers
    };
};
export default CMSUserMenuServices;