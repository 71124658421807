import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import { InputGroup, inputType } from "../../models/IDynamicForm";
import { Field } from "react-final-form";
import { AppContextConsumer } from "../../DynamicFormContext";
import _ from "lodash";
import getType from "../../Inputs/InputsMapper";
import { validar } from "../../Inputs/Validations";
const useStyles = makeStyles({
  LessPadding: {
    padding: "0 5px 0 5px",
  },
  smallTableCell: {
    paddingLeft: "1px",
    paddingRight: "1px",
    "& input": {
      minWidth: "-webkit-fill-available",
      width: "auto",
    },
  },
});

type Props = {
  values: any;
  input: any;
  name: string;
  isModal?: boolean;
  initialValuesInmutables?: boolean;
  loadingAsyncData?: boolean;
  isTableView?: boolean;
};

const TableInput = (props: Props) => {
  const classes = useStyles();

  const { values, input, name } = props;

  const grupo = name.split("[")[0];
  const index = name.split("[")[1].split("]")[0];

  const shouldDisable = (appContext, input, values) => {
    if (appContext.viewOnly || input.disabled) {
      return true;
    }
    if (appContext.initialSnapshot && props.initialValuesInmutables) {
      return parseInt(index) <= appContext.initialSnapshot[grupo].length - 1;
    }
  };

  const getLenght = () => {
    const val = _.get(values, name ? `${name}.${input.name}` : input.name);
    if (typeof val === "string" || typeof val === "number") {
      return val.toString().length > 12 ? val.toString().length : 12;
    } else return 0;
  };
  return (
    <AppContextConsumer>
      {(appContext) =>
        appContext && (
          <TableCell className={classes.smallTableCell}>
            {input.type === inputType.paragraph ? (
              <p>{input.texto ? input.texto : "No hay texto que mostrar"}</p>
            ) : (
              <Field
                component={getType(
                  props.isModal ? inputType.modalInput : input.type
                )}
                inputelement={{
                  ...input,
                  label: input.type === inputType.link ? input.label : "", // input.label + (input.required ? "*" : ""),
                }}
                style={{
                  width: "fit-content", //getLenght() + 3 + "em",
                  minWidth: "100%",
                }}
                name={name ? `${name}.${input.name}` : input.name}
                type="text"
                disabled={shouldDisable(appContext, input, values)}
                // subscription={{ value: true }}
                isTableView={props.isTableView}
                loadingAsyncData={props.loadingAsyncData}
                variant={"outlined"}
                size="small"
                placeholder={input.placeholder}
                values={values}
                validate={validar(input)}
                isLastPaso={appContext.lastPasoId === appContext.pasoId}
                onFocus={() => {
                  appContext.setInputFocus(
                    name ? `${name}.${input.name}` : input.name
                  );
                }}
                onBlur={() => {
                  appContext.setInputFocus(null);
                }}
                appContext={appContext}
                // validate={input.required ? required : null}
              />
            )}
          </TableCell>
        )
      }
    </AppContextConsumer>
  );
};

export default TableInput;
