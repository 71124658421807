import React from 'react';
import TaxTypeAccordion from '../TaxTypeAccordion/TaxTypeAccordion';
import TaxTypeTabs from '../TaxTypeTabs'
import {connect} from 'react-redux';
import {taxAccordeonDisplay} from "../../../../constants/taxAccordeonDisplay";
import {taxTabsDisplay} from "../../../../constants/taxTabsDisplay";
import LoadingComponent, {FalsoTexto} from '../../../common/LoadingComponent';
import { Card } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import NoTaxes from '../NoTaxes';
import { FormattedMessage } from 'react-intl';

const ThirdTaxes = (props) => {
    props.toogleShowPayAll(false)

    const selectViewFormat = () => {
        let types = props.renderedThirdPartObjects.map((o) => o.tipo);
        const distinct = (value, index, self) => self.indexOf(value) === index;
        const checkType = (value, type) => value === type;
        let distinctTypes = types.filter(distinct);

        switch (props.config.taxesDisplay) {
            case taxAccordeonDisplay.taxAccordeonDisplay: {
                return <div>
                    {distinctTypes.map((t) => <TaxTypeAccordion key={t} type={t} objects={props.renderedThirdPartObjects.filter((o) => checkType(o.tipo, t))}/>)}
                </div>}
            case taxTabsDisplay.taxTabsDisplay: {
                return <TaxTypeTabs distinctTypes={distinctTypes} subjects={props.renderedThirdPartObjects} isSelectedGroup={false}/>;
            }
            default: {
                return <p>No se determino una visual</p>;
            }
        }
    }

    if (props.loadingData || props.filteringObjects) {
        props.setFilteringObjects(false);
        return (
            <div>

            <LoadingComponent  isLoading={props.loadingData} text={props.loadingText}>
            <TableContainer>
            <Table>
              <TableHead  >
                <TableRow>
                  <TableCell><FalsoTexto/></TableCell>
                  <TableCell align="right"><FalsoTexto/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {['a', 'b'].map((row) => (
                  <TableRow  key={row}>
                    <TableCell component="th" scope="row">
                    <FalsoTexto/>
                    </TableCell>
                    <TableCell  align="right"><IconButton ><FalsoTexto/></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
            </LoadingComponent>
            </div>)
    } else {
        if (props.renderedThirdPartObjects.length === 0) {
            return <NoTaxes  msj={<FormattedMessage id="myTaxes.Nothing" defaultMessage="No tienes objetos" />} icon={'alert'}/>
        } else {
            props.toogleShowPayAll(true)
            return <Card elevation={1}>{selectViewFormat()}</Card>
        }
    }
}

const mapDispatch = dispatch => ({
    setAllObjects: (objectList) => dispatch.payments.setAllObjects(objectList),
    setRenderedThirdPartObjects: (arrayObjects) => dispatch.payments.setRenderedThirdPartObjects(arrayObjects),
    toogleShowPayAll: (value) => dispatch.payments.toogleShowPayAll(value),
    setAllJudgments: (judgmentList) => dispatch.payments.setAllJudgments(judgmentList),
    setAllDeclarations: (declarationList) => dispatch.payments.setAllDeclarations(declarationList),
    setFilteringObjects: (bool) => dispatch.payments.setFilteringObjects(bool),
})

const mapState = state => ({
    config: state.auth.config,
    cuit: state.auth.cuit,
    renderedThirdPartObjects: state.payments.renderedThirdPartObjects,
    allObjects: state.payments.allObjects,
    allDeclarations: state.payments.allDeclarations,
    thirdPartObjects: state.payments.thirdPartObjects
})

export default connect(mapState, mapDispatch)(ThirdTaxes);