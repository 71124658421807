import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {FormattedMessage} from "react-intl";

import {format} from "date-fns";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";
import ContactsDetail from "../ContactsDetail";
import ContactTableHead from "./ContactTableHead";
import {IContact} from "../../../models/IContact";
import {DEFAULT_SORT_CONTACT_TABLE, Order} from "../../../constants/tables";
import Button from '@material-ui/core/Button';
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import Badge from "../Badge";
import {connect} from 'react-redux';
import apiBUC from "../../../services/BUCServices";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        marginBottom: '50px'
    },
    tableCell: {
        padding: '5px 16px'
    },
    tableRow: {
        cursor: 'pointer',
        '&:hover': {
            background: '#f3f3f3'
        }
    },
    button: {
        margin: '3px 10px 3px 0',

        borderRadius: '20px',
        color: '#525252',
        fontSize: '7px',
        padding: '0px 10px',
    },
    msj: {
        textAlign: 'center',
        fontSize: '20px',

    },
    tableCellFecha: {width: '10%'},
    tableCellTipo: {width: '15%'},
    tableCellDesc: {
        width: '65%',

    },
    tableCellBtn: {width: '5%'},
    descripcion: {

        textOverflow: 'ellipsis',
        overflow: 'hidden',
        margin: 0,
    }
});

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy].toString().toLowerCase() < a[orderBy].toString().toLowerCase()) {
        return -1;
    }
    if (b[orderBy].toString().toLowerCase() > a[orderBy].toString().toLowerCase()) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

const ContactTable = (props) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof IContact>(DEFAULT_SORT_CONTACT_TABLE);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [contacto, setContacto] = React.useState();
    const [isOpen, setisOpen] = React.useState(false);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDetail = (contact) => {
        setisOpen(true);
        setContacto(contact);
        apiBUC.SubjectServices.markContactAsReaded(contact.id)
            .then((response) => {
                // contact.fecha_interaccion = response.data.fecha;
                contact.fechaInteraccion = true;
                props.refreshRead();

                 apiBUC.SubjectServices.getContacts()
                  .then((response) => {
                      props.setUnreadMessages((response.data).filter(x => !x.fechaInteraccion).length)
              });
            });
    }

    const handleClose = () => {
        setisOpen(false)
    }

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IContact) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleDownload = (e) => {
        e.stopPropagation();
        return;
    }

    return (
        <div>
            <TableContainer component={"div"}>
                <Table className={classes.table} aria-label="contactos">
                    <ContactTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                        {props.contactList.length <= 0 &&
                        <TableCell colSpan={5} className={classes.msj}>{props.msjEmpty}</TableCell>
                        }
                        {stableSort(props.contactList, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                <TableRow className={classes.tableRow} onClick={() => handleDetail(row)}
                                          key={row.id}>
                                    <TableCell className={classes.tableCell + ' ' +
                                    classes.tableCellFecha} scope="row">
                                        {format(new Date(row.fechaInicio), "dd/MM/yyyy HH:mm")}
                                    </TableCell>
                                    <TableCell className={classes.tableCell + ' ' +
                                    classes.tableCellTipo} scope="row">
                                        {row.tipo}
                                        {!row.fechaInteraccion ? <Badge
                                            text={<FormattedMessage id="contacts.table.New"
                                                                     defaultMessage="Nuevo"/>}/> : null}
                                    </TableCell>
                                    <TableCell className={classes.tableCell + ' ' +
                                    classes.tableCellDesc} scope="row" align="left">

                                        <p className={classes.descripcion}>{row.descripcion.toString().slice(0, 100)}{row.descripcion.toString().length > 100 && '...'}</p>
                                        {row.archivo && <Button
                                            onClick={e => window.open('http://' + row.archivo.toString(), "_blank")}
                                            variant="outlined"
                                            color="default"
                                            className={classes.button}
                                            startIcon={<AttachmentOutlinedIcon/>}
                                        >
                                            <FormattedMessage id="contacts.table.Adjunto" defaultMessage="Adjunto"/>
                                        </Button>}
                                    </TableCell>
                                    <TableCell className={classes.tableCell + ' ' +
                                    classes.tableCellBtn} scope="row" align="right">
                                        <IconButton onClick={() => handleDetail(row)}><VisibilityIcon/></IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination 
                    rowsPerPageOptions={[5, 10, 25, {label: 'All', value: props.contactList.length}]}
                    component="div"
                    count={props.contactList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </TableContainer>

            {isOpen && <ContactsDetail data={contacto} isOpen={isOpen} handleClose={handleClose}/>}
        </div>
    );
}
const mapState = state => ({
    cuit: state.auth.cuit,
    unreadMessages: state.contacts.unreadMessages,
    jwt: state.auth.jwt
});

const mapDispatch = dispatch => ({
    setUnreadMessages: (unreadMessages) => dispatch.contacts.setUnreadMessages(unreadMessages)
})

export default connect(mapState, mapDispatch)(ContactTable);
