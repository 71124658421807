import React, { useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const LoadingContainer = (props) => {
  const history = useHistory();
  useEffect(() => {
    if (props.menu.length > 0 && !props.noRedirect) {
      history.push(props.menu[0].link);
    }
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: 1,
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h3>Estamos cargando la informacion, por favor aguarde</h3>
      <LinearProgress />
    </div>
  );
};

const mapState = (state) => ({
  taxRole: state.auth.taxRole,
  menu: state.auth.menu,
});

export default connect(mapState, null)(LoadingContainer);
