import React, {useEffect} from 'react';
import TaxTypeAccordion from '../Taxes/TaxTypeAccordion/TaxTypeAccordion';
import TaxTypeTabs from '../Taxes/TaxTypeTabs'
import LoadingAccordion from './LoadingAccordion/LoadingAccordion';
import {connect} from 'react-redux';
import Spinner from "../../common/Spinner";
import {taxAccordeonDisplay} from "../../../constants/taxAccordeonDisplay";
import {taxTabsDisplay} from "../../../constants/taxTabsDisplay";
import LoadingComponent, {FalsoTexto} from '../../common/LoadingComponent';
import { Card } from '@material-ui/core';
import NoTaxes from '../Taxes/NoTaxes';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';

const NoResults = () => {
    return (
        <div className="col text-center">
            <p/>
            <b>No se encontraron resultados para la busqueda realizada</b>
        </div>
    )
}

const Taxes = (props) => {

    props.toogleShowPayAll(false)

    const selectViewFormat = () => {
        let types = props.renderedObjects.map((o) => o.tipo);
        const distinct = (value, index, self) => self.indexOf(value) === index;
        const checkType = (value, type) => value === type;
        let distinctTypes = types.filter(distinct);
        
        switch (props.config.taxesDisplay) {
            case taxAccordeonDisplay.taxAccordeonDisplay: {
                return <div>
                    {distinctTypes.filter( t => t !== 'J').map((t) => <TaxTypeAccordion key={t} type={t} objects={props.renderedObjects.filter((o) => checkType(o.tipo, t))}/>)}
                    {distinctTypes.includes('J') ? <TaxTypeAccordion key={'J'} type={'J'} objects={props.renderedObjects.filter((o) => checkType(o.tipo, 'J'))}/> : null}
                </div>}
            case taxTabsDisplay.taxTabsDisplay: {
                return <TaxTypeTabs distinctTypes={distinctTypes} subjects={props.renderedObjects} isSelectedGroup={false}/>;
            }
            default: {
                return <p>No se determino una visual</p>;
            }
        }
    }

    if (props.loadingData || props.filteringObjects) {
        props.setFilteringObjects(false);
        return (
            <div>

            <LoadingComponent isLoading={props.loadingData} text={props.loadingText}>
            <TableContainer>
            <Table>
              <TableHead  >
                <TableRow>
                  <TableCell><FalsoTexto/></TableCell>
                  <TableCell align="right"><FalsoTexto/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {['a', 'b'].map((row, i) => (
                  <TableRow  key={i}>
                    <TableCell component="th" scope="row">
                    <FalsoTexto/>
                    </TableCell>
                  
                    <TableCell  align="right"><IconButton ><FalsoTexto/></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

            </LoadingComponent>
        {/*        <LoadingAccordion text={loadingText}/>
                <Spinner/>*/}
            </div>)
    } else {
        if (props.renderedObjects.length === 0) {
            return <NoTaxes  msj={<FormattedMessage id="myTaxes.Nothing" defaultMessage="No tienes objetos" />} icon={'alert'}/>
            // <NoResults/>;
        } else {
            props.toogleShowPayAll(true)
            return <Card elevation={1}>{selectViewFormat()}</Card>
        }
    }

}

const mapDispatch = dispatch => ({
    addTaxesTypes: (taxesList) => dispatch.taxes.addTaxesTypes(taxesList),
    setLoadingObjects: (loading) => dispatch.payments.setLoadingObjects(loading),
    setAllObjects: (objectList) => dispatch.payments.setAllObjects(objectList),
    setRenderedObjects: (arrayObjects) => dispatch.payments.setRenderedObjects(arrayObjects),
    toogleShowPayAll: (value) => dispatch.payments.toogleShowPayAll(value),
    addJudgment: (judgment) => dispatch.payments.addJudgment(judgment),
    setAllDeclarations: (declarationList) => dispatch.payments.setAllDeclarations(declarationList),
    setDebtExist: (debtExist) => dispatch.userInfo.setDebtExist(debtExist),
    setThirdPartObjects: (objects) => dispatch.payments.setThirdPartObjects(objects),
    setFilteringObjects: (bool) => dispatch.payments.setFilteringObjects(bool),
    setObligationStates: (states) => dispatch.payments.setObligationStates(states),
})

const mapState = state => ({
    config: state.auth.config,
    cuit: state.auth.cuit,
    renderedObjects: state.payments.renderedObjects,
    allObjects: state.payments.allObjects,
    allDeclarations: state.payments.allDeclarations,
    taxesTypes: state.taxes.taxesTypes,
    filteringObjects: state.payments.filteringObjects
})

export default connect(mapState, mapDispatch)(Taxes);