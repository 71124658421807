import { invokeAPI } from "../utils/Tools";

export type SearchSubjectResponse = {
    denominacion: string;
    cuit: string;
    parsedCuit: string;
  };

export type NextAppointmentResponse = {
    turno: string,
    usuario: string;
    turnoDate: string;
    procedencia: string;
    descripcion: string;
};
export type StopAppointmentResponse = {
    stopped: boolean
};

const CMSAppointmentsServices = (instance) => {

    const getAppointmentConfig = () => instance.get('appointments/1');

    const searchSubject = (cuit : any, config : any, jwt : any):Promise<SearchSubjectResponse> => {
        let parsedCuit = String(cuit);
        parsedCuit = parsedCuit.slice(0,2) + '-' + parsedCuit.slice(2,10) + '-' + parsedCuit.slice(10);
        const data:any = {cuit,parsedCuit};

        return new Promise((resolve, reject) => {
            if(!config.urlSearchSubject)
            {
                data.denominacion = parsedCuit;
                resolve(data);
            }
            else
            {
                invokeAPI({
                    url:config.urlSearchSubject,
                    method: config.methodSearchSubject,
                    sendAuthHeader:config.sendAuthHeaderSearchSubject,
                    jsonRequest: config.jsonRequestSearchSubject,
                    jsonResponse: config.jsonResponseSearchSubject,
                }, data, jwt)
                .then((r) => resolve(r as SearchSubjectResponse))
                .catch((err) => reject(err));
            }
        })
    }

    const searchNextAppoinment = (config : any, jwt : any):Promise<NextAppointmentResponse> => {
        return new Promise((resolve, reject) => {
            invokeAPI({
                url:config.urlNextAppointment,
                method: config.methodNextAppointment,
                sendAuthHeader:config.sendAuthHeaderSearchSubject,
                jsonRequest: config.jsonRequestNextAppointment,
                jsonResponse: config.jsonResponseNextAppointment,
            }, undefined, jwt)
                .then((r) => resolve(r as NextAppointmentResponse))
                .catch((err) => reject(err));
        })
    }

    const stopAppointment = (config : any, jwt : any):Promise<StopAppointmentResponse> => {
        return new Promise((resolve, reject) => {
            invokeAPI({
                url:config.urlStopAppointment,
                method: config.methodStopAppointment,
                sendAuthHeader:config.sendAuthHeaderSearchSubject,
                jsonRequest: config.jsonRequestStopAppointment,
                jsonResponse: config.jsonResponseStopAppointment,
            }, undefined, jwt)
                .then((r) => resolve(r as StopAppointmentResponse))
                .catch((err) => reject(err));
        })
    }

    return {
        getAppointmentConfig,
        searchSubject,
        searchNextAppoinment,
        stopAppointment
    };
};
export default CMSAppointmentsServices;