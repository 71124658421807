export default {
    state: {
        objectsActions: []
    },
    reducers: {
        setObjectsActions(state, actionsList){
            return Object.assign({}, {...state, objectsActions:actionsList})
        },
    },
    effects: {},
}