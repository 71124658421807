import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Input } from '@material-ui/core';
import apiBUC from '../../../../services/BUCServices';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 240,
    },
  }),
);

const AddTagDialog = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClickBox = (x: any) => {
    x.stopPropagation();
  }

  const handleChange = (event: any ) => {
    event.stopPropagation();
    setValue(event.target.value);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    props.callbackFromParent();
  };

  const handleClickClose = (e: any) => {
    e.stopPropagation();
    setOpen(false);
  };

  const openTags = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  const onChangeValue = (event : any) => {
    setValue(event.target.value);
  }

  const handleAdd = () => {
    let aux = null;
    if(props.obj.etiquetas !== null){
      aux = props.obj.etiquetas + ',' + value;
    } else {
      aux = value;
    }
    if(props.obj.asociado){
      apiBUC.SubjectServices.putThirdObjectTags(props.cuit, {
        objeto_id: props.obj.id,
        tipo_objeto: props.obj.tipo,
        etiquetas: aux,
      })
      .then((response) => {
        window.location.reload();
      }) 
    } else {
      apiBUC.SubjectServices.putObjectTags(props.obj.id, {
        sujeto_id: props.cuit,
        tipo_objeto: props.obj.tipo,
        etiqueta: aux,
      })
      .then((response) => {
        window.location.reload();
      }) 
    }
    
    props.callbackFromParent();
  }

  return (
    <div>
      <Dialog open={props.show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Añadir Etiqueta</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingrese el nombre del Tag que desea insertar:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="tag"
            label="Nombre del Tag"
            type="tag"
            onChange={onChangeValue}
            fullWidth
            onClick={handleClickBox}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={openTags} color="primary">
            <SearchIcon/>
          </Button>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAdd} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Seleccione un tag en uso:</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-dialog-select-label">Tags</InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={value}
                onChange={handleChange}
                input={<Input />}
              >
                {props.tagsInUse.map( (tag, i) => {
                if(tag != '') {return(<MenuItem key={i} value={tag}>{tag}</MenuItem>)}
                })}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapState = state => ({
  objectsActions: state.objectsActions.objectsActions,
  locale: state.auth.locale,
  cuit: state.auth.cuit,
  tagsInUse: state.payments.tagsInUse
})

export default connect(mapState, null)(AddTagDialog);