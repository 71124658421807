import React from "react";
import { Route, Switch, Redirect } from "react-router";
import Login from "../../../containers/Login";
import PublicLogin from "../../../containers/Login/PublicLogin";
import IndexComponent from "../../../containers/Public/Index";
import PaymentsPublic from "../../PublicPage/Payments";
import DummyWeb from "../../../containers/DummyWeb";

import ExpirationsContainer from "../../../containers/Public/Expirations";
export default class PublicRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        {
          <Route exact path="/public-login">
            <PublicLogin />
          </Route>
        }
        <Route exact path="/dummy">
          <DummyWeb />
        </Route>
        <Route exact path="/home">
          <IndexComponent />
        </Route>
        <Route exact path="/applications">
          <ExpirationsContainer />
        </Route>
        <Route path="/payments/:type/:code">
          <PaymentsPublic />
        </Route>
        <Route path="/*">
          <Redirect to="/home" />
        </Route>
      </Switch>
    );
  }
}
