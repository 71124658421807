import React, { useEffect, useState } from "react";
import DynamicForm from "../../components/Applications/DinamycForm";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import LoadingComponent from "../../components/common/LoadingComponent";
import { Paper } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import apiBUC from "../../services/BUCServices";
import TramiteFormContainer from "../TramiteFormContainer";
import apiApplication from "../../services/ApplicationServices";
import Breadcrumbs from "../../components/common/MaterialComponents/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { AppContextProvider } from "../../components/Applications/DinamycForm/DynamicFormContext";
import Alert from "@material-ui/lab/Alert";
import Button from "../../components/Applications/DinamycForm/Inputs/Button";
import { ReactComponent as ErrorImg } from "../../assets/iconos/error.tdr.svg";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      marginTop: "40px",
      marginBottom: "80px",
    },
    marginLoading: {
      marginTop: "40px",
      marginBottom: "80px",
      display: "block",
    },
    loading: {
      minHeight: "500px",
      background: "#eeeeee",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    titulodelForm: {
      padding: "10px 10px",
      textTransform: "capitalize",
      color: "#6b6b6b",
      borderBottom: "solid 1px #444",
    },
    contForm: {
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
    superficie: {
      border: theme.superficies.border,
      borderRadius: theme.superficies.borderRadius,
    },
  })
);
const FormContainer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const parameters = useParams();
  const [hasError, setHasError] = React.useState(false);
  const [parametrica, setParametrica] = useState(
    JSON.parse(parameters["params"])
  );

  const location = useLocation();
  const [jsonform, setJsonform] = useState(null);
  const [paramTramiteId, setParamTramiteId] = useState(null);
  const [paramTramiteTipo, setParamTramiteTipo] = useState(null);
  const [pasos, setPasos] = useState(null);

  const [title, setTitle] = useState("");
  const [pasoId, setPasoId] = useState(null);
  const [initialPasoId, setInitialPasoId] = useState(null);
  const [tramiteId, setTramiteId] = useState(null);
  const [initial, setInitial] = React.useState({});
  const [loadingApplications, setLoadingApplications] = React.useState(true);
  const [loadingText, setLoadingText] = React.useState(null);
  const [readOnly, setReadOnly] = React.useState(false);
  const [tramite, setTramite] = React.useState<any>({});
  const [urlExterna, setUrlExterna] = React.useState<any>("");
  const [formularioEnriquecidoId, setFormularioEnriquecidoId] =
    React.useState("");
  const [muestroPaso, setMuestroPaso] = React.useState(true);
  const history = useHistory();
  const [disabledFooter, setDisabledFooter] = React.useState(false);
  // carga los datos de un tramite en el estadod el componente
  const loadForm = async (tramite) => {
    setParamTramiteId(tramite.tipo);
    setInitial(JSON.parse(tramite.descripcion));
    loadFormById(tramite.tipo);
    if (!tramite.estado) {
      const p = JSON.parse(tramite.estado);
      setPasos(tramite.processing ? p.slice(0, -1) : p);
    }
  };

  // Obtiene las paramtericas del tramite seleccionado (param-tramite)
  const loadFormById = (paramTramiteId) => {
    if (!props.jwt) {
      return;
    }
    apiApplication.setToken(props.jwt);
    setHasError(false);
    apiApplication.services
      .getApplicationForm(paramTramiteId)
      .then((response) => {
        setJsonform(JSON.parse(response.data.formulario));
        setFormularioEnriquecidoId(response.data.formularioEnriquecerId);
        setTitle(response.data.nombre);
        setUrlExterna(response.data.urlExterna);
        setDisabledFooter(response.data.readOnly);

        setParamTramiteTipo(response.data.tipo);
        setLoadingApplications(false);
      })
      .catch((error) => {
        // TODO: define an error message
        setLoadingApplications(false);
        setHasError(true);
        // console.error(
        //   "Este mensaje indica que el backend genero un error y para fines de de desarrollo se mockea una respuesta."
        // );
        //history.push("/nuevo-tramite");
      });
  };

  const getSubjectTramite = (cb) => {
    setTramiteId(parametrica["tramiteId"]);
    setHasError(false);
    apiBUC.ProceduresServices.getProcedure(parametrica["tramiteId"])
      .then((res) => {
        // setLoadingApplications(false);
        setTramite(res.data);
        cb(res.data);
      })
      .catch((err) => {
        console.log(err);
        setHasError(true);
        setLoadingText(
          <FormattedMessage
            id={"formContainer.notFoundProcedure"}
            defaultMessage="No se encontro el tramite solicitado"
          />
        );
        setLoadingApplications(false);
      });
  };

  useEffect(() => {
    if (!parametrica["tramiteId"]) {
      setParamTramiteId(parametrica["paramTramiteId"]);
      loadFormById(parametrica["paramTramiteId"]);
      setMuestroPaso(false);
    } else {
      getSubjectTramite(async (tramite) => {
        setInitial(JSON.parse(tramite.descripcion));

        if (tramite.estado !== "undefined") {
          let p = JSON.parse(tramite.estado);

          p = tramite.processing
            ? p.slice(0, -1).filter((step) => !(step.noMostrar === "true"))
            : p.filter((step) => !(step.noMostrar === "true"));
          setPasos(p);
          setPasoId(
            parametrica["pasoId"]
              ? parametrica["pasoId"]
              : p
              ? p.length > 0
                ? p[p.length - 1]?.proceso
                : null
              : null
          );
          setInitialPasoId(
            parametrica["pasoId"]
              ? parametrica["pasoId"]
              : p
              ? p.length > 0
                ? p[p.length - 1]?.proceso
                : null
              : null
          );
        }

        loadForm(tramite);
      });
    }
  }, []);
  const goBack = () => {
    if (props.urlToGoBack) {
      window.top.location.href = props.urlToGoBack;
    } else {
      window.history.back();
    }
  };
  useEffect(() => {
    setReadOnly(parametrica["readOnly"] ? true : false);
  }, [parametrica["readOnly"]]);

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/dashboard">Inicio</Link>
        <Link to="/mis-tramites">Trámites</Link>

        <Typography color="textPrimary">
          {" "}
          {title}
          <small>
            {tramiteId && "- (" + tramiteId.slice(-6, tramiteId.length) + ")"}
          </small>
        </Typography>
      </Breadcrumbs>
      <div className={classes.margin}>
        <TramiteFormContainer
          pasoId={pasoId}
          pasoIdToShow={initialPasoId}
          viewOnly={readOnly}
          initial={initial}
          muestroPaso={muestroPaso}
          steps={pasos}
          tramiteId={tramiteId}
          paramTramiteId={paramTramiteId}
          formularioEnriquecidoId={formularioEnriquecidoId}
          tramite={tramite}
          initialPasoId={initialPasoId}
        >
          {urlExterna ? (
            <Paper
              className={classes.superficie}
              elevation={theme.superficies.elevation}
            >
              <iframe
                id="inlineFrameExample"
                title="Inline Frame Example"
                width="100%"
                frameBorder="0"
                height="600px"
                src={
                  Object.keys(initial).length > 0
                    ? urlExterna.replace(
                        "{{access_token}}",
                        props.accessToken
                      ) +
                      "?data=" +
                      JSON.stringify(initial)
                    : urlExterna.replace("{{access_token}}", props.accessToken)
                }
              />
            </Paper>
          ) : !loadingApplications ? (
            jsonform ? (
              <DynamicForm
                disabledFooter={disabledFooter}
                initial={initial}
                viewOnly={readOnly}
                paramTramiteId={paramTramiteId}
                jsonform={jsonform}
                pasoId={pasoId}
                tramiteId={tramiteId}
                steps={pasos}
                paramTramiteTipo={paramTramiteTipo}
                lastPaso={pasos ? pasos[pasos.length - 1]?.proceso : pasoId}
                title={title}
              />
            ) : (
              <>
                {hasError ? (
                  <>
                    <div className="text-center p-5">
                      <ErrorImg />
                      <h6 className="mt-2">
                        {`No encontramos el formulario ${
                          pasoId && "para el paso " + pasoId
                        }`}
                      </h6>
                    </div>

                    <div className="d-flex justify-content-center mt-2">
                      <Button
                        variant={"contained"}
                        color="primary"
                        onClick={goBack}
                      >
                        Volver
                      </Button>
                    </div>
                  </>
                ) : null}
              </>
            )
          ) : (
            <LoadingComponent isLoading>
              <Paper className={classes.loading}>
                <h2 className="text-center"> Cargando...</h2>
              </Paper>
            </LoadingComponent>
          )}
        </TramiteFormContainer>
      </div>
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  formList: state.applications.formList,
  jwt: state.auth.jwt,
  accessToken: state.auth.accessToken,
  urlToGoBack: state.auth.urlToGoBack,
});

const mapDispatch = (dispatch) => ({
  setFormList: (formList) => dispatch.applications.setFormList(formList),
});

export default connect(mapState, mapDispatch)(FormContainer);
