import * as React from "react";

export interface AppContextInterface {
  viewOnly: boolean;
  initialSnapshot?: any;
  tramiteId?: any;
  pasoId?: any;
  lastPasoId?: any;
  inputFocus?: any;
  setInputFocus: any; // () => {}
  BaseUrls?: any;
}

const ctxt = React.createContext<AppContextInterface | null>(null);

export const AppContextProvider = ctxt.Provider;

export const AppContextConsumer = ctxt.Consumer;
export const ctxts = ctxt;
