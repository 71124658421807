import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { makeStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import ApplicationSteps from "../ApplicationSteps";
import Grid from "@material-ui/core/Grid";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import AdjustIcon from "@material-ui/icons/Adjust";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme: Theme) => ({
  stepsButtons: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  timeline: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    "&::before": {
      top: "20px",
      width: "2px",
      bottom: "20px",
      content: "''",
      right: "24px",
      position: "absolute",
      background: "#c7c7c7",
    },
  },
  stepButton: {
    textTransform: "none",
    fontWeight: 400,
    color: "#9a9a9a",
    textAlign: "right",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  lineamobile: {
    [theme.breakpoints.down("sm")]: {
      "&:before": {
        content: "' '",
        position: "absolute",
        top: "4px",
        bottom: "6px",
        borderLeft: "solid 2px #d8d8d8",
        left: "24px",
      },
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
  hideOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  icon: {
    margin: "5px",
    borderRadius: "100%",
    background: "#ebebeb",
    zIndex: 1,
  },
  stepLabel: { textTransform: "none" },
  pasosMobile: {
    display: "flex",
    flexDirection: "row",
  },
}));

const StepsMenu = (props) => {
  const classes = useStyles();

  // const [anchorEl, setAnchorEl] = React.useState(null);
  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  return (
    <>
      {props.mobile ? (
        <div className={classes.pasosMobile}>
          <Button
            aria-controls="pasos-del-tramite"
            aria-haspopup="true"
            onClick={props.handleOpen}
          >
            {" "}
            <span className={classes.stepLabel}>
              Paso:{" "}
              {props.primero === props.active
                ? "Inicio del trámite"
                : props.enriquecer === props.active
                ? "Enriquecer"
                : props.active}
              <ExpandMoreIcon className={classes.icon} />
            </span>
          </Button>
          <Menu
            id="pasos-del-tramite"
            anchorEl={props.open}
            keepMounted
            open={Boolean(props.open)}
            onClose={props.handleClose}
          >
            <MenuItem
              onClick={props.mostrarInicio}
              style={{
                justifyContent: "flex-start",
                whiteSpace: "initial",
                textAlign: "left",
                lineHeight: "1rem",
                paddingBottom: "1rem",
              }}
            >
              {" "}
              <AdjustIcon className={classes.icon} /> Inicio del trámite{" "}
              <small
                style={{
                  position: "absolute",
                  bottom: "0px",
                  right: "40px",
                  fontSize: "9px",
                }}
              >
                {props.viewOnly &&
                  moment(props.tramite.fechaInicio)
                    .utc()
                    .format("DD/MM/YYYY HH:mm") + "hs"}
              </small>
            </MenuItem>
            {props.steps &&
              props.steps.map((el, i) => (
                <React.Fragment key={"spm" + i}>
                  {/* {(i !== props.steps.length - 1 || !props.viewOnly) && ( */}
                  <MenuItem
                    key={i}
                    className={
                      el.proceso === props.active
                        ? `${classes.stepButton} ${classes.active}`
                        : classes.stepButton
                    }
                    style={{
                      justifyContent: "flex-start",
                      whiteSpace: "initial",
                      textAlign: "left",
                      lineHeight: "1rem",
                      paddingBottom: "1rem",
                    }}
                    onClick={() => props.mostrarPaso(el.proceso)}
                  >
                    <AdjustIcon className={classes.icon} />
                    {el.name ? el.name : el.proceso.replace(/_/g, " ")}
                    <small
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        right: "40px",
                        fontSize: "9px",
                      }}
                    >
                      {moment(el.fecha).format("DD/MM/YYYY HH:mm") + "hs"}
                    </small>
                  </MenuItem>
                  {/* )} */}
                </React.Fragment>
              ))}
            {
              // Se chequea si se debe mostrar el boton de enriquecer o no
              props.formularioEnriquecidoId && props.tramite.id ? (
                <MenuItem
                  className={
                    props.enriquecer === props.active
                      ? `${classes.stepButton} ${classes.active}`
                      : classes.stepButton
                  }
                  onClick={props.mostrarEnriquecer}
                >
                  <AdjustIcon className={classes.icon} />
                  Enriquecer
                </MenuItem>
              ) : null
            }
          </Menu>
        </div>
      ) : (
        <div className={classes.stepsButtons}>
          <div className={classes.timeline}>
            <Button
              className={
                props.primero === props.active
                  ? `${classes.stepButton} ${classes.active}`
                  : classes.stepButton
              }
              onClick={props.mostrarInicio}
            >
              Inicio del trámite
              <small
                style={{
                  position: "absolute",
                  bottom: "0px",
                  right: "40px",
                  fontSize: "9px",
                }}
              >
                {props.viewOnly &&
                  moment(props.tramite.fechaInicio)
                    .utc()
                    .format("DD/MM/YYYY HH:mm") + "hs"}
              </small>
              <AdjustIcon className={classes.icon} />
            </Button>
            {props.steps &&
              props.steps.map((el, i) => (
                <React.Fragment key={"smp" + i}>
                  {/* {(i !== props.steps.length - 1 || !props.viewOnly) && ( */}
                  <Button
                    key={i}
                    className={
                      el.proceso === props.active
                        ? `${classes.stepButton} ${classes.active}`
                        : classes.stepButton
                    }
                    onClick={() => props.mostrarPaso(el.proceso)}
                    style={{ lineHeight: "1rem", padding: "10px 8px 15px" }}
                  >
                    {el.name ? el.name : el.proceso.replace(/_/g, " ")}
                    <small
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        right: "40px",
                        fontSize: "9px",
                      }}
                    >
                      {moment(el.fecha).format("DD/MM/YYYY HH:mm") + "hs"}
                    </small>
                    <AdjustIcon className={classes.icon} />
                  </Button>
                  {/* )} */}
                </React.Fragment>
              ))}
          </div>

          {
            // Se chequea si se debe mostrar el boton de enriquecer o no
            props.formularioEnriquecidoId && props.tramite.id ? (
              <Button
                className={
                  props.enriquecer === props.active
                    ? `${classes.stepButton} ${classes.active}`
                    : classes.stepButton
                }
                onClick={props.mostrarEnriquecer}
              >
                Enriquecer <FiberManualRecordIcon className={classes.icon} />
              </Button>
            ) : null
          }
        </div>
      )}
    </>
  );
};

export default StepsMenu;
