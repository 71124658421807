import React from "react";
import Container from "@material-ui/core/Container";

import { FormattedMessage } from "react-intl";
import Button from '@material-ui/core/Button';
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import  Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';
import {callToAnActionMock} from '../../../mock/publicInfo';
const useStyles = makeStyles(theme=>({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "5px auto",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  calltoanaction:{
    background:'#73a4e5',
    color:'white',
    fontWeight:200,
    padding:'40px',
    [theme.breakpoints.up("md")]: {
    fontSize: '40px',
    },
    margin:'40px 0',
  },
  alignRight:{justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
      textAlign:'right',
     
  }
}));
const CallToAnAction = (props) => {
  const classes = useStyles();
  return <div className={classes.calltoanaction}> <Container maxWidth="xl">
  <Grid container>
  <Grid item sm={8}>
     <h2>{callToAnActionMock.title}</h2>
  </Grid>
  <Grid item sm={4} className={classes.alignRight}>
 <Link to={callToAnActionMock.action}> <Button variant='contained'>
 {callToAnActionMock.actionLabel}

      </Button></Link>
  </Grid>
  </Grid>
  </Container></div>;
};
export default CallToAnAction;
