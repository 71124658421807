import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles({
  title: {
    display: "flex",
    justifyContent: "space-between",
  },
  titulo: {
    fontWeight: 300,
  },
  button: {
    margin: "3px 10px 3px 0",

    borderRadius: "20px",
    color: "#525252",
    fontSize: "7px",
    padding: "0px 10px",
  },
  descripcion: {
    marginLeft: "5px",
    padding: "3px 5px",
    fontSize: "18px",
    fontWeight: 300,
  },
});
const MyAffidavitModal = (props) => {
  const classes = useStyles();
  if(props.affidavit===null)return<span></span>;
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={props.isOpen}
      onClose={props.handleClose}
    >
      <DialogTitle id="responsive-dialog-title">
        <div className={classes.title}>
          <span>
            <FormattedMessage
              id="affidavitModal.title"
              defaultMessage="Detalle de DDJJ: "
            />
            {`${props.affidavit.sojIdentificador }`}
          </span>
         </div>
     
      </DialogTitle>
      <DialogContent>
      <h5 className={classes.titulo}> 
            <FormattedMessage
              id="affidavitModal.identificador"
              defaultMessage="Cuit: "
            />{props.affidavit.sojIdentificador }
        </h5>
       
      </DialogContent>
      <DialogActions> 
        <Button
          onClick={props.handleClose}
          variant="outlined"
          color="primary"
          autoFocus
        >
          <FormattedMessage id="affidavitModal.btnCerrar"
           defaultMessage="Cerrar" />
        </Button>
        <Button
          onClick={props.handleSubmit}
          variant="contained"
          color="primary"
          autoFocus
        >
          <FormattedMessage id="affidavitModal.btnSubmit"
           defaultMessage="Aceptar" />
        </Button>
       </DialogActions>
    </Dialog>
  );
};


export default MyAffidavitModal;