export default {
    state: {
        debtLoading: true,
        debtExist: false
    },
    reducers: {
        setDebtLoading(state, debtLoading) {
            return Object.assign({}, {...state, debtLoading: debtLoading})
        },
        setDebtExist(state, debtExist) {
            return Object.assign({}, {...state, debtExist: debtExist})
        },
    },
    effects: {},
}