import React, { useEffect, Children } from "react";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

import SeccionHeader from "../../common/SeccionHeader";
import { FormattedMessage } from "react-intl";
import Tramite from "../Components/Tramite";
import ConsultaTramite from "../Components/ConsultaTramite";
import PaymentSearch from '../Components/PaymentSearch';
import apiCMS from '../../../services/CMSServices';
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
root:{
margin:'40px auto',
},
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "5px auto",
    flexWrap:'wrap',
    justifyContent:'center'
  },
  link:{textDecoration:'none','&:hover':{
    textDecoration:'none',  
  }},

  title: {
    color: "#444",
    fontSize: "24px",
    fontFamily: "roboto",
    fontWeight: 300,
    textAlign: "center",
  },
  footer: { display: "flex", margin: "30px auto", justifyContent: "center" },

});
const SectionTramites = (props) => {
  const [procedures, setProcedures] = React.useState([]);
    const classes= useStyles();


    useEffect(() => {
      apiCMS.ProceduresServices.getProceduresData()
          .then((response) => {
            setProcedures(response.data);
          });
  }, []);

const handleSearch =()=>{}
  return (
    <Container maxWidth="xl">
     
     <SeccionHeader
        title={
          <FormattedMessage
            id="public.applications.searchTitle"
            defaultMessage="Consultá el estado de tu trámite"
          />
        }
      >

      </SeccionHeader>     <Container maxWidth="lg">
     <PaymentSearch label={'N° de trámite'} onClick={handleSearch} placeholder={'A15609,  I7856, ...'}/>
     </Container>
     <div className={classes.root}>
     <h4 className={classes.title}>
     <FormattedMessage
            id="public.applications.title"
            defaultMessage="Trámites más consultados"
          />
        </h4>
    <Container maxWidth="lg">

      <div className={classes.container}>
        {procedures.map((el) => (
          <Tramite obj={el} key={el.id} />
        ))}
      </div></Container>
      {/* <div className={classes.footer}>
            <Link className={classes.link} to={'/Applications'}>
            <Button variant='outlined' color='primary' >   
            <FormattedMessage
              id="public.applications.button.showAll"
              defaultMessage="Todos los trámites"
            />
            </Button>
            </Link>
  </div> */}
  </div>
    </Container>
  );
};
export default SectionTramites;
