
const CMSConfigsServices = (instance) => {

    const getConfigs = () => instance.get('configs/');
    const getThemeSelected = () => instance.get('themes/selected');

    return {
        getConfigs,
        getThemeSelected
    };
};
export default CMSConfigsServices;