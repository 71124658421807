import { useField } from "react-final-form";
import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { useTheme } from "@material-ui/core/styles";
const fullWidthOp = false;

const SelectSearchedField = (props) => {
  const [val, setVal] = React.useState(
    props.meta.initial ? props.meta.initial : []
  );
  const inputelement: InputElement = props.inputelement;
  const classes = useStyles();
  const {
    label,
    input,
    meta: { touched, invalid, error },
    name,
    meta,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const setValue = (value, input) => {
    setVal(value);
    input.onChange(value); //update forn-redux value
  };
  const theme = useTheme();
  return (
    <FormControl
      {...custom}
      fullWidth={fullWidthOp}
      error={touched && invalid}
      className={classes.formcontrol}
    >
      <Autocomplete
        multiple={custom.multiple}
        id="tags-filled"
        options={custom.options.map((option) => option.name)}
        onChange={(event, value, reason) => setValue(value, input)}
        value={val}
        renderTags={(value: string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            variant={theme.inputs.variant ? theme.inputs.variant : "standard"}
            {...custom}
            {...params}
            label={inputelement.label}
            placeholder={custom.placeholder}
          />
        )}
      />
    </FormControl>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(SelectSearchedField);
