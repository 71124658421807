import React from 'react';
import Obligation from '../Obligation/Obligation';
import {connect} from 'react-redux';
import 'moment-timezone';

function ObligationMapper(props) {
    return props.obligations.map((ob, i) => {
        const excludedStates = props.obligationStates.filter(state => !state.show).map((ob) => ob.code);
        if (!excludedStates.includes(ob.estado) && ob.estado !== 'JUDICIAL') {
            return <Obligation key={ob.id + i} obligation={ob}/>
        } else {
            return null
        }
    })
}

const mapState = state => ({
    obligationStates: state.payments.obligationStates
})

export default connect(mapState, null)(ObligationMapper);