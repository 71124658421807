import React, { useEffect, useRef } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";

import { useHistory } from "react-router";
import { applyDependencies, applySimpleCondition, getUrl } from "./Operations";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
const fullWidthOp = false;

const LinkComponent = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const inputelement: InputElement = props.inputelement;
  const {
    label,
    input,
    meta: { touched, invalid, error },
    name,
    meta,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const context = useContext(ctxts);
  const [visible, setVisible] = React.useState(
    inputelement.isConditional ? false : true
  );

  const [url, setUrl] = React.useState(
    // url para datos asincronos si cambia ejecuta useEffect
    inputelement.to
      ? getUrl(
          input.name,
          inputelement.to,
          props.cuit,
          values,
          null,
          null,
          inputelement.linkBaseUrl,
          context.BaseUrls
        )
      : ""
  );

  const [urlCondition, setUrlCondition] = React.useState(
    // url para datos asincronos determina condicion de visualizacion si cambia ejecuta useEffect
    inputelement.isConditional && inputelement.conditional?.asyncData
      ? getUrl(
          input.name,
          inputelement.conditional.asyncConf.url,
          props.cuit,
          values,
          null,
          null,
          inputelement.conditional.asyncConf.baseUrl,
          context.BaseUrls
        )
      : ""
  );

  useEffect(() => {
    setUrl(
      inputelement.to
        ? getUrl(
            input.name,
            inputelement.to,
            props.cuit,
            values,
            null,
            null,
            inputelement.linkBaseUrl,
            context.BaseUrls
          )
        : ""
    );

    applyDependencies(props);
    if (
      inputelement.isConditional &&
      inputelement.conditional &&
      !inputelement.conditional.asyncData
    ) {
      setVisible(applySimpleCondition(props));
    }
  });
  useEffect(() => {
    setUrl(
      inputelement.asyncData
        ? getUrl(
            input.name,
            inputelement.asyncConf.url.replace("[value]", input.value),
            props.cuit,
            values,
            null,
            null,
            inputelement.asyncConf.baseUrl,
            context.BaseUrls
          )
        : ""
    );
  }, [values]);

  const renderLink = () => {
    if (inputelement.target && inputelement.target === "blank") {
      return (
        <a target="_blank" href={url}>
          {inputelement.label}
        </a>
      );
    } else {
      return (
        <a
          href=""
          onClick={() => {
            history.push(url);
          }}
        >
          {inputelement.label}
        </a>
      );
    }
  };

  if (visible) {
    return (
      <FormControl
        {...custom}
        fullWidth={fullWidthOp}
        error={touched && invalid}
        className={classes.formcontrol}
      >
        <div>{renderLink()}</div>
      </FormControl>
    );
  } else {
    return null;
  }
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(LinkComponent);
