import React, { useEffect, useRef } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import {
  getUrl,
  applyDependencies,
  applyInitialDependencies,
  applySimpleCondition,
  getDependencies,
  validarDato,
  dependenciaCondicional,
  getAsyncData,
  getDataFromResponse,
} from "./Operations";

import { Button } from "@material-ui/core";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import _ from "lodash";
import {
  TextFieldProps,
  BaseTextFieldProps,
} from "@material-ui/core/TextField";
import { useTheme } from "@material-ui/core/styles";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
const fullWidthOp = false;

const DateField = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    meta: { touched, invalid, error },
    name,
    input,
    meta,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const theme = useTheme();
  //#region  //? Condicional  visible o no config
  const [visible, setVisible] = React.useState(
    inputelement.isConditional ? false : true
  );
  const context = useContext(ctxts);
  const mountedRef = useRef(true);
  const [urlCondition, setUrlCondition] = React.useState("");
  useEffect(() => {
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      dependenciaCondicional(inputelement, urlCondition, setVisible, props);
    }
  }, [urlCondition]);
  //#endregion
  //#region //! actualizar  urls
  //? con estas dependencias y useEffect recalculo las urls
  //? solo cuando cambien los valores de los inputs que la url requiere
  const [isLoading, setIsLoading] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const getArrayDependecies = () => {
    const dependencias = getDependencies(input, inputelement);
    return [
      dependencias.map((d) => {
        const dependenciaValue = _.get(values, d);
        return dependenciaValue ? dependenciaValue : "";
      }),
    ];
  };
  useEffect(() => {
    if (inputelement.asyncData) {
      setUrl(
        getUrl(
          input.name,
          inputelement.asyncConf.url,
          props.cuit,
          values,
          inputelement.asyncConf.JsonFieldsName,
          null,
          inputelement.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      setUrlCondition(
        getUrl(
          input.name,
          inputelement.conditional.asyncConf.url,
          props.cuit,
          values,
          inputelement.conditional.asyncConf.JsonFieldsName,
          null,
          inputelement.conditional.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [...getArrayDependecies()]);
  //#endregion
  //#region //? validaciones asyncronas
  const [msj, setMsj] = React.useState({
    show: false,
    type: "error",
    msj: inputelement.asyncValidationConf?.errorMsg,
  });

  const validar = () =>
    validarDato(inputelement, input, props, values, setMsj, context.BaseUrls);
  //#endregion
  //#region  //? calculos entre campos
  useEffect(() => {
    applyDependencies(props);
    if (
      inputelement.isConditional &&
      inputelement.conditional &&
      !inputelement.conditional.asyncData
    ) {
      setVisible(applySimpleCondition(props));
    }
  });
  //#endregion
  //#region //?traer datos asincronos
  const dependencias = getDependencies(input, inputelement);
  const focusedInput = props.appContext.inputFocus;
  useEffect(() => {
    if (!props.isLastPaso) return;
    if (
      inputelement.asyncData &&
      !dependencias.some((d) => d === props.appContext.inputFocus)
    ) {
      setIsLoading(true);
      const response = getAsyncData(inputelement, url, props, input);

      response.then((res) => {
        if (!mountedRef.current) return null;
        setIsLoading(false);
        if (res) {
          const ValueToSet = getDataFromResponse(
            res,
            inputelement.asyncConf.propertyText
          );
          handleDateChange(new Date(ValueToSet));
        }
      });
    }
  }, [url, focusedInput]); //se ejecuta solo si la url cambia
  //#endregion
  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#region //? inicializar el componente
  useEffect(() => {
    applyInitialDependencies(props);
  }, [
    inputelement.dependsOn ? _.get(values, inputelement.dependsOn[0]) : null,
  ]);
  //#endregion

  const handleDateChange = (date: Date | null) => {
    input.onChange(date);
  };
  const [isOpen, setIsIpen] = React.useState(false);
  const open = () => {
    if (props.disabled) return;
    setIsIpen(true);
  };
  const close = () => {
    setIsIpen(false);
  };

  const posiblyDates = {
    noLimits: (d) => {
      return null;
    },
    today: (d) => {
      return new Date();
    },
    customDate: (d) => {
      return d ? new Date(d) : null;
    },
  };

  const maxDate = posiblyDates[inputelement.dateMax]?.(
    inputelement.customDateMax
  );
  const minDate = posiblyDates[inputelement.dateMin]?.(
    inputelement.customDateMin
  );

  if (visible) {
    return (
      <div
        className={isLoading || props.loadingAsyncData ? classes.loading : null}
      >
        <FormControl
          {...custom}
          fullWidth={fullWidthOp}
          error={touched && invalid}
          className={classes.formcontrol}
          variant={theme.inputs.variant ? theme.inputs.variant : "standard"}
        >
          <div
            className={"d-flex" + props.isTableView ? classes.tableView : ""}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <KeyboardDatePicker
                inputVariant={
                  theme.inputs.variant
                    ? (theme.inputs.variant as TextFieldProps["variant"])
                    : "standard"
                }
                autoOk
                margin="normal"
                id={input.name + "date-picker-dialog"}
                label={inputelement.label}
                disabled={props.disabled}
                format="dd/MM/yyyy"
                open={isOpen}
                onClick={open}
                maxDate={maxDate ? maxDate : "10/10/3000"}
                minDate={minDate}
                cancelLabel={"Cancelar"}
                okLabel={"Aceptar"}
                onClose={close}
                invalidDateMessage="fecha inválida"
                value={input.value ? new Date(input.value) : null}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  className: props.isTableView ? classes.paddingSmall : "",
                }}
                InputAdornmentProps={{
                  className: props.isTableView ? "d-none" : "",
                }}
              />
            </MuiPickersUtilsProvider>
            {inputelement.asyncValidation ? (
              <Button onClick={validar}>
                {inputelement.asyncValidationConf?.label}
              </Button>
            ) : null}
          </div>
          {msj.show ? (
            <span
              style={
                msj.type === "error" ? { color: "red" } : { color: "green" }
              }
            >
              {msj.msj}
            </span>
          ) : null}
          {props.meta.touched && error && (
            <span style={{ color: "red" }}>{error}</span>
          )}
        </FormControl>
      </div>
    );
  } else return null;
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(DateField);
