import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import {
  getUrl,
  getDataFromResponse,
  applyDependencies,
  applyInitialDependencies,
} from "./Operations";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
import Axios, { Method } from "axios";
const TextSimpleField = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const theme = useTheme();
  const {
    meta: { touched, invalid, error },
    name,
    input,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const context = useContext(ctxts);

  const [url, setUrl] = React.useState(
    // url para datos asincronos si cambia ejecuta useEffect
    inputelement.asyncData
      ? getUrl(
          input.name,
          inputelement.asyncConf.url,
          props.cuit,
          values,
          null,
          null,
          inputelement.asyncConf.baseUrl,
          context.BaseUrls
        )
      : ""
  );

  //use efect para calculos entre campos
  useEffect(() => {
    applyDependencies(props);
  });

  //use efect para traer datos asincronos
  useEffect(() => {
    // TIENE DEPENDENCIA EN URL
    if (inputelement.asyncData) {
      Axios({
        method: inputelement.asyncConf.method as Method,
        url: url,
        headers: { Authorization: `Bearer ${props.jwt}` },
      })
        .then((response) => {
          const res = response;
          const ValueToSet = getDataFromResponse(
            res,
            inputelement.asyncConf.propertyText
          );
          input.onChange(ValueToSet); //esto setea el valor al input se usa en campos tipo text, number: En otros campos podrian ser las opciones del select o checkboxs
        })
        .catch(function (error) {
          console.log(error.message);
        });
      //get data from api with newUrl
    }
  }, [url]); //se ejecuta solo si la url cambia

  //use efect para inicializar el componente
  useEffect(() => {
    applyInitialDependencies(props);
  }, []);

  return (
    <FormControl
      {...custom}
      error={touched && invalid}
      className={classes.formcontrol}
    >
      <TextField
        variant={theme.inputs.variant ? theme.inputs.variant : "standard"}
        className={classes.outputField}
        disabled
        fullWidth
        label={inputelement.label}
        placeholder={inputelement.label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
      />
    </FormControl>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(TextSimpleField);
