import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { InputGroup } from "../../models/IDynamicForm";
import { FieldArray } from "react-final-form-arrays";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import TableInput from "../RenderInputs/TableInput";
import { AppContextConsumer } from "../../DynamicFormContext";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import Axios, { Method } from "axios";
import {
  getUrl,
  getDependenciasByUrl,
  getValuesFromOtherGroup,
} from "../../Inputs/Operations";
import { connect } from "react-redux";
import { useField } from "react-final-form";
import { useContext } from "react";
import { ctxts } from "../../DynamicFormContext";
const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
    maxWidth: "100%",
    padding: "5px 0",
    [theme.breakpoints.down("sm")]: {
      display: "inline-block",
    },
  },
  LessPadding: {
    padding: "0 5px 0 5px",
  },
  tableHeader: {
    background: "#eee",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  deleteAction: {},
  tableContainer: {
    position: "relative",
    padding: "5px 0",
    overflowY: "hidden",
    margin: "15px 0",
    maxWidth: "100%",
  },
  addButton: {
    background: "white",
    border: " solid #ddd",
    "&:hover": {
      background: "#eee",
    },
  },
  tableRow: {
    // display:'flex',
    // flexDirection:'column',
    // maxWidth:'100%'
  },
  addButtonContainer: {
    textAlign: "center",
    margin: "30px",
    position: "relative",

    "&:before": {
      content: "''",
      borderBottom: "solid 2px #ddd",
      width: "100%",
      display: "block",
      position: "absolute",
      top: "50%",
    },
  },
  smallTableCell: {
    paddingLeft: "1px",
    paddingRight: "1px",
  },
}));
type Props = {
  values: any;
  group: InputGroup;
  push: any;

  pop: any;
};

const TableView = (props) => {
  const { values, group, push, pop } = props;
  const context = useContext(ctxts);
  const classes = useStyles();
  const [url, setUrl] = React.useState("");
  const field = useField(group.name);

  let dependencias = [];
  if (group.asyncData)
    getDependenciasByUrl(group.name, group.asyncConf.url, null, dependencias);

  useEffect(() => {
    if (group.asyncData) {
      setUrl(
        getUrl(
          group.name,
          group.asyncConf.url,
          props.cuit,
          values,
          group.asyncConf.JsonFieldsName,
          null,
          group.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [
    ...dependencias.map((d) => {
      const dependenciaValue = _.get(values, d);
      return dependenciaValue ? dependenciaValue : "";
    }),
  ]);

  const [initialValue, setInitialValue] = React.useState(values[group.name]);
  // let initialValue = null;
  //!TODO GET VALUES FROM OTHER GROUP
  useEffect(() => {
    getValuesFromOtherGroup(group, values, field);
  }, [_.get(values, group.otherGroupValues)]);
  const [isLoading, setIsLoading] = React.useState(false);

  //use efect para traer datos asincronos
  useEffect(() => {
    // TIENE DEPENDENCIA EN URL
    if (!props.isLastPaso) return;
    if (group.asyncData && url && !(url.includes("[") || url.includes("{"))) {
      setIsLoading(true);
      Axios({
        method: group.asyncConf.method as Method,
        url: url,
        headers: { Authorization: `Bearer ${props.jwt}` },
      })
        .then((response) => {
          field.input.onChange(_.get(response, group.asyncConf.dataKey));
          setIsLoading(false);
          // setInitialValue(response.data);
        })
        .catch(function (error) {
          console.log(error.message);
          setIsLoading(false);
        });
    }
  }, [url]); //se ejecuta solo si la url cambia
  const disableForce = (appContext, name) => {
    const grupo = name.split("[")[0];
    const index = name.split("[")[1].split("]")[0];
    return appContext.initialSnapshot &&
      group.initialValuesInmutables &&
      appContext.initialSnapshot[grupo]
      ? parseInt(index) <= appContext.initialSnapshot[grupo].length - 1
      : false;
  };
  const requiredArray = (value) =>
    value && value.length > 0 ? undefined : "Required";

  const isRequired = (group) => {
    return group.inputs.some((i) => i.required);
  };

  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.smallTableCell}></TableCell>
              {group.inputs.map((inp, index) => (
                <TableCell
                  className={classes.smallTableCell}
                  key={index}
                  align="left"
                >
                  {inp.label + (inp.required ? "*" : "")}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <FieldArray
              name={group.name}
              validate={isRequired(group) ? requiredArray : null}
            >
              {({ fields }) => {
                return fields.map((name, index) => (
                  <React.Fragment key={"tb-" + index}>
                    {group.inputs && group.inputs.length > 0 ? (
                      <TableRow className={classes.tableRow} key={index}>
                        <TableCell
                          className={classes.smallTableCell}
                          key={index}
                        >
                          <AppContextConsumer>
                            {(appContext) =>
                              appContext &&
                              !appContext.viewOnly && (
                                <>
                                  {!disableForce(appContext, name) &&
                                  !group.initialValuesInmutables ? (
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => fields.remove(index)}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  ) : null}
                                </>
                              )
                            }
                          </AppContextConsumer>
                        </TableCell>

                        <AppContextConsumer>
                          {(appContext) =>
                            group.inputs.map((input, index) => (
                              <TableInput
                                key={index}
                                name={name}
                                input={input}
                                values={values}
                                isTableView={true}
                                loadingAsyncData={isLoading}
                                initialValuesInmutables={disableForce(
                                  appContext,
                                  name
                                )}
                              />
                            ))
                          }
                        </AppContextConsumer>
                      </TableRow>
                    ) : (
                      <Alert variant="outlined" severity="warning">
                        El grupo no tiene ningun input
                      </Alert>
                    )}
                  </React.Fragment>
                ));
              }}
            </FieldArray>
          </TableBody>
        </Table>
      </TableContainer>
      <AppContextConsumer>
        {(appContext) =>
          appContext &&
          !appContext.viewOnly &&
          !group.initialValuesInmutables && (
            <div className={classes.addButtonContainer}>
              <IconButton
                className={classes.addButton}
                aria-label="add"
                onClick={() => push(group.name, undefined)}
              >
                <Add fontSize="large" />
              </IconButton>
            </div>
          )
        }
      </AppContextConsumer>
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(TableView);
