import React from 'react';
import ObligationCheckbox from "../ObligationCheckbox/ObligationCheckbox";
import { connect } from 'react-redux';
import NumberCurrency from '../../../common/NumberCurrency';
import './styles.css';
import moment from 'moment';
import 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import { Checkbox, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import JuicioTags from '../JuicioTags';
import ModalJuicio from '../ModalJuicio';

const Juicio = (props) => {
    // props.juicio (juicioId) y props.obligations (obligaciones judiciales sin filtrar)
    const [showModal, setShowModal] = React.useState(false);
    const juicioObligations = props.obligations.filter( ob => ob.juicio_id == props.juicio);
    
    let juicioToRender;
    let fechaDemanda = null;
    props.juicios.forEach( (juicio) => {if(juicio.id == props.juicio){ juicioToRender = juicio }})
    
    let totalToPay = 0;
    juicioObligations.forEach( ob => {
        if(ob.exenta){
            totalToPay = totalToPay + (ob.saldoConRecargo * ((100 - ob.porcentaje_exencion)) / 100);
        } else {
            totalToPay = totalToPay + ob.saldoConRecargo;
        }
    })
    
    if(juicioToRender){
    fechaDemanda = moment(juicioToRender.inicio_demanda).format('DD/MM/YYYY');
    }

    
    const doShowModal = () => {
        setShowModal(true);
    }

    const hideModal = () => {
        setShowModal(false);
    }

    return (
        <div className="row justify-content-end Obligacion">
            <div className="col">
                <div className="row">
                    Juicio {props.juicio}<span style={{ display: 'inline-block', width: '1%' }}></span><JuicioTags/><IconButton size='small' onClick={doShowModal}><SearchIcon/></IconButton><br />
                </div>
                {<div className="row">
                    <small>Fecha de demanda: {fechaDemanda}</small>
                </div>}
            </div>
            <div className="col-auto">
                {totalToPay > 0 ? <b><NumberCurrency value={totalToPay.toFixed(props.config.decimals)} /></b> : null}
                <span style={{ display: 'block', height: '1%' }}></span>
            </div>
            <div className="col-auto">
            </div>
            {<ModalJuicio Juicio={juicioToRender} Obligations={props.obligations} ToPay={totalToPay}
                open={showModal}
                handleClose={hideModal}
                onClose={hideModal}>
            </ModalJuicio>}
        </div>
    )
}
const mapState = state => ({
    config: state.auth.config,
    showCapitalDebt: state.payments.showCapitalDebt,
    juicios: state.payments.allJudgments
})

export default connect(mapState, null)(Juicio);