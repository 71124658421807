import React from 'react';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";

/**
 * Render the "show more" button if there are more elements than the page size and execute a function received by the props
 * @param props
 */
const ShowMoreButton = (props) => {
    if (typeof props.elements !== 'undefined' && props.cant < props.elements.length) {
        return (
            <Grid container justify="center">
                <Button onClick={props.index >= 0 ? () => props.showMore(props.index) : props.showMore} color="primary"
                        style={{width: '100%'}}>{props.text}</Button>
            </Grid>
        )
    } else {
        return null
    }
}

export default ShowMoreButton;