import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import apiBUC from '../../../../services/BUCServices';

interface ChipData {
  key: number;
  label: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      padding: theme.spacing(0.5),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);
 const TagsArray = (props) => {
  const classes = useStyles({});
  
  let formatState = [];
  
  let tags = props.object.etiquetas !== null ? props.object.etiquetas.split(",") : 0;
  if(tags.length > 0){
    for(let i = 0; i < tags.length; i++){
      if(tags[i] !== '') {
        formatState.push(
          {
            key: i,
            label: tags[i]
          }
        )
      }
    }
  }
  
  const [chipData, setChipData] = React.useState<ChipData[]>(formatState);

  const handleDelete = (chipToDelete: ChipData) => () => {
    let newTags = formatState.map( el => el.label).filter( el => el !== chipToDelete.label);
    let aux = null;
    newTags.forEach(el => {
      if(aux == null){
        aux = el
      } else {
        aux = aux + ',' + el;
      }
    })
    if (aux == null) {aux = ''};

    if(props.object.asociado){
      apiBUC.SubjectServices.putThirdObjectTags(props.cuit, {
        objeto_id: props.object.id,
        tipo_objeto: props.object.tipo,
        etiquetas: aux,
      })
      .then((response) => {
        window.location.reload();
      }) 
    } else {
      apiBUC.SubjectServices.putObjectTags(props.object.id, {
        sujeto_id: props.cuit,
        tipo_objeto: props.object.tipo,
        etiqueta: aux,
      })
      .then((response) => {
        window.location.reload();
      }) 
    }

    setChipData(chips => chips.filter(chip => chip.key !== chipToDelete.key));
  };

  return (
    <div>
      {chipData.map(data => {
        return (
          <Chip
            key={data.key}
            label={data.label}
            onDelete={data.label === 'React' ? undefined : handleDelete(data)}
            className={classes.chip}
            color={'primary'}
            size={'small'}
          />
        );
      })}
    </div>
  );
}

const mapState = state => ({
  cuit: state.auth.cuit,
})

export default connect(mapState, null)(TagsArray);