import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import ApplicationDetail from "../ApplicationDetail";
import { connect } from "react-redux";
import "moment/locale/es";
import MaterialTable from "material-table";
import Moment from "react-moment";
import ApplicationState from "../ApplicationState";
import { useIntl, FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "../../common/MaterialComponents/Button";
import IconButton from "@material-ui/core/IconButton";
import ChatIcon from "@material-ui/icons/Chat";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { isArray } from "util";

const useStyles = makeStyles({
  actionButtons: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gridGap: "15px",
  },
  btns: { margin: "0 5px" },
});
const UserApplications = (props) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [ApplicatonSelected, selectApplication] = React.useState(null);

  const history = useHistory();

  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const applications = props.procedures.map(function (elem) {
    const tramite = props.formList.find((tipo) => {
      return tipo.id === elem.tipoTramiteInfo.id;
    });
    const tipo = tramite ? tramite.nombre : "------";
    return {
      id: elem.tramiteId,
      fecha_inicio: elem.fechaCreacion,
      tramite: tramite,
      descripcion: null, // JSON.parse(elem.descripcion),
      paramTramiteId: elem.tipoTramiteInfo.id,
      tipo: tipo,
      processing: elem.processing,
      estado: elem.estado ?? [],
      draft: elem.workflowInstanceKey === "undefined",
      jobKey: elem.datosAdicionales ? elem.datosAdicionales.jobKey : null,
      permiteCompletarStep: elem.permiteCompletarStep,
    };
  });

  const openApplicationDetail = (row) => {
    selectApplication(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = applications.map((row) => {
    return {
      id: row.id,
      tipo: row.tipo,
      fecha_solicitud: row.fecha_inicio,
      estado: row.estado ? row.estado : [],
      descripcion: row.tramite ? row.tramite.descripcion : "",
      draft: row.draft,
      jobKey: row.jobKey,
      processing: row.processing,
      tramite: row.tramite,
      permiteCompletarStep: row.permiteCompletarStep,
      paramTramiteId: row.paramTramiteId,
    };
  });

  // TODO: Review this line, the state should always have at least one state... this if condition is because in development the state might not exist
  const getLastState = (estado) =>
    estado.length > 0 &&
    estado !== "undefined" &&
    estado[estado.length - 1] !== undefined
      ? estado[estado.length - 1].proceso.replace(/_/g, " ")
      : null;
  const getTextState = (data) =>
    data.processing
      ? "procesando"
      : !data.draft && (data.estado.length === 0 || data.estado[0] == null)
      ? "procesando"
      : data.draft
      ? "borrador"
      : getLastState(data.estado).toLowerCase();

  const startChat = (data, e) => {
    e.stopPropagation();
    props.createChat("tramite", data.id);
  };

  return (
    <TableContainer>
      <MaterialTable
        onRowClick={(event, rowdata) => openApplicationDetail(rowdata)}
        title=""
        data={data}
        localization={{
          toolbar: {
            searchPlaceholder: formatMessage({
              id: "userApplication.table.searchPlaceholder",
              defaultMessage: "Buscar",
            }),
          },
          pagination: {
            labelRowsSelect: "filas",
            labelDisplayedRows: "a",
            firstTooltip: "Primera página",
            lastTooltip: "Ultima página",
            nextTooltip: "Siguiente",
            previousTooltip: "Anterior",
          },
        }}
        columns={[
          {
            title: (
              <FormattedMessage
                id={"userApplication.table.id"}
                defaultMessage="Id"
              />
            ),
            width: "40px",
            field: "id",
            headerStyle: {
              backgroundColor: "#ddd",
              position: "relative",
              zIndex: 0,
            },
            render: (data) => (
              <Typography>{data.id.slice(-6, data.id.length)}</Typography>
            ),
          },
          {
            title: (
              <FormattedMessage
                id={"userApplication.table.type"}
                defaultMessage="Tipo"
              />
            ),
            field: "tipo",
            width: "auto",
            headerStyle: {
              backgroundColor: "#ddd",
              position: "relative",
              zIndex: 0,
            },
            render: (data) => <Typography>{data.tipo}</Typography>,
          },

          {
            title: (
              <FormattedMessage
                id={"userApplication.table.started"}
                defaultMessage="Iniciado"
              />
            ),
            field: "fecha_inicio",
            headerStyle: {
              backgroundColor: "#ddd",
              position: "relative",
              zIndex: 0,
            },

            render: (data) => (
              <Typography>
                <Moment locale="es" format="DD/MM/YYYY HH:mm">
                  {moment(data.fecha_solicitud).utc()}
                </Moment>
              </Typography>
            ),
            defaultSort: "desc",
            customSort: (a, b) =>
              moment(a.fecha_solicitud).diff(moment(b.fecha_solicitud)),
          },
          {
            title: (
              <FormattedMessage
                id={"userApplication.table.state"}
                defaultMessage="Estado"
              />
            ),
            headerStyle: {
              backgroundColor: "#ddd",
              position: "relative",
              zIndex: 0,
            },

            field: "estado",
            customSort: (a, b) => {
              let stateA = getTextState(a);
              let stateB = getTextState(b);
              if (stateA < stateB) return 1;
              if (stateA > stateB) return -1;
              return 0;
            },
            render: (data) => <ApplicationState estado={getTextState(data)} />,
          },
          {
            title: (
              <FormattedMessage
                id={"userApplication.table.action"}
                defaultMessage="Acción"
              />
            ),
            headerStyle: {
              backgroundColor: "#ddd",
              position: "relative",
              zIndex: 0,
              textAlign: "right",
            },

            field: "draft",
            render: (data: any) => (
              <span style={{ justifyContent: "flex-end", display: "flex" }}>
                {data.draft ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      history.push({
                        // TODO: change this url to config param.
                        pathname:
                          "/detalle-tramite/" +
                          JSON.stringify({
                            tramiteId: data.id,
                            readOnly: false,
                          }),
                      });
                    }}
                  >
                    <FormattedMessage
                      id={"userApplication.table.completeApplication"}
                      defaultMessage="COMPLETAR"
                    />
                  </Button>
                ) : (
                  <span className={classes.actionButtons}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        history.push({
                          pathname:
                            "/detalle-tramite/" +
                            JSON.stringify({
                              tramiteId: data.id,
                              readOnly: true,
                            }),
                          // state: { tramiteId: data.id, readOnly: true },
                        });
                      }}
                    >
                      <FormattedMessage
                        id={"userApplication.table.showApplication"}
                        defaultMessage="VER TRAMITE"
                      />
                    </Button>
                    {data.permiteCompletarStep ? (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          const estadosParse = data.estado;
                          let estado = null;
                          if (isArray(estadosParse))
                            estado =
                              estadosParse[estadosParse.length - 1].proceso;
                          else estado = estadosParse;

                          history.push({
                            pathname:
                              "/detalle-tramite/" +
                              JSON.stringify({
                                paramTramiteId: data.paramTramiteId,
                                tramiteId: data.id,
                                pasoId: estado,
                              }),
                            // state: {
                            //   id: data.paramTramiteId,
                            //   tramiteId: data.id,
                            //   pasoId: estado,
                            // },
                          });
                        }}
                      >
                        <FormattedMessage
                          id={"userApplication.table.completeStep"}
                          defaultMessage="COMPLETAR PASO"
                        />
                      </Button>
                    ) : null}
                    <Button
                      onClick={(e) => startChat(data, e)}
                      color="primary"
                      aria-label="iniciar chat"
                    >
                      <ChatIcon />
                    </Button>
                  </span>
                )}
              </span>
            ),
          },
        ]}
        options={{
          sorting: true,
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, data.length],
        }}
      />
      {ApplicatonSelected ? (
        <ApplicationDetail
          applicationSelected={ApplicatonSelected}
          open={open}
          onClose={handleClose}
        />
      ) : null}
    </TableContainer>
  );
};

const mapDispatch = (dispatch) => ({
  createChat: (tramite, clave) => dispatch.chats.createChat(tramite, clave),
});

const mapState = (state) => ({
  formList: state.applications.formList,
});

export default connect(mapState, mapDispatch)(UserApplications);
