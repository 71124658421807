import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SeccionHeader from "../../components/common/SeccionHeader";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {useLocation} from 'react-router-dom'
import MyAffidavitdList from "../../components/MyAffidavit/MyAffidavitList";
import {
  affidavitsMock,
  affidavitsMock2,
  affidavitsMock3,
} from "../../mock/AffidavitMock";
import { affidavitsObjectsMock } from "../../mock/AffidavitMock";
import AffidavitObject from "../../components/MyAffidavit/AffidavitObject";
import { Container } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";
import apiCMS from "../../services/CMSServices";
import apiBUC from "../../services/BUCServices";
const useStyles = makeStyles({
  buscar: {
    marginTop: "-16px",
    marginBottom: "16px",
  },
  toggleView: {
    marginTop: "-10px",
    marginBottom: "10px",
    color: "#444",
  },
  AffidavitObjects: {
    justifyContent: "center",
    display: "flex",
    margin: "5px auto",
    flexWrap: "wrap",
    flexDirection: "row",
  },
});

const MyAffidavit = (props) => {
  const [filter, setFilter] = React.useState("");
  const [affidavit, setAffidavit] = useState([]);
  const [allAffidavits, setAllAffidavits] = useState([]);
  const classes = useStyles();
  const intl = useIntl();
  const validTaxTypes = props.taxesTypes.filter( taxType => taxType.hasAffidavit == true && taxType.active == true);
  const [objeto, setObjeto] = React.useState(validTaxTypes.length > 0 ? validTaxTypes[0] : {});

  const location = useLocation();
  let defaultValue;
  if(location.state){
    defaultValue = location.state['detail'];
  } else { defaultValue = '' }

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFilter(event.target.value);
  const searchItems = (data) =>
    data.filter(
      (el) =>
        JSON.stringify(el).toLowerCase().indexOf(filter.toLowerCase()) > -1
    );

  useEffect( () => {
    if(location.state){
      setFilter(location.state['detail'].toString());
    }
    apiBUC.AffidavitServices
      .getAffidavits(props.subjectId)
      .then((res) => {
        setAffidavit(res.data.filter(affidavit => affidavit.objeto_tipo == objeto.code));
        setAllAffidavits(res.data);
      });

  }, []);

  const onChange = (obj) => {
    setObjeto(obj);
    setAffidavit(allAffidavits.filter(affidavit => affidavit.objeto_tipo == obj.code))
  };
  return (
    <div>
      {validTaxTypes.length > 1 && objeto != null ? (
        <div>
          <SeccionHeader
            title={intl.formatMessage({
              id: "myAffidavits.title.full",
              defaultMessage: "Mis declaraciones juradas",
            })}
          ></SeccionHeader>
          <Container className={classes.AffidavitObjects}>
            {validTaxTypes.map((el) => (
              <AffidavitObject
                key={el.id}
                obj={el}
                onChange={() => onChange(el)}
                active={el.code === objeto.code}
                adeudadas={allAffidavits.filter(affidavit => affidavit.objeto_tipo == el.code && affidavit.estado == 'PSV').length}
              />
            ))}
          </Container>
        </div>
      ) : null}
      <SeccionHeader
        title={
          intl.formatMessage({
            id: "myAffidavits.title.short",
            defaultMessage: "DDJJ",
          })
        }
      >
        <div>
          <TextField
            id="searchbar"
            label={
              <FormattedMessage id="myAffidavits.search" defaultMessage="Buscar" />
            }
            type="text"
            defaultValue={defaultValue}
            onChange={handleFilter}
            className={classes.buscar}
          />
        </div>
      </SeccionHeader>
      <MyAffidavitdList data={searchItems(affidavit)} />
      <p/>
    </div>
  );
};

const mapState = (state) => ({
  subjectId: state.auth.cuit,
  data: state.payments.selectedObligations,
  allObjects: state.payments.allObjects,
  taxesTypes: state.taxes.taxesTypes,
});

const mapDispatch = dispatch => ({
  addTaxesTypes: (taxesList) => dispatch.taxes.addTaxesTypes(taxesList),
})
export default connect(mapState, mapDispatch)(MyAffidavit);
