import React, { useEffect, Children } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
const icon = require("../../../assets/logo.png");
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    icono: {
      maxHeight: "30px",
    },
    appbar: {
      background: "#3f51b5",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    btn: {
      color: "white",
    },
    link: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
  })
);
const Header = (props) => {
  const classes = useStyles();

  return (
    <AppBar position="static" className={classes.appbar}>
      <Container maxWidth="xl">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            
            <Link to={"/home"} className={classes.link}>
              <img src={icon} className={classes.icono} />
            </Link>
          </Typography>

          <Link to={"/login"} className={classes.link}>
            <Button className={classes.btn} variant="text">
              Login
            </Button>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
