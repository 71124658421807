export default {
    state: {
        contactTypes: [],
        subContactTypes: [],
        cantTypeMessage: 0,
        cantTypeNotification: 0,
        contactList: [],
        contactListFiltered: [],
        unreadMessages:0,
        currentSubContactType: null,
        currentContactId: null
    },
    reducers: {
        setCurrentContactId(state, currentContactId){
            return Object.assign({}, {...state, currentContactId: currentContactId})
        },
        setCurrentSubContactType(state, subContactType){
            return Object.assign({}, {...state, currentSubContactType: subContactType})
        },
        setContactTypes(state, contactTypes){
            return Object.assign({}, {...state, contactTypes: contactTypes})
        },
        setSubContactTypes(state, subContactTypes){
            return Object.assign({}, {...state, subContactTypes: subContactTypes})
        },
        setCantTypeNotification(state, cant){
            return Object.assign({}, {...state, cantTypeNotification: cant})
        },
        setContactList(state, contactList){
            return Object.assign({}, {...state, contactList: contactList})
        },
        setContactListFiltered(state, contactList){
            return Object.assign({}, {...state, renderList: contactList})
        },
        setUnreadMessages(state, unreadMessages){
            return Object.assign({}, {...state, unreadMessages: unreadMessages})
        }
    },
    effects: {},
}