import React from 'react';
import {Grid} from '@material-ui/core';
import ObligationMapper from './ObligationMapper';
// import JuicioMapper from '../../../MyTaxes/Taxes/JuicioMapper/JuicioMapper';
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '../../../common/Accoderon';
//import './styles.css';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { FormattedMessage } from 'react-intl';

const ObligationAccordion = (props) => {
  //  const getColor = () => props.isExpired ? '#CB3234' : '#39FF14'
/*style={{color: getColor()}}*/
    const getClass = () => props.isExpired ? 'Obligacion-vencida' : 'Obligacion-no-vencida';
    const getEstado = () => props.isExpired ? <FormattedMessage id="myTaxes.hasExpiredObligations" defaultMessage="Tiene obligaciones vencidas" /> : <FormattedMessage id="myTaxes.upToDate" defaultMessage="Esta al dia" />;

    if(!props.isExpired){
        return (
            <Grid className='PeriodoAcordionContainer' item xs={12}>
                <ExpansionPanel elevation={0} className='PeriodoAcordion' defaultExpanded={!props.isExpired}>
                    <ExpansionPanelSummary className='PeriodoAcordionHeader'>
                        <Grid  item xs={12}
                              className="row justify-content-center PeriodoAcordionHeaderTitle">
                                <div className="col-auto columnExpand">
                           <ChevronRightOutlinedIcon className='expandIconPeriodo'/>
                           </div>
                           <div className="col">
    
                           <b>{props.year}</b> <br/>
                           <span className={"estadoPeriodo " + getClass()} >{getEstado()}</span>
                           </div>
                           <div className="col-auto">
    
                           </div>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid item xs={12}>
                            <ObligationMapper obligations={props.obligations}/>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        )
    } else {
        return (
            <Grid className='PeriodoAcordionContainer' item xs={12}>
                <ExpansionPanel elevation={0} className='PeriodoAcordion' defaultExpanded={!props.isExpired}>
                    <ExpansionPanelSummary className='PeriodoAcordionHeader'>
                        <Grid  item xs={12}
                              className="row justify-content-center PeriodoAcordionHeaderTitle">
                                <div className="col-auto columnExpand">
                           <ChevronRightOutlinedIcon className='expandIconPeriodo'/>
                           </div>
                           <div className="col">
    
                           <b>{props.year}</b> <br/>
                           <span className={"estadoPeriodo " + getClass()} >{getEstado()}</span>
                           </div>
                           <div className="col-auto">
    
                           </div>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid item xs={12}>
                            <ObligationMapper obligations={props.obligations}/>
                            {/* <JuicioMapper obligations={props.obligations}/> */}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        )
    }
    
}

export default ObligationAccordion;