import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

export default function AddMoreButton(props) {
    return (
        <Link to={props.to}>
            <Button variant='contained' size="small" color="primary">
                <FormattedMessage id="paymentMethod.addMore" defaultMessage="Agregar más obligaciones" />
            </Button>
        </Link>
    )
}