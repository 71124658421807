import { IPost } from "../models/IPost";
import { IProduct } from "../models/IProduct";
import { FormattedMessage } from "react-intl";
//#region taxes mock
export const PRODUCTS: IProduct[] = [
  {
    category: "Sporting Goods",
    price: "$49.99",
    stocked: true,
    name: "Football"
  },
  {
    category: "Sporting Goods",
    price: "$9.99",
    stocked: true,
    name: "Baseball"
  },
  {
    category: "Sporting Goods",
    price: "$29.99",
    stocked: false,
    name: "Basketball"
  },
  {
    category: "Electronics",
    price: "$99.99",
    stocked: true,
    name: "iPod Touch"
  },
  {
    category: "Electronics",
    price: "$399.99",
    stocked: false,
    name: "iPhone 5"
  },
  { category: "Electronics", price: "$199.99", stocked: true, name: "Nexus 7" }
];
export const POSTS: Array<IPost> = [
  {
    userId: 1,
    id: 1,
    title:
      "sunt aut facere repellat provident occaecati excepturi optio reprehenderit",
    body:
      "quia et suscipit\nsuscipit recusandae consequuntur expedita et cum\nreprehenderit molestiae ut ut quas totam\nnostrum rerum est autem sunt rem eveniet architecto"
  },
  {
    userId: 1,
    id: 2,
    title: "qui est esse",
    body:
      "est rerum tempore vitae\nsequi sint nihil reprehenderit dolor beatae ea dolores neque\nfugiat blanditiis voluptate porro vel nihil molestiae ut reiciendis\nqui aperiam non debitis possimus qui neque nisi nulla"
  },
  {
    userId: 1,
    id: 3,
    title: "ea molestias quasi exercitationem repellat qui ipsa sit aut",
    body:
      "et iusto sed quo iure\nvoluptatem occaecati omnis eligendi aut ad\nvoluptatem doloribus vel accusantium quis pariatur\nmolestiae porro eius odio et labore et velit aut"
  }
];
export const taxesObject = [
  {
    id: "AC168JE",
    tipo: "A",
    categoria: 0,
    descripcion: "JEEP - RURAL 5 PUERTAS - RENEGADE SPORT 1.8 AT6",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 1200000,
    id_externo: "9951823",
    datos_adicionales: null,
    sujeto_id: "27-30267821-4",
    obligaciones: [
      {
        id: "20160000000000528431",
        capital: 5000,
        cuota: "2",
        estado: "ACTIVO",
        fiscalizada: "",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 1000,
        juicio_id: 0,
        periodo: "2016",
        plan_id: null,
        prorroga: "2017-12-14T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 5000,
        vencimiento: "2019-12-25T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "AC168JE",
        objeto_tipo: "A"
      },
      {
        id: "20160000000000528455",
        capital: 5000,
        cuota: "3",
        estado: "ACTIVO",
        fiscalizada: "",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 1000,
        juicio_id: 0,
        periodo: "2016",
        plan_id: null,
        prorroga: "2017-12-14T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 5000,
        vencimiento: "2019-12-25T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "AC168JE",
        objeto_tipo: "A"
      },
      {
        id: "20160000000000528456",
        capital: 5000,
        cuota: "4",
        estado: "ACTIVO",
        fiscalizada: "",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 1000,
        juicio_id: 0,
        periodo: "2016",
        plan_id: null,
        prorroga: "2017-12-14T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 5000,
        vencimiento: "2019-12-25T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "AC168JE",
        objeto_tipo: "A"
      },
      {
        id: "20160000000000528432",
        capital: 5000,
        cuota: "3",
        estado: "ACTIVO",
        fiscalizada: "",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 1000,
        juicio_id: 0,
        periodo: "2017",
        plan_id: null,
        prorroga: "2018-12-14T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 5000,
        vencimiento: "2020-12-25T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "AC168JE",
        objeto_tipo: "A"
      },
      {
        id: "20160000000000528433",
        capital: 5000,
        cuota: "4",
        estado: "ACTIVO",
        fiscalizada: "",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 1000,
        juicio_id: 0,
        periodo: "2018",
        plan_id: null,
        prorroga: "2019-12-14T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 5000,
        vencimiento: "2021-12-25T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "AC168JE",
        objeto_tipo: "A"
      },
      {
        id: "20160000000000528434",
        capital: 5000,
        cuota: "5",
        estado: "ACTIVO",
        fiscalizada: "",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 1000,
        juicio_id: 0,
        periodo: "2019",
        plan_id: null,
        prorroga: "2020-12-14T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 5000,
        vencimiento: "2022-12-25T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "AC168JE",
        objeto_tipo: "A"
      }
    ]
  },
  {
    id: "281287800",
    tipo: "E",
    categoria: 0,
    descripcion: "Contribuyente Local",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 1000000,
    id_externo: "875495",
    datos_adicionales: null,
    sujeto_id: "27-30267821-4",
    obligaciones: [
      {
        id: "20160000000000528428",
        capital: 22000,
        cuota: "5",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2016",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2019-12-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000000000528435",
        capital: 22000,
        cuota: "6",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2016",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2019-12-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000000000528436",
        capital: 22000,
        cuota: "7",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2016",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2019-12-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000000000528428",
        capital: 22000,
        cuota: "5",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2018",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2019-12-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000000000528435",
        capital: 22000,
        cuota: "6",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2017",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2019-12-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000000000528436",
        capital: 22000,
        cuota: "7",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2017",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2019-12-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000000000528437",
        capital: 22000,
        cuota: "3",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2020",
        plan_id: null,
        prorroga: "2021-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2020-02-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000046456456457",
        capital: 22000,
        cuota: "2",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2020",
        plan_id: null,
        prorroga: "2021-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2020-01-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000000045645645",
        capital: 22000,
        cuota: "1",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2020",
        plan_id: null,
        prorroga: "2021-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2020-01-05T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000000000528438",
        capital: 22000,
        cuota: "4",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2020",
        plan_id: null,
        prorroga: "2021-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2020-03-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000000000528439",
        capital: 22000,
        cuota: "5",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2020",
        plan_id: null,
        prorroga: "2021-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2020-04-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      },
      {
        id: "20160000000000528440",
        capital: 22000,
        cuota: "6",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 2000,
        juicio_id: 0,
        periodo: "2020",
        plan_id: null,
        prorroga: "2021-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 22000,
        vencimiento: "2020-05-19T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "281287800",
        objeto_tipo: "E"
      }
    ]
  },
  {
    id: "110100019132",
    tipo: "I",
    categoria: 0,
    descripcion: "SANTIAGO DEL ESTERO 66   CENTRO  CORDOBA - CAPITAL",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 8000000,
    id_externo: "1447",
    datos_adicionales: null,
    sujeto_id: "27-30267821-4",
    obligaciones: [
      {
        id: "20160000000000528444",
        capital: 8000,
        cuota: "1",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 800,
        juicio_id: 0,
        periodo: "2016",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 8000,
        vencimiento: "2019-12-30T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "110100019132",
        objeto_tipo: "I"
      }
    ]
  },
  {
    id: "110100043572",
    tipo: "I",
    categoria: 0,
    descripcion:
      "BVRD DR ARTURO HUMBERTO ILLIA 487   CENTRO X5000ASE CORDOBA - CAPITAL",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 7320000,
    id_externo: "3541",
    datos_adicionales: null,
    sujeto_id: "27-30267821-4",
    obligaciones: []
  },
  {
    id: "110111089671",
    tipo: "I",
    categoria: 0,
    descripcion:
      "AV FUERZA AEREA ARGENTINA 3323   AMEGHINO SUD X5010JKS CORDOBA - CAPITAL",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 5840000,
    id_externo: "191422",
    datos_adicionales: null,
    sujeto_id: "27-30267821-4",
    obligaciones: [
      {
        id: "20160000000000528445",
        capital: 14000,
        cuota: "7",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 1400,
        juicio_id: 0,
        periodo: "2016",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 14000,
        vencimiento: "2019-12-27T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "110111089671",
        objeto_tipo: "I"
      }
    ]
  },
  {
    id: "110123257235",
    tipo: "I",
    categoria: 0,
    descripcion: "9 DE JULIO 1215   ALBERDI X5000EMY CORDOBA - CAPITAL",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 500,
    base_imponible: 3900000,
    id_externo: "407391",
    datos_adicionales: null,
    sujeto_id: "27-30267821-4",
    obligaciones: [
      {
        id: "2016000000001231231445",
        capital: 14000,
        cuota: "7",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 1400,
        juicio_id: 0,
        periodo: "2017",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 14000,
        vencimiento: "2019-12-27T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "110111089671",
        objeto_tipo: "I"
      }
    ]
  },
  {
    id: "110123257243",
    tipo: "I",
    categoria: 0,
    descripcion: "9 DE JULIO 1211   ALBERDI X5000EMY CORDOBA - CAPITAL",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 4300000,
    id_externo: "407392",
    datos_adicionales: null,
    sujeto_id: "27-30267821-4",
    obligaciones: [
      {
        id: "2341432342342342",
        capital: 14000,
        cuota: "7",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 1400,
        juicio_id: 0,
        periodo: "2016",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 14000,
        vencimiento: "2019-12-27T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "110111089671",
        objeto_tipo: "I"
      }
    ]
  },
  {
    id: "110123257251",
    tipo: "I",
    categoria: 0,
    descripcion: "9 DE JULIO 1207   ALBERDI X5000EMY CORDOBA - CAPITAL",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 9300000,
    id_externo: "407393",
    datos_adicionales: null,
    sujeto_id: "27-30267821-4",
    obligaciones: [
      {
        id: "201600000000088",
        capital: 14000,
        cuota: "7",
        estado: "ACTIVO",
        fiscalizada: null,
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: 0,
        interes_resarcitorio: 1400,
        juicio_id: 0,
        periodo: "2016",
        plan_id: null,
        prorroga: "2017-12-06T00:00:00.000Z",
        tipo: "ADMINISTRATIVA",
        total: 14000,
        vencimiento: "2019-12-27T00:00:00.000Z",
        datos_adicionales: null,
        sujeto_id: "27-30267821-4",
        objeto_id: "110111089671",
        objeto_tipo: "I"
      }
    ]
  },
  {
    id: "110123257266",
    tipo: "I",
    categoria: 0,
    descripcion: "9 DE JULIO 1207   ALBERDI X5000EMY CORDOBA - CAPITAL",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 9300000,
    id_externo: "407393",
    datos_adicionales: null,
    sujeto_id: "27-30267821-4",
    obligaciones: []
  },
  {
    id: "110123257267",
    tipo: "I",
    categoria: 0,
    descripcion: "9 DE JULIO 1207   ALBERDI X5000EMY CORDOBA - CAPITAL",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 9300000,
    id_externo: "407393",
    datos_adicionales: null,
    sujeto_id: "27-30267821-4",
    obligaciones: []
  }
];
//#endregion
//#region  Applications Mock

export const applications = [
  {
    id: 1,
    descripcion: "Alicuota"
  },
  {
    id: 2,
    descripcion: "Tasa"
  },
  { id:3,
  descripcion:'DJ Pcial Mensual'}


];

export const applicationforms = [
  {
    id: 1,
    description: "Alicuota",
    requirements:
      '<h2>Alicuota</h2><p>Este tramite requiere que el contribuyente cuente con determinada documentacion antes de iniciarlo.</p><ul><li>DNI (origina y fotocopia)</li><li>Partida de nacimiento</li><li><span style="text-decoration: underline;">Factura del domicilio</span></li></ul><p>Una vez que tenga la documentacion puede completar el tramite.</p><p>Attme. Convergente.</p>',
    requirementsImage: require("../assets/Public/compleja.png"),
    form: [
      {
        id: "9541CD76-8AE8-46DA-928C-5D3F8DF66240",
        element: "Header",
        text: "Header Text",
        static: true,
        required: false,
        bold: false,
        italic: false,
        content: "Solicitud Alta de Alicuota ",
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        dirty: true
      },
      {
        id: "ADD53A9E-A01F-47ED-83CB-617D631C7344",
        element: "TextInput",
        text: "Text Input",
        required: false,
        canHaveAnswer: true,
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        field_name: "text_input_08555E2D-30F9-4074-822C-7165A07860B4",
        label: "Usuario Solicitante ",
        dirty: true
      },
      {
        id: "B92DAF48-918B-4103-8E47-2A39873EB832",
        element: "Dropdown",
        text: "Dropdown",
        required: false,
        canHaveAnswer: true,
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        field_name: "dropdown_19BA2275-75C6-45C3-80A8-1BB416CCCA53",
        label: "Prioridad ",
        options: [
          {
            value: "alta",
            text: "Alta",
            key: "dropdown_option_AA265C27-77AE-4D6C-9261-D9E18BFEA39B"
          },
          {
            value: "media",
            text: "Media",
            key: "dropdown_option_43067B6F-89D9-47BF-B54D-24BEA4A9C758"
          },
          {
            value: "baja",
            text: "Baja",
            key: "dropdown_option_C3D0BF06-EFBC-4DCF-AB2D-0CC25CF97194"
          }
        ],
        dirty: false
      },
      {
        id: "354E22B3-C727-4CE7-BF8B-4E0BDB687BC2",
        element: "Dropdown",
        text: "Dropdown",
        required: false,
        canHaveAnswer: true,
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        field_name: "dropdown_2FA81C3B-EEC1-4DAB-8615-5E475F4A89C3",
        label: "Severidad ",
        options: [
          {
            value: "alta",
            text: "Alta",
            key: "dropdown_option_62E03A6B-599F-4D35-956D-511998816AB8"
          },
          {
            value: "media",
            text: "Media",
            key: "dropdown_option_D0267191-E9C0-4D91-8724-565A0FDD5055"
          },
          {
            value: "baja",
            text: "Baja",
            key: "dropdown_option_09780032-CEFA-44AA-B307-31BE53E4A07A"
          }
        ],
        dirty: true
      },
      {
        id: "0476AD1C-3CE2-4776-BD0A-C145CB3EC89C",
        element: "DatePicker",
        text: "Date",
        required: false,
        readOnly: false,
        defaultToday: false,
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        dateFormat: "MM/dd/yyyy",
        timeFormat: "hh:mm aa",
        showTimeSelect: false,
        showTimeSelectOnly: false,
        field_name: "date_picker_46B65A6F-6430-48BE-AE03-8D44ECFDAA6F",
        label: "Fecha Vencimiento ",
        dirty: true
      },
      {
        id: "A6D7D063-70DF-4799-BF89-9EAE4BC901B6",
        element: "TextInput",
        text: "Text Input",
        required: false,
        canHaveAnswer: true,
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        field_name: "text_input_98990D28-5D92-45F7-B540-81462B6AEB90",
        label: "Titulo ",
        dirty: true
      },
      {
        id: "AE7F3895-CD83-4EEC-ADEC-C3186AAE3191",
        element: "TextArea",
        text: "Multi-line Input",
        required: false,
        canHaveAnswer: true,
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        field_name: "text_area_41965716-FEB0-42E9-9DA8-970E39F6BF1A",
        label: "Descripcion ",
        dirty: true
      },
      {
        id: "CD74A820-AEC3-4EEB-8D20-3F59DFA2763B",
        element: "Checkboxes",
        text: "Checkboxes",
        required: false,
        canHaveAnswer: true,
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        field_name: "checkboxes_13A8A921-8132-477A-8003-B2E7C7ED27DD",
        label: " ",
        options: [
          {
            value: "requiere_comunicacion_institucional",
            text: "Requiere Comunicacion Institucional",
            key: "checkboxes_option_12657943-185A-41F4-BDD0-4F538B3D667F"
          }
        ],
        dirty: false
      },
      {
        id: "2A66AB0B-281F-452D-AA20-341B2A94770E",
        element: "RadioButtons",
        text: "Multiple Choice",
        required: false,
        canHaveAnswer: true,
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        field_name: "radiobuttons_5EEEFA2E-B692-4D80-9E0B-5EEFB5307507",
        label: "Agenda de Publicacion ",
        options: [
          {
            value: "lunes",
            text: "Lunes",
            key: "radiobuttons_option_F5C0211A-1FDB-48A9-9CBC-072464AFA3CB"
          },
          {
            value: "martes",
            text: "Martes",
            key: "radiobuttons_option_B06F49E5-96D9-42F8-BACD-63B2B627593D"
          },
          {
            value: "miercoles",
            text: "Miercoles",
            key: "radiobuttons_option_ABB217AA-A803-4D71-B8A1-7A444476AD21"
          },
          {
            value: "jueves",
            text: "Jueves",
            key: "3A8D8C0E-B0B3-4C38-AA97-E5C4F4577405"
          },
          {
            value: "viernes",
            text: "Viernes",
            key: "4290D935-9722-406E-B68F-F7B2153C6A98"
          }
        ],
        dirty: true
      },
      {
        id: "776EB87E-7B7F-48E5-A876-8C0942E58678",
        element: "Download",
        text: "File Attachment",
        static: true,
        required: false,
        bold: false,
        italic: false,
        content: "Seleccione un archivo... ",
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        _href: "",
        file_path: "",
        field_name: "download_FC8073D0-62E5-4AA8-9F59-E931ADB76A78",
        dirty: false
      }
    ],
    submit: "http://localhost:9090/endpoint/1"
  },
  {
    id: 2,
    description: "Tasa",
    requirements:
      "<h2>Tasa</h2><p>Esto es un texto con los requerimientos basicos para la Tasa.",
    requirementsImage: require("../assets/Public/simple.png"),
    form: [
      {
        id: "9541CD76-8AE8-46DA-928C-5D3F8DF66240",
        element: "Header",
        text: "Header Text",
        static: true,
        required: false,
        bold: false,
        italic: false,
        content: "Solicitud Alta de Alicuota ",
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        dirty: true
      },
      {
        id: "EE46F685-E949-45F8-958D-3AFF54238FA7",
        element: "TextInput",
        text: "Para ver",
        required: true,
        canHaveAnswer: true,
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        field_name: "text_input_5347D840-2D5C-4A8A-9DF2-DE4A589AC92B",
        label: "Numero de Solicitud ",
        dirty: false
      }
    ],
    submit: "http://localhost:9090/endpoint/2"
  },
  {
    id: 3,
    description: "DJ Pcial Mensual",
    requirements:
      "<h2>Declaracion Jurada</h2><p>Declare sus ingresos, las retenciones que tuvo y los pagos hechos en su declaracion Jurada.",
    requirementsImage: require("../assets/Public/simple.png"),
    form: [
      {
        id: "9541CD76-8AE8-46DA-928C-5D3F8DF66240",
        element: "Header",
        text: "Header Text",
        static: true,
        required: false,
        bold: false,
        italic: false,
        content: "Solicitud Alta de Alicuota ",
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        dirty: true
      },
      {
        id: "EE46F685-E949-45F8-958D-3AFF54238FA7",
        element: "TextInput",
        text: "Para ver",
        required: true,
        canHaveAnswer: true,
        canHavePageBreakBefore: true,
        canHaveAlternateForm: true,
        canHaveDisplayHorizontal: true,
        canHaveOptionCorrect: true,
        canHaveOptionValue: true,
        field_name: "text_input_5347D840-2D5C-4A8A-9DF2-DE4A589AC92B",
        label: "Numero de Solicitud ",
        dirty: false
      }
    ],
    submit: "http://localhost:9090/endpoint/2"
  }
];

export const userapplications = [
  {
    id: 1583524916870,
    number: 1583524916870,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-06T20:01:56.870Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-06T20:01:56.870Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-06T20:01:56.870Z",
        description: "se asigno a Fernandez Joaquin como responsable"
      },
      {
        id: 2,
        date: "2020-03-09T20:01:56.870Z",
        description: "Se cargo en test una nueva alicuota"
      },
      {
        id: 3,
        date: "2020-03-10T20:01:56.870Z",
        description: "Ingreso a proceso de validacion"
      }
    ]
  },
  {
    id: 1583760222680,
    number: 1583760222680,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-09T13:23:42.680Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-09T13:23:42.680Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-06T20:01:56.870Z",
        description: "Se asigno a Fernandez Joaquin como responsable",
        resuelto: true
      },
      {
        id: 2,
        date: "2020-03-09T20:01:56.870Z",
        description: "Se cargo en test una nueva alicuota",
        resuelto: true
      },
      {
        id: 3,
        date: "2020-03-10T20:01:56.870Z",
        description: "Ingreso a proceso de validacion",
        resuelto: false
      },
      {
        id: 4,
        date: "2020-03-10T20:01:56.870Z",
        description: "Ingreso a proceso de validacion",
        resuelto: false
      }
    ]
  },
  {
    id: 1583760551240,
    number: 1583760551240,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-09T13:29:11.240Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-09T13:29:11.240Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-06T20:01:56.870Z",
        description: "se asigno a Fernandez Joaquin como responsable"
      },
      {
        id: 2,
        date: "2020-03-09T20:01:56.870Z",
        description: "Se cargo en test una nueva alicuota"
      },
      {
        id: 3,
        date: "2020-03-10T20:01:56.870Z",
        description: "Ingreso a proceso de validacion"
      }
    ]
  },
  {
    id: 1583762310238,
    number: 1583762310238,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-09T13:58:30.238Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-09T13:58:30.238Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: []
  },
  {
    id: 1583762418462,
    number: 1583762418462,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-09T14:00:18.462Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-09T14:00:18.462Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-09T14:00:18.462Z",
        description: "se inicio el tramite"
      }
    ]
  },
  {
    id: 1583762848866,
    number: 1583762848866,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-09T14:07:28.866Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-09T14:07:28.866Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-09T14:07:28.866Z",
        description: "se inicio el tramite"
      }
    ]
  },
  {
    id: 1583763592229,
    number: 1583763592229,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-09T14:19:52.229Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-09T14:19:52.229Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-09T14:19:52.229Z",
        description: "se inicio el tramite"
      }
    ]
  },
  {
    id: 1583763644919,
    number: 1583763644919,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-09T14:20:44.919Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-09T14:20:44.919Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-09T14:20:44.919Z",
        description: "se inicio el tramite"
      }
    ]
  },
  {
    id: 1583765444288,
    number: 1583765444288,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-09T14:50:44.288Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-09T14:50:44.288Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-09T14:50:44.288Z",
        description: "se inicio el tramite"
      }
    ]
  },
  {
    id: 1583780944873,
    number: 1583780944873,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-09T19:09:04.873Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-09T19:09:04.873Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-09T19:09:04.873Z",
        description: "se inicio el tramite"
      }
    ]
  },
  {
    id: 1583782016173,
    number: 1583782016173,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-09T19:26:56.173Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-09T19:26:56.173Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-09T19:26:56.173Z",
        description: "se inicio el tramite"
      }
    ]
  },
  {
    id: 1583843699355,
    number: 1583843699355,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-10T12:34:59.355Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-10T12:34:59.355Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-10T12:34:59.355Z",
        description: "se inicio el tramite"
      }
    ]
  },
  {
    id: 1583847252071,
    number: 1583847252071,
    user: "Victoria",
    priority: "alta",
    severity: "media",
    initDate: "2020-03-10T13:34:12.071Z",
    closeDate: "2/03/2020",
    expireDate: "2020-03-10T13:34:12.071Z",
    state: "iniciado",
    title: "iniciado",
    description:
      "consectetur adipiscing elit. Aenean vel euismod tortor. Vivamus non condimentum elit. Proin luctus mattis sapien non sollicitudin. Sed sollicitudin leo eros, vitae tristique augue pellentesque et. Aliquam erat volutpat. Vestibulum vestibulum aliquet nibh, ultricies congue tortor fermentum ac. ",
    communicationRequired: false,
    communicationDate: 2,
    movements: [
      {
        id: 1,
        date: "2020-03-10T13:34:12.071Z",
        description: "se inicio el tramite"
      }
    ]
  },
  {
    comunicacion_institucional: true,
    descripcion: "se empezo un nuevo tramite",
    estado: "iniciado",
    fecha_cierre: "2020-03-26T18:59:00.000Z",
    fecha_solicitud: "2020-03-10T18:59:00.000Z",
    fecha_vencimiento: "2020-03-26T18:59:00.000Z",
    numero_solicitud: "hola",
    prioridad: "media",
    severidad: "media",
    titulo: "temon",
    usuario_solicitante: "esto",
    id: 1583847252072
  },
  {
    id: 1583847252073
  },
  {
    id: 1583847252074
  },
  {
    id: 1583847252075
  },
  {
    id: 1583847252076
  },
  {
    id: 1583847252077
  },
  {
    comunicacion_institucional: true,
    descripcion: "nueva descripcion",
    estado: "pendiente",
    fecha_cierre: "2020-03-26T19:37:00.000Z",
    fecha_solicitud: "2020-03-10T19:37:00.000Z",
    fecha_vencimiento: "2020-03-18T19:37:00.000Z",
    numero_solicitud: "12345",
    prioridad: "media",
    severidad: "media",
    titulo: "titulonuevo",
    usuario_solicitante: "leandro",
    id: 1583847252078
  },
  {
    comunicacion_institucional: true,
    descripcion: "la descripcion",
    estado: "iniciado",
    numero_solicitud: "123456",
    prioridad: "media",
    severidad: "baja",
    titulo: "Titulo Nuevo",
    usuario_solicitante: "usuariocuit",
    id: 1583847252079
  },
  {
    id: 1583847252080
  },
  {
    id: 1583847252081
  },
  {
    id: 1583847252082
  },
  {
    id: 1583847252083
  },
  {
    id: 1583847252084
  },
  {
    id: 1583847252085
  },
  {
    id: 1583847252086
  },
  {
    id: 1583847252087
  },
  {
    id: 1583847252088
  },
  {
    titulo: "sfdgsdg",
    id: 1583847252089
  },
  {
    titulo: "sfdgsdg",
    id: 1583847252090
  },
  {
    id: 1583847252091
  },
  {
    id: 1583847252092
  },
  {
    id: 1583847252093
  },
  {
    titulo: "asd",
    id: 1583847252094
  },
  {
    id: 1583847252095
  },
  {
    titulo: "asdfgh",
    id: 1583847252096
  },
  {
    titulo: "asdfgh",
    id: 1583847252097
  }
];

//#endregion
//#region payments mock

export const FormasDePago = [
  {
    title: "Online",
    titleColor: "blue",
    modifier: 1,
    footerColor: "green",
    footerTextColor: "white",
    hasFooter: "one",
    action: "/e-payments",
    state: "enabled"
  },
  {
    title: "Efectivo",
    titleColor: "blue",
    modifier: 1,
    footerColor: "white",
    footerTextColor: "green",
    hasFooter: "two",
    action: "/cash-payments",
    state: "enabled"
  },
  {
    title: "Plan de Pagos",
    titleColor: "blue",
    modifier: 0,
    subText: "Hasta 48 cuotas",
    footerColor: "green",
    footerTextColor: "white",
    hasFooter: false,
    action: "/plan-payments",
    state: "disabled"
  }
];
export const ElectronicMethods = [
  {
    title: "Red Link",
    subtext: "Pague ahora desde la web de Red Link",
    image: require("../assets/Public/link.png"),
    state: "enabled",
    link: "https://www.redlink.com.ar/"
  },
  {
    title: "Red Link",
    subtext: "Genere un VEP y pagelo desde su homebanking",
    image: require("../assets/Public/link.png"),
    state: "enabled",
    link: "https://www.redlink.com.ar/"
  },
  {
    title: "Pagos360",
    subtext: "Pague ahora desde la web de pagos360",
    image: require("../assets/Public/logo_pagos360-01.png"),
    state: "enabled",
    link: "https://web.pagos360.com/login"
  },
  {
    title: "Interbanking",
    subtext: "Pague ahora desde la web de Interbanking",
    image: require("../assets/Public/interbanking.png"),
    state: "disabled",
    link: "https://www.interbanking.com.ar/"
  }
];
export const CashData = require("../assets/Public/callefalsa123.pdf");
export const SimulationResponse = [
  {
    impuestoPortalId: "3",
    tasa: 78,
    totalAdeudado: 0,
    simuladorId: "qwCHE6Tq6tF7lLO",
    totalPlanes: 20558.77,
    totalCapital: 12240.57,
    planes: [
      {
        numeroPlan: 1,
        totalPlan: 2174.49,
        totalPlanCapital: 1326.24,
        incluidas: [
          {
            id: "20200000000009997611",
            capital: 800,
            cuota: "1",
            estado: "JUDICIAL",
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: 52511,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-02-10T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-02-10T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: false,
            porcentaje_exencion: 80,
            datos_adicionales: null,
            sujeto_id: "23-34401916-9",
            objeto_id: "A032GTO",
            objeto_tipo: "A",
            vencida: true
          },
          {
            id: "20200000000009997613",
            capital: 800,
            cuota: "2",
            estado: "JUDICIAL",
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: 52511,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-03-10T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-03-10T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: false,
            porcentaje_exencion: 80,
            datos_adicionales: null,
            sujeto_id: "23-34401916-9",
            objeto_id: "A032GTO",
            objeto_tipo: "A",
            vencida: true
          },
          {
            id: "20200000000009997615",
            capital: 800,
            cuota: "3",
            estado: null,
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: null,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-04-13T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-04-13T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: false,
            porcentaje_exencion: 80,
            datos_adicionales: null,
            sujeto_id: "23-34401916-9",
            objeto_id: "A032GTO",
            objeto_tipo: "A",
            vencida: false
          }
        ],
        descripcion: "W30002863",
        cantidadVencimientos: 2,
        cantidadPeriodos: 1,
        decretos: [
          {
            tipoPlan: "0024",
            decreto: "DECRETO 174/01",
            porcentajeAnticipo: null,
            deudaMinima: 100,
            deudaMaxima: 99999999.99,
            cantidadCuotaMinima: 1,
            cantidadCuotaMaxima: 6,
            montoMinimoCuota: 50,
            minimoAnticipo: 200,
            maximoAnticipo: 10000,
            anticipoSugerido: 0,
            leyendaAnticipo: "",
            leyendaCuotas: "",
            fechaAnticipo: "2020-04-04T00:00:00.000Z",
            diasAnticipo: 15,
            interesCuota: 1,
            capitalEsMinimo: "N",
            cantidadMaxCuotaCriterio: 60,
            totalDeudaCapital: 1248.24,
            link:
              "/consultarContenidoTDR.do?categoria=138&breadcrumbTrack=Ayuda*ayuda#anc1696",
            TotalDeudaActualizada: 2096.49,
            totalIntereses: 630.0,
            totalCondonacionCapital: 0,
            totalCondonacionIntereses: 0,
            totalCapital: 3000.0,
            totalActualizado: 3630.0,
            cantidadCuotasSugeridas: 1,
            porcenAnticipoMinimo: [
              {
                cuota: 1,
                anticipo: 16.66,
                anticipoPlan: 362.27
              }
            ]
          },
          {
            tipoPlan: "0024",
            decreto: "DECRETO 240/49",
            porcentajeAnticipo: null,
            deudaMinima: 100,
            deudaMaxima: 99999999.99,
            cantidadCuotaMinima: 1,
            cantidadCuotaMaxima: 12,
            montoMinimoCuota: 50,
            minimoAnticipo: 0,
            maximoAnticipo: 5000,
            anticipoSugerido: 0,
            leyendaAnticipo: "",
            leyendaCuotas: "",
            fechaAnticipo: "2020-04-08T00:00:00.000Z",
            diasAnticipo: 15,
            interesCuota: 1,
            capitalEsMinimo: "N",
            cantidadMaxCuotaCriterio: 60,
            totalDeudaCapital: 1248.24,
            link:
              "/consultarContenidoTDR.do?categoria=138&breadcrumbTrack=Ayuda*ayuda#anc1696",
            TotalDeudaActualizada: 2096.49,
            totalIntereses: 630.0,
            totalCondonacionCapital: 0,
            totalCondonacionIntereses: 0,
            totalCapital: 3000.0,
            totalActualizado: 3630.0,
            cantidadCuotasSugeridas: 1,
            porcenAnticipoMinimo: [
              {
                cuota: 1,
                anticipo: 16.66,
                anticipoPlan: 362.27
              }
            ]
          }
        ]
      },
      {
        numeroPlan: 2,
        totalPlan: 2174.49,
        totalPlanCapital: 1326.24,
        incluidas: [
          {
            id: "20200000000009997611",
            capital: 1000,
            cuota: "1",
            estado: "JUDICIAL",
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: 52511,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-02-10T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-02-10T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: false,
            porcentaje_exencion: 80,
            datos_adicionales: null,
            sujeto_id: "23-34401916-9",
            objeto_id: "A032GTO",
            objeto_tipo: "A",
            vencida: true
          },
          {
            id: "20200000000009997613",
            capital: 1000,
            cuota: "2",
            estado: "JUDICIAL",
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: 52511,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-03-10T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-03-10T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: false,
            porcentaje_exencion: 80,
            datos_adicionales: null,
            sujeto_id: "23-34401916-9",
            objeto_id: "A032GTO",
            objeto_tipo: "A",
            vencida: true
          },
          {
            id: "20200000000009997615",
            capital: 1000,
            cuota: "3",
            estado: null,
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: null,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-04-13T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-04-13T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: false,
            porcentaje_exencion: 80,
            datos_adicionales: null,
            sujeto_id: "23-34401916-9",
            objeto_id: "A032GTO",
            objeto_tipo: "A",
            vencida: false
          },
          {
            id: "20200000000009997617",
            capital: 1000,
            cuota: "4",
            estado: null,
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: null,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-05-11T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-05-11T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: false,
            porcentaje_exencion: 80,
            datos_adicionales: null,
            sujeto_id: "23-34401916-9",
            objeto_id: "A032GTO",
            objeto_tipo: "A",
            vencida: false
          },
          {
            id: "20200000000009997619",
            capital: 1000,
            cuota: "5",
            estado: null,
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: null,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-06-10T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-06-10T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: false,
            porcentaje_exencion: 80,
            datos_adicionales: null,
            sujeto_id: "23-34401916-9",
            objeto_id: "A032GTO",
            objeto_tipo: "A",
            vencida: false
          }
        ],
        descripcion: "W30028394",
        cantidadVencimientos: 2,
        cantidadPeriodos: 1,
        decretos: [
          {
            tipoPlan: "0024",
            decreto: "DECRETO 201/03",
            porcentajeAnticipo: null,
            deudaMinima: 100,
            deudaMaxima: 99999999.99,
            cantidadCuotaMinima: 1,
            cantidadCuotaMaxima: 6,
            montoMinimoCuota: 50,
            minimoAnticipo: 1000,
            maximoAnticipo: 10000,
            anticipoSugerido: 0,
            leyendaAnticipo: "",
            leyendaCuotas: "",
            fechaAnticipo: "2020-04-13T00:00:00.000Z",
            diasAnticipo: 15,
            interesCuota: 1,
            capitalEsMinimo: "N",
            cantidadMaxCuotaCriterio: 60,
            totalDeudaCapital: 1248.24,
            link:
              "/consultarContenidoTDR.do?categoria=138&breadcrumbTrack=Ayuda*ayuda#anc1696",
            TotalDeudaActualizada: 2096.49,
            totalIntereses: 1050,
            totalCondonacionCapital: 0,
            totalCondonacionIntereses: 0,
            totalCapital: 1248.24,
            totalActualizado: 6050.0,
            cantidadCuotasSugeridas: 1,
            porcenAnticipoMinimo: [
              {
                cuota: 1,
                anticipo: 16.66,
                anticipoPlan: 362.27
              }
            ]
          }
        ]
      }
    ],
    decretos: [],
    excluidos: [
      {
        id: "20200000000002197627",
        capital: 800,
        cuota: "9",
        estado: null,
        fiscalizada: "N",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: null,
        interes_resarcitorio: null,
        juicio_id: null,
        periodo: "2020",
        plan_id: null,
        prorroga: "2020-10-13T00:00:00.000Z",
        tipo: "tributaria",
        total: 1000,
        saldo: 1000,
        vencimiento: "2020-10-13T00:00:00.000Z",
        concepto: "101",
        impuesto: "5",
        exenta: null,
        porcentaje_exencion: null,
        datos_adicionales: null,
        sujeto_id: "55-12389329-2",
        objeto_id: "120130134616",
        objeto_tipo: "I",
        vencida: false
      },
      {
        id: "20200000000002197628",
        capital: 800,
        cuota: "10",
        estado: null,
        fiscalizada: "N",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: null,
        interes_resarcitorio: null,
        juicio_id: null,
        periodo: "2020",
        plan_id: null,
        prorroga: "2020-11-10T00:00:00.000Z",
        tipo: "tributaria",
        total: 1000,
        saldo: 1000,
        vencimiento: "2020-11-10T00:00:00.000Z",
        concepto: "101",
        impuesto: "5",
        exenta: null,
        porcentaje_exencion: null,
        datos_adicionales: null,
        sujeto_id: "55-12389329-2",
        objeto_id: "120130134616",
        objeto_tipo: "I",
        vencida: false
      },
      {
        id: "20200000000002197629",
        capital: 800,
        cuota: "11",
        estado: null,
        fiscalizada: "N",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: null,
        interes_resarcitorio: null,
        juicio_id: null,
        periodo: "2020",
        plan_id: null,
        prorroga: "2020-12-10T00:00:00.000Z",
        tipo: "tributaria",
        total: 1000,
        saldo: 1000,
        vencimiento: "2020-12-10T00:00:00.000Z",
        concepto: "101",
        impuesto: "5",
        exenta: null,
        porcentaje_exencion: null,
        datos_adicionales: null,
        sujeto_id: "55-12389329-2",
        objeto_id: "120130134616",
        objeto_tipo: "I",
        vencida: false
      }
    ],
    incluidas: []
  }
];

export const CashMethods = [
  "Rapipago",
  "Pagofacil",
  "Banco Nacion",
  "Banco de Cordoba",
  "Banco Rio"
];

export const PlanesDePagos = [
  {
    obligacionesExcluidas: [
      {
        id: "20200000000002197627",
        capital: 800,
        cuota: "9",
        estado: null,
        fiscalizada: "N",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: null,
        interes_resarcitorio: null,
        juicio_id: null,
        periodo: "2020",
        plan_id: null,
        prorroga: "2020-10-13T00:00:00.000Z",
        tipo: "tributaria",
        total: 1000,
        saldo: 1000,
        vencimiento: "2020-10-13T00:00:00.000Z",
        concepto: "101",
        impuesto: "5",
        exenta: null,
        porcentaje_exencion: null,
        datos_adicionales: null,
        sujeto_id: "55-12389329-2",
        objeto_id: "120130134616",
        objeto_tipo: "I",
        vencida: false
      },
      {
        id: "20200000000002197628",
        capital: 800,
        cuota: "10",
        estado: null,
        fiscalizada: "N",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: null,
        interes_resarcitorio: null,
        juicio_id: null,
        periodo: "2020",
        plan_id: null,
        prorroga: "2020-11-10T00:00:00.000Z",
        tipo: "tributaria",
        total: 1000,
        saldo: 1000,
        vencimiento: "2020-11-10T00:00:00.000Z",
        concepto: "101",
        impuesto: "5",
        exenta: null,
        porcentaje_exencion: null,
        datos_adicionales: null,
        sujeto_id: "55-12389329-2",
        objeto_id: "120130134616",
        objeto_tipo: "I",
        vencida: false
      },
      {
        id: "20200000000002197629",
        capital: 800,
        cuota: "11",
        estado: null,
        fiscalizada: "N",
        indice_interes_punitorio: null,
        indice_interes_resarcitorio: null,
        interes_punitorio: null,
        interes_resarcitorio: null,
        juicio_id: null,
        periodo: "2020",
        plan_id: null,
        prorroga: "2020-12-10T00:00:00.000Z",
        tipo: "tributaria",
        total: 1000,
        saldo: 1000,
        vencimiento: "2020-12-10T00:00:00.000Z",
        concepto: "101",
        impuesto: "5",
        exenta: null,
        porcentaje_exencion: null,
        datos_adicionales: null,
        sujeto_id: "55-12389329-2",
        objeto_id: "120130134616",
        objeto_tipo: "I",
        vencida: false
      }
    ],
    Plan: [
      {
        Decreto: {
          numero: 1234567,
          id: 1,
          msj: "condona $10.000",
          normativa: "linknormativa",
          alerta: "este plan tiene descuento y solo acepta pago contado",
          anticipoMinimo: 10,
          anticipoVence: "2017-12-14T00:00:00.000Z",
          coutasMin: 1,
          coutasMax: 24
        },
        ObligacionesIncluidas: [
          {
            id: "20200000000002197608",
            capital: 9600,
            cuota: "0",
            estado: null,
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: null,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-02-10T00:00:00.000Z",
            tipo: "tributaria",
            total: 12000,
            saldo: 12000,
            vencimiento: "2020-02-10T00:00:00.000Z",
            concepto: "100",
            impuesto: "5",
            exenta: null,
            porcentaje_exencion: null,
            datos_adicionales: null,
            sujeto_id: "55-12389329-2",
            objeto_id: "120130134616",
            objeto_tipo: "I",
            vencida: true
          },
          {
            id: "20200000000002197611",
            capital: 800,
            cuota: "1",
            estado: null,
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: null,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-02-10T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-02-10T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: null,
            porcentaje_exencion: null,
            datos_adicionales: null,
            sujeto_id: "55-12389329-2",
            objeto_id: "120130134616",
            objeto_tipo: "I",
            vencida: true
          },
          {
            id: "20200000000002197613",
            capital: 800,
            cuota: "2",
            estado: null,
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: null,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-03-10T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-03-10T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: null,
            porcentaje_exencion: null,
            datos_adicionales: null,
            sujeto_id: "55-12389329-2",
            objeto_id: "120130134616",
            objeto_tipo: "I",
            vencida: true
          },
          {
            id: "20200000000002197615",
            capital: 800,
            cuota: "3",
            estado: null,
            fiscalizada: "N",
            indice_interes_punitorio: null,
            indice_interes_resarcitorio: null,
            interes_punitorio: null,
            interes_resarcitorio: null,
            juicio_id: null,
            periodo: "2020",
            plan_id: null,
            prorroga: "2020-04-13T00:00:00.000Z",
            tipo: "tributaria",
            total: 1000,
            saldo: 1000,
            vencimiento: "2020-04-13T00:00:00.000Z",
            concepto: "101",
            impuesto: "5",
            exenta: null,
            porcentaje_exencion: null,
            datos_adicionales: null,
            sujeto_id: "55-12389329-2",
            objeto_id: "120130134616",
            objeto_tipo: "I",
            vencida: false
          }
        ]
      }
    ]
  },
  {
    Plan: [
      {
        Decreto: {
          numero: 1234567,
          id: 1,
          msj: "condona $10.000",
          normativa: "linknormativa",
          alerta: "este plan tiene descuento y solo acepta pago contado",
          anticipoMinimo: 10,
          anticipoVence: "2017-12-14T00:00:00.000Z",
          coutasMin: 1,
          coutasMax: 24,
          ObligacionesIncluidas: [
            {
              id: "20200000000002197608",
              capital: 9600,
              cuota: "0",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-02-10T00:00:00.000Z",
              tipo: "tributaria",
              total: 12000,
              saldo: 12000,
              vencimiento: "2020-02-10T00:00:00.000Z",
              concepto: "100",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: true
            },
            {
              id: "20200000000002197611",
              capital: 800,
              cuota: "1",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-02-10T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-02-10T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: true
            },
            {
              id: "20200000000002197613",
              capital: 800,
              cuota: "2",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-03-10T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-03-10T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: true
            },
            {
              id: "20200000000002197615",
              capital: 800,
              cuota: "3",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-04-13T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-04-13T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: false
            }
          ],
          obligacionesExcluidas: [
            {
              id: "20200000000002197627",
              capital: 800,
              cuota: "9",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-10-13T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-10-13T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: false
            },
            {
              id: "20200000000002197628",
              capital: 800,
              cuota: "10",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-11-10T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-11-10T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: false
            },
            {
              id: "20200000000002197629",
              capital: 800,
              cuota: "11",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-12-10T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-12-10T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: false
            }
          ]
        }
      },
      {
        Decreto: {
          numero: 1234567,
          id: 1,
          msj: "condona $10.000",
          normativa: "linknormativa",
          alerta: "este plan tiene descuento y solo acepta pago contado",
          anticipoMinimo: 10,
          anticipoVence: "2017-12-14T00:00:00.000Z",
          coutasMin: 1,
          coutasMax: 24,
          ObligacionesIncluidas: [
            {
              id: "20200000000002197608",
              capital: 9600,
              cuota: "0",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-02-10T00:00:00.000Z",
              tipo: "tributaria",
              total: 12000,
              saldo: 12000,
              vencimiento: "2020-02-10T00:00:00.000Z",
              concepto: "100",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: true
            },
            {
              id: "20200000000002197611",
              capital: 800,
              cuota: "1",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-02-10T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-02-10T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: true
            },
            {
              id: "20200000000002197613",
              capital: 800,
              cuota: "2",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-03-10T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-03-10T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: true
            },
            {
              id: "20200000000002197615",
              capital: 800,
              cuota: "3",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-04-13T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-04-13T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: false
            }
          ],
          obligacionesExcluidas: [
            {
              id: "20200000000002197627",
              capital: 800,
              cuota: "9",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-10-13T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-10-13T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: false
            },
            {
              id: "20200000000002197628",
              capital: 800,
              cuota: "10",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-11-10T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-11-10T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: false
            },
            {
              id: "20200000000002197629",
              capital: 800,
              cuota: "11",
              estado: null,
              fiscalizada: "N",
              indice_interes_punitorio: null,
              indice_interes_resarcitorio: null,
              interes_punitorio: null,
              interes_resarcitorio: null,
              juicio_id: null,
              periodo: "2020",
              plan_id: null,
              prorroga: "2020-12-10T00:00:00.000Z",
              tipo: "tributaria",
              total: 1000,
              saldo: 1000,
              vencimiento: "2020-12-10T00:00:00.000Z",
              concepto: "101",
              impuesto: "5",
              exenta: null,
              porcentaje_exencion: null,
              datos_adicionales: null,
              sujeto_id: "55-12389329-2",
              objeto_id: "120130134616",
              objeto_tipo: "I",
              vencida: false
            }
          ]
        }
      }
    ]
  }
];

export const PlanDePagos = {
  obligacionesExcluidas: [
    {
      id: "20200000000002197627",
      capital: 800,
      cuota: "9",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-10-13T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-10-13T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "55-12389329-2",
      objeto_id: "120130134616",
      objeto_tipo: "I",
      vencida: false
    },
    {
      id: "20200000000002197628",
      capital: 800,
      cuota: "10",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-11-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-11-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "55-12389329-2",
      objeto_id: "120130134616",
      objeto_tipo: "I",
      vencida: false
    },
    {
      id: "20200000000002197629",
      capital: 800,
      cuota: "11",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-12-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-12-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "55-12389329-2",
      objeto_id: "120130134616",
      objeto_tipo: "I",
      vencida: false
    }
  ],
  Partidas: [
    {
      PartidaId: "123456",
      PartidaNombre: "Nombre Partida",
      Decretos: [
        {
          numero: 1234567,
          id: 1,
          msj: "condona $10.000",
          normativa: "linknormativa",
          alerta: "este plan tiene descuento y solo acepta pago contado",
          anticipoMinimo: 10,
          anticipoVence: "2017-12-14T00:00:00.000Z",
          coutasMin: 1,
          coutasMax: 24
        },
        {
          numero: 562486,
          id: 2,
          msj: "mas informacion del decreto",
          normativa: "linknormativa",
          alerta: "",
          anticipoMinimo: 1500,
          anticipoVence: "2017-12-14T00:00:00.000Z",
          coutasMin: 1,
          coutasMax: 12
        }
      ],
      ObligacionesIncluidas: [
        {
          id: "20200000000002197608",
          capital: 9600,
          cuota: "0",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-02-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 12000,
          saldo: 12000,
          vencimiento: "2020-02-10T00:00:00.000Z",
          concepto: "100",
          impuesto: "5",
          exenta: null,
          porcentaje_exencion: null,
          datos_adicionales: null,
          sujeto_id: "55-12389329-2",
          objeto_id: "120130134616",
          objeto_tipo: "I",
          vencida: true
        },
        {
          id: "20200000000002197611",
          capital: 800,
          cuota: "1",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-02-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 1000,
          saldo: 1000,
          vencimiento: "2020-02-10T00:00:00.000Z",
          concepto: "101",
          impuesto: "5",
          exenta: null,
          porcentaje_exencion: null,
          datos_adicionales: null,
          sujeto_id: "55-12389329-2",
          objeto_id: "120130134616",
          objeto_tipo: "I",
          vencida: true
        },
        {
          id: "20200000000002197613",
          capital: 800,
          cuota: "2",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-03-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 1000,
          saldo: 1000,
          vencimiento: "2020-03-10T00:00:00.000Z",
          concepto: "101",
          impuesto: "5",
          exenta: null,
          porcentaje_exencion: null,
          datos_adicionales: null,
          sujeto_id: "55-12389329-2",
          objeto_id: "120130134616",
          objeto_tipo: "I",
          vencida: true
        },
        {
          id: "20200000000002197615",
          capital: 800,
          cuota: "3",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-04-13T00:00:00.000Z",
          tipo: "tributaria",
          total: 1000,
          saldo: 1000,
          vencimiento: "2020-04-13T00:00:00.000Z",
          concepto: "101",
          impuesto: "5",
          exenta: null,
          porcentaje_exencion: null,
          datos_adicionales: null,
          sujeto_id: "55-12389329-2",
          objeto_id: "120130134616",
          objeto_tipo: "I",
          vencida: false
        }
      ]
    },
    {
      PartidaId: "45689",
      PartidaNombre: "Nombre Partida 2",
      Decretos: [
        {
          numero: 1234567,
          id: 1,
          msj: "condona $10.000",
          normativa: "linknormativa",
          alerta: "este plan tiene descuento y solo acepta pago contado",
          anticipoMinimo: 10,
          anticipoVence: "2017-12-14T00:00:00.000Z",
          coutasMin: 1,
          coutasMax: 24
        },
        {
          numero: 562486,
          id: 2,
          msj: "mas informacion del decreto",
          normativa: "linknormativa",
          alerta: "",
          anticipoMinimo: 1500,
          anticipoVence: "2017-12-14T00:00:00.000Z",
          coutasMin: 1,
          coutasMax: 12
        }
      ],
      ObligacionesIncluidas: [
        {
          id: "20200000000002197608",
          capital: 9600,
          cuota: "0",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-02-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 12000,
          saldo: 12000,
          vencimiento: "2020-02-10T00:00:00.000Z",
          concepto: "100",
          impuesto: "5",
          exenta: null,
          porcentaje_exencion: null,
          datos_adicionales: null,
          sujeto_id: "55-12389329-2",
          objeto_id: "120130134616",
          objeto_tipo: "I",
          vencida: true
        },
        {
          id: "20200000000002197611",
          capital: 800,
          cuota: "1",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-02-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 1000,
          saldo: 1000,
          vencimiento: "2020-02-10T00:00:00.000Z",
          concepto: "101",
          impuesto: "5",
          exenta: null,
          porcentaje_exencion: null,
          datos_adicionales: null,
          sujeto_id: "55-12389329-2",
          objeto_id: "120130134616",
          objeto_tipo: "I",
          vencida: true
        },
        {
          id: "20200000000002197613",
          capital: 800,
          cuota: "2",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-03-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 1000,
          saldo: 1000,
          vencimiento: "2020-03-10T00:00:00.000Z",
          concepto: "101",
          impuesto: "5",
          exenta: null,
          porcentaje_exencion: null,
          datos_adicionales: null,
          sujeto_id: "55-12389329-2",
          objeto_id: "120130134616",
          objeto_tipo: "I",
          vencida: true
        },
        {
          id: "20200000000002197615",
          capital: 800,
          cuota: "3",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-04-13T00:00:00.000Z",
          tipo: "tributaria",
          total: 1000,
          saldo: 1000,
          vencimiento: "2020-04-13T00:00:00.000Z",
          concepto: "101",
          impuesto: "5",
          exenta: null,
          porcentaje_exencion: null,
          datos_adicionales: null,
          sujeto_id: "55-12389329-2",
          objeto_id: "120130134616",
          objeto_tipo: "I",
          vencida: false
        }
      ]
    }
  ]
};

//#endregion

//#region Contactos
export const Contactos = [
  {
    id: 1,
    area: "Mesa de entrada",
    tipo: "Mensaje",
    recepciono: "Martin Garabal",
    descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus placerat vel est in tristique. Donec egestas, massa at hendrerit laoreet, augue metus egestas dolor, sed dignissim nisl diam id sapien. Sed in ipsum tempus, aliquam justo quis, feugiat augue. Phasellus non ante pharetra urna tincidunt maximus eget vitae tortor. Pellentesque eu facilisis dolor, eget faucibus magna. Cras eu est nunc. Nunc malesuada sit amet elit vel iaculis. Aliquam at sem lobortis, tristique diam id, tempus nulla. Fusce vel lectus quam. Praesent ac mi ut velit iaculis tristique. Phasellus ut imperdiet ex. Morbi massa elit, bibendum sit amet feugiat dictum, eleifend non sem. Quisque dapibus lacinia ante eget efficitur. Nulla vitae laoreet tortor. Mauris rhoncus nisi mauris, iaculis aliquet ipsum varius in.",
    archivo: require("../assets/Public/link.png"),
    fecha: "2020-03-18T19:37:00.000Z",
    origen: "usuario",
    estado: "pendiente",
    fecha_interaccion: null,
      },
  {
    id: 2,
    area: "Intimaciones",
    tipo: "Notificacion",
    recepciono: "",
    descripcion: "Notificacion de nueva intimacion",
    archivo: "",
    fecha: "2020-03-12T19:37:00.000Z",
    origen: "Convergente",
    estado: "Notificado",
    fecha_interaccion: null
  },
  {
    id: 3,
    area: "Mesa de entrada",
    tipo: "Presencial",
    recepciono: "Martin Garabal",
    descripcion: "ingreso de expediente - Solicitud de reimputacion de deuda",
    archivo: require("../assets/Public/link.png"),
    fecha: "2020-03-16T19:37:00.000Z",
    origen: "usuario",
    estado: "pendiente",
    fecha_interaccion: null
  },
  {
    id: 4,
    area: "Intimaciones",
    tipo: "Notificacion",
    recepciono: "",
    descripcion: "Notificacion de nueva intimacion",
    archivo: "",
    fecha: "2020-03-18T19:37:00.000Z",
    origen: "Convergente",
    estado: "Notificado",
    leido:true
  },
  {
    id: 5,
    area: "Mesa de entrada",
    tipo: "Mensaje",
    recepciono: "Martin Garabal",
    descripcion: "ingreso de expediente - Solicitud de reimputacion de deuda",
    archivo: require("../assets/Public/link.png"),
    fecha: "2020-02-16T19:37:00.000Z",
    origen: "usuario",
    estado: "pendiente",
    leido:true
  },
  {
    id: 6,
    area: "Intimaciones",
    tipo: "Notificacion",
    recepciono: "",
    descripcion: "Notificacion de nueva intimacion",
    archivo: "",
    fecha: "2020-03-18T19:37:00.000Z",
    origen: "Convergente",
    estado: "Notificado",
    fecha_interaccion: null
  },{
    id: 7,
    area: "Mesa de entrada",
    tipo: "presencial",
    recepciono: "Martin Garabal",
    descripcion: "ingreso de expediente - Solicitud de reimputacion de deuda",
    archivo: require("../assets/Public/link.png"),
    fecha: "2020-03-18T19:37:00.000Z",
    origen: "usuario",
    estado: "pendiente",
    fecha_interaccion: null
  },
  {
    id: 8,
    area: "Intimaciones",
    tipo: "Notificacion",
    recepciono: "",
    descripcion: "Notificacion de nueva intimacion",
    archivo: "",
    fecha: "2020-03-18T19:37:00.000Z",
    origen: "Convergente",
    estado: "Notificado",
    fecha_interaccion: null
  },{
    id: 9,
    area: "Mesa de entrada",
    tipo: "Mensaje",
    recepciono: "Martin Garabal",
    descripcion: "ingreso de expediente - Solicitud de reimputacion de deuda",
    archivo: require("../assets/Public/link.png"),
    fecha: "2020-03-18T19:37:00.000Z",
    origen: "usuario",
    estado: "pendiente",
    leido:true
  },
  {
    id: 10,
    area: "Intimaciones",
    tipo: "Notificacion",
    recepciono: "",
    descripcion: "Notificacion de nueva intimacion",
    archivo: "",
    fecha: "2020-03-18T19:37:00.000Z",
    origen: "Convergente",
    estado: "Notificado",
    fecha_interaccion: null
  },{
    id: 11,
    area: "Mesa de entrada",
    tipo: "Presencial",
    recepciono: "Martin Garabal",
    descripcion: "ingreso de expediente - Solicitud de reimputacion de deuda",
    archivo: require("../assets/Public/link.png"),
    fecha: "2020-03-18T19:37:00.000Z",
    origen: "usuario",
    estado: "pendiente",
    fecha_interaccion: null
  },{
    id: 12,
    area: "Mesa de entrada",
    tipo: "DFE",
    recepciono: "Martin Garabal",
    descripcion: "ingreso de expediente - Solicitud de reimputacion de deuda",
    archivo: require("../assets/Public/link.png"),
    fecha: "2020-03-18T19:37:00.000Z",
    origen: "usuario",
    estado: "pendiente",
    fecha_interaccion: null
  },
  {
    id: 13,
    area: "Mesa de entrada",
    tipo: "DFE",
    recepciono: "Martin Garabal",
    descripcion: "El vencimiento se aplazo hasta el 31 de mayo por la situacion COVID-19",
    archivo:null,
    fecha: "2020-03-18T19:37:00.000Z",
    origen: "usuario",
    estado: "pendiente",
    fecha_interaccion: "2020-03-18T19:37:00.000Z"
  }
];

//#endregion

//#region ThirPArtObjects
export const SelectedObject = [
  {
    id: "A132FED",
    tipo: "A",
    categoria: "0",
    descripcion: "SUZUKI - MOTOCICLETA - VSTROM650",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 1200000,
    etiquetas: null,
    id_externo: "91248135",
    datos_adicionales: null,
    sujeto_id: "20-35090403-5"
  },

  {
    id: "110124257251",
    tipo: "I",
    categoria: "0",
    descripcion: "RAFAEL NUÑEZ 5400 X5000EMY CORDOBA - CAPITAL",
    estado: "ACTIVO",
    fecha_fin: null,
    fecha_inicio: null,
    saldo: 0,
    base_imponible: 1300000,
    etiquetas: "TEST ETIQUETA",
    id_externo: "123456",
    datos_adicionales: null,
    sujeto_id: "20-35090403-5"
  }
];
//#endregion

//#region Vouchers
export const VouchersData = [{
  id: 234567,
  detalle:SimulationResponse[0].planes[0].incluidas  ,
  direccion_ip: "192.168.1.02",
  medio_pago: 'interbanking',
  importe: 1234.56,
  fecha: "2020-03-18T19:37:00.000Z",
  estado_codigo: "V",
  estado_descripcion: "Pendiente de pago",
  sujeto_id: "20-35090403-5",
  api_key: "rev:18.3.124",
  fecha_informado:  "2020-03-18T19:37:00.000Z",
  id_externo: "idexterno",
  respuesta_ente: "Respuesta ente",
  cliente_id:"20-35090403-5"

}
,
  {
    id: 234567,
    detalle:SimulationResponse[0].planes[0].incluidas
    ,
    direccion_ip: "192.168.1.02",
    medio_pago: 'interbanking',
    importe: 1234.56,
    fecha: "2020-03-18T19:37:00.000Z",
    estado_codigo: "P",
    estado_descripcion: "Pendiente de pago",
    sujeto_id: "20-35090403-5",
    api_key: "rev:18.3.124",
    fecha_informado:  "2020-03-18T19:37:00.000Z",
    id_externo: "idexterno",
    respuesta_ente: "Respuesta ente",
    cliente_id:"20-35090403-5"

  }
  ,
  {
    id: 83783,
    detalle:SimulationResponse[0].planes[0].incluidas
    ,
    direccion_ip: "192.168.1.02",
    medio_pago: 'efectivo',
    importe: 567.96,
    fecha: "2020-06-27T19:37:00.000Z",
    estado_codigo: "A",
    estado_descripcion: "Pago recibido",
    sujeto_id: "20-35090403-5",
    api_key: "rev:18.3.124",
    fecha_informado:  "2020-03-18T19:37:00.000Z",
    id_externo: "idexterno",
    respuesta_ente: "Pago aceptado",
    cliente_id:"20-35090403-5",
    archivo:'hola'
  }
  ,
  {
    id: 927453,
    detalle:SimulationResponse[0].planes[0].incluidas
    ,
    direccion_ip: "192.168.1.02",
    medio_pago: 'efectivo',
    importe: 567.96,
    fecha: "2020-06-27T19:37:00.000Z",
    estado_codigo: "I",
    estado_descripcion: "Pago recibido",
    sujeto_id: "20-35090403-5",
    api_key: "rev:18.3.124",
    fecha_informado:  "2020-03-18T19:37:00.000Z",
    id_externo: "idexterno",
    respuesta_ente: "Pago aceptado",
    cliente_id:"20-35090403-5",
archivo:''
  }
]

//#endregion

//#region ObligationStates
export const obligationSatesMock =    [
    {
      "id": 1,
      "description": "Pago sujeto a verificación",
      "code": "PSV",
      "show": false,
      "pay": false,
      "partialPay": false,
      "disabled": false,
      "totalToPay": null,
      "created_at": "2020-06-02T17:23:19.000Z",
      "updated_at": "2020-06-03T15:14:20.000Z"
    },
        {
          "id": 2,
          "description": "Plan de pagos",
          "code": "PP",
          "show": false,
          "pay": false,
          "partialPay": false,
          "disabled": false,
          "totalToPay": null,
          "created_at": "2020-06-02T17:23:48.000Z",
          "updated_at": "2020-06-05T19:30:48.000Z"
        },
        {
          "id": 3,
          "description": "Adherido a débito automático",
          "code": "DEB",
          "show": true,
          "pay": false,
          "partialPay": false,
          "disabled": true,
          "totalToPay": true,
          "created_at": "2020-06-02T17:24:19.000Z",
          "updated_at": "2020-06-09T20:05:16.000Z"
        },
        {
          "id": 4,
          "description": "Sujeto a proceso judicial",
          "code": "JUDICIAL",
          "show": true,
          "pay": true,
          "partialPay": true,
          "disabled": false,
          "totalToPay": true,
          "created_at": "2020-06-02T17:24:44.000Z",
          "updated_at": "2020-08-28T15:06:49.000Z"
        },
        {
          "id": 5,
          "description": "Sin estado",
          "code": "NONE",
          "show": true,
          "pay": true,
          "partialPay": false,
          "disabled": false,
          "totalToPay": true,
          "created_at": "2020-06-03T21:04:43.000Z",
          "updated_at": "2020-06-09T19:06:09.000Z"
        },
        {
          "id": 6,
          "description": "PRESENTADA",
          "code": "presentada",
          "show": true,
          "pay": true,
          "partialPay": false,
          "disabled": false,
          "totalToPay": true,
          "created_at": "2020-06-05T15:45:10.000Z",
          "updated_at": "2020-06-09T19:06:02.000Z"
        },
        {
          "id": 7,
          "description": "SIN PRESENTAR",
          "code": "sinPresentar",
          "show": true,
          "pay": false,
          "partialPay": true,
          "disabled": false,
          "totalToPay": null,
          "created_at": "2020-06-05T15:45:52.000Z",
          "updated_at": "2020-06-09T13:19:51.000Z"
        },
        {
          "id": 8,
          "description": "INACTIVA",
          "code": "inactiva",
          "show": true,
          "pay": false,
          "partialPay": false,
          "disabled": false,
          "totalToPay": false,
          "created_at": "2020-07-08T17:39:24.000Z",
          "updated_at": "2020-07-08T17:39:24.000Z"
        },
        {
          "id": 9,
          "description": "RECTIFICADA",
          "code": "rectificada",
          "show": true,
          "pay": true,
          "partialPay": false,
          "disabled": false,
          "totalToPay": true,
          "created_at": "2020-07-08T17:40:17.000Z",
          "updated_at": "2020-07-17T14:13:14.000Z"
        },
        {
          "id": 10,
          "description": "testing",
          "code": "testing",
          "show": true,
          "pay": false,
          "partialPay": true,
          "disabled": false,
          "totalToPay": false,
          "created_at": "2020-07-17T15:03:41.000Z",
          "updated_at": "2020-07-17T15:15:01.000Z"
        }
    ]
//#endregion

//#region publicObject
export const publicObjectMock =   {
  "id": "110124257251",
  "tipo": "I",
  "categoria": "0",
  "descripcion": "RAFAEL NUÑEZ 5400 X5000EMY CORDOBA - CAPITAL",
  "estado": "ACTIVO",
  "fecha_fin": null,
  "fecha_inicio": null,
  "saldo": 0,
  "base_imponible": 1300000,
  "etiquetas": "TEST ETIQUETA",
  "id_externo": "123456",
  "datos_adicionales": null,
  "sujeto_id": "20-35090403-5",
  "obligaciones": [
    {
      "id": "20200000000001228880",
      "capital": 15000,
      "cuota": "0",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-02-10T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 18000,
      "saldo": 18000,
      "vencimiento": "2020-02-10T00:00:00.000Z",
      "concepto": "100",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": true,
      "interesRecargo": 10708.58,
      "saldoConRecargo": 28708.58
    },
    {
      "id": "20200000000001228881",
      "capital": 1250,
      "cuota": "1",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-02-10T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-02-10T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": true,
      "interesRecargo": 912.08,
      "saldoConRecargo": 2412.08
    },
    {
      "id": "20200000000001228882",
      "capital": 1250,
      "cuota": "2",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-03-10T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-03-10T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": true,
      "interesRecargo": 787.9,
      "saldoConRecargo": 2287.9
    },
    {
      "id": "20200000000001228883",
      "capital": 1250,
      "cuota": "3",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-04-13T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-04-13T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": true,
      "interesRecargo": 642.31,
      "saldoConRecargo": 2142.31
    },
    {
      "id": "20200000000001228884",
      "capital": 1250,
      "cuota": "4",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-05-11T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-05-11T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": true,
      "interesRecargo": 522.41,
      "saldoConRecargo": 2022.4099999999999
    },
    {
      "id": "20200000000001228885",
      "capital": 1250,
      "cuota": "5",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-06-10T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-06-10T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": true,
      "interesRecargo": 393.95,
      "saldoConRecargo": 1893.95
    },
    {
      "id": "20200000000001228886",
      "capital": 1250,
      "cuota": "6",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-07-10T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-07-10T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": true,
      "interesRecargo": 265.49,
      "saldoConRecargo": 1765.49
    },
    {
      "id": "20200000000001228887",
      "capital": 1250,
      "cuota": "7",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-08-10T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-08-10T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": true,
      "interesRecargo": 132.74,
      "saldoConRecargo": 1632.74
    },
    {
      "id": "20200000000001228888",
      "capital": 1250,
      "cuota": "8",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-09-10T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-09-10T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": true,
      "interesRecargo": 0,
      "saldoConRecargo": 1500
    },
    {
      "id": "20200000000001228889",
      "capital": 1250,
      "cuota": "9",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-10-13T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-10-13T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": false,
      "interesRecargo": 0,
      "saldoConRecargo": 1500
    },
    {
      "id": "20200000000001228890",
      "capital": 1250,
      "cuota": "10",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-11-10T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-11-10T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": false,
      "interesRecargo": 0,
      "saldoConRecargo": 1500
    },
    {
      "id": "20200000000001228891",
      "capital": 1250,
      "cuota": "11",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2020-12-10T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2020-12-10T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": false,
      "interesRecargo": 0,
      "saldoConRecargo": 1500
    },
    {
      "id": "20200000000001228892",
      "capital": 1250,
      "cuota": "12",
      "estado": null,
      "fiscalizada": "N",
      "indice_interes_punitorio": null,
      "indice_interes_resarcitorio": null,
      "interes_punitorio": null,
      "interes_resarcitorio": null,
      "juicio_id": null,
      "periodo": "2020",
      "plan_id": null,
      "prorroga": "2021-01-11T00:00:00.000Z",
      "tipo": "tributaria",
      "total": 1500,
      "saldo": 1500,
      "vencimiento": "2021-01-11T00:00:00.000Z",
      "concepto": "101",
      "impuesto": "5",
      "exenta": null,
      "porcentaje_exencion": null,
      "sujeto_id": "20-35090403-5",
      "objeto_id": "110124257251",
      "objeto_tipo": "I",
      "vencida": false,
      "interesRecargo": 0,
      "saldoConRecargo": 1500
    }
  ]
}
//#endregion