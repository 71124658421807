import React from "react";
import MenuButton from "./MenuButton";
import Divider from '@material-ui/core/Divider';
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme=> createStyles({
    menuLinks: {
        flex: "1",
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: '-10px'
      },
      mobile: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      },
      divider:{
        background:theme.header.color,
        opacity:'0.9',
        margin:'10px',
        height:'50%',

      }
}));

const MenuItems = (props) => {
  const classes = useStyles();
const filtrados =props.availableMenus.filter(el=>el.user !==true )
const hayDivider =props.availableMenus.filter(el=>el.user ===true ).length>0;



  return (
    <div className={props.mobile ? classes.mobile : classes.menuLinks}>
      {props.availableMenus.filter(el=>el.user ===true ).map(function (option, i) {
        return (
          <MenuButton
            close={()=>props.close}
            key={i}
            locale={props.locale}
            option={option}
            mobile={props.mobile}
            contactos={
              option["name_ES"].includes("Contactos")
                ? props.unreadMessages
                : null
            }
          />
        );
      })}

{hayDivider && <Divider orientation={props.mobile?'horizontal':'vertical'} className={props.mobile?null:classes.divider}/> }
   
       {props.availableMenus.filter(el=>el.user !==true ).map(function (option, i) {
        return (
          <MenuButton
          close={props.close}
            key={i}
            locale={props.locale}
            option={option}
            mobile={props.mobile}
            contactos={
              option["name_ES"].includes("Contactos")
                ? props.unreadMessages
                : null
            }
          />
        );
      })}
      <Divider orientation={props.mobile?'horizontal':'vertical'} className={props.mobile?null:classes.divider} />
    </div>
  );
};

export default MenuItems;
