import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getUrl } from "./Operations";
import Axios from "axios";
import { Icon } from "@material-ui/core";
import _ from "lodash";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
import { read } from "fs";
const ImageRender = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    meta: { touched, invalid, error },
    name,
    input,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const theme = useTheme();
  const [visible, setVisible] = React.useState(
    inputelement.isConditional ? false : true
  );
  const [imageData, setImageData] = React.useState(null);
  const context = useContext(ctxts);
  const [isValid, setIsValid] = React.useState(false);
  const [imgUrl, setImgUrl] = React.useState(
    inputelement.urlImg
      ? getUrl(
          input.name,
          inputelement.urlImg,
          props.cuit,
          values,
          null,
          null,
          inputelement.urlImgBaseUrl,
          context.BaseUrls
        )
      : ""
  );

  useEffect(() => {
    setImgUrl(
      inputelement.urlImg
        ? getUrl(
            input.name,
            inputelement.urlImg,
            props.cuit,
            values,
            null,
            null,
            inputelement.urlImgBaseUrl,
            context.BaseUrls
          )
        : ""
    );
    setIsValid(true);
  }, [values]);

  useEffect(() => {
    Axios.get(imgUrl, {
      responseType: "blob",
      headers: { Authorization: `Bearer ${props.jwt}` },
    }).then((r) => {
      var reader = new window.FileReader();
      reader.readAsDataURL(r.data);
      reader.onload = function () {
        setImageData(reader.result);
        setIsValid(true);
      };
    });
  }, [imgUrl]);

  const handleError = () => {
    setIsValid(false);
  };

  if (visible) {
    return (
      <FormControl
        {...custom}
        error={touched && invalid}
        className={classes.formcontrol}
      >
        {isTableView ? null : (
          <label className={classes.switchLabel}>{inputelement.label}</label>
        )}
        <div
          className={
            "d-flex " + (isTableView ? "justify-content-center" : null)
          }
        >
          {isValid ? (
            <img
              className={classes.image}
              src={imageData ?? imgUrl}
              alt={inputelement.label}
              width={
                inputelement.imgWidth ? inputelement.imgWidth + "px" : "100%"
              }
              onError={handleError}
            />
          ) : (
            <div
              className={classes.invalidImg}
              style={{
                width: inputelement.imgWidth
                  ? inputelement.imgWidth + "px"
                  : "400px",
                height: inputelement.imgWidth
                  ? (2 * inputelement.imgWidth) / 3
                  : "300px",
              }}
            >
              <Icon fontSize={"large"} className="m-auto">
                {"crop_original"}
              </Icon>{" "}
            </div>
          )}
        </div>
      </FormControl>
    );
  } else return null;
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(ImageRender);
