import Login from "../containers/Login";


export const automotorMock = {
  deuda:true,
  id: "832GON",
  tipo: "A",
  categoria: "0",
  descripcion: "GUERRERO - MOTOCICLETA - GT70",
  estado: "ACTIVO",
  fecha_fin: null,
  fecha_inicio: null,
  saldo: 0,
  base_imponible: 120000,
  etiquetas: "Moto Tag,Flota 1",
  id_externo: "7357271",
  datos_adicionales: null,
  sujeto_id: "20-23686248-9",
  obligaciones: [
    {
      id: "20200000000023997608",
      capital: 9600,
      cuota: "0",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-02-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 12000,
      saldo: 12000,
      vencimiento: "2020-02-10T00:00:00.000Z",
      concepto: "100",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: true,
    },
    {
      id: "20200000002009997617",
      capital: 800,
      cuota: "4",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-05-11T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-05-11T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: false,
    },
    {
      id: "20200000020009997622",
      capital: 800,
      cuota: "6",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-07-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-07-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: false,
    },
    {
      id: "20200000023009997613",
      capital: 800,
      cuota: "2",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-03-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-03-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: true,
    },
    {
      id: "20200000050009997629",
      capital: 800,
      cuota: "11",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-12-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-12-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: false,
    },
    {
      id: "20200000200009997626",
      capital: 800,
      cuota: "8",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-09-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-09-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: false,
    },
    {
      id: "20200000200009997630",
      capital: 800,
      cuota: "12",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2021-01-11T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2021-01-11T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: false,
    },
    {
      id: "20200000230009997611",
      capital: 800,
      cuota: "1",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-02-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-02-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: true,
    },
    {
      id: "20200000230009997615",
      capital: 800,
      cuota: "3",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-04-13T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-04-13T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: true,
    },
    {
      id: "20200000230009997619",
      capital: 800,
      cuota: "5",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-06-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-06-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: false,
    },
    {
      id: "20200010000009997623",
      capital: 800,
      cuota: "7",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-08-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-08-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: false,
    },
    {
      id: "20200040000009997628",
      capital: 800,
      cuota: "10",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-11-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-11-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: false,
    },
    {
      id: "20200200000009997627",
      capital: 800,
      cuota: "9",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-10-13T00:00:00.000Z",
      tipo: "tributaria",
      total: 1000,
      saldo: 1000,
      vencimiento: "2020-10-13T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-23686248-9",
      objeto_id: "832GON",
      objeto_tipo: "A",
      vencida: false,
    },
  ],
  exenciones: [],
};

export const IBMock = {
  deuda:false,
  id: "650369123",
  tipo: "E",
  categoria: "0",
  descripcion: "Contribuyente Local",
  estado: "ACTIVO",
  fecha_fin: null,
  fecha_inicio: null,
  saldo: 0,
  base_imponible: null,
  etiquetas: "PRUEBA ETIQUETA",
  id_externo: "220390",
  datos_adicionales: null,
  sujeto_id: "20-35090403-5",
  obligaciones: [
    {
      id: "20200000000009097608",
      capital: 19200,
      cuota: "0",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-02-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 24000,
      saldo: 24000,
      vencimiento: "2020-02-10T00:00:00.000Z",
      concepto: "100",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: true,
    },
    {
      id: "20200000000009097611",
      capital: 1600,
      cuota: "1",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-02-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-02-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: true,
    },
    {
      id: "20200000000009097613",
      capital: 1600,
      cuota: "2",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-03-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-03-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: true,
    },
    {
      id: "20200000000009097615",
      capital: 1600,
      cuota: "3",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-04-13T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-04-13T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: true,
    },
    {
      id: "20200000000009097617",
      capital: 1600,
      cuota: "4",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-05-11T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-05-11T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: false,
    },
    {
      id: "20200000000009097619",
      capital: 1600,
      cuota: "5",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-06-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-06-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: false,
    },
    {
      id: "20200000000009097621",
      capital: 1600,
      cuota: "6",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-07-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-07-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: false,
    },
    {
      id: "20200000000009097623",
      capital: 1600,
      cuota: "7",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-08-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-08-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: false,
    },
    {
      id: "20200000000009097626",
      capital: 1600,
      cuota: "8",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-09-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-09-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: false,
    },
    {
      id: "20200000000009097627",
      capital: 1600,
      cuota: "9",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-10-13T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-10-13T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: false,
    },
    {
      id: "20200000000009097628",
      capital: 1600,
      cuota: "10",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-11-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-11-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: false,
    },
    {
      id: "20200000000009097629",
      capital: 1600,
      cuota: "11",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2020-12-10T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2020-12-10T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: false,
    },
    {
      id: "20200000000009097630",
      capital: 1600,
      cuota: "12",
      estado: null,
      fiscalizada: "N",
      indice_interes_punitorio: null,
      indice_interes_resarcitorio: null,
      interes_punitorio: null,
      interes_resarcitorio: null,
      juicio_id: null,
      periodo: "2020",
      plan_id: null,
      prorroga: "2021-01-11T00:00:00.000Z",
      tipo: "tributaria",
      total: 2000,
      saldo: 2000,
      vencimiento: "2021-01-11T00:00:00.000Z",
      concepto: "101",
      impuesto: "5",
      exenta: null,
      porcentaje_exencion: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      objeto_id: "650369123",
      objeto_tipo: "E",
      vencida: false,
    },
  ],
  exenciones: [],
};

export const InmobiliarioMock = {
  deuda:false,
  id: "110104130801",
  tipo: "I",
  categoria: "0",
  descripcion: "MANUEL QUINTANA 2010   ALTOS DE VILLA CABRERA ",
  estado: "ACTIVO",
  fecha_fin: null,
  fecha_inicio: null,
  saldo: 0,
  base_imponible: null,
  etiquetas: "PRUEBA Lucas",
  id_externo: "220390",
  datos_adicionales: null,
  sujeto_id: "20-31450688-0",
  obligaciones: [
  ],
  exenciones: [],
};

export const callToAnActionMock = {
  title:'¡Con convergente estar al dia es más facil!',
  action:'/login',
  actionLabel:'Ingresar'

}