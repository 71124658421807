import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grupos from "../Grupo/Grupos";
import { StepForm } from "../../models/IDynamicForm";
import { fieldSubscriptionItems } from "final-form";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import { LogicalOperationsArray } from "../../Inputs/Operations";
//#region Estilos
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
    danger: {
      color: "red",
    },
  })
);
//#endregion
const getSteps = (pasos) => {
  const steps = pasos ? pasos.map((step) => step.tituloPaso) : "";
  return steps;
};

type Props = {
  push: any;
  pop: any;
  values: any;
  steps: Array<StepForm>;
  Footer: any;
  errors: any;
  rest: any;
};
const Steps = (props) => {
  const { push, pop, values, steps } = props;
  const { submitFailed } = props.rest;

  const classes = useStyles();
  const [propbar, setpropbar] = React.useState(fieldSubscriptionItems);
  const [activeStep, setActiveStep] = React.useState(0);
  const stepses = getSteps(steps);
  const goToStep = (i) => {
    setActiveStep(i);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const hasError = (step) => {
    const b = step.inputGroups.find((group) =>
      props.errors[group.name] ? true : false
    );
    return b && submitFailed ? true : false; //foreach get name
  };
  return (
    <div>
      {steps && steps.length > 1 ? (
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          // TransitionProps={{ unmountOnExit: false }}
        >
          {steps.map((step, index) =>
            !step.isConditional ? (
              <Step
                key={"a" + step.titleStep}

                //
              >
                <StepLabel
                  style={{ cursor: "pointer" }}
                  onClick={() => goToStep(index)}
                >
                  {" "}
                  <span className={hasError(step) ? classes.danger : ""}>
                    {hasError(step) && "! "} {step.titleStep}
                  </span>{" "}
                </StepLabel>
                <StepContent TransitionProps={{ unmountOnExit: false }}>
                  <Grupos
                    values={values}
                    groups={step.inputGroups}
                    push={push}
                    pop={pop}
                  />
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Volver
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === stepses.length - 1
                          ? "Finalizar"
                          : "Siguiente"}
                      </Button>
                    </div>
                  </div>
                  {/* {activeStep === stepses.length - 1 && props.Footer} */}
                </StepContent>
              </Step>
            ) : step.isConditional &&
              // _.get(values, step.condition?.observed) &&
              LogicalOperationsArray[
                step.condition?.operation ? step.condition?.operation : "same"
              ]?.(
                _.get(values, step.condition?.observed),
                step.condition?.value
              ) ? (
              // _.get(values, step.condition?.observed).toString() ===
              //   step.condition?.value.toString()
              <Step key={"b" + step.titleStep}>
                <StepLabel>
                  <span className={hasError(step) ? classes.danger : ""}>
                    {hasError(step) && "! "} {step.titleStep}
                  </span>{" "}
                </StepLabel>
                <StepContent>
                  <Grupos
                    values={values}
                    groups={step.inputGroups}
                    push={push}
                    pop={pop}
                  />
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Volver
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === stepses.length - 1
                          ? "Finalizar"
                          : "Siguiente"}
                      </Button>
                    </div>
                  </div>
                  {/* {activeStep === stepses.length - 1 && props.Footer} */}
                </StepContent>
              </Step>
            ) : null
          )}
        </Stepper>
      ) : (
        <>
          {steps && steps?.length > 0 ? (
            steps.map((step, index) => (
              <div key={index}>
                <Grupos
                  values={values}
                  groups={step.inputGroups}
                  push={push}
                  pop={pop}
                />
                {props.Footer}
              </div>
            ))
          ) : (
            <Alert variant="outlined" severity="warning">
              El formulario no tiene ningun paso
            </Alert>
          )}
        </>
      )}
      {activeStep === stepses?.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          {(activeStep >= steps?.length || steps?.length === 1) && props.Footer}
          {/* <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button> */}
        </Paper>
      )}
    </div>
  );
};

export default Steps;
