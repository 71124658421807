import React from 'react';
import Message from '../../EndMessage';
import PlanToConfirm from '../PlanToConfirm';
import { Container } from '@material-ui/core';
import SeccionHeader from '../../../common/SeccionHeader';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import PaymentPlansServices from '../../../../services/PaymentPlansServices';
import moment from 'moment';

const useStyles = makeStyles({
    btns:{
        display:'block',
        textAlign:'right',
        marginTop:'20px',
    },margin:{
        marginRight:'20px'
    },
    Link:{
        textDecoration:'none'
    }


});
const Confirm = (props) => {
    let history = useHistory();
    const classes = useStyles();
    const [Loading, setLoading] = React.useState(true);
    const [Confirmed, setConfirmed] = React.useState(false);
    const [cantPlanes, setCantPlanes] = React.useState(Object.values(props.plans).length);
    


    const [Mensaje, setMensaje] = React.useState({
        msj: 'Estamos preparando el plan pago',
        subMsj: `Aguarde un instante`,
        type: 'loading',
        confirmedObjects:[],
        action: [],
        link: null
    });

    const handleClick =()=>{
        let data ={clienteId: '30709110078', planes:[]};
        data.planes = Object.values(props.plans).map((plan:any)=>{
            return {
                sujeto_id: props.cuit,
                cantidadCuotas: plan.cantidadCuotas,
                fechaAnticipo: moment(plan.fechaAnticipo, 'DD/MM/YYYY').toDate(),
                importeAFinanciar: plan.totalPlan - plan.anticipo,
                importeAnticipo: plan.anticipo,
                importeFinanciado: plan.totalPlan,
                numeroReferencia: plan.descripcion,
                tipo: plan.descripcionDecreto,
                detalle: props.simulation.planes[plan.numeroPlan-1].incluidas.map((oblig:any) => {return {id:oblig.id}})
              }
        });
        PaymentPlansServices()
        .confirm(data, props.jwt)
        .then((response) => {
          
            const confirmedObjects = response.data.map((p) => p.obligaciones[0].objeto_id);
            actualizarMsj(confirmedObjects);
            setConfirmed(true);
            props.clearObligations();
            props.clearSimulation();
        });
    }
    
    /*const handleClick =()=>{
        setTimeout(actualizarMsj,2000);
        setConfirmed(true);
    }*/
     
const handleClickError =()=>{
    setTimeout(actualizarMsj2,2000);
    setConfirmed(true);


} 
function actualizarMsj2(){
    setMensaje(
    {
        msj: `Tuvimos un error, intente más tarde`,
        subMsj: `Error ${'codigo 124'} :`,
        type: 'error',
        confirmedObjects:[
       ],
        action: [
         {
            action: '/plan-payments',
            actionTxt: 'Volver a Planes',
            type:'primary'}
        ],
        link: null
    });
    setLoading(false);
}
    function actualizarMsj(confirmedObjects){
        const msg = cantPlanes === 1? 'Se generó un Plan de Pago' : `Se generaron ${cantPlanes} Planes de Pago`;
        const subMsj = cantPlanes === 1? 'Plan generado:' : 'Planes generados:';
        setMensaje(
        {
            msj: msg,
            subMsj: subMsj,
            type: 'success',
            confirmedObjects:confirmedObjects,
            action: [
             {
                action: '/my-taxes',
                actionTxt: 'Ir al inicio',
                type:'primary'}
            ],
            link: null
        });
        setLoading(false);
    }


    return(

   <div>
       {!Confirmed ?  
        <Container>
        <SeccionHeader title={'Revise y confirme los planes que va a generar'}>
            
        </SeccionHeader>
            {
                Object.values(props.plans).map((plan,index)=>{
                    return <PlanToConfirm key={index} plan={plan}/>
                })
            }
            
            <div className={classes.btns}>
            <Button className={classes.margin} size="large" color="primary" variant='outlined' onClick={props.handleCorregir}>
               Corregir
            </Button>
          
            <Button size="large" color="primary" variant='contained' onClick={handleClick}>
                {cantPlanes === 1? 'Confirma el Plan' : `Confirmar ${cantPlanes} Planes`}
            </Button>
            </div>
        </Container>
            :
        <Message  msj={Mensaje} loading={Loading} />
    }
    </div>);
}

const mapState = state => ({
    plans: state.paymentPlans.plans,
    simulation: state.paymentPlans.simulation,
    cuit: state.auth.cuit,
    jwt: state.auth.jwt
})

const mapDispatch = (dispatch) => ({
    clearObligations: () => dispatch.payments.clearObligations(),
    clearSimulation: () => dispatch.paymentPlans.clearSimulation(),
  });

export default connect(mapState, mapDispatch)(Confirm);