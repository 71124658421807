import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import SeccionHeader from "../SeccionHeader";
import {FormattedMessage} from "react-intl";
import LoadingComponent from "../LoadingComponent";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import IconButton from "@material-ui/core/IconButton";
import {IContact} from "../../../models/IContact";
import {Order} from "../../../constants/tables";
import _ from "lodash"

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        marginBottom: '50px'
    },
    tableCell: {
        padding: '5px 16px'
    },
    tableHead: {
        background: '#efefef'
    },
    falsoTexto: {

        width: '63%',
        height: '15px',
        borderRadius: '15px',
        display: 'inline-block',
        margin:'25px',
        background: '#dadada',

    },
    tabs: {
        background: 'white',
        flexDirection: 'row',
        '& span': {
            flexDirection: 'row',
        }
    },
    tableRow: {
        cursor: 'pointer',
        '&:hover': {
            background: '#f3f3f3'
        }
    }
    , tabsContainer: {
        boxShadow: ' inset 3px -1px 2px 0px #dddddd8c',
        background: ' #f7f7f7'
    }
    , buscar: {
        marginTop: '-16px',
        marginBottom: '16px',
    }

});
interface TableSkeletonProps {
    loadingApplications: (event: React.MouseEvent<unknown>, property: keyof IContact) => void;
    loadingText: Order;
    cantCols: string;
    cantRows?: number;
}

const TableSkeleton = (props) => {
    const classes = useStyles();

    const cantRows = props.cantRows ? props.cantRows : 5;
    return (
            <LoadingComponent isLoading={props.loadingApplications} text={props.loadingText}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="contactos">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                {_.times(props.cantCols, String).forEach((i) =>
                                <TableCell align="center"><span className={classes.falsoTexto} key={i}></span></TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {_.times(cantRows, (i) => (
                                <TableRow className={classes.tableRow} key={i}>
                                    {_.times(props.cantCols, (j) =>
                                        <TableCell className={classes.tableCell} component="th" scope="row" key={j}>
                                            <span className={classes.falsoTexto}>.</span>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </LoadingComponent>
    );
}

export default TableSkeleton;

