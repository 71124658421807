import React, { Fragment } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { NavLink, useRouteMatch } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import UserData from "./UserData";

const useStyles = makeStyles((theme) =>
  createStyles({
    userData: {
      background: theme.colores.main,
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row-reverse",
      padding: "30px",
    },
    icon: {
      margin: "16px 15px 8px",
      color: theme.userMenu.color,
    },
    menuLinks: {
      flex: "1",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    menutext: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    subText: {
      fontWeight: 100,
      margin: 0,
      lineHeight: "1rem",
      maxWidth: "230px",
    },
    text: { fontWeight: 500, margin: 0 },
    mobile: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    active: {
      borderBottom: "solid 3px red",
    },
    link: {
      color: theme.userMenu.color,
      display: "flex",
      padding: "14px 20px 14px 10px",
      textDecoration: "none !important",
    },
  })
);

const UserMenuItems = (props) => {
  const classes = useStyles();

  return (
    <>
      {!props.mobile && (
        <div className={classes.userData}>
          <UserData denominacion={props.denominacion} />
        </div>
      )}
      {props.userMenu.map((elem, i) => (
        <Fragment key={i}>
          {elem.link === "/definir-representado" &&
          props.representedList.length <= 1 ? null : (
            <NavLink key={i} to={elem.link} className={classes.link}>
              <Icon className={classes.icon}>{elem.icon}</Icon>
              <span className={classes.menutext}>
                <Typography className={classes.text}>
                  {elem["label_" + props.locale.toUpperCase()]}
                </Typography>
                <Typography className={classes.subText}>
                  {elem["helpText_" + props.locale.toUpperCase()]}
                </Typography>
              </span>
            </NavLink>
          )}
        </Fragment>
      ))}

      <NavLink
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
        to={"/home"}
        className={classes.link}
      >
        <Icon className={classes.icon}>exit_to_app</Icon>
        <span className={classes.menutext}>
          <Typography className={classes.text}>
            <FormattedMessage id="menu.logout" defaultMessage="Cerrar sesion" />
          </Typography>
          <Typography className={classes.subText}>
            <FormattedMessage
              id="menu.logutDescription"
              defaultMessage="Salir de la cuenta"
            />
          </Typography>
        </span>
      </NavLink>
    </>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  userMenu: state.auth.userMenu,
  taxRole: state.auth.taxRole,
  denominacion: state.auth.denominacion,
  locale: state.auth.locale,
  representedList: state.auth.representedList,
});

export default connect(mapState, null)(UserMenuItems);
