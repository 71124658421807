import {withStyles} from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

export const ExpansionPanel = withStyles({
   /* root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        borderRadius: 10,
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            borderRadius: 0
        },
        position: 'relative',
        overflow: 'visible',
        zIndex:0,
    },
    expanded: {},*/
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
 /*   root: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        borderRadius: 8,
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
            borderRadius: 0
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},*/
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
   /* root: {
        padding: theme.spacing(2),
        paddingBottom: '0px',
        marginLeft: '0px',
        marginRight: '0px',
    },*/
}))(MuiExpansionPanelDetails);
