import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "../../Inputs/Button";

import { FormattedMessage } from "react-intl";
import CircularProgress from "@material-ui/core/CircularProgress";
import apiApplication from "../../../../../services/ApplicationServices";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import apiBUC from "../../../../../services/BUCServices";
import ModalErrors from "./ModalErrors";
import ModalEnd from "./ModalEnd";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionContainer: {
      display: "flex",
      flexDirection: "row",
      marginTop: theme.spacing(2),
      justifyContent: "flex-end",
      borderTop: "1px solid #aaa",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    btn: {
      margin: "10px",
    },
  })
);

const Footer = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [cerrando, setCerrando] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isPosting, setPosting] = React.useState(false);
  const [endModal, setEndModal] = React.useState({
    isOpen: false,
    title: "",
    text: "",
    instance: null,
    type: null,
    data: null,
  });
  // if (props.disabledFooter) {
  //   return null;
  // }

  const b64DecodeUnicode = (str) => {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(b64DecodeUnicode(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const user = parseJwt(props.jwt);
  const handleClose = () => {
    setIsOpen(false);
    setPosting(false);
  };
  const handleEndClose = (redirect, isDraft, data) => {
    setCerrando(true);
    // setEndModal({
    //   isOpen: false,
    //   title: "",
    //   text: "",
    //   instance: null,
    //   type: null,
    //   data: null,
    // });
    if (user.isBackOffice) {
      setTimeout(() => {
        history.goBack();
        setCerrando(false);
        setEndModal({
          isOpen: false,
          title: "",
          text: "",
          instance: null,
          type: null,
          data: null,
        });
        // goBack();
      }, 10000);
    } else {
      setTimeout(() => {
        setCerrando(false);
        setEndModal({
          isOpen: false,
          title: "",
          text: "",
          instance: null,
          type: null,
          data: null,
        });
        if (!data) return;
        window.location.href =
          "/detalle-tramite/" +
          JSON.stringify({
            // paramTramiteId: data.paramTramiteId,
            tramiteId: data.tramiteId,
            readOnly: !isDraft,
            // pasoId: estado,
          });

        // goBack();
      }, 10000);
    }

    //     if (redirect) {
    //       setTimeout(()=>
    // {
    //       window.location.href =
    //         "/detalle-tramite/" +
    //         JSON.stringify({
    //           // paramTramiteId: data.paramTramiteId,
    //           tramiteId: data.tramiteId,
    //           readOnly: true,
    //           // pasoId: estado,
    //         });
    //         setCerrando(false)
    //         setEndModal({
    //           isOpen: false,
    //           title: "",
    //           text: "",
    //           instance: null,
    //           type: null,
    //           data: null,
    //         });
    //       // goBack();
    //     },10000)
    //     }
    //     if (!redirect && isDraft && data) {

    //       // window.location.reload();
    //     }
  };

  const handlePaso = (values, isDraft) => {
    setPosting(true);
    if (!props.hasValidationErrors || isDraft) {
      apiBUC.ProceduresServices.postCompletarTarea(
        props.tramiteId,
        values,
        isDraft
      )
        .then((response) => {
          setPosting(false);

          setEndModal({
            isOpen: true,
            title: response.data.isDraft
              ? "Se guardó el borrador"
              : "Se completó el paso",
            text: response.data.isDraft
              ? `${
                  isDraft ? "" : "Falló al intentar avanzar el paso.\n"
                } El trámite se guardó como borrador en el paso "` +
                (props.pasoId ? props.pasoId : "actual") +
                `" para el trámite ` +
                response.data?.id?.slice(-6, response.data.id.length)
              : 'Completaste el paso "' +
                (props.pasoId ? props.pasoId : "actual") +
                '" para el trámite ' +
                response.data?.id?.slice(-6, response.data.id.length),
            instance: "paso",
            type: "success",
            data: {
              tramiteId: response.data.id,
              paramTramiteId: response.data.paramTramiteId,
            },
          });
          if (!response.data?.isDraft) {
            props.setLastApplicationUpdated({
              id: props.tramiteId,
              state: props.pasoId,
            });
          }

          //  history.goBack();
          // history.goBack();
          //history.push("/mis-tareas");
        })
        .catch((err) => {
          setPosting(false);
          setEndModal({
            isOpen: true,
            title: "Falló el completar paso",
            text:
              "Hubo un error al completar el paso " +
              (props.pasoId ? props.pasoId : "actual") +
              " para el tramite " +
              props.tramiteId?.slice(-6, props.tramiteId.length),
            instance: "paso",
            type: "error",
            data: null,
          });
          alert(err);
        });
    } else {
      setIsOpen(true);
      // alert(JSON.stringify(props.errors));
    }
  };

  const handlePresentar = (values) => {
    props.handleSubmit();

    if (!props.hasValidationErrors) {
      setPosting(true);
      apiApplication.services
        .postTramite(
          props.cuit,
          "presentar",
          {
            paramTramite: {
              id: props.paramTramiteId,
              nombre: props.title,
              tipo: props.paramTramiteTipo,
            },
            ...values,
          },
          props.paramTramiteId,
          props.tramiteId
        )
        .then((response) => {
          setPosting(false);
          setEndModal({
            isOpen: true,
            title:
              response.data.workflowInstanceKey === "undefined" ||
              !response.data.workflowInstanceKey
                ? "El trámite se guardo como borrador"
                : "Se inicio el tramite",
            text:
              response.data.workflowInstanceKey === "undefined" ||
              !response.data.workflowInstanceKey
                ? "El trámite no pudo ingresar a la cola de procesamiento, pero lo guardamos como borrador con id " +
                  response.data?.id?.slice(-6, response.data.id.length) +
                  ". Por favor intente presentarlo más tarde."
                : "Inicio correctamente el tramite " +
                  response.data?.id?.slice(-6, response.data.id.length),
            instance: "present",
            type: "success",
            data: {
              tramiteId: response.data.id,
              paramTramiteId: props.paramTramiteId,
            },
          });
          // goBack();
          //  history.goBack();
          //   history.goBack();
          // history.push("/mis-tramites");
        })
        .catch((err) => {
          setPosting(false);
          setEndModal({
            isOpen: true,
            title: "Falló el inicio del tramite",
            text: "hubo un  error al intentar iniciar el tramite",
            instance: "present",
            type: "error",
            data: null,
          });
          alert(err);
        });
    } else {
      setIsOpen(true);
      // alert(JSON.stringify(props.errors));
    }
  };

  const handleVolver = () => {
    if (user.isBackOffice) {
      history.goBack();
      // window.top.location.href = "/misTareas/";
    } else {
      window.top.location.href = "/tramites/";
    }
    //         "/detalle-tramite/"
    // goBack();
    //  history.goBack();
    // history.goBack();
  };

  const handleBorrador = (values) => {
    setPosting(true);

    apiApplication.services
      .postTramite(
        props.cuit,
        "borrador",
        {
          paramTramite: {
            id: props.paramTramiteId,
            nombre: props.title,
            tipo: props.paramTramiteTipo,
          },
          ...values,
        },
        props.paramTramiteId,
        props.tramiteId
      )
      .then((response) => {
        setPosting(false);
        setEndModal({
          isOpen: true,
          title: "Se guardo el borrador",
          text:
            "Se guardo el borrador para el tramite " +
            response.data.id?.slice(-6, response.data.id.length),
          instance: "draft",
          type: "draft",
          data: {
            paramTramiteId: response.data.paramTramite,
            tramiteId: response.data.id,
          },
        });
        // goBack();
      })
      .catch((err) => {
        setPosting(false);
        setEndModal({
          isOpen: true,
          title: "Falló al guardar el borrador",
          text: "No se pudo completar la tarea de guardar el borrador",
          instance: "draft",
          type: "error",
          data: null,
        });
        alert(err);
      });
  };
  const goBack = () => {
    //redireccionar
    if (props.urlToGoBack) {
      window.top.location.href = props.urlToGoBack;
    } else {
      // const user = parseJwt(props.jwt);

      if (user.isBackOffice) {
        history.goBack();
        // window.top.location.href = "/misTareas/";
      } else {
        window.top.location.href = "/tramites/";
      }

      // window.history.back();
    }
  };
  const handleCancel = () => {
    goBack();
  };

  const readOnlyRender = () =>
    props.viewOnly ? (
      <div className={classes.actionContainer}>
        <Button
          disabled={isPosting}
          className={classes.btn}
          variant={"outlined"}
          color="primary"
          onClick={props.handleVolver ? props.handleVolver : handleVolver}
        >
          <FormattedMessage id="dynamicForm.back" defaultMessage="Volver" />
        </Button>
      </div>
    ) : props.disabledFooter && !props.viewOnly ? (
      <div className={classes.actionContainer}>
        <Button
          disabled={isPosting}
          className={classes.btn}
          variant={"outlined"}
          color="primary"
          onClick={handleVolver}
        >
          <FormattedMessage id="dynamicForm.back" defaultMessage="Volver" />
        </Button>
      </div>
    ) : (
      <Button
        className={classes.btn}
        disabled={isPosting}
        variant={"outlined"}
        color="primary"
        onClick={handleCancel}
      >
        <FormattedMessage id="dynamicForm.cancel" defaultMessage="Cancelar" />
      </Button>
    );

  const stepViewRender = () => (
    <div className={classes.actionContainer}>
      <Button
        disabled={isPosting}
        className={classes.btn}
        variant={"outlined"}
        color="primary"
        onClick={handleCancel}
      >
        <FormattedMessage id="dynamicForm.cancel" defaultMessage="Cancelar" />
      </Button>
      <Button
        disabled={isPosting}
        className={classes.btn}
        variant={"outlined"}
        color="primary"
        onClick={() => handlePaso(props.values, true)}
      >
        {isPosting ? <CircularProgress size={24} /> : null}
        <FormattedMessage
          id="dynamicForms.declaringStep"
          defaultMessage="Guardar borrador"
        />
      </Button>
      <Button
        disabled={isPosting}
        className={classes.btn}
        variant={"contained"}
        color="primary"
        onClick={() => handlePaso(props.values, false)}
      >
        {isPosting ? (
          <>
            <CircularProgress size={24} />
            <FormattedMessage
              id="dynamicForm.declaringStep"
              defaultMessage="Presentando"
            />
          </>
        ) : (
          <FormattedMessage
            id="dynamicForm.declareStep"
            defaultMessage="Presentar paso"
          />
        )}
      </Button>
    </div>
  );

  const saveRender = () => (
    <div className={classes.actionContainer}>
      <Button
        disabled={isPosting}
        className={classes.btn}
        variant={"outlined"}
        color="primary"
        onClick={handleCancel}
      >
        <FormattedMessage id="dynamicForm.cancel" defaultMessage="Cancelar" />
      </Button>
      <Button
        disabled={isPosting}
        className={classes.btn}
        variant={"outlined"}
        color="primary"
        onClick={() => handleBorrador(props.values)}
      >
        <FormattedMessage
          id="dynamicForm.saveDraft"
          defaultMessage="Guardar Borrador"
        />
      </Button>
      <Button
        disabled={isPosting}
        className={classes.btn}
        variant={"contained"}
        color="primary"
        onClick={() => handlePresentar(props.values)}
      >
        {isPosting ? (
          <>
            <CircularProgress size={24} />
            <FormattedMessage
              id="dynamicForm.saving"
              defaultMessage="Presentando"
            />
          </>
        ) : (
          <FormattedMessage id="dynamicForm.save" defaultMessage="Presentar" />
        )}
      </Button>
    </div>
  );

  const handleEnriquecer = (values) => {
    setPosting(true);
    apiBUC.ProceduresServices.putEnriquecer(props.tramiteId, values)
      .then((response) => {
        setPosting(false);
        goBack();
        // history.goBack();
        // history.push("/mis-tramites");
      })
      .catch((err) => {
        setPosting(false);
        console.log(err);
      });
  };

  const enriquecidoRender = () => (
    <div className={classes.actionContainer}>
      <Button
        disabled={isPosting || Object.entries(props.values).length === 0}
        className={classes.btn}
        variant={"contained"}
        color="primary"
        onClick={() => handleEnriquecer(props.values)}
      >
        <FormattedMessage
          id="dynamicForm.enriquecer"
          defaultMessage="Enriquecer"
        />
      </Button>
    </div>
  );

  return (
    <>
      {(props.viewOnly || props.disabledFooter) && readOnlyRender()}
      {!props.viewOnly &&
      !props.disabledFooter &&
      props.pasoId &&
      !props.enriquecer
        ? stepViewRender()
        : null}
      {!props.viewOnly &&
      !props.disabledFooter &&
      !props.pasoId &&
      !props.enriquecer
        ? saveRender()
        : null}
      {props.enriquecer ? enriquecidoRender() : null}
      <ModalErrors
        formulario={props.formulario}
        errors={props.errors}
        open={isOpen}
        handleClose={handleClose}
      />
      <ModalEnd
        cerrando={cerrando}
        title={endModal.title}
        text={endModal.text}
        open={endModal.isOpen}
        user={user}
        type={endModal.type}
        instance={endModal.instance}
        handleClose={() =>
          handleEndClose(
            endModal.type === "success",
            endModal.type === "draft",
            endModal.data
          )
        }
      ></ModalEnd>
    </>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
  urlToGoBack: state.auth.urlToGoBack,
});
const mapDispatch = (dispatch) => ({
  setLastApplicationUpdated: (app) =>
    dispatch.applications.setLastApplicationUpdated(app),
});
export default connect(mapState, mapDispatch)(Footer);
