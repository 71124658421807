import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import ButtonBase from "@material-ui/core/ButtonBase";

import UserData from "./UserData";
import UserMenuItems from "./UserMenuItems";
import StateButton from "./StateButton";
import Button from "../../../common/MaterialComponents/Button";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import PayAllButton from "../../../MyTaxes/Taxes/PayAllButton/PayAllButton";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) =>
  createStyles({
    userMenuContainer: {
      background: theme.userMenu.background,
    },
    lista: {
      padding: 0,
    },
    ConditionHeader: {
      background: "red",
      color: "white",
      padding: "20px",
      textAlign: "left",

      display: "flex",
      flexDirection: "row",
    },
    regular: {
      background: theme.colores.regular,
    },
    irregular: {
      background: theme.colores.irregular,
    },
    ConditionMsj: {
      color: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    ConditionIconMsj: {
      display: "flex",
      marginRight: "10px",
      flexDirection: "column",
      justifyContent: "center",
      "& svg": {
        fontSize: "50px",
      },
    },

    ConditionFooter: {
      padding: "10px 20px",
      display: "flex",
      justifyContent: "space-around",
    },
  })
);

const StateMenu = (props) => {
  const classes = useStyles();

  const [openUserMenu, setOpenuserMenu] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleToggle = () => {
    setOpenuserMenu((prevOpen) => !prevOpen);
  };

  const handleCloseUserMenu = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenuserMenu(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenuserMenu(false);
    }
  }

  return (
    <>
      <ButtonBase
        aria-controls={openUserMenu ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={props.isLoading ? () => null : handleToggle}
        ref={anchorRef}
      >
        <StateButton isLoading={props.isLoading} debtExist={props.debtExist} />
      </ButtonBase>
      {!props.mobile && (
        <Popper
          open={openUserMenu}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={classes.userMenuContainer}>
                <ClickAwayListener onClickAway={handleCloseUserMenu}>
                  <MenuList
                    className={classes.lista}
                    autoFocusItem={openUserMenu}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <div
                      className={`${classes.ConditionHeader} ${
                        props.debtExist ? classes.irregular : classes.regular
                      }`}
                    >
                      <div className={classes.ConditionIconMsj}>
                        {!props.debtExist ? (
                          <CheckCircleOutlinedIcon />
                        ) : (
                          <InfoOutlinedIcon />
                        )}
                      </div>
                      <div className={classes.ConditionMsj}>
                        <Typography>
                          {props.debtExist ? (
                            <FormattedMessage
                              id="userInfo.irregularStateMessage"
                              defaultMessage="El contribuyente tiene deuda que debe saldar"
                            />
                          ) : (
                            <FormattedMessage
                              id="userInfo.regularStateMessage"
                              defaultMessage="El contribuyente esta al dia"
                            />
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={classes.ConditionFooter}
                      onClick={handleCloseUserMenu}
                    >
                      <Link to={"/mis-impuestos"}>
                        <Button color="primary">
                          <FormattedMessage
                            id="userInfo.details"
                            defaultMessage="Detalles"
                          />
                        </Button>
                      </Link>
                      {props.debtExist ? (
                        <PayAllButton
                          linkTo="/payment-methods"
                          buttonText={
                            <FormattedMessage
                              id="userInfo.payDebt"
                              defaultMessage="Saldar Deuda"
                            />
                          }
                        />
                      ) : null}
                    </div>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default StateMenu;
