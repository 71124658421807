const SubjectServices = (instance) => {
  const getSubjectData = (
    id: any,
    filtroEstados: string = "",
    filtroTipos: string = ""
  ) =>
    instance.get("sujetos/" + id + "/objetos/obligaciones", {
      params: { filtroEstados: filtroEstados, filtroTipos: filtroTipos },
    });
  const getSubjectTramites = (id: any) =>
    instance.get("sujetos/" + id + "/tramites/nuevo");
  const getSubjectJudgments = (id: any) =>
    instance.get("sujetos/" + id + "/juicios");
  const getSubjectDeclarations = (id: any) =>
    instance.get("sujetos/" + id + "/declaraciones-juradas");
  const postNewTramite = (id: any, json: any) =>
    instance.post(
      window.config.apiBase.tramite + "sujetos/" + id + "/alicuotas/",
      json
    );
  const getContacts = () => instance.get("sujetos/contactos");
  const getObjectData = (id: any) => instance.get("objetos/" + id);
  const getObjectObligations = (id: any) =>
    instance.get("objetos/" + id + "/obligaciones");
  const getPublicObjectObligations = (id: any) =>
    instance.get("objetos/" + id + "/obligaciones/publico");
  const getObjectExemptions = (id: any) =>
    instance.get("objetos/" + id + "/exenciones");
  const getObjectJudgments = (id: any) =>
    instance.get("objetos/" + id + "/juicios");
  const putObjectTags = (id: any, json: any) =>
    instance.put("objetos/" + id + "/etiquetas", json);
  const putThirdObjectTags = (id: any, json: any) =>
    instance.put("sujetos/" + id + "/objetos-terceros/" + "etiquetas", json);
  const markContactAsReaded = (contactId: string) =>
    instance.post("/sujetos/contacto/" + contactId + "/interaccion");
  const getVouchers = (id: any) =>
    instance.get("sujetos/" + id + "/boleta-pagos");
  const postThirdPartTax = (id: any, json: any) =>
    instance.post("sujetos/" + id + "/objetos-terceros", json);
  const deleteThirdPartTax = (id: any, objectId: any, type: any) =>
    instance.delete(
      "sujetos/" +
        id +
        "/objetos-terceros?objeto_id=" +
        objectId +
        "&tipo_objeto=" +
        type
    );
  const getSubject = (id: any) => instance.get("sujetos/" + id);
  const getSubjectNextExpirations = (
    id: any,
    filtroEstados: string = "",
    filtroTipos: string = ""
  ) => instance.get("sujetos/" + id + "/objetos/obligacion-a-vencer");
  const generateBoleta = (json: any) => instance.post("boleta-pagos", json);
  const generateContacto = (json: any) =>
    instance.post("sujetos/contacto", json);
  const endContacto = (id: any) =>
    instance.post("sujetos/contacto/" + id + "/fin");
  const getSubjectSituation = () =>
    instance.get("sujetos/objetos/obligaciones/situacion");

  return {
    getSubjectData,
    getSubjectJudgments,
    getSubjectDeclarations,
    getSubjectTramites,
    postNewTramite,
    getContacts,
    getObjectData,
    getObjectObligations,
    getPublicObjectObligations,
    getObjectExemptions,
    getObjectJudgments,
    putObjectTags,
    putThirdObjectTags,
    markContactAsReaded,
    getVouchers,
    postThirdPartTax,
    deleteThirdPartTax,
    getSubject,
    getSubjectNextExpirations,
    generateBoleta,
    generateContacto,
    endContacto,
    getSubjectSituation,
  };
};
export default SubjectServices;
