import React, { useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Container from "@material-ui/core/Container";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import HeaderForAgent from "../HeaderForAgent";
import apiBUC from "../../../services/BUCServices";
import DraweMobile from "./Components/DrawerMobile";
import MenuItems from "./Components/MenuItems";
import UserMenuItems from "./Components/UserMenuItems";
import StateButton from "./Components/StateButton";
import Divider from "@material-ui/core/Divider";
import UserData from "./Components/UserData";
import MenuUsuario from "./Components/MenuUsuario";
import { ReactComponent as Logo } from "../../../assets/logocorrientes.svg";
import StateMenu from "./Components/StateMenu";

const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      color: theme.header.color,
    },
    AppBar: {
      background: theme.header.background,
    },
    menu: {
      padding: "10px 0px",
      display: "flex",
      justifyContent: "space-between",
    },
    menuLinks: {
      flex: "1",
      display: "flex",
      justifyContent: "flex-end",
    },
    mobile: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    mobileBtns: {
      display: "flex",
    },
    regular: {
      margin: "auto",
      background: "green",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "10px 30px",
      height: "fit-content",
      borderRadius: "4px",
      textTransform: "uppercase",
    },
    irregular: {
      margin: "auto",
      background: "red",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "10px 30px",
      height: "fit-content",
      borderRadius: "4px",
      textTransform: "uppercase",
    },
    logo: {
      maxWidth: "100%",
    },
    infoLoading: {
      margin: "auto",
      background: "#eee",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: "10px 30px",
      height: "fit-content",
      borderRadius: "4px",
      textTransform: "uppercase",
    },
    userData: {
      background: theme.header.background,
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "row-reverse",
      padding: "30px",
    },
    menubtn: {
      color: theme.header.color,
    },
  })
);
const Header = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpen, setOpen] = React.useState(false);
  const currentLocation = window.location.pathname;
  const [openUserMenu, setOpenuserMenu] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const availableMenus = [...props.menu.filter((m) => m.visible)];

  const countDosentRead = (data) =>
    data.filter((x) => !x.fecha_interaccion).length;

  useEffect(() => {
    apiBUC.SubjectServices.getContacts().then((response) => {
      props.setUnreadMessages(countDosentRead(response.data));
    });
  }, [props.loadingObjects]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const prevOpen = React.useRef(openUserMenu);
  React.useEffect(() => {
    if (prevOpen.current === true && openUserMenu === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = openUserMenu;
  }, [openUserMenu]);
  if (props.inIframe) return <></>;
  return (
    <div>
      <HeaderForAgent />
      <AppBar
        className={classes.AppBar}
        position="sticky"
        elevation={theme.header.elevation}
      >
        <Toolbar>
          <Container maxWidth="lg" className={classes.menu}>
            <div>
              <Typography className={classes.text} variant="h6" id="upper">
                <Logo className={classes.logo} />
              </Typography>
            </div>
            <DraweMobile open={isOpen} onClose={handleClose} mobile={mobile}>
              {mobile ? (
                <>
                  <div className={classes.userData}>
                    <UserData />
                  </div>
                  <Divider />
                </>
              ) : null}
              <MenuItems
                mobile={mobile}
                availableMenus={availableMenus}
                locale={props.locale}
              />
              {mobile ? (
                <>
                  <Divider />
                  <UserMenuItems mobile={mobile} />
                </>
              ) : null}
            </DraweMobile>

            <div className={classes.mobileBtns}>
              {currentLocation == "/mis-impuestos" ? (
                <StateMenu
                  isLoading={props.loadingObjects}
                  debtExist={props.debtExist}
                />
              ) : props.allObjects.length > 0 && !props.publicLogin ? (
                <StateMenu
                  isLoading={props.loadingObjects}
                  debtExist={props.debtExist}
                />
              ) : null}

              {mobile ? (
                <>
                  <IconButton onClick={handleOpen} aria-label="menu">
                    <MenuIcon className={classes.menubtn} />
                  </IconButton>

                  <MenuUsuario
                    denominacion={props.denominacion}
                    onClick={handleOpen}
                    mobile
                  />
                </>
              ) : (
                <MenuUsuario
                  enAtencion={props.atencion}
                  denominacion={props.denominacion}
                />
              )}
            </div>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapDispatch = (dispatch) => ({
  setLocale: (locale) => dispatch.auth.setLocale(locale),
  setDebtExist: (debtExist) => dispatch.userInfo.setDebtExist(debtExist),

  setUnreadMessages: (unreadMessages) =>
    dispatch.contacts.setUnreadMessages(unreadMessages),
});

const mapState = (state) => ({
  menu: state.auth.menu,
  publicLogin: state.auth.publicLogin,
  locale: state.auth.locale,
  inIframe: state.auth.inIframe,
  languages: state.languages.languages,
  cuit: state.auth.cuit,
  denominacion: state.auth.denominacion,
  jwt: state.auth.jwt,
  loadingObjects: state.payments.loadingObjects,
  unreadMessages: state.contacts.unreadMessages,
  allObjects: state.payments.allObjects,
  debtExist: state.userInfo.debtExist,
  userMenu: state.auth.userMenu,
  taxRole: state.auth.taxRole,
  representedList: state.auth.representedList,
  atencion: state.auth.atencion,
});

export default connect(mapState, mapDispatch)(Header);
