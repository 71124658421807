import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Table from "../common/Table";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import Button from "../../components/common/MaterialComponents/Button";
import { format } from "date-fns";
import NumberCurrency from "../common/NumberCurrency";
import { useHistory } from "react-router-dom";
import { capitalize } from "@material-ui/core";
import TaskServices from "../../services/TaskServices";
import Typography from "@material-ui/core/Typography";
import { isArray } from "util";
import "moment/locale/es";
import moment from "moment";
import Moment from "react-moment";
import ChatIcon from "@material-ui/icons/Chat";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  fullWidth: {
    width: "100%",
  },
  actionButtons: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gridGap: "15px",
  },
  tramiteTipo: {
    position: "absolute",
    // position: absolute;
    top: 0,
    width: "210px",
    background: "#e8e8e8",
    left: 0,
  },
  cellRelative: { positio: "relative" },
});

const MyTasksList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const startChat = (data, e) => {
    e.stopPropagation();
    props.createChat("tramite", data.id);
  };

  //TODO HACER TRADUCCIONES
  const columnas = [
    {
      title: "Cuit",
      field: "sujeto_id",
      width: 40,
      headerStyle: { position: "relative", zIndex: 0 },
      render: (rowData) => <Typography>{rowData.sujeto_id}</Typography>,
    },
    {
      title: "Id y Tipo",
      field: "id",
      width: 130,
      headerStyle: { position: "relative", zIndex: 0 },
      render: (rowData) => (
        <Typography>
          <small>
            {rowData.id.slice(-6, rowData.id.length)}
            <br />
            {rowData.paramTramite?.nombre}
          </small>
        </Typography>
      ),
    },
    {
      title: "Grupo",
      field: "datos_adicionales.grupo",
      width: 50,
      headerStyle: { position: "relative", zIndex: 0 },
      render: (rowData) => (
        <Typography> {capitalize(rowData.datos_adicionales.grupo)} </Typography>
      ),
    },
    {
      title: "Estado",
      field: "estado",
      width: 50,
      headerStyle: { position: "relative", zIndex: 0 },
      render: (rowData) => (
        <Typography>
          {rowData.processing
            ? "procesando"
            : rowData.estado.proceso.replace(/_/g, " ")}
        </Typography>
      ),
    },
    {
      title: "Asignado",
      field: "datos_adicionales.asignadoNombre",
      headerStyle: { position: "relative", zIndex: 0 },
      width: 50,
      render: (rowData) => (
        <Typography>
          {rowData.datos_adicionales.asignadoNombre
            ? rowData.datos_adicionales.asignadoNombre
            : rowData.datos_adicionales.asignado}
        </Typography>
      ),
    },
    // {
    //   title: "Asignado",
    //   field: "datos_adicionales.asignado",
    //   headerStyle: { position: "relative", zIndex: 0 },
    //   width: 20,
    // },
    {
      title: "Fecha de Inicio",
      field: "fechaInicio",
      width: 150,
      headerStyle: { position: "relative", zIndex: 0 },
      render: (data) => {
        return (
          <Typography>
            {moment(data.fechaInicio).utc().format("DD/MM/YYYY HH:mm")}
          </Typography>
        );
      },
      defaultSort: "desc",
      customSort: (a, b) => moment(a.fechaInicio).diff(moment(b.fechaInicio)),
    },
    {
      title: "Accion",
      width: 50,
      headerStyle: { position: "relative", zIndex: 0 },
      render: (rowData) => {
        const handleClickRequest = () => {
          setIsLoading(true);
          TaskServices()
            .requestTask(rowData.id, props.jwt)
            .then(async (response) => {
              await props.reload();
            })
            .finally(() => {
              // setIsLoading(false);
            })
            .catch(async (e) => {
              await props.reload();
              console.log(e.message);
              // setIsLoading(false);
            });
        };

        const handleClickComplete = () => {
          const estadosParse = rowData.estado;
          let estado = null;

          if (Array.isArray(estadosParse))
            estado = estadosParse[estadosParse.length - 1].proceso;
          else estado = estadosParse.proceso;
          // props.setCompleting(rowData.id);
          history.push({
            pathname:
              "/detalle-tramite/" +
              JSON.stringify({
                paramTramiteId: rowData.tipo,
                tramiteId: rowData.id,
                pasoId: estado,
              }),
          });
        };

        return isLoading ? (
          <Button color="primary" disabled>
            Actualizando...
          </Button>
        ) : props.lastApplicationUpdated.id === rowData.id &&
          props.lastApplicationUpdated.state === rowData.estado.proceso ? (
          "Procesando..."
        ) : "asignado" in rowData.datos_adicionales ? (
          <span className={classes.actionButtons}>
            {rowData.datos_adicionales.asignado == props.id ? (
              <Button
                variant="outlined"
                color="primary"
                disabled={rowData.processing || isLoading}
                onClick={handleClickComplete}
              >
                COMPLETAR TAREA
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                disabled={rowData.processing || isLoading}
                onClick={handleClickRequest}
              >
                REASIGNAR TAREA
              </Button>
            )}
            <Button
              disabled={isLoading}
              onClick={(e) => startChat(rowData, e)}
              color="primary"
              aria-label="iniciar chat"
            >
              <ChatIcon />
            </Button>
          </span>
        ) : (
          <Button
            color="primary"
            disabled={rowData.processing || isLoading}
            onClick={handleClickRequest}
          >
            SOLICITAR TAREA
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        className={classes.fullWidth}
        columnas={columnas}
        Data={props.data}
        filter={props.filter}
        mensajeVacio={"No hay tareas para mostrar."}
      />
    </div>
  );
};
const mapDispatch = (dispatch) => ({
  createChat: (tramite, clave) => dispatch.chats.createChat(tramite, clave),
});

const mapState = (state) => ({
  id: state.auth.id,
  jwt: state.auth.jwt,
  lastApplicationUpdated: state.applications.lastApplicationUpdated,
});

export default connect(mapState, mapDispatch)(MyTasksList);
