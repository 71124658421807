import React, { useEffect } from "react";
import LoadingAccordion from "../../components/MyTaxes/Taxes/LoadingAccordion/LoadingAccordion";
import Spinner from "../../components/common/Spinner";
import ApplicationsList from "./ApplicationList";
import SeccionHeader from "../common/SeccionHeader";
import { FormattedMessage } from "react-intl";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import apiApplication from "../../services/ApplicationServices";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "../../components/common/MaterialComponents/Breadcrumbs";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import LoadingComponent from "../common/LoadingComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: "50px",
    },
  })
);

const ApplicationForms = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [loadingText, setLoadingText] = React.useState(
    <FormattedMessage
      id="applicationForm.loadingText"
      defaultMessage="Cargando tramites..."
    />
  );
  const [loadingApplications, setLoadingApplications] = React.useState(true);

  useEffect(() => {
    // if (props.formList.length == 0) {
    apiApplication.services
      .getApplications()
      .then((fields) => {
        props.setFormList(fields.data);
        setLoadingApplications(false);
      })
      .catch((err) => {
        setLoadingText(
          <FormattedMessage
            id="applicationForm.notFound"
            defaultMessage="No se encontraron campos asociados"
          />
        );
        setLoadingApplications(false);
      });
    // } else {
    // setLoadingApplications(false);
    // }
  }, []);

  return (
    <div className={classes.container}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/dashboard">Inicio</Link>
        <Link to="/mis-tramites">Mis trámites</Link>
        <Typography color="textPrimary">Nuevo trámite</Typography>
      </Breadcrumbs>

      <SeccionHeader
        title={
          <FormattedMessage
            id="applicationForm.title"
            defaultMessage="Nuevo tramite"
          />
        }
      />

      <LoadingComponent isLoading={loadingApplications}>
        <ApplicationsList mobile={mobile} />
      </LoadingComponent>
    </div>
  );
};

const mapState = (state) => ({
  procedureForm: state.procedures.procedureForm,
  cuit: state.auth.cuit,
  formList: state.applications.formList,
});

const mapDispatch = (dispatch) => ({
  setFormList: (formList) => dispatch.applications.setFormList(formList),
});

export default connect(mapState, mapDispatch)(ApplicationForms);
