import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

import { SelectedObject } from "../../../../mock/data";
import { Button, TextField } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import TaxIcon from "../TaxIcon";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormFields from "../../../Applications/FormField";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
import apiBUC from "../../../../services/BUCServices";

let icon = require("../../../../assets/iconos/center-error@1x.png");

interface ThirdPartTaxesFormProps {
  isOpen: boolean;
  handleClose: any;
  handleAdd: any;
}

interface SelectedObject {
  sujeto_id: boolean;
  tipo: any;
  id: any;
  descripcion: any;
}

const useStyles = makeStyles({
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    flexWrap: "wrap",
    transition: "all 300ms",
  },
  btnBase: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minWidth: "25%",
    border: "solid 1px #ddd",
    borderRadius: "10px",
    padding: "20px",
    margin: "5px",
    background: "white",
    transition: "all 300ms",
    "&:hover": {
      zIndex: 9,
      transition: "all 300ms",
      background: "white",
      transform: "scale(1.1)",
      boxShadow: "0px 3px 7px 1px #ddd",
    },
  },
  focusVisible: {},
  icon: {},
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    border: "solid 1px #ddd",
    padding: "20px 0 0",
  },
  loading: {
    padding: "20px 0",
  },
  Text: {
    width: "100%",
    marginBottom: "15px",
  },
});

const ObjectsTypes = [
  {
    title: "inmobiliario",
    type: "I",
    inputs: [
      {
        element: "TextInput",
        field_name: "titulo",
        required: true,
        label: "Titulo",
      },
      {
        element: "TextInput",
        field_name: "data",
        required: true,
        label: "Partida inmobiliaria",
      },
    ],
    inputSize: 12,
  },
  {
    title: "Automotor",
    type: "A",
    inputs: [
      {
        element: "TextInput",
        field_name: "titulo",
        required: true,
        label: "Titulo",
      },
      {
        element: "TextInput",
        field_name: "data",
        required: true,
        label: "Patente",
      },
    ],
    inputSize: 7,
  },
  {
    title: "Embarcaciones",
    type: "EMq",
    inputs: [
      {
        element: "TextInput",
        field_name: "titulo",
        required: true,
        label: "Titulo",
      },
      {
        element: "TextInput",
        field_name: "data",
        required: true,
        label: "Identificador",
      },
    ],
    inputSize: 12,
  },
];

const ThirdPartTaxesForm = (props) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);
  const [selectedObject, setselectedObject] = React.useState<SelectedObject>();
  const [SelectedType, setType] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedName, setSelectedName] = React.useState(null);
  const ObjectTypeButton = (props) => {
    const classes = useStyles();
    const taxType = props.obje;
    return (
      <ButtonBase
        focusRipple
        key={taxType.description}
        className={classes.btnBase}
        focusVisibleClassName={classes.focusVisible}
        onClick={(e) => props.onClick(e)}
      >
        <TaxIcon type={taxType.code} />
      </ButtonBase>
    );
  };
  const handleClick = (e) => {
    setType(e);
    setActiveStep(1);
  };

  const handleAdd = (event: any) => {
    let thirdPartToPost = {};
    thirdPartToPost["objeto_id"] = selectedObject.id;
    thirdPartToPost["tipo_objeto"] = selectedObject.tipo;
    thirdPartToPost["etiquetas"] = "";
    thirdPartToPost["tercero_id"] = selectedObject.sujeto_id;
    /*thirdPartToPost['sujeto_id'] = props.cuit;
        thirdPartToPost['categoria_id'] = '0';
        thirdPartToPost['descripcion'] = selectedObject.descripcion;
        thirdPartToPost['estado'] = selectedObject['estado'];
        thirdPartToPost['id_externo'] = selectedObject['id_externo'];
        thirdPartToPost['otros_atributos'] = {'uno': 'dos'};
        thirdPartToPost['saldo'] = selectedObject['saldo'];
        thirdPartToPost['base_imponible'] = selectedObject['base_imponible'];
        thirdPartToPost['porcentaje_cotitular'] = 0;
        thirdPartToPost['tercero_denominacion'] = 'Natalia Natalia';*/

    apiBUC.SubjectServices
      .postThirdPartTax(props.cuit, thirdPartToPost)
      .then((response) => {
        console.log(response);
      });

    setTimeout(
      function () {
        window.location.reload();
      }.bind(window),
      1000
    );
  };

  const onIdChange = (event: any) => {
    setSelectedId(event.target.value);
  };
  const onNameChange = (event: any) => {
    setSelectedName(event.target.value);
  };

  const selectObject = (e) => {
    setActiveStep(3);
    setLoading(true);
    let aux = null;

    apiBUC.SubjectServices
      .getObjectData(e.toUpperCase())
      .then((response) => {
        aux = response.data;

        apiBUC.SubjectServices
          .getObjectObligations(e)
          .then((response) => {
            aux["obligaciones"] = response.data;

            apiBUC.SubjectServices
              .getObjectExemptions(e)
              .then((response) => {
                aux["exenciones"] = response.data;
                setselectedObject(aux);
                setActiveStep(2);
              })
              .catch((err) => {
                setselectedObject(aux);
                setActiveStep(2);
              });
          })
          .catch((err) => {
            setselectedObject(null);
            setActiveStep(2);
          });

        apiBUC.SubjectServices
          .getObjectJudgments(e)
          .then((response) => {
            if (response.data.length > 0) props.addJudgment(response.data[0]);
          });
      })
      .catch((err) => {
        setselectedObject(null);
        setActiveStep(2);
      });
  };
  function getSteps() {
    return ["tipo de impuesto", "nombre y codigo", "confirmacion"];
  }

  function getInfo() {
    if (selectedObject) {
      if (
        selectedObject.sujeto_id == props.cuit ||
        selectedObject.tipo != SelectedType.code
      ) {
        setselectedObject(null);
      }

      props.thirdPartObjects.forEach((object) => {
        if (object.objeto_id == selectedObject.id) {
          setselectedObject(null);
        }
      });
    }

    if (!selectedObject) {
      return (
        <Alert icon={<img src={icon} />} variant="outlined" severity="error">
          <AlertTitle>
            <FormattedMessage
              id="myTaxes.thridPart.ErrorMsj"
              defaultMessage="No encontramos el objeto"
            />
          </AlertTitle>
          <FormattedMessage
            id="myTaxes.thridPart.errSubmsj"
            defaultMessage="Intenta con otro parametro de busqueda"
          />
        </Alert>
      );
    } else {
      return (
        <div className={classes.info}>
          <div>
            <h4>
              {" "}
              <FormattedMessage
                id="myTaxes.thridPart.Identificador"
                defaultMessage="Codigo Identificador"
              />{" "}
            </h4>
            <p>{selectedObject.id} </p>
          </div>
          <div>
            <h4>
              <FormattedMessage
                id="myTaxes.thridPart.Descripcion"
                defaultMessage="Descripcion"
              />{" "}
            </h4>
            <p>{selectedObject.descripcion} </p>
          </div>
          <div>
            <h4>
              <FormattedMessage
                id="myTaxes.thridPart.CuitTitular"
                defaultMessage="Cuit titular"
              />{" "}
            </h4>
            <p>{selectedObject.sujeto_id} </p>
          </div>
        </div>
      );
    }
  }

  const onKeyDownSearch = (e) => {
    if (e.keyCode == 13) {
      selectObject(selectedId);
    }
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <div>
            <DialogTitle id="responsive-dialog-title">
              <FormattedMessage
                id="myTaxes.thridPart.QueAgregar"
                defaultMessage="¿Que impuesto desea agregar?"
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className={classes.btnContainer}>
                  {props.taxesTypes.map((taxType) => (
                    <ObjectTypeButton
                      onClick={() => handleClick(taxType)}
                      obje={taxType}
                    />
                  ))}
                </div>
              </DialogContentText>
            </DialogContent>
          </div>
        );
      case 1:
        return (
          <div>
            <DialogTitle id="responsive-dialog-title">
              <FormattedMessage
                id="myTaxes.thridPart.IngreseTipo"
                defaultMessage="Ingrese el impuesto de tipo"
              />{" "}
              {SelectedType.Description}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <TextField
                  className={classes.Text}
                  type="text"
                  name={"objId"}
                  required={true}
                  label={
                    <FormattedMessage
                      id="myTaxes.thridPart.ObjectID"
                      defaultMessage="Identificador"
                    />
                  }
                  onChange={onIdChange}
                  onKeyDown={onKeyDownSearch}
                  variant="outlined"
                  autoFocus
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={props.handleClose}
                variant="outlined"
                color="primary"
              >
                <FormattedMessage
                  id="myTaxes.thridPart.cancel"
                  defaultMessage="Cancelar"
                />
              </Button>
              <Button
                onClick={() => selectObject(selectedId)}
                variant="contained"
                color="primary"
              >
                <FormattedMessage
                  id="myTaxes.thridPart.find"
                  defaultMessage="Buscar"
                />
              </Button>
            </DialogActions>
          </div>
        );
      case 2:
        return (
          <div>
            <DialogTitle id="responsive-dialog-title">
              <FormattedMessage
                id="myTaxes.thridPart.Isthis"
                defaultMessage="¿Este es el impuesto que queres agregar?"
              />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{getInfo()}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setActiveStep(1);
                }}
                variant="outlined"
                color="primary"
                autoFocus
              >
                <FormattedMessage
                  id="myTaxes.thridPart.cancel"
                  defaultMessage="Cancelar"
                />
              </Button>
              {selectedObject && (
                <Button
                  onClick={handleAdd}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  <FormattedMessage
                    id="myTaxes.thridPart.Confirm"
                    defaultMessage="Si, agregarlo"
                  />
                </Button>
              )}
            </DialogActions>
          </div>
        );
      case 3:
        return (
          <div>
            <DialogTitle id="responsive-dialog-title">
              <FormattedMessage
                id="myTaxes.thridPart.searching"
                defaultMessage="Estamos buscando el impuesto"
              />
            </DialogTitle>
            <DialogContent>
              <div className={classes.loading}>
                <LinearProgress />
              </div>
            </DialogContent>
          </div>
        );
      default:
        return null;
    }
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={props.isOpen}
      onClose={props.handleClose}
    >
      {getStepContent(activeStep)}
    </Dialog>
  );
};

const mapState = (state) => ({
  taxesTypes: state.taxes.taxesTypes,
  cuit: state.auth.cuit,
  thirdPartObjects: state.payments.thirdPartObjects,
});

const mapDispatch = (dispatch) => ({
  addJudgment: (judgment) => dispatch.payments.addJudgment(judgment),
  addThirdPartObject: (object) => dispatch.payments.addThirdPartObject(object),
});

export default connect(mapState, mapDispatch)(ThirdPartTaxesForm);
