import React from "react";
import Button from "../../Inputs/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import _ from "lodash";
const ModalErrors = (props) => {
  //   const [open, setOpen] = React.useState(false);

  const formatedForm = props.formulario?.steps?.reduce((allInputs, step) => {
    return [
      ...allInputs,
      ...step?.inputGroups?.reduce((inputsInGruops, group) => {
        return [
          ...inputsInGruops,
          ...group?.inputs?.reduce((inputs, input) => {
            return [
              ...inputs,
              {
                inputname: input?.name,
                inputGoup: group?.name,
                inputStep: step?.titleStep,
                inputLabel: input?.label,
              },
            ];
          }, []),
        ];
      }, []),
    ];
  }, []);

  const handleClose = () => {
    props.handleClose(false);
  };

  const getInputByGroup = (groupName) => {
    return formatedForm.find((inpt) => inpt.inputGoup === groupName);
  };
  const getInputByGroupAndInput = (groupName, inputName) => {
    return formatedForm.find(
      (inpt) => inpt.inputGoup === groupName && inputName === inpt.inputname
    );
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        El formulario contiene errores
      </DialogTitle>
      <DialogContent>
        {Object.keys(props.errors)?.map((k, i) => (
          <React.Fragment key={k + "-" + i}>
            <div>
              En el paso <b>{getInputByGroup(k)?.inputStep}</b> grupo <b>{k}</b>
            </div>

            <ul>
              {Array.isArray(props.errors[k]) ? (
                <>
                  {props.errors[k].map((reg, idx) => (
                    <React.Fragment key={k + "sub" + idx}>
                      {/* <span> {idx}</span> */}
                      <ul>
                        {Object.keys(props.errors[k][idx])?.map((ik) => {
                          return (
                            <li key={idx + ik}>
                              El campo{" "}
                              <b>
                                {" "}
                                {getInputByGroupAndInput(k, ik)?.inputLabel +
                                  " "}
                              </b>
                              {props.errors[k][idx][ik]}
                            </li>
                          );
                        })}
                      </ul>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  {Object.keys(props.errors[k])?.map((ik) => {
                    return (
                      <li key={ik}>
                        El campo{" "}
                        <b>
                          {" "}
                          {getInputByGroupAndInput(k, ik)?.inputLabel + " "}
                        </b>{" "}
                        {props.errors[k][ik]}
                      </li>
                    );
                  })}
                </>
              )}
            </ul>
          </React.Fragment>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant={"contained"}
          autoFocus
        >
          Corregir
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalErrors;
