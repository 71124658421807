import axios from 'axios';
import CMSAuthServices from './CMSAuthServices';
import CMSConfigsServices from './CMSConfigsServices';
import CMSTaxesServices from './CMSTaxesServices';
import CMSLanguagesServices from './CMSLanguagesServices';
import CMSTagsServices from './CMSTagsServices';
import CMSObjectsActionsServices from './CMSObjectsActionsServices';
import CMSUserMenuServices from "./CMSUserMenuServices";
import CMSExpirationsServices from "./CMSExpirationsServices";
import CMSProceduresServices from "./CMSProceduresServices";
import CMSCarrouselTabsServices from "./CMSCarrouselTabsServices";
import CMSAppointmentsServices from './CMSAppointmentsServices';

const instance = axios.create({
    baseURL: window.config.apiBase.cms
    });

const setToken = (jwt) => {instance.defaults.headers.common['Authorization'] =  'Bearer ' + jwt};    

const apiCMS = {
    AuthServices: CMSAuthServices(instance),
    ConfigServices: CMSConfigsServices(instance),
    TaxesServices: CMSTaxesServices(instance),
    LanguagesServices: CMSLanguagesServices(instance),
    TagsServices: CMSTagsServices(instance),
    ObjectsActionsServices: CMSObjectsActionsServices(instance),
    UserMenuServices: CMSUserMenuServices(instance),
    setToken: setToken,
    ExpirationsServices: CMSExpirationsServices(instance),
    ProceduresServices: CMSProceduresServices(instance),
    CarrouselTabsServices: CMSCarrouselTabsServices(instance),
    AppointmentsServices: CMSAppointmentsServices(instance)
}
export default apiCMS;