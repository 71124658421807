import React, {useEffect} from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {Container} from "@material-ui/core";
import {makeStyles, createStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Button from '@material-ui/core/Button'

import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import {Icon} from "@material-ui/core";
import StopAppointmentButton from "../../Shell/HeaderForAgent/StopAppointmentButton";

const useStyles = makeStyles((theme) =>
    createStyles({
        welcome: {
            fontWeight: 400,
            padding: "25px",
        },
        toolbar: {
            background: theme.colores.main,
            borderTop: `solid 1px ${theme.colores.contrast + '55'}`
        },
        formContainer: {
            padding: "20px",
        },
        formContainerAtendiendo: {
            padding: "20px",
            justifyContent: "space-between",
        },

        textInput: {
            color: theme.colores.contrast + "!important",
            width: "100%",
            padding: "0 10px",
            "& fieldset": {
                borderColor: theme.colores.contrast + "!important",
            },
        },
        iconColor: {color: theme.colores.contrast},
        gridBscar: {
            display: "flex",
        },
        Disabled: {
            color: theme.colores.contrast,
        },
        BtnBuscar: {
            color: theme.colores.contrast,
            padding: "10px 30px",
            borderColor: theme.colores.contrast,
            "&$Disabled": {
                color: theme.colores.contrast,
                opacity: ".8",
            },
        },
        BtnDetener: {
            color: theme.colores.contrast,
            padding: "10px 10px",
            borderColor: theme.colores.contrast,
            "&$Disabled": {
                color: theme.colores.contrast,
                opacity: ".8",
            },
        },
        formControl: {
            borderColor: theme.colores.contrast,
            width: "100%",
        },
        selectLabel: {
            color: theme.colores.contrast + " !important",
            background: theme.colores.main,
            padding: "0 3px",
        },
        select: {
            "& fieldset": {
                borderColor: theme.colores.contrast + " !important",
            },
            color: theme.colores.contrast,
        },
        Nombre: {
            fontSize: "20px",
            lineHeight: "20px",
        },
        EnRepresentacion: {
            display: "flex",
            flexDirection: "row",
        },
        Clock: {
            fontSize: "20px",
            lineHeight: "20px",
            margin: " 0 5px",
        },

        tiempo: {
            padding: "15px",
        },
    })
);
const AgentHeader = (props) => {
    const classes = useStyles();
    const [tipoAtencion, setTipoAtencion] = React.useState("0");
    const [tiempoAtencion, setTiempoAtencion] = React.useState(0);
    const [cuit, setCuit] = React.useState("");

    const tick = () => {
        setTiempoAtencion(Date.now() - props.atencion.horaInicio);
    };

    const availableSubContactTypes = props.subContactTypes.filter(subContactType => subContactType.contact_type.showInAgentDashboard);

    useEffect(() => {
        if (props.currentSubContactType == null) {
            props.setCurrentSubContactType(availableSubContactTypes[0]);
        } else {
            if (availableSubContactTypes[0].id !== props.currentSubContactType.id) {
                setTipoAtencion((props.currentSubContactType.id - 1).toString());
            }
        }

        let timerID;
        if (props.atencion) {
            timerID = setInterval(() => tick(), 1000);
        }

        return () => {
            clearInterval(timerID);
        };
    }, [props.atencion]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTipoAtencion(event.target.value as string);
        props.setCurrentSubContactType(availableSubContactTypes[parseInt(event.target.value as string)]);
    };

    const handleTextChange = (event) => {
        setCuit(event.target.value as string);
    };

    const keyPress = (event) => {
        if (event.which == 13 && cuit.length >= 10) {
            props.handleSearch(cuit);
        }
    };

    return (
        <AppBar className={classes.toolbar} position="static">
            <Container>
                <Toolbar>
                    {props.atencion ? (
            <Grid
              container
              spacing={1}
              className={classes.formContainerAtendiendo}
            >
              <Grid item>
                <div>
                  <i>
                                        {`Estas atendiendo  ${
                                            props.atencion.turno
                                                ? "el turno " + props.atencion.turno
                        : " a "
                    }`}
                  </i>
                  {/* {props.atencion.turno? <span>{`turno: ${props.atencion.turno}`}</span>:null} */}
                  <br />
                  <b className={classes.Nombre}>
                     {props.atencion.Persona.denominacion}
                  </b>
                  <br />
                  <i>{props.atencion.Persona.cuit}</i>
                  
                </div>
                            </Grid>
                            <Grid item>
                                {props.atencion.enRepresentacion ? (
                  <div className={classes.EnRepresentacion}>
                    <div>
                      <i> {"En representacion de"}</i>
                      <br />
                      <b className={classes.Nombre}>
                        {" "}
                        {props.atencion.Representado.denominacion}
                      </b>
                      <br />
                      <i>{props.atencion.Representado.cuit}</i>
                    </div>
                    <IconButton onClick={props.changeRepresentation}>
                                            <Icon className={classes.iconColor}>sync_alt</Icon>
                                        </IconButton>
                                    </div>
                                ) : (
                                    <div className={classes.tiempo}>
                    <Button
                      className={classes.BtnBuscar}
                      onClick={props.changeRepresentation}
                      variant="outlined"
                    >
                      Actua en representacion
                    </Button>
                  </div>
                                )}
                            </Grid>
                            <Grid item>
                                <div className={classes.tiempo}>
                                    AgentHeader
                                    <StopAppointmentButton
                                        classes={classes}
                                        {...props}
                                        bold={true}/>
                                </div>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2} className={classes.formContainer}>
                            <Grid item xs={6} md={2}>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel
                                        className={classes.selectLabel}
                                        id="demo-simple-select-label"
                                    >
                                        Estoy atendiendo
                                    </InputLabel>
                                    <Select
                                        disabled={props.isLoading}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={tipoAtencion}
                    className={classes.select}
                    onChange={handleChange}
                  >
                    {props.subContactTypes.filter( subContactType => subContactType.contact_type.showInAgentDashboard ).map( (subContactType, index) => <MenuItem key={index} value={index}>{subContactType.name_ES}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>

                            <Grid item xs={12} md={8}>
                                <FormControl className={classes.formControl} variant="outlined">
                                    <InputLabel
                                        className={classes.textInput}
                                        htmlFor="outlined-adornment-password"
                                    >
                                        Cuit
                                    </InputLabel>
                                    <OutlinedInput
                                        disabled={props.isLoading}
                                        className={classes.textInput}
                                        id="outlined-basic"
                                        label="Cuit del contribuyente"
                                        onChange={handleTextChange}
                                        onKeyDown={keyPress}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <Button
                                                    disabled={props.isLoading || cuit.length < 10}
                                                    className={`${classes.BtnBuscar} ${
                                                        props.isLoading ||
                                                        (cuit.length < 10 && classes.Disabled)
                                                    }`}
                                                    onClick={() =>
                                                        cuit.length >= 10 && props.handleSearch(cuit)
                                                    }
                        >
                          {props.isLoading ? (
                            <>
                              <CircularProgress size={24} />
                            </>
                          ) : (
                            <>
                              <SearchIcon />
                            </>
                          )}
                        </Button>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={2} className={classes.gridBscar}>
                                <Button
                                    disabled={props.isLoading}
                                    className={classes.BtnBuscar}
                                    variant="outlined"
                                    onClick={() => props.getTurno()}
                >
                  {props.isLoading ? (
                    <>
                      <CircularProgress size={24} />
                      Buscando
                    </>
                  ) : (
                    <>
                      Siguiente <SkipNextIcon />
                    </>
                  )}
                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const mapState = (state) => ({
    atencion: state.auth.atencion,
    taxRole: state.auth.taxRole,
    subContactTypes: state.contacts.subContactTypes,
    currentSubContactType: state.contacts.currentSubContactType
});

const mapDispatch = (dispatch) => ({
    setAtencion: (atencion) => dispatch.auth.setAtencion(atencion),
    setLocale: (locale) => dispatch.auth.setLocale(locale),
    setMenu: (menu) => dispatch.auth.setMenu(menu),
    setUserMenu: (menu) => dispatch.auth.setUserMenu(menu),
    setCurrentSubContactType: (subContactType) => dispatch.contacts.setCurrentSubContactType(subContactType),
    resetAtencion: () => dispatch.auth.resetAtencion(),
});

export default connect(mapState, mapDispatch)(AgentHeader);
