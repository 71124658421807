import React from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  formcontrol: {
    //margin: "20px 20px 40px",
    display: "block",
    minWidth: "140px",
    margin: 0,
  },
  dropzone: {
    background: "red",
  },
  image: {
    maxWidth: "100%",
  },
  invalidImg: {
    background: "#ddd",
    maxWidth: "100%",
    textAlign: "center",
    // minHeight: 120px,
    display: "grid",
    alignItems: "center",
  },
  inputfield: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  },
  fileActions: { display: "flex", justifyContent: "center" },
  inputlabel: {
    fontSize: "1.25em",
    fontWeight: 700,
    color: "white",
    backgroundColor: "#d4d4d4",
    background:
      "linear-gradient(140deg, #e0e0e0 25%, #d4d4d4 25%, #d4d4d4 50%, #e0e0e0 50%, #e0e0e0 75%, #d4d4d4 75%, #d4d4d4 100%)",
    backgroundSize: "31.11px 26.11px",
    width: "140px",
    height: "140px",
    padding: "5px",
    textAlign: "center",
    borderRadius: "5px",
    cursor: "pointer",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    border: "dashed #9e9a9a",
    transition: "all 300ms",
    "&:hover": {
      transition: "all 300ms",
      border: "dashed #444",
      "& $fileInputLabel": {
        color: "#444",
        transition: "all 300ms",
      },
      "& ~ $imgeButtons": {
        display: "flex",
      },
    },
  },
  fileInputContainer: { width: "140px", position: "relative", margin: "auto" },
  fileInputLabel: {
    transition: "all 300ms",
    color: "#9e9a9a",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
  },
  fileInputImage: {
    maxWidth: "100%",
  },
  imgeButtons: {
    background: "#4a4a4a36",
    height: "100%",
    top: "50%",
    zIndex: 9999,
    position: "absolute",
    display: "none",
    flexDirection: "row",
    justifyContent: "center",
    transform: "translate(0, -50%)",

    width: " 100%",
    "&:hover": {
      display: "flex",
    },
  },
  showImgOptions: {
    position: "relative",
  },
  outputField: {
    color: "#444",
    "&  label.Mui-disabled": {
      color: "#666",
    },
    "& input": {
      color: "#444",
    },
  },
  margin: {
    margin: "5px auto 10px",
  },
  input: {
    width: 42,
  },
  inputSlider: {
    margin: "-5px 10px",
    padding: "5px 20px",
    background: "#eee",
  },
  cuotaseIntereses: {
    position: "relative",
    "&::before": {
      content: "' '",
      position: "absolute",
      top: 50,
      bottom: 40,
      width: "3px",
      left: "10px",
      background: "#aaa",
    },
    "& >div": {
      background: "white",
    },
  },
  breakpoint: {},
  interes: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "10px",
    paddingLeft: "5px",
    background: "#eee",
  },
  switch: {
    margin: "auto",
  },
  selects: {
    //th:'100%'
  },
  Text: {
    //width:'100%'
  },
  textView: {
    textAlign: "center",
  },
  switchForm: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  switchLabel: {
    textTransform: "capitalize",
    color: "#555",
  },
  heading: {
    fontSize: "15px",
    flexBasis: "33.33%",
    flexShrink: 0,
    justifyContent: "space-between",
  },
  secondaryHeading: {
    fontSize: "15px",
  },
  table: {
    minWidth: 650,
  },
  collapseHeader: {
    justifyContent: "space-between",
    display: "flex",
    flex: "1",
  },
  "@keyframes loading": {
    "0%": {
      left: "-12%",
    },

    "100%": {
      left: "112%",
      // width: '100%',
    },
  },
  loading: {
    background: "#f0f0f0",
    position: "relative",
    overflow: "hidden",
    "&::after": {
      top: "0",
      left: "0",
      bottom: "0",
      content: "' '",
      position: "absolute",
      background: "#ffffff",
      borderRadius: "3px",
      opacity: ".51",
      width: "30px",
      transform: " skewX(-27deg)",
      animation: "$loading 3s linear infinite",
    },
  },
  paddingSmall: {
    padding: 0,
    "& input": {
      padding: "11px 10px 10px",
    },
  },
  tableView: {
    "& >div": {
      margin: 0,
    },
  },
});
