import React, { useEffect, Children } from "react";
import MaterialTable from "material-table";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useIntl, FormattedDate } from "react-intl";
import { Container } from "@material-ui/core";
import Paper from '../../common/MaterialComponents/Paper'
 



const Table = (props) => {
  const [searcheables, setSearcheables] = React.useState([]);
  const intl = useIntl();
  const getColumnsSearchables = () => {
    let colum = [];
    props.columnas.forEach((element) => {
      if (element.searchable != false) colum.push(element.field);
    });
    setSearcheables(colum);
  };

  const searchItems = (data) => {
    if(!props.filter){return data};
    return data.filter(
      (el) =>
        JSON.stringify(el, searcheables)
          .toLowerCase()
          .indexOf(props.filter.toLowerCase()) > -1
    );
  };
  
  
  useEffect(() => getColumnsSearchables(), []);
  return (
    <MaterialTable 
    
      columns={props.columnas}
      data={searchItems(props.Data)}
      actions={
        props.acciones ? 
        [...props.acciones]: []}
      localization={{
        body: {
          emptyDataSourceMessage: props.mensajeVacio,
        },
        header: {
          actions: intl.formatMessage({
            id: "Table.header.actions",
            defaultMessage: "acciones",
          }),
        },
        pagination: {
          labelRowsSelect: intl.formatMessage({
            id: "Table.pagination.labelRowsSelect",
            defaultMessage: "Filas",
          }),
          labelDisplayedRows:"{from}-{to}" 
          +intl.formatMessage({
            id: "Table.pagination.labelDisplayedRows",
            defaultMessage: " de ",
          })+
           "{count}",
          firstTooltip: intl.formatMessage({
            id: "Table.pagination.firstTooltip",
            defaultMessage: "Primera",
          }),
          previousTooltip: intl.formatMessage({
            id: "Table.pagination.previousTooltip",
            defaultMessage: "Anterior",
          }),
          nextTooltip: intl.formatMessage({
            id: "Table.pagination.nextTooltip",
            defaultMessage: "Siguiente",
          }),
          lastTooltip: intl.formatMessage({
            id: "Table.pagination.lastTooltip",
            defaultMessage: "Ultima",
          }),
        },
      }} 
      components={{...props.components,Container:Paper}}
      onRowClick={props.rowClick ? (event, row) => props.rowClick(row):null}
      options={{
        toolbar: false,
        actionsColumnIndex: -1,
        ...props.options
      }
    }
    />
  );
};

export default Table;
