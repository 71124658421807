import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Moment from "react-moment";
import "moment/locale/es";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import clsx from "clsx";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { FormattedMessage } from "react-intl";
import Button from "../../common/MaterialComponents/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  Link: {
    "&:hover": {
      color: "white",
    },
    color: "white",
  },
  row: {
    "&:hover": {
      background: "#eee",
    },
  },
  cell: {
    background: "transparent",
  },
  cellDate: {
    background: "transparent",
    width: "155px",
  },
  cellLarge: {
    width: "75%",
  },
  table: {
    margin: "auto",
    width: "70%",
    background: "#f9f9f9",
  },
  header: {
    background: "#ddd",
  },
  stepsStyle: {
    fontWeight: 400,
    textAlign: "center",
    fontSize: "12px",
  },
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#784af4",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

export default function ApplicationMoves(props) {
  const moves = Array.isArray(props.moves) ? props.moves : [];
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(1);
  const history = useHistory();
  const handleClickComplete = (rowData) => {
    history.push({
      pathname:
        "/detalle-tramite/" +
        JSON.stringify({
          tramiteId: props.app.id,
          paramTramiteId: props.app.tramite.id,
          pasoId: rowData.proceso,
          jobKey: props.app.jobKey,
          readOnly: true,
        }),
    });
  };
  const actionContent = (rowData, index) => {
    if (rowData.instancia_id && index + 1 < moves.length) {
      return (
        <Button
          color="primary"
          size={"small"}
          onClick={() => handleClickComplete(rowData)}
        >
          <FormattedMessage
            id={"applicationMoves.showStep"}
            defaultMessage="VER"
          />
        </Button>
      );
    }
    if (rowData.instancia_id && index + 1 === moves.length) {
      return (
        <Typography>
          <FormattedMessage
            id={"applicationMoves.inProcess"}
            defaultMessage="En proceso"
          />
        </Typography>
      );
    }
  };
  if (props.table) {
    return (
      <Table size="small" className={classes.table} aria-label="simple table">
        <TableHead className={classes.header}>
          <TableRow>
            <TableCell size="small" align="center">
              #
            </TableCell>
            <TableCell size="small">
              <FormattedMessage
                id={"userApplication.detail.moves.date"}
                defaultMessage="Fecha"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id={"userApplication.detail.moves.detail"}
                defaultMessage="Detalle"
              />
            </TableCell>
            <TableCell size="small" align="center">
              <FormattedMessage
                id={"applicationMoves.actions"}
                defaultMessage="Acciónes"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {moves.map((row, i) => (
            <TableRow className={classes.row} key={i}>
              <TableCell
                className={classes.cell}
                component="td"
                scope="row"
                align="center"
                size="small"
              >
                {row.instancia_id}
              </TableCell>
              <TableCell size="small" className={classes.cellDate}>
                <Moment locale="es" format="DD MM YYYY hh:mm">
                  {row.fecha}
                </Moment>
              </TableCell>
              <TableCell className={classes.cell}>
                {row.proceso.replace(/_/g, " ")}
              </TableCell>
              <TableCell className={classes.cell}>
                {actionContent(row, i)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  } else {
    return (
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {moves.map((row) => (
          <Step key={row.id}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <div className={classes.stepsStyle}>
                <b>
                  {row.resuelto ? (
                    <Moment locale="es" format="l">
                      {row.date}
                    </Moment>
                  ) : null}
                </b>
                <br />
                {row.description}
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
}
