import React, { useEffect } from "react";
import {
  Carousel,
  SectionPayments,
  SectionTramites,
  SectionVencimientos,
  SectionFooter,
  CallToAnAction
} from "../../../components/PublicPage";

const IndexComponent = (props) => {
  return (
    <div style={{    paddingBottom: '140px'}}>
      <Carousel />
      <SectionPayments />
      <SectionVencimientos />
      <CallToAnAction/>
      <SectionTramites />
      
    </div>
  );
};

export default IndexComponent;
