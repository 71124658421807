import React from 'react';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import { Link } from 'react-router-dom';
import { objectColor,EobjectType} from '../../../../models/IObjects';

const useStyles = makeStyles({
    link: {
        textDecoration: "none",
        backgroundColor: "transparent",
        margin: "5px 10px",
        '&:hover':{
            textDecoration:'none',
            

        }
    },
    Btn: {
       
    },
    Paper: {
        display:"flex",
    minHeight:"180px",
    minWidth:"182px",
    textAlign:"center",
    flexDirection:"column",
    flex:"1",
    justifyContent:"center",
    margin:"auto",
    
    },
    icon: {

       fontSize:'40px',
       margin: '0 auto 20px',


    },
});

const ObjectButton = (props) => {
    const classes = useStyles();
    const [elevation, setElevation] = React.useState(1);
    const _onMouseOver= () =>{
        setElevation(10);
    }
    const _onMouseOut =() =>{
        setElevation(1);
    }
    return (
        <Link       onMouseOver={_onMouseOver}
        onMouseOut={_onMouseOut} className={classes.link} to={props.obj.link + '/' + props.obj.code}>
        <ButtonBase focusRipple className={classes.Btn}>
           <Paper elevation={elevation} className={classes.Paper} >
               <Icon style={{color:objectColor[EobjectType[ props.obj.code]] }} className={classes.icon}>
               {props.obj.icon}
               </Icon>
               {props.obj.Description}
           </Paper >

        </ButtonBase></Link>
    );
  };
  
  export default ObjectButton;