import React from "react";
import AgentAction from "../AgentAction";
import Grid from "@material-ui/core/Grid";
import { FormattedMessage } from "react-intl";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lessMargin: {
      marginTop: "-40px",
      minWidth:'300px'
    },
    title:{    
      fontWeight: 100,
      padding:'10px 0 5px',}
  })
);
const AgentActions = (props) => {
  const classes = useStyles();

  return (
    <>
      <div>
        {props.actions && props.actions.map(
          (el, idx) =>
            !el.externalContent && <AgentAction key={idx} action={el} />
        )}
      </div>
      <Grid item xs={12}>
              <Typography variant='h5' className={classes.title}>
              <FormattedMessage
                id="agentDashboard.titlde"
                defaultMessage="Aplicaciones administrativas"
              /></Typography>
        {props.actions && props.actions.map(
          (el, idx) =>
            el.externalContent && <AgentAction key={idx} action={el} />
        )}
      </Grid>
    </>
  );
};
export default AgentActions;
