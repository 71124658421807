const ProceduresServices = (instance) => {
  const queryparams = window.config.spectedJWT
    ? Object.keys(window.config.spectedJWT)?.reduce(
        (acc, k) =>
          acc +
          "&" +
          k.toString() +
          "=" +
          window.config.spectedJWT[k].toString(),
        ""
      )
    : null;
  const getProcedure = (id: string) => instance.get(`tramites/${id}`);
  const postCompletarTarea = (tramiteId, json, isDraft = false) =>
    instance.post(
      `/tramites/${tramiteId}/completar` +
        (queryparams
          ? "?" + queryparams + "&isDraft=" + isDraft
          : "?isDraft=" + isDraft),
      json
    );
  const putEnriquecer = (tramiteId, values) =>
    instance.put(
      `${window.config.apiBase.buc}tramites/${tramiteId}/enriquecer`,
      values
    );

  return {
    getProcedure,
    postCompletarTarea,
    putEnriquecer,
  };
};
export default ProceduresServices;
