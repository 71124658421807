import React from "react";
import {
  Alert as AlertLab,
  AlertTitle as AlertLabTitle,
} from "@material-ui/lab";
import Button from "@material-ui/core/Button";

import { EAlertType, alertICon } from "../../../models/IAlerts";

interface AlertProps {
    severity: 'success' | 'info' | 'warning' | 'error';
    title: string | React.ReactNode;
    message: string | React.ReactNode;
    action?: any;
    actionLabel?:string | React.ReactNode;
}

const Alert = (props:AlertProps) => {
  const icon = alertICon[EAlertType[props.severity]];

  return (
    <AlertLab
      icon={<img src={icon} />}
      variant="outlined"
      severity={props.severity}

      action={props.action?
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={props.action}
        >
        {props.actionLabel}
        </Button>:null}
      
    >
    
      <AlertLabTitle>{props.title}</AlertLabTitle>
      {props.message}
    </AlertLab>
  );
};
export default Alert;