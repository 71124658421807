import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {  useHistory } from "react-router-dom";
import NumberCurrency from "../../common/NumberCurrency/NumberCurrency";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core/styles";
//import ObligationCheckbox from "../ObligationCheckbox/ObligationCheckbox";
import {
  obligationStateCodes,
  obligationStates,
} from "../../../constants/obligationStates";
import apiCMS from "../../../services/CMSServices";
import { FormattedMessage } from "react-intl";
import Button from "../../../components/common/MaterialComponents/Button";
import Link from "../../../components/common/MaterialComponents/Link";
const useStyles = makeStyles({
  oblBtn: {
    display: "inline-block",
    marginTop: "5px",
  },
  oblInput: {
    width: "130px ",
    marginLeft: "20px",
  },
  oblCheckbox: {
    minWidth: "40px",
  },
});

const ExpirationRightDetail = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [pagoAcuenta, setPagoAcuenta] = React.useState(0);

  const linkToDdjj = [
    obligationStateCodes[obligationStates.PRESENTADA],
    obligationStateCodes[obligationStates.SIN_PRESENTAR],
  ].includes(props.state.code);

  const totalToPay = props.obligation.exenta
    ? (props.obligation.saldoConRecargo *
        (100 - props.obligation.porcentaje_exencion)) /
      100
    : props.obligation.saldoConRecargo;

  const capitalToPay = props.obligation.exenta
    ? (props.obligation.saldo * (100 - props.obligation.porcentaje_exencion)) /
      100
    : props.obligation.saldo;

  const obligationStateAction = props.obligationStateActions.filter(
    (stateAction) =>
      stateAction.obligation_state.code === props.obligation.estado
  );

  const obligationActions =
    (obligationStateAction &&
      obligationStateAction.map((obStaAct) => obStaAct.obligation_action)) ||
    [];

  const handleChange = (e) => {
    const val = e.target.value ? e.target.value : 0;
    if (val === 0) {
      props.setAllObligations(
        props.selectedObligations.filter(
          (elem) => props.obligation.id === elem.id
        )
      );
    }
    setPagoAcuenta(val);
  };

  const partialPay = () => {
    return props.state.partialPay ? (
      <TextField
        className={classes.oblInput}
        inputProps={{ min: 0 }}
        InputProps={{
          type: "number",
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        onChange={handleChange}
        type="number"
        label="pago a cuenta"
        variant="outlined"
        size="small"
      />
    ) : null;
  };

  const renderActions = () =>
    obligationActions.map((obAct) => {
      const getTramiteId = () => {
        return (obAct.json && obAct.json.tramiteId) || null;
      };
      const handleClickAction = () => {
        history.push({
          pathname: obAct.url,
          state: { id: getTramiteId(), detail: props.obligation.objeto_id },
        });
      };
      return linkToDdjj ? (
        <Button color="primary" variant="text" onClick={handleClickAction}>
          {obAct["name_" + props.locale.toUpperCase()]}
        </Button>
      ) : null;
    });

  const disableCheck = () => {
    if (linkToDdjj) {
      return !props.state.pay && !pagoAcuenta && props.state.partialPay;
    }
    return !props.state.pay;
  };

  const setObligation = () => {
    props.clearObligations();
    props.modifyObligation(props.obligation, true);
  };

  const showCapitalDebt = () => {
    return props.showCapitalDebt ? (
      <small>
        <NumberCurrency value={capitalToPay.toFixed(props.config.decimals)} />
      </small>
    ) : null;
  };

  const showTotalToPay = () => {
    return totalToPay && !props.state.partialPay ? (
      <b>
        <NumberCurrency value={totalToPay.toFixed(props.config.decimals)} />
      </b>
    ) : null;
  };

  return (
    <>
      <div className="col-auto">
        {renderActions()}
        {showTotalToPay()}
        {partialPay()}
        <span style={{ display: "block", height: "1%" }}></span>
        {showCapitalDebt()}
      </div>
      <div className="col-auto">
        {props.state.disabled ? null : (
          <Link to="/payment-methods">
            <Button
              onClick={setObligation}
              variant="outlined"
              size="small"
              color="primary"
            >
              <FormattedMessage
                id="myTaxes.payButton.link"
                defaultMessage="Pagar "
              />
            </Button>{" "}
          </Link>
        )}
      </div>
    </>
  );
};
// obligation={props.obligation}

const mapDispatch = (dispatch) => ({
  clearObligations: () => dispatch.payments.clearObligations(),
  modifyObligation: (checkedObligation, isCheked) =>
    dispatch.payments.modifyObligation(checkedObligation, isCheked),
  setAllObligations: (obligationList) =>
    dispatch.payments.setAllObligations(obligationList),
});

const mapState = (state) => ({
  config: state.auth.config,
  locale: state.auth.locale,
  showCapitalDebt: state.payments.showCapitalDebt,
  selectedObligations: state.payments.selectedObligations,
  formList: state.applications.formList,
  obligationStateActions: state.payments.obligationStateActions,
});

export default connect(mapState, mapDispatch)(ExpirationRightDetail);
