import React, { useEffect, useState, Fragment } from 'react';
import { Icon } from '@material-ui/core';
import { Markdown } from 'react-showdown';
import { connect } from 'react-redux';

const TaxIcon = (props) => {
    const [tax, setTax] = useState(null);

    useEffect(() => {
        setTax(props.taxesTypes.find((t) => t.code === props.type));
    }, []);

    if(!tax)
    {
        return null;
    }
    else
    {    
        return <Fragment ><Icon color={tax.color === "default" ? 'primary' : tax.color}>{tax.icon}</Icon><Markdown color={tax.color === "default" ? 'primary' : tax.color}  markup={ tax['richtext_' + props.locale.toUpperCase()] } /></Fragment>;
    }

}

const mapState = state => ({
    taxesTypes: state.taxes.taxesTypes,
    locale: state.auth.locale,
  })

export default connect(mapState,null)(TaxIcon);