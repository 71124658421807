import React from "react";
import './styles.css';
export default class Footer extends React.Component {

    render() {
        return (
            <footer className="main-footer Footer" style={{textAlign: "center"}}>
                <div className="pull-right hidden-xs">
               Copyright &copy; 2020 <a href="#">SyK</a>. All rights reserved. <b>Version</b> 0.0.4
                </div>
                
            </footer>
        );
    }

}
