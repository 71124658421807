import React, { useEffect } from "react";
import RenderInputs from "../RenderInputs";
import { connect } from "react-redux";
import { useField } from "react-final-form";
import { InputGroup } from "../../models/IDynamicForm";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import _ from "lodash";
import {
  getUrl,
  getDependenciasByUrl,
  getValuesFromOtherGroup,
} from "../../Inputs/Operations";
import Axios, { Method } from "axios";
import { useContext } from "react";
import { ctxts } from "../../DynamicFormContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupH: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
    groupV: {
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      "& .field-input": {
        margin: "15px",
      },
    },
    buttonsContainer: {},
    button: {
      margin: "10px",
    },
  })
);

type Props = {
  values: any;
  group: InputGroup;
  push: any;
  pop: any;
};
const Grupo = (props) => {
  const classes = useStyles();
  const { values, group, push, pop } = props;
  const theme = useTheme();
  const context = useContext(ctxts);

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const field = useField(group.name);

  let dependencias = [];
  if (group.asyncData)
    getDependenciasByUrl(group.name, group.asyncConf.url, null, dependencias);

  useEffect(() => {
    if (group.asyncData) {
      setUrl(
        getUrl(
          group.name,
          group.asyncConf.url,
          props.cuit,
          values,
          group.asyncConf.JsonFieldsName,
          null,
          group.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [
    ...dependencias.map((d) => {
      const dependenciaValue = _.get(values, d);
      return dependenciaValue ? dependenciaValue : "";
    }),
  ]);

  const [initialValue, setInitialValue] = React.useState(values[group.name]);
  // let initialValue = null;
  //!TODO GET VALUES FROM OTHER GROUP
  useEffect(() => {
    getValuesFromOtherGroup(group, values, field);
  }, [_.get(values, group.otherGroupValues)]);

  //use efect para traer datos asincronos
  useEffect(() => {
    // TIENE DEPENDENCIA EN URL
    if (!props.isLastPaso) return;
    if (group.asyncData && url && !(url.includes("[") || url.includes("{"))) {
      setIsLoading(true);
      Axios({
        method: group.asyncConf.method as Method,
        url: url,
        headers: { Authorization: `Bearer ${props.jwt}` },
      })
        .then((response) => {
          setIsLoading(false);
          field.input.onChange(_.get(response, group.asyncConf.dataKey));
          // setInitialValue(response.data);
        })
        .catch(function (error) {
          setIsLoading(false);
          console.log(error.message);
        });
    }
  }, [url]); //se ejecuta solo si la url cambia

  return (
    <div
      className={
        group.show === "groupHorizontal" && !mobile
          ? classes.groupH
          : classes.groupV
      }
    >
      <RenderInputs
        values={values}
        name={group.name}
        group={group.name}
        loadingAsyncData={isLoading}
        inputs={group.inputs}
      />
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(Grupo);
