import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import ObligationList from '../../ObligationList';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles({
    Link: {
        "&:hover": {
            color: "white"
        },
        color: "white"
    },
    icon: {
        fontSize: '30px',
        color: 'green',
        textAlign: 'center'
    },
    iconerror:{
        fontSize: '30px',
        color: 'red',
        textAlign: 'center'
    }
});

function createData(id: number, tipo: string, inicio, ultimoMov, estado: string) {
    return { id, tipo, inicio, ultimoMov, estado };
}

const rows = [
    createData(1, 'alícuota', '20/09/2019 15:35 hs', '12/02/2020 10:00 hs', 'resuelto'),
];

export default function ModalExcluidas(props) {
    const classes = useStyles();
    return (

        <Dialog open={props.open}   fullWidth={true}
        maxWidth={'md'} onClose={props.handleClose}>
            <DialogTitle id="responsive-dialog-title">
              Obligaciones excluidas
            </DialogTitle>
            <DialogContent>
                 <ObligationList  obligationList={props.ObExcluidas} />
            </DialogContent>
            <DialogActions>
            
            <Button
                onClick={props.handleClose}
                variant="contained"
                color="primary"
                autoFocus
            >
                Aceptar
            </Button>
   
            
            </DialogActions>
        </Dialog>
    );
}
