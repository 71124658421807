import React from 'react';
import Message from '../../components/PaymentMethods/EndMessage';
import PlanToConfirm from '../../components/PaymentMethods/PaymentsPlan/PlanToConfirm';
import { Container } from '@material-ui/core';
import SeccionHeader from '../../components/common/SeccionHeader';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Footer from '../../components/PaymentMethods/PaymentsPlan/Footer';

const useStyles = makeStyles({
    btns:{
        display:'block',
        textAlign:'right',
        marginTop:'20px',
    },margin:{
        marginRight:'20px'
    },
    Link:{
        textDecoration:'none'
    },
    container : { 
minHeight:'75vh'
    }


});
const PlansConfirm = (props) => {
    let history = useHistory();
    const classes = useStyles();
    const [Loading, setLoading] = React.useState(true);
    const [Confirmed, setConfirmed] = React.useState(false);
    const [cantPlanes, setCantPlanes]=React.useState(3);


    const [Mensaje, setMensaje] = React.useState({
        msj: 'Estamos preparando el plan pago',
        subMsj: `Aguarde un instante`,
        type: 'loading',
        confirmedObjects:[],
        action: [],
        link: null
    });
    
    const handleClick =()=>{
         setTimeout(actualizarMsj,2000);
         setConfirmed(true);

   
}
const handleClickError =()=>{
    setTimeout(actualizarMsj2,2000);
    setConfirmed(true);


} 
function actualizarMsj2(){
    setMensaje(
    {
        msj: `Tuvimos un error, intente más tarde`,
        subMsj: `Error ${'codigo 124'} :`,
        type: 'error',
        confirmedObjects:[
       ],
        action: [
         {
            action: '/plan-payments',
            actionTxt: 'Volver a Planes',
            type:'primary'}
        ],
        link: null
    });
    setLoading(false);
}
    function actualizarMsj(){
        setMensaje(
        {
            msj: `Se generaron ${cantPlanes} planes de pagos`,
            subMsj: `Planes generados:`,
            type: 'success',
            confirmedObjects:[
                `Plan ${'planId'}`,
           `Plan ${'planIdvariable'}`
           ],
            action: [
             {
                action: '/my-taxes',
                actionTxt: 'Ir al inicio',
                type:'primary'}
            ],
            link: null
        });
        setLoading(false);
    }


    return(

   <div>
       {!Confirmed ?  
       <div >
        <Container className={classes.container}>
        <SeccionHeader title={'Revise y confirme los planes que va a generar'}>
            
        </SeccionHeader>
            <PlanToConfirm  />
            <PlanToConfirm />
            <PlanToConfirm />
            
        {/* para probar un mensaje de error */}
        
            <Button size="large" color="primary" variant='contained' onClick={handleClickError}>
               probar msj error
            </Button>

           
        </Container>
           <Footer onClickVolver={()=> {history.push('/plan-payments');}} onClick={handleClick} />
         
         </div>   :
        <Message  msj={Mensaje} loading={Loading} />
    }
    </div>);
}
export default PlansConfirm;