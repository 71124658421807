import React, {useEffect, useState} from 'react';
import ObligationCheckbox from "../ObligationCheckbox/ObligationCheckbox";
import { connect } from 'react-redux';
import './styles.css';
import { FormattedMessage } from 'react-intl';
import ObligationTags from '../ObligationTags';
import {obligationStateCodes, obligationStates} from "../../../../constants/obligationStates";
import {makeStyles} from "@material-ui/core/styles";
import {objectTypeCodes, objectTypes} from "../../../../constants/objectTypes";
import ObligationRightDetail from "../ObligationRightDetail";
import ObligationExpire from "../ObligationExpire";
import moment from "moment";
import apiCMS from "../../../../services/CMSServices";

const useStyles = makeStyles({
    ddjjDescripcion: {},
    ddjjBtn: {
        display: "inline-block",
        marginTop: "5px",
    },
    ddjjInput: {
        width: "130px ",
        marginLeft: "20px",
    },
    ddjjCheckbox: {
        minWidth: "40px",
    },
});

const Obligation = (props) => {
    const classes = useStyles();

    const expirationDate = moment(props.obligation.vencimiento).add(3, 'hours');
    const isExpired = moment(expirationDate.format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD'));

    if( !props.obligation.estado ){
        props.obligation.estado = obligationStateCodes[obligationStates.NONE]
    }

    const obligationState = props.obligationStates.find(state => state.code === props.obligation.estado);

    const obligationDescription = () => {
        return props.obligation.objeto_tipo === objectTypeCodes[objectTypes.ingresosBrutos] ?
            <strong className={classes.ddjjDescripcion}>
                - {obligationState.description}
            </strong>: null
    }
    if( !(props.obligation.cuota == 0 && isExpired) ) {
        return (
            <div className="row justify-content-end Obligacion" >
                <div className="col">
                    <div className="row">
                        <FormattedMessage id="myTaxes.installment" defaultMessage="Cuota" /> {props.obligation.cuota}
                        {obligationDescription()}
                        <span style={{ display: 'inline-block', width: '1%' }}></span>
                        <ObligationTags obligation={props.obligation}/>
                        <br/>
                    </div>
                    <div className="row">
                        <small><ObligationExpire expirationDate={expirationDate}/></small>
                    </div>
                </div>
                <ObligationRightDetail obligation={props.obligation}
                                       state={obligationState}
                                       config={props.config}/>
            </div>
        )
    }
    return null;
}
const mapState = state => ({
    config: state.auth.config,
    showCapitalDebt: state.payments.showCapitalDebt,
    obligationStates: state.payments.obligationStates
})

export default connect(mapState, null)(Obligation);