import React from 'react';
import { makeStyles } from "@material-ui/core/styles";


const iconAlert = require('../../../../assets/iconos/bottom-alert@1x.png')
const iconError = require('../../../../assets/iconos/bottom-error@1x.png')
const iconSuccess = require('../../../../assets/iconos/bottom-success@1x.png')

const useStyles = makeStyles({
    container: {
        width: '100%',
        position: 'relative',
        textAlign: 'center',

        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 0,
        border: 'dashed #ddd',
        padding: '10px',

    },
    line: {
        zIndex: -1,
        top: '50%',
        left: '40px',
        right: '40px',
        position: 'absolute',
        borderTop: ' solid 3px #c7c7c7',

    },
    button: {

        padding: '10px',
        background: '#eaeaea',
        display: 'inline-block',

    },
    iconSize: {
        fontSize: '70px',
    },
    iconborder: {
        margin: '10px',
        borderRadios: '100%',
        border: ' solid 3px #c7c7c7',
    },
    Msj: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'left',
        margin: ' 0 20px',
    },
    img: {
        padding: '0 20px',
        maxHeight: '90px',
        marginBottom: '10px',
    },
    title: {
        fontFamily: 'roboto',
        fontWeight: 400,
        margin: 0,
    },
    text: {
        fontWeight: 100,
        fontSize: '20px'
    },
    btn: {
        marginTop: '10px'
    }
});

const NoTaxes = (props) => {
    let Icon = iconAlert;
    if( props.icon == 'success'){
        Icon = iconSuccess;
    }else if (props.icon == 'error'){
        Icon = iconError
    }else if (props.icon == 'alert'){
        Icon = iconAlert
    }

    const classes = useStyles();
    return (
        <div className={classes.container}>
            <img className={classes.img} src={Icon} alt="alerta" />
            <div className={classes.Msj}>
                <h4 className={classes.title}>{props.msj}</h4>
               </div>
        </div>
    );
}
export default NoTaxes;