import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Partida from "../../components/PaymentMethods/PaymentsPlan/Partida";
import Button from "@material-ui/core/Button";
import { Alert, AlertTitle } from "@material-ui/lab";
import ModalExcluidas from "../../components/PaymentMethods/PaymentsPlan/ModalExcluidas";
import { Container } from "@material-ui/core";
import Footer from "../../components/PaymentMethods/PaymentsPlan/Footer";
import Confirm from "../../components/PaymentMethods/PaymentsPlan/Confirm";
import MoreObligations from "../../components/PaymentMethods/PaymentsPlan/MoreObligations";
import AddObligations from "../../components/PaymentMethods/PaymentsPlan/AddObligations";
import LoadingComponent, {
  FalsoTexto,
} from "../../components/common/LoadingComponent";

import PaymentPlansServices from "../../services/PaymentPlansServices";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";

let icon = require("../../assets/iconos/center-alert@1x.png");

const useStyles = makeStyles({
  root: {
    width: 250,
  },
  input: {
    width: 50,
  },
  seccion: {
    margin: "25px 0px 0px",
  },
  panel: {
    background: "white",
  },
  marginbtn: {
    marginBottom: "90px",
  },
  load: { flex: 1, margin: "30px 20px", borderRadius: "10px", width: "30%" },
});

const PaymentsPlan = (props) => {
  const [open, setOpen] = React.useState(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [addIsLoading, setAddIsLoading] = React.useState(false);
  const [SimulationResponse, setSimulationResponse] = React.useState(null);
  const [oblAgregables, setOblAgregables] = React.useState(null);
  const [State, setState] = React.useState("createplan");
  const classes = useStyles();
  let history = useHistory();

  useEffect(() => {
    props.clearObligationsForSimulation();
    setSimulationResponse(props.simulation);
    setIsLoading(false);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleAdd = () => {
    setIsLoading(true);
    setAddOpen(false);

    props.obligationsForSimulation.forEach( obligation => {
      props.modifyObligation(obligation, true)
    });
    PaymentPlansServices()
            .simulate(
                props.selectedObligations.map((o) => {
                let saldo = o.saldo;
                let saldoConRecargo = o.saldoConRecargo;
                if (o.exenta) {
                    saldo = (o.saldo * (100 - o.porcentaje_exencion)) / 100;
                    saldoConRecargo =
                    (o.saldoConRecargo * (100 - o.porcentaje_exencion)) / 100;
                }
                return {
                    periodo: o.periodo,
                    cuota: o.cuota,
                    impuesto: o.impuesto,
                    concepto: o.concepto,
                    vencimiento: o.vencimiento,
                    prorroga: o.prorroga,
                    saldo,
                    saldoConRecargo,
                    id: o.id,
                    sujeto_id: o.sujeto_id,
                    objeto_id: o.objeto_id,
                    objeto_tipo: o.objeto_tipo,
                };
            }), props.jwt)
              .then((response) =>{
                props.setSimulation(response.data);
                setTimeout(function () {
                    window.location.reload()
                }, 2000);
              })
  };

  const handleRemove = (index) => {
    setIsLoading(true);
    props.clearObligationsForSimulation();
    props.clearSimulation();
      let oblForSimulation =[];
      let incluidas =[];
      const planesEliminado = SimulationResponse.planes.splice( index, 1 );
        incluidas = planesEliminado.map((plan)=> plan.incluidas)
        oblForSimulation = [].concat(...incluidas.map((obligaciones)=>obligaciones));

      PaymentPlansServices()
      .simulate(
        oblForSimulation.map((o) => {
          let saldo = o.saldo;
          let saldoConRecargo = o.saldoConRecargo;
          return {
              periodo: o.periodo,
              cuota: o.cuota,
              impuesto: o.impuesto,
              concepto: o.concepto,
              vencimiento: o.vencimiento,
              prorroga: o.prorroga,
              saldo,
              saldoConRecargo,
              id: o.id,
              sujeto_id: o.sujeto_id,
              objeto_id: o.objeto_id,
              objeto_tipo: o.objeto_tipo,
          };
      }), props.jwt)
        .then((response) =>{
          props.setSimulation(response.data);
          setIsLoading(false);
        //   setTimeout(function () {
        //     window.location.reload()
        // }, 2000);
        })
  };

  const handleClose_add = () => {
    setAddOpen(false);
  };
  const handleClickAgregar = () => {
    props.clearObligationsForSimulation();
    setAddIsLoading(true);
    setAddOpen(true);
    let alreadyInPlan = []
    props.selectedObligations.forEach( obg => { alreadyInPlan.push(obg.id)});
    PaymentPlansServices()
      .getAvailable(props.cuit, alreadyInPlan, props.jwt)
      .then((response) => {
        setOblAgregables(response.data);
        setAddIsLoading(false);
      });
  };

  const handleCorregir = () => {
    setState("createplan");
  };
  if (State == "createplan") {
    return (
      <div className={classes.seccion}>
        <Container maxWidth="lg">
          {SimulationResponse != null &&
            SimulationResponse.excluidos.length > 0 && (
              <Alert
                icon={<img src={icon} />}
                variant="outlined"
                severity="warning"
                action={
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    <FormattedMessage
                      id="paymentsPlan.alert.excluidas"
                      defaultMessage="Ver Excluidas"
                    />
                  </Button>
                }
              >
                <AlertTitle>
                  <FormattedMessage
                    id="paymentsPlan.title.excluidas"
                    defaultMessage="Hay obligaciones excluidas"
                  />
                </AlertTitle>
                <FormattedMessage
                  id="paymentsPlan.message.obligations"
                  defaultMessage="Seleccionaste obligaciones que no pueden
                            ingresar en ningun plan de pagos."
                />
              </Alert>
            )}

          {SimulationResponse != null && SimulationResponse.planes.length ?
            SimulationResponse.planes.map((part, index) => (
            
                <Partida key={index} partida={part} isLoading={isLoading} handleDeletePlan={() => handleRemove(index)}/>
         
            )) :
            <Alert
            icon={<img src={icon} />}
            variant="outlined"
            severity="warning"
            //className={classes.marginbtn}
          >             
            <AlertTitle>
            <FormattedMessage
              id="paymentsPlan.alertTitle.noPlans"
              defaultMessage="Se han quitado todos los planes de pago"
            />
          </AlertTitle>
          </Alert>}

          <Alert
            icon={<img src={icon} />}
            variant="outlined"
            severity="warning"
            className={classes.marginbtn}
            action={
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleClickAgregar}
              >
                <FormattedMessage
                  id="paymentsPlan.alert.agregar"
                  defaultMessage="Agregar mas"
                />
              </Button>
            }
          >
            <AlertTitle>
              <FormattedMessage
                id="paymentsPlan.alertTitle.agregar"
                defaultMessage="Agregue mas obligaciones"
              />
            </AlertTitle>
            <FormattedMessage
              id="paymentsPlan.alertTitle.incluir"
              defaultMessage="Puedes incluir mas obligaciones de tus objetos."
            />
          </Alert>

          <ModalExcluidas
            ObExcluidas={SimulationResponse?.excluidos}
            open={open}
            handleClose={handleClose}
            onClose={handleClose}
          ></ModalExcluidas>
          <AddObligations
            open={addOpen}
            loading={addIsLoading}
            handleClose={handleClose_add}
            handleAdd={handleAdd}
            onClose={handleClose_add}
            ObAgregables={oblAgregables}
          />
        </Container>
        <Footer
        disabled={!SimulationResponse || SimulationResponse.planes.length < 1 ? true : false }
          onClickVolver={() => {
            history.push("/payment-methods");
          }}
          onClick={() => setState("Confirm")}
        />
      </div>
    );
  } else {
    return (
      <div className={classes.seccion}>
        <Confirm handleCorregir={handleCorregir} />
      </div>
    );
  }
};

const mapState = (state) => ({
  selectedObligations: state.payments.selectedObligations,
  simulation: state.paymentPlans.simulation,
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
  obligationsForSimulation: state.payments.obligationsForSimulation
});

const mapDispatch = dispatch => ({
  clearObligationsForSimulation: () => dispatch.payments.clearObligationsForSimulation(),
  modifyObligation: (checkedObligation, isCheked) => dispatch.payments.modifyObligation(checkedObligation, isCheked),
  setSimulation: (simulation) =>
    dispatch.paymentPlans.setSimulation(simulation),
    clearSimulation: () => dispatch.paymentPlans.clearSimulation()
})

export default connect(mapState, mapDispatch)(PaymentsPlan);
