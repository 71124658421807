import apiCMS from "../services/CMSServices";
import apiBUC from "../services/BUCServices";

export default {
    state: {
        appointmentsConfig: null,
        appointmentsLoading: false
    },
    reducers: {
        setAppointmentsConfig(state, config) {
            return Object.assign({}, {...state, appointmentsConfig: config})
        },
        setAppointmentsLoading(state, val) {
            return Object.assign({}, {...state, appointmentsLoading: val})
        },
    },
    effects: (dispatch) => ({
        async stopAtencion(payload, state) {
            apiCMS.AppointmentsServices.stopAppointment(state.appointments.appointmentsConfig, state.auth.jwt)
                .then((response) => {
                    // console.log("termino la atencion desde AgenDashboard: ", response.stopped)
                })
                .catch((error) => {
                    console.log(error.message);
                });

            apiBUC.SubjectServices.endContacto(state.contacts.currentContactId).then(
                (response) => {
                    dispatch.contacts.setCurrentContactId("");
                }
            );

            apiCMS.UserMenuServices.getUserMenu(state.auth.taxRole)
                .then((res) =>
                    dispatch.auth.setUserMenu(res.data.map((item) => item["user_menu"]))
                )
                .catch((err) =>
                    console.log(
                        "No se pudo obtener el menú del usuario. ERROR: ",
                        err.message
                    )
                );

            apiCMS.AuthServices.getMenuItems(state.auth.taxRole)
                .then((res) =>
                    dispatch.auth.setMenu(
                        res.data
                            .map((item) => {
                                return item.action;
                            })
                            .sort((a, b) => (a.order > b.order ? 1 : -1))
                    )
                )
                .catch((err) =>
                    console.log(
                        "No se pudo obtener el menú del usuario. ERROR: ",
                        err.message
                    )
                );

        }
    }),
}