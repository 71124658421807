
const AffidavitServices = (instance) => {
    const getAffidavits = (id: string) => instance.get(`sujetos/${id}/declaraciones-juradas`);


    return {
        getAffidavits
    };
};
export default AffidavitServices;
