import React from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import styled from "styled-components";
import ButtonBase from "@material-ui/core/ButtonBase";
import NumberCurrency from "../../common/NumberCurrency";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    BtnPayMethod: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },
    Links: {
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
    BtnPayMethodFocus: {},
    buttonProgress: {
      color: "green",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    BtnData: {},
    BtnTitle: {},
    BtnSubtitle: {},
    BtnFooter: {},
  })
);

const BoxHover = styled.div`
  border-radius: 6px;
  box-shadow: 10px 20px 30px 0 rgba(51, 51, 51, 0.2);
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
  height: 100%;
  justify-content: center;
  padding: 40px 30px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  &.disabled {
    box-shadow: none;
    background: transparent;
    border: dashed 1px #444;
    opacity: 0.8;
  }
  .subtext {
    color: #0574b9;
    font-size: 18px;
  }
  .boxImage {
    display: block;
    height: 100%;
    margin: auto;
    object-fit: contain;
    text-align: center;
    width: 80%;
  }
  .boxTitle,
  .boxPayment {
    font-size: 1.4rem;
    @media (max-width: 1199px) {
      font-size: 1.4rem;
    }
  }
  .boxTitle {
    color: #444;
    font-weight: 500;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
  .boxPayment {
    color: #0574b9;
    margin: 0;
    span {
      font-size: 1.4rem;
    }
  }
  .boxDescription {
    color: #eee;
    font-size: 1rem;
    margin: 10px auto 0;
    width: 90%;
    p {
      margin: 0;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        color: #eee;
        font-size: 0.8rem;
        list-style: none;
        &::before {
          content: "- ";
        }
      }
    }
  }
  &:not(:first-of-type) {
    margin-left: 3%;
    @media (max-width: 991px) {
      margin-left: 0;
    }
  }
`;

const BoxButtons = (props) => {
  const classes = useStyles({});
  const btnProps = props.btnProps;

  return (
    <div className={"boxCol"}>
      <BoxHover
        onClick={btnProps.disabled ? null : props.onClickFc}
        focusRipple
        key={btnProps.title}
        focusVisibleClassName={classes.BtnPayMethodFocus}
        className={`boxHover ${btnProps.disabled ? "disabled" : null}`}
      >
        <div>
          <div className="sc-gzVnrw gNVUbC">
           <h3 className="boxTitle">{btnProps.title}</h3>
            <div className="boxPayment">
               {btnProps.modifier ? (
                <p>
                  <NumberCurrency
                    value={(props.total * btnProps.modifier).toFixed(
                      props.config.decimals
                    )}
                  />
                </p>
              ) : null}
            </div>
            <div className="boxDescription">
              {btnProps.subtext ? (
                <p className="subtext"> {btnProps.subtext}</p>
              ) : null}
            </div>
          </div>
        </div>
      </BoxHover>
    </div>
  );
};

//export default BoxButtons;

const mapState = (state) => ({
  config: state.auth.config,
});

export default connect(mapState, null)(BoxButtons);
