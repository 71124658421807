import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {connect} from 'react-redux';
import { FormattedMessage } from 'react-intl';

/**
 *
 * @param options
 * @param text
 */
function filterTaxes(options, text) {
    return options.filter(function (object) {
        const hasTag = object.etiquetas && object.etiquetas.split(',')
            .map(t => t.toLowerCase().includes(text.toLowerCase()))
            .some(b => b);
        if (object.id.toUpperCase().includes(text.toUpperCase()) || hasTag) {
            return true;
        } else {
            if (object.base_imponible && object.base_imponible.toString().toUpperCase().includes(text.toUpperCase())) {
                return true;
            } else {
                return object.descripcion.toUpperCase().includes(text.toUpperCase());
            }
        }
    })
}

const SearchBox = (props) => {
    let objects = JSON.parse(JSON.stringify(props.objects));
    let jsonString = JSON.stringify(props.thirdPartObjects);
    jsonString = jsonString.replace(/objeto_id/g, "id");
    jsonString = jsonString.replace(/tipo_objeto/g, "tipo");
    objects = objects.concat(JSON.parse(jsonString));

    const taxesTypes = props.taxesTypes;
    const options = objects.map(object => {
        let completeType;
        taxesTypes.forEach(taxType => {
            if (taxType.code === object.tipo) {
                completeType = taxType.Description;
            }
        })
        return {
            completeType: completeType,
            ...object
        }
    });
    return (
        <Autocomplete
            freeSolo
            clearOnEscape
            id="grouped-autocomplete"
            onChange={(event, value:any) => {
                props.setFilteringObjects(true);
                if (value === null) {
                    // Se limpio el filtro
                    props.setRenderedObjects(options.filter(option => !("asociado" in option)));
                    props.setRenderedThirdPartObjects(options.filter(option => "asociado" in option));
                } else {
                    // Se eligio un objeto especifico
                    if(typeof value === 'string' || value instanceof String){
                        // Se escribio un texto para fitlrar los objetos.
                        let filteredList = filterTaxes(options, value);
                        props.setRenderedObjects(filteredList.filter(option => !("asociado" in option)));
                        props.setRenderedThirdPartObjects(filteredList.filter(option => "asociado" in option));
                    } else {
                        if(!("asociado" in value)){
                            props.setRenderedObjects([value]);
                            props.setRenderedThirdPartObjects([]);
                        } else {
                            props.setRenderedObjects([]);
                            props.setRenderedThirdPartObjects([value]);
                        }
                    }
                }
            }}
            options={options}
            groupBy={option => option['completeType']}
            getOptionLabel={option => typeof option === 'string' ? option : option['id']}
            renderInput={params => (
            <TextField {...params} label={<FormattedMessage id="myTaxes.searchBox.label" defaultMessage="Buscar palabras clave" />} variant="outlined" fullWidth/>
            )}
        />
    );
}

const mapDispatch = dispatch => ({
    setRenderedObjects: (renderedObjects) => dispatch.payments.setRenderedObjects(renderedObjects),
    setRenderedThirdPartObjects : (renderedThirdPartObjects) => dispatch.payments.setRenderedThirdPartObjects(renderedThirdPartObjects),
    setFilteringObjects: (bool) => dispatch.payments.setFilteringObjects(bool),
})

const mapState = state => ({
    objects: state.payments.allObjects,
    thirdPartObjects: state.payments.thirdPartObjects,
    taxesTypes: state.taxes.taxesTypes,
})

export default connect(mapState, mapDispatch)(SearchBox);