import React from "react";
import Container from "@material-ui/core/Container";

import { FormattedMessage } from "react-intl";

import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
const icon = require("../../../assets/logo.png");

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "5px auto",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  footer: {
    background: "#222",
    color: "white",
    fontWeight: 200,
    padding: "40px",
    bottom: "-139px",
    position: "absolute",
    width: "100%",
  },
  icono: {
    maxHeight: "60px",
  },
});
const SectionFooter = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      {" "}
      <Container maxWidth="xl">
        <Grid container>
          <Grid item sm={6}>
            <img src={icon} className={classes.icono}></img>
          </Grid>
          <Grid item sm={6}>
            Contacto Medios y lugares de pago Oficinas de atención Buzón de
            sugerencias Recursos Denuncias Información Fiscal Redes Sociales
            Rentas Córdoba
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default SectionFooter;
