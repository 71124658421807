import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Chat from "../../components/Chat";
import {connect} from "react-redux";


const useStyles = makeStyles((theme) => ({
    chatsPosition: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        pointerEvents:'none'
    },
    chatContainer: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
    },
}));

const ChatContainer = (props) => {
    const classes = useStyles();

    const handleClose = (chat) => {
        props.closeChat(chat.entidad, chat.clave)
    }

    return (
        <div className={classes.chatsPosition}>
            <Container className={classes.chatContainer}>
                {props.chats.map( (chat, index) =>{
                    return <Chat key={chat.entidad + '-' + chat.clave}
                          chat={chat}
                          handleClose={()=> handleClose(chat)}></Chat>
                    }
                )}
            </Container>
        </div>
    );
};

const mapDispatch = (dispatch) => ({
    //cerrar  chat  en redux
    closeChat: (entidad, clave) => dispatch.chats.closeChat(entidad, clave)
    //state.applications.formList,
});

const mapState = (state) => ({
    //setear que chat esta abierto desde redux
    chats: state.chats.chats,
    flagRefresh: state.chats.flagRefresh,
});

export default connect(mapState, mapDispatch)(ChatContainer);
