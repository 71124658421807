import React from "react";
import { FormattedMessage } from "react-intl";
import SeccionHeader from "../../components/common/SeccionHeader";
import { makeStyles } from "@material-ui/core/styles";
import { VouchersData } from "../../mock/data";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { format } from "date-fns";
import CancelIcon from '@material-ui/icons/Cancel';
import Table from "../../components/common/Table";
import Chip from "@material-ui/core/Chip";
import { voucherEstadoCodigo } from "../../models/IVouchers";
import NumberCurrency from "../../components/common/NumberCurrency";
import PaymentServices from "../../services/PaymentServices";
import SubjectServices from "../../services/SubjectServices";
import TableSkeleton from "../../components/common/TableSkeleton";
import { TurnosConfigMock, TurnosListMock } from "../../mock/Turnos.mock";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  cell: {
    backgroundColor: "20px",
  },
  container: {
    marginBottom: "50px",
  },
  tableCell: {
    padding: "5px 16px",
  },
  tableHead: {
    background: "#efefef",
  },
  falsoTexto: {
    width: "63%",
    height: "15px",
    borderRadius: "15px",
    display: "inline-block",
    background: "#dadada",
  },
  tabs: {
    background: "white",
    flexDirection: "row",
    "& span": {
      flexDirection: "row",
    },
  },
  tableRow: {
    cursor: "pointer",
    "&:hover": {
      background: "#f3f3f3",
    },
  },
  tabsContainer: {
    boxShadow: " inset 3px -1px 2px 0px #dddddd8c",
    background: " #f7f7f7",
  },
  buscar: {
    marginTop: "-16px",
    marginBottom: "16px",
  },
  marginTop: {
    marginTop: "-20px",
  },
});

const AdmiTurnos = (props) => {
  const classes = useStyles();
  const [filter, setFilter] = React.useState("");
  const [searcheables, setSearcheables] = React.useState([]);
  const [isOpen, setisOpen] = React.useState(false);
  const [selectedVoucher, setVoucher] = React.useState();
  const [voucherList, setVoucherList] = React.useState([]);
  const [loadingApplications, setLoadingApplications] = React.useState(true);
  const [loadingText, setLoadingText] = React.useState(
    <FormattedMessage
      id={"contacts.loadingText"}
      defaultMessage={"Cargando..."}
    />
  );
  const columnas = TurnosConfigMock.columnas.map((el) => {
    return {
      title: el.columnaLabel,
      field: el.columnaValue,
      render: (rowData) => {
        switch (el.columnaTypo) {
          case "date":
            return (
              <span>
                {format(new Date(rowData[el.columnaValue]), "dd/MM/yy - hh:mm")}
              </span>
            );
            break;
          case "currency":
            return (
              <NumberCurrency
                value={parseFloat(rowData[el.columnaValue]).toFixed(
                  props.config.decimals
                )}
              />
            );
            break;
          case "state":
            return <Chip variant="outlined" size="small" label={rowData[el.columnaValue]}/> 
            break;
          default:
            return <span>{rowData[el.columnaValue]} </span>;
            break;
        }
      },
    };
  });

  const handleFilter = (event) => {
    setFilter(event.target.value);
  };

  return (
    <div className={classes.container}>
      <SeccionHeader
        title={
          <FormattedMessage
            id="Vouchers.titlde"
            defaultMessage="Administracion de turnos"
          />
        }
      >
        <TextField
          className={classes.marginTop}
          onChange={(e) => handleFilter(e)}
          id="Filtrar"
          label="Buscar"
        />
      </SeccionHeader>
      <Table
        columnas={columnas}
        Data={TurnosListMock}
        filter={filter}
        // rowClick={(r) => selectVoucher(r)}
        mensajeVacio={
          <FormattedMessage
            id="Vouchers.emptyMessage"
            defaultMessage="No hay Boletas para mostrar"
          />
        }
        acciones={[
          {
            icon: () => <DesktopMacIcon />,
            tooltip: (
              <FormattedMessage
                id="turnero.atVirtual"
                defaultMessage="Atencion virtual"
              />
            ),
            // onClick: (e, r) => selectVoucher(r)
          },
          {
            icon: () => <CancelIcon />,
            tooltip: (
              <FormattedMessage
                id="turnero.cancelar"
                defaultMessage="Cancelar turno"
              />
            ),
            // onClick: (e, r) => selectVoucher(r)
          },
        ]}
      />
    </div>
  );
};

export default AdmiTurnos;
