import React, { useEffect } from "react";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles,createStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AgentHeader from "../../components/AgentDashboard/AgentHeader";
import apiCMS from "../../services/CMSServices";
import { vencimientos } from "../../mock/Venciminetos.TaxProfile.mock";
import AgentActions from "../../components/AgentDashboard/AgentActions";
import AgentLinks from "../../components/AgentDashboard/AgentLinks";
import AgentModal from "../../components/AgentDashboard/AgentModal";
import {useHistory} from "react-router-dom";
import apiBUC from "../../services/BUCServices";

const useStyles = makeStyles((theme) =>
  createStyles({
  profile: {
    margin: "30px auto",
    position: "relative",
    padding: "0 80px",
    [theme.breakpoints.down('sm')]: {
      padding: "0 0",
    },
    [theme.breakpoints.down('md')]: {
      padding: "0 20px",
    }
  },
  btnsContainer: {
    display: "flex",
    height: "300px",
    flexDirection: "column",
    justifyContent: "center",
  },
  btnLateral: {
    width: "100%",
    border: "solid #eee 4px",
    display: "flex",
    padding: "10px 30px",
    flex: "1",
    background: "#fff",
    transition: "all 300ms",
    flexDirection: "column",
    "&:hover": {
      margin: "0 -10px",
      transition: "all 300ms",
      textDecoration: "none",
    },
  },
  btnLateralDer: {
    padding: "10px 30px 10px 170px",
    transition: "all 300ms",
    "&:hover": {
      margin: "0 -10px 0 10px",
      transition: "all 300ms",
      textDecoration: "none",
    },
  },
  circuloCentral: {
    left: "50%",
    color: "white",
    width: "300px",
    height: "300px",
    top: "0",
    display: "flex",
    padding: "10px",
    position: "absolute",
    borderRadius: "50%",
    transform: "translate(-50%, 0px)",
    background: "#7979ff",
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  txtSmall: {
    fontSize: "11px",
    color: "#6b6b6b",
  },
  txtBig: {
    fontSize: "34px",
    color: "#3684ca",
    lineHeight: "36px",
  },
  txtMd: {
    color: "#545454",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "21px",
  },
  btnsNoCentered: {
    marginTop: "80px",
    flexWrap: "wrap",
    justifyContent: "center",
    display: "flex",
  },
  icon: { fontSize: "220px", lineHeight: "80px", marginBottom: "-20px" },
  userName: { fontSize: "22px", paddingBottom: "30px", lineHeight: "22px" },
  userCuit: { fontSize: "18px" },
  welcome: {
    fontWeight: 400,
    padding: "25px",
  },
  toolbar: {
    background: "#7979ff",
  },
  taxesFooter: {
    color: "white",
    position: "fixed",
    bottom: "20px",
    width: "100%",
    left: "0",
    padding: "30px",
    background: "#0584ce",
  },
  flex1:{flex:1}
})
);

const AgentDashboard = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [turno, setTurno] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [userFound, setUserFound] = React.useState(null);
  const history = useHistory();
  const [changeRepresented, setChangeRepresented] = React.useState(false);

  useEffect(() => {
    if (location.state && location.state["changeRep"] && props.atencion) {
      setUserFound(props.atencion.Persona);
      stopAtencion()
      setChangeRepresented(location.state["changeRep"]);
      setOpen(location.state["changeRep"]);
    }
  }, [location.state]);

  useEffect(() => {
    if (props.appointmentsConfig === null && !props.appointmentsLoading) {
      props.setAppointmentsLoading(true);
      apiCMS.AppointmentsServices.getAppointmentConfig()
          .then((res) => {
            props.setAppointmentsConfig(res.data);
            props.setAppointmentsLoading(false);
          })
          .catch((err) => {
            setError(err.message);
            console.log("No encontramos configuracion. ERROR: ", err.message);
          });
    }
  }, []);

  const handleSearch = (cuit) => {
    setLoading(true);
    apiCMS.AppointmentsServices.searchSubject(cuit, props.appointmentsConfig, props.jwt)
    .then((response) => {
      const userFound = {
        cuit: response.parsedCuit,
        denominacion:response.denominacion,
        username: cuit
      }
      setUserFound(userFound);
      setLoading(false);
      setError(null)
      setOpen(true);   
      })
      .catch((err) => {
        setLoading(false);
        setOpen(true);
        setError(err.message);
        console.log("No encontramos el usuario. ERROR: ", err.message);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setAtencion = (rep, contr) => {
    const atencion = {
      Persona: userFound,
      Representado: contr ? contr : null,
      enRepresentacion: rep ? rep : null,
      horaInicio:  Date.now(),
      turno: turno? turno.turno :null 
    };
    props.setAtencion(atencion);

    setOpen(false);
    const personaRepresentar = atencion.Representado
      ? atencion.Representado
      : atencion.Persona;

    apiCMS.AuthServices.impersonate(personaRepresentar.cuit, props.jwt)
      .then((res) => {
        props.impersonateUser(res.token, personaRepresentar);

        apiCMS.AuthServices.getMenuItems(1)
        .then((res) => {
          const orderedMenu = res.data
            .map((item) => {
              item.action.user = true;
              return item.action;
            })
            .sort((a, b) => (a.order > b.order ? 1 : -1));
          props.setMenu([...orderedMenu, ...props.menu]);

          apiCMS.UserMenuServices.getUserMenu(1)
            .then((res) => {
              props.setUserMenu(res.data.map((item) => item["user_menu"]));
              history.push({
                pathname: "/dashboard",
              });
            })
            .catch((err) =>
              console.log(
                "No se pudo obtener el menú del usuario. ERROR: ",
                err.message
              )
            );
        })
        .catch((err) =>
          console.log(
            "No se pudo obtener el menú del usuario. ERROR: ",
            err.message
          )
        );
      
        setTimeout(function () {
          const atencionJson = {
            "descripcion": props.currentSubContactType.contact_type.description,
            "tipo": props.currentSubContactType.contact_type.code,
            "subtipo": props.currentSubContactType.code,
          }
          apiBUC.SubjectServices.generateContacto(atencionJson)
          .then( (response) => props.setCurrentContactId(response.data.id) )
        }, 2000);
      })
      .catch((err) =>
        console.log(
          "No se pudo obtener el menú del usuario. ERROR: ",
          err.message
        )
      );
    
  };

  const stopAtencion = async () => {
    await props.stopAtencion()
    props.resetAtencion();
  };

  const changeRepresentation = () =>{
    setUserFound(props.atencion.Persona);
    stopAtencion()
    setChangeRepresented(true);
    setOpen(true);
  }

  const getTurno = () => {
    setLoading(true);
    apiCMS.AppointmentsServices.searchNextAppoinment(props.appointmentsConfig, props.jwt)
        .then((response) => {
          setTurno(response);
          handleSearch(response.usuario);
        })
        .catch((err) => {
          console.log(error);
        });
  };

  return (
    <div className={classes.flex1}>
      <AgentHeader
        getTurno={() => getTurno()}
        isLoading={loading}
        changeRepresentation={() => changeRepresentation()}
        handleSearch={(cuit) => handleSearch(cuit)}
      />
      <Container maxWidth={"lg"} className={classes.profile}>
        <Grid container>
          <Grid item  xs={12} md={9} >
            <Grid container>
              <Grid item xs={12}>
                <AgentActions actions={props.agentActions} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <AgentLinks links={props.agentLinks} />
          </Grid>
        </Grid>
      </Container>

      <AgentModal
        SetAtencion={(rep, contr) => setAtencion(rep, contr)}
        handleClose={()=>handleClose()}
        turno={turno}
        open={open}
        enRepresentacion={ changeRepresented}
        user={userFound ? userFound : null}
        error={error ? error : null}
      />
    </div>
  );
};

const mapState = (state) => ({
  config: state.auth.config,
  jwt: state.auth.jwt,
  taxRole: state.auth.taxRole,
  allObjects: vencimientos,
  totalToPay: state.payments.totalToPay,
  state: state,
  atencion: state.auth.atencion,
  menu: state.auth.menu,
  agentActions: state.auth.agentActions,
  agentLinks: state.auth.agentLinks,
  currentSubContactType: state.contacts.currentSubContactType,
  denominacion: state.auth.denominacion,
  appointmentsConfig: state.appointments.appointmentsConfig,
  appointmentsLoading: state.appointments.appointmentsLoading
});

const mapDispatch = (dispatch) => ({
  impersonateUser: (jwt, user) => dispatch.auth.impersonateUser(jwt, user),
  setMenu: (menu) => dispatch.auth.setMenu(menu),
  setUserMenu: (menu) => dispatch.auth.setUserMenu(menu),
  setAtencion: (atencion) => dispatch.auth.setAtencion(atencion),
  resetAtencion: () => dispatch.auth.resetAtencion(),
  setCurrentContactId: (contactId) => dispatch.contacts.setCurrentContactId(contactId),
  setAppointmentsConfig: (config) => dispatch.appointments.setAppointmentsConfig(config),
  setAppointmentsLoading: (val) => dispatch.appointments.setAppointmentsLoading(val),
  stopAtencion: () => dispatch.appointments.stopAtencion(),
});

export default connect(mapState, mapDispatch)(AgentDashboard);
