import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import {connect} from "react-redux";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Messages from "./Messages";
import apiBUC from "../../services/BUCServices";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "350px",
        borderLeft: theme.superficies.border,
        borderRight: theme.superficies.border,
        borderRadius: '0 !important',
        boxShadow: theme.shadows[theme.superficies.elevation],
    },
    chatRoot: {
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        pointerEvents: 'initial',
    },
    msj: {
        padding: "5px 14px",
        background: "#ededed",
        borderRadius: "10px",
        margin: "10px auto 10px 10px",
        width: "fit-content",
        fontSize: "12px",
        maxWidth: "70%",
        position: "relative",
        "&:before": {
            content: "' '",
            position: "absolute",
            top: 0,
            left: "-6px",
            width: "20px",
            borderTop: "solid #ededed 30px",
            borderLeft: "solid transparent 20px",
        },
    },
    chatContent: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        padding: 0,
        borderRadius: 0,
    },
    propio: {
        margin: "10px 10px 10px auto",
        background: "#cde3f1",

        "&:before": {
            right: "-6px",
            left: "auto",
            borderLeft: "none",
            borderTopColor: "#cde3f1",
            borderRight: "solid transparent 20px",
        },
    },
    chatFooter: {
        padding: "10px ",
        borderTop: '1px solid #ededed',
    },
    chatMensajes: {
        height: "250px",
        flexDirection: "column",
        padding: "0 5px",
        overflow: "auto",
    },
    chatHeader: {
        background: theme.colores.main,
        color: theme.colores.contrast,
        minHeight: "auto !important",
        paddingRight: "50px",
        margin: '0 !important',
        '& div': {
            margin: '0 !important',
        }
    },
    hora: {
        fontSize: "8px",
        display: "flex",
        justifyContent: "flex-end",
    },
    leido: {fontSize: "0.8rem", padding: "0 0 0 3px", color: "#0574b9"},
    dia: {
        fontSize: "8px",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        whiteSpace: "nowrap",
        "&:before": {
            content: "' '",
            width: "100%",
            borderTop: "solid 1px #ededed",
            margin: "5px",
        },
        "&:after": {
            content: "' '",
            width: "100%",
            borderTop: "solid 1px #ededed",
            margin: "5px",
        },
    },
    formControl: {
        width: '100%',
        '& div': {
            padding: '14px 0 14px 12px'
        }
    },
    user: {
        fontSize: "8px",
        display: "flex",
        textAlign: "left",

        whiteSpace: "nowrap",

        "&:after": {
            content: "' '",
            width: "100%",
            borderTop: "solid 1px #ededed",
            margin: "5px",
        },
    },

    dot: {
        width: "10px",
        height: "10px",
        background: "red",
        borderRadius: "100%",
        margin: "5px",
        display: "inline-block",
    },

    buttonClose: {
        position: "absolute",
        right: "0",
        top: "0",
        color: theme.colores.contrast,
    },
    iconHeader: {
        color: theme.colores.contrast,
    },
}));

const Chat = (props) => {
    const classes = useStyles();
    const [isExpanded, setExpanded] = React.useState(false);
    const [mensaje, setMensaje] = React.useState("");
    const [mensajes, setMensajes] = React.useState([]);

    const chat = props.chat;
    const inputFocus= React.useRef(null);
    const handleEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSend(e);
        }
    };

    const handleSend = (e) => {
        //aca va la api de enviar mensajes
        e.stopPropagation();
        if (mensaje.length < 1) {
            return;
        }

        const newMsj = {
            mensaje: mensaje,
            fecha: new Date().toISOString(),
            usuario: props.cuit,
            leidopor: "",
        };
        const newMessageChain = mensajes;
        newMessageChain.push(newMsj)
        apiBUC.ChatService.saveMessages(chat.entidad, chat.clave, newMessageChain)
        setMensajes(newMessageChain);
        getNewMessages();
        setMensaje("");
    };

    const setLeido = () => {
        //aca hay que pegarle a la api de marcar como leido
       let aux = mensajes 
        if (aux.length>0){
            if(aux[aux.length-1].leidopor==="" && aux[aux.length-1].usuario!==props.cuit){ 
            aux[aux.length-1].leidopor=props.cuit}
        }
        apiBUC.ChatService.saveMessages(chat.entidad, chat.clave, aux)
        setMensajes(aux);
        inputFocus.current.focus();
    };

    const getNewMessages = () => {
        apiBUC.ChatService.getChat(chat.entidad, chat.clave)
            .then( (response) => {
               if(response.data){
                   setMensajes(response.data.mensajes ? response.data.mensajes : []);
                }else{
                    console.error('no tuvimos data del chat')
                }
            })
    };

    const handleChange = (e) => {
        setMensaje(e.target.value);
    };

    const onChange = () => {
        setExpanded((exp) => !exp);
    };

    useEffect(() => {
        apiBUC.ChatService.createChat('tramite' , chat.clave)
        getNewMessages();
        const timerID = setInterval(() => getNewMessages(), 500);
        return () => {
            clearInterval(timerID);
        };
    }, []);
 
    useEffect(()=>{
        if(isExpanded){
            console.log(inputFocus.current)
            inputFocus.current.focus();
        }
    },[isExpanded])

    return (
        <div className={classes.chatRoot}>
            <ExpansionPanel
                className={classes.root}
                expanded={isExpanded}
                onChange={onChange}>

                <ExpansionPanelSummary
                    className={classes.chatHeader}
                    expandIcon={<ExpandLessIcon className={classes.iconHeader}/>}
                    aria-controls={"titulo"}
                    id={chat.clave}>

                    <Typography variant="body1">
                        {`${chat.entidad} |  ${chat.clave.slice(chat.clave.length-5)}`}
                        {mensajes && mensajes.length >1 && mensajes[mensajes.length-1].leidopor!==props.cuit && mensajes[mensajes.length-1].usuario !==props.cuit ?
                            <span className={classes.dot}></span> : null} 
                    </Typography>

                    <IconButton
                        className={classes.buttonClose}
                        aria-label="toggle password visibility"
                        onClick={props.handleClose}>
                        <CloseIcon/>
                    </IconButton>

                </ExpansionPanelSummary>

                <ExpansionPanelDetails
                    className={classes.chatContent}
                    onClick={() => setLeido()}>

                   <Messages mensajes={mensajes}/>

                    <div className={classes.chatFooter}>
                        <FormControl className={classes.formControl}>
                            <OutlinedInput
                            inputRef={inputFocus}
                                aria-label="mensaje"
                                multiline
                                placeholder='mensaje'
                                id="standard-adornment-password"
                                type={"text"}
                                value={mensaje}
                                onChange={handleChange}
                                onKeyUp={handleEnter}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ handleSend}>
                                            <SendIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                }/>
                        </FormControl>

                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
};

const mapState = (state) => ({
    cuit: state.auth.authenticatedUser.cuit,
    chatId: state.chats.clave
});


export default connect(mapState, null)(Chat);
