import React from 'react';
import TaxItem from '../TaxItem/TaxItem';
import { Grid } from "@material-ui/core";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails
} from '../../../common/Accoderon';
import MoreButton from '../MoreButton';
import TagsArray from '../TagsArray';
import { connect } from 'react-redux';
import './styles.css';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import NumberCurrency from '../../../common/NumberCurrency';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { FormattedMessage } from 'react-intl';
const rowStyleMoreButton = {
    marginRight: 20,
}

const rowStyleTags = {
    paddingLeft: 12,
    paddingTop: 10,
}

const getTotal = (obligations) => {
    let total = 0;
    obligations.forEach( obligation => {
        if(obligation.cuota != '0'){
            if(obligation.exenta){
                total = total + ((obligation.saldoConRecargo * (100 - obligation.porcentaje_exencion)) / 100);
            } else {
                total = total + obligation.saldoConRecargo;
            }
        }
    })
    return total;
}

const getTotalCapital = (obligations) => {
    let total = 0;
    obligations.forEach(obligation => {
        if(obligation.cuota != '0'){
            if(obligation.exenta){
                total = total + ((obligation.saldo  * (100 - obligation.porcentaje_exencion)) / 100);
            } else {
                total = total + obligation.saldo;
            }
        }
    })
    return total;
}

export function TaxItemsMapper(props) {
    return (
        props.objects.map((o, i) =>
            <Grid item xs={12} key={i}>
                <ExpansionPanel elevation={0} className={'SubAcordion ' + 'SubAcrodionTipo_'+o.tipo}>
                    <ExpansionPanelSummary  className='SubAcordionHeader'>
                        <Grid item xs={12} className='SubAcordionTitle'> 
                            <div className="row justify-content-between">
                            <div className="col-auto columnExpand">
                                   <ChevronRightOutlinedIcon className='expandIcon'/>
                                                               </div>
                                <div className="col-auto">
                                    <b>{o.objeto_id ? o.objeto_id : o.id}</b>{o.base_imponible ? <small> - <FormattedMessage id="myTaxes.taxBase" defaultMessage="Base imponible" />: <NumberCurrency value={o.base_imponible}/>
                                    <br/>{o.descripcion}</small> : <small><br/>{o.descripcion}</small>}
                                                               </div>
                                <div className="col">
                                         {props.cuit === o.sujeto_id ? <TagsArray object={o} /> : null}
                                </div>
                                <div className="col-auto">
                                    <div className="row justify-content-end">
                                        <b>Subtotal:</b><div style={{paddingLeft: "5px"}}>{<NumberCurrency value={getTotal(o.obligaciones).toFixed(props.config.decimals)}/>}</div><br/>
                                    </div>
                                    <div className="row justify-content-end">
                                        {props.showCapitalDebt ? <small><NumberCurrency value={getTotalCapital(o.obligaciones).toFixed(props.config.decimals)} /></small> : null}
                                    </div>
                                </div>
                                 <div className="col-auto">
                                    <MoreButton  size="small" obj={o}/>                     
                                </div>
                            </div>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="row justify-content-end">
                        <div className="col">
                          
                        <TaxItem className='SubAcordionItem' object={o} />
                   </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
               
            </Grid>
        )
    )
}

const mapState = state => ({
    config: state.auth.config,
    cuit: state.auth.cuit,
    showCapitalDebt: state.payments.showCapitalDebt,
})

export default connect(mapState, null)(TaxItemsMapper);