import {
  createMuiTheme,
  ThemeProvider,
  ThemeOptions,
} from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";
import {
  TextFieldProps,
  BaseTextFieldProps,
} from "@material-ui/core/TextField";

import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
declare module "@material-ui/core/styles/createMuiTheme" {
  interface Theme {
    agentHeader: {
      background: React.CSSProperties["background"];
      fontFamily: React.CSSProperties["fontFamily"];
      color: React.CSSProperties["color"];
      fontSize: React.CSSProperties["fontSize"];
    };
    header: {
      background: React.CSSProperties["background"];
      fontFamily: React.CSSProperties["fontFamily"];
      color: React.CSSProperties["color"];
      fontSize: React.CSSProperties["fontSize"];
      elevation: number;
      active: React.CSSProperties["color"];
    };
    botones: {
      borderRadius: React.CSSProperties["borderRadius"];
      colorContraste: React.CSSProperties["color"];
      color: React.CSSProperties["color"];
    };
    userMenu: {
      background: React.CSSProperties["background"];
      fontFamily: React.CSSProperties["fontFamily"];
      color: React.CSSProperties["color"];
      fontSize: React.CSSProperties["fontSize"];
    };
    tipografias: {
      primary: React.CSSProperties["fontFamily"];
    };
    colores: {
      regular: React.CSSProperties["color"];
      irregular: React.CSSProperties["color"];
      inmobiliario: React.CSSProperties["color"];
      automotor: React.CSSProperties["color"];
      embarcaciones: React.CSSProperties["color"];
      planes: React.CSSProperties["color"];
      ingresosbrutos: React.CSSProperties["color"];
      gris: React.CSSProperties["color"];
      main: React.CSSProperties["color"];
      contrast: React.CSSProperties["color"];
      body: React.CSSProperties["color"];
      acento: React.CSSProperties["color"];
      botones: React.CSSProperties["color"];
      botonesContraste: React.CSSProperties["color"];
    };

    superficies: {
      elevation: number;
      border: React.CSSProperties["border"];
      elevationHover: number;
      borderRadius: React.CSSProperties["borderRadius"];
    };
    sectionHeader: {
      color: React.CSSProperties["color"];
      borderBottom: React.CSSProperties["border"];
      fontWeight?: React.CSSProperties["fontWeight"];
    };
    estados: {
      background: React.CSSProperties["background"];
      textColor: React.CSSProperties["color"];
      borderRadius: React.CSSProperties["borderRadius"];
      iniciado: React.CSSProperties["background"];
      solicitado: React.CSSProperties["background"];
      aprobado: React.CSSProperties["background"];
      rechazado: React.CSSProperties["background"];
      borderLeft: React.CSSProperties["border"];
    };
    inputs: { variant: TextFieldProps["variant"] };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    tipografias?: {
      primary?: React.CSSProperties["fontFamily"];
    };
    agentHeader?: {
      background?: React.CSSProperties["background"];
      fontFamily?: React.CSSProperties["fontFamily"];
      color?: React.CSSProperties["color"];
      fontSize?: React.CSSProperties["fontSize"];
    };
    header?: {
      background?: React.CSSProperties["background"];
      fontFamily?: React.CSSProperties["fontFamily"];
      color?: React.CSSProperties["color"];
      fontSize?: React.CSSProperties["fontSize"];
      elevation?: number;
      active?: React.CSSProperties["color"];
    };
    botones?: {
      borderRadius?: React.CSSProperties["borderRadius"];
      colorContraste?: React.CSSProperties["color"];
      color?: React.CSSProperties["color"];
    };
    userMenu?: {
      background?: React.CSSProperties["background"];
      fontFamily?: React.CSSProperties["fontFamily"];
      color?: React.CSSProperties["color"];
      fontSize?: React.CSSProperties["fontSize"];
    };
    colores?: {
      regular?: React.CSSProperties["color"];
      irregular?: React.CSSProperties["color"];
      inmobiliario?: React.CSSProperties["color"];
      automotor?: React.CSSProperties["color"];
      embarcaciones?: React.CSSProperties["color"];
      planes?: React.CSSProperties["color"];
      ingresosbrutos?: React.CSSProperties["color"];
      gris?: React.CSSProperties["color"];
      main?: React.CSSProperties["color"];
      contrast?: React.CSSProperties["color"];
      body?: React.CSSProperties["color"];
      acento?: React.CSSProperties["color"];
      botones?: React.CSSProperties["color"];
      botonesContraste?: React.CSSProperties["color"];
    };
    superficies?: {
      elevation?: number;
      elevationHover?: number;
      border?: React.CSSProperties["border"];
      borderRadius?: React.CSSProperties["borderRadius"];
    };
    sectionHeader?: {
      color?: React.CSSProperties["color"];
      borderBottom?: React.CSSProperties["border"];
      fontWeight?: React.CSSProperties["fontWeight"];
    };
    estados?: {
      background?: React.CSSProperties["background"];
      textColor?: React.CSSProperties["color"];
      borderRadius?: React.CSSProperties["borderRadius"];
      iniciado?: React.CSSProperties["background"];
      solicitado?: React.CSSProperties["background"];
      aprobado?: React.CSSProperties["background"];
      rechazado?: React.CSSProperties["background"];
      borderLeft?: React.CSSProperties["border"];
    };
    inputs?: { variant?: TextFieldProps["variant"] };
  }
}

export default function defTheme(options: ThemeOptions) {
  return createMuiTheme(
    {
      header: {
        background: "#0574b9",
        color: "#fff",
        fontSize: "12px",
        elevation: 0,
        active: "#fff",
      },
      tipografias: {
        primary: "Roboto, sans-serif",
      },

      colores: {
        regular: "#7aac39",
        irregular: "#d0021b",
        inmobiliario: "#ff0000",
        automotor: "#ff0000",
        embarcaciones: "#ff0000",
        planes: "#ff0000",
        ingresosbrutos: "#ff0000",
        gris: "#eee",
        main: "#0574b9",
        body: "#ebebeb",
        contrast: "#fff",
        acento: "#ff8b1e",
        botones: "#7979ff",
        botonesContraste: "#ffffff",
      },
      inputs: { variant: "standard" },
      breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl"],
        values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 },
      },
      direction: "ltr",
      mixins: {
        toolbar: {
          minHeight: 56,
          "@media (min-width:0px) and (orientation: landscape)": {
            minHeight: 48,
          },
          "@media (min-width:600px)": { minHeight: 64 },
        },
      },
      overrides: {},
      palette: {
        common: { black: "#000", white: "#fff" },
        type: "light",
        primary: {
          light: "#7986cb",
          main: options.colores.main ? options.colores.main : "#0574b9",
          dark: "#303f9f",
          contrastText: "#fff",
        },
        secondary: {
          light: "#ff4081",
          main: "#f50057",
          dark: "#c51162",
          contrastText: "#fff",
        },
        error: {
          light: "#e57373",
          main: "#f44336",
          dark: "#d32f2f",
          contrastText: "#fff",
        },
        warning: {
          light: "#ffb74d",
          main: "#ff9800",
          dark: "#f57c00",
          contrastText: "rgba(0, 0, 0, 0.87)",
        },
        info: {
          light: "#64b5f6",
          main: "#2196f3",
          dark: "#1976d2",
          contrastText: "#fff",
        },
        success: {
          light: "#81c784",
          main: "#4caf50",
          dark: "#388e3c",
          contrastText: "rgba(0, 0, 0, 0.87)",
        },
        grey: {
          "50": "#fafafa",
          "100": "#f5f5f5",
          "200": "#eeeeee",
          "300": "#e0e0e0",
          "400": "#bdbdbd",
          "500": "#9e9e9e",
          "600": "#757575",
          "700": "#616161",
          "800": "#424242",
          "900": "#212121",
          A100: "#d5d5d5",
          A200: "#aaaaaa",
          A400: "#303030",
          A700: "#616161",
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        text: {
          primary: "rgba(0, 0, 0, 0.87)",
          secondary: "rgba(0, 0, 0, 0.80)",
          disabled: "rgba(0, 0, 0, 0.80)",
          hint: "rgba(0, 0, 0, 0.80)",
        },
        divider: "rgba(0, 0, 0, 0.12)",
        background: { paper: "#fff", default: "#fafafa" },
        action: {
          active: "rgba(0, 0, 0, 0.54)",
          hover: "rgba(0, 0, 0, 0.04)",
          hoverOpacity: 0.04,
          selected: "rgba(0, 0, 0, 0.08)",
          selectedOpacity: 0.08,
          disabled: "rgba(0, 0, 0, 0.80)",
          disabledBackground: "rgba(0, 0, 0, 0.12)",
          disabledOpacity: 0.8,
          focus: "rgba(0, 0, 0, 0.12)",
          focusOpacity: 0.12,
          activatedOpacity: 0.12,
        },
      },

      shadows: [
        "none",
        "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
        "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
        "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
        "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
        "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
        "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
        "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
        "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
        "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
        "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
        "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
        "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
        "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
        "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
        "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
        "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
        "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
        "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
      ],
      typography: {
        htmlFontSize: 16,
        fontFamily: options.tipografias.primary,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
          fontFamily: options.tipografias.primary,
          fontWeight: 300,
          fontSize: "6rem",
          lineHeight: 1.167,
          letterSpacing: "-0.01562em",
        },
        h2: {
          fontFamily: options.tipografias.primary,
          fontWeight: 300,
          fontSize: "3.75rem",
          lineHeight: 1.2,
          letterSpacing: "-0.00833em",
        },
        h3: {
          fontFamily: options.tipografias.primary,
          fontWeight: 400,
          fontSize: "3rem",
          lineHeight: 1.167,
          letterSpacing: "0em",
        },
        h4: {
          fontFamily: options.tipografias.primary,
          fontWeight: 400,
          fontSize: "2.125rem",
          lineHeight: 1.235,
          letterSpacing: "0.00735em",
        },
        h5: {
          fontFamily: options.tipografias.primary,
          fontWeight: 400,
          fontSize: "1.5rem",
          lineHeight: 1.334,
          letterSpacing: "0em",
        },
        h6: {
          fontFamily: options.tipografias.primary,
          fontWeight: 500,
          fontSize: "1.25rem",
          lineHeight: 1.6,
          letterSpacing: "0.0075em",
        },
        subtitle1: {
          fontFamily: options.tipografias.primary,
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: 1.75,
          letterSpacing: "0.00938em",
        },
        subtitle2: {
          fontFamily: options.tipografias.primary,
          fontWeight: 500,
          fontSize: "0.875rem",
          lineHeight: 1.57,
          letterSpacing: "0.00714em",
        },
        body1: {
          fontFamily: options.tipografias.primary,
          fontWeight: 400,
          fontSize: "1rem",
          lineHeight: 1.5,
          letterSpacing: "0.00938em",
        },
        body2: {
          fontFamily: options.tipografias.primary,
          fontWeight: 400,
          fontSize: "0.875rem",
          lineHeight: 1.43,
          letterSpacing: "0.01071em",
        },
        button: {
          fontFamily: options.tipografias.primary,
          fontWeight: 500,
          fontSize: "0.875rem",
          lineHeight: 1.75,
          letterSpacing: "0.02857em",
          textTransform: "uppercase",
        },
        caption: {
          fontFamily: options.tipografias.primary,
          fontWeight: 400,
          fontSize: "0.75rem",
          lineHeight: 1.66,
          letterSpacing: "0.03333em",
        },
        overline: {
          fontFamily: options.tipografias.primary,
          fontWeight: 400,
          fontSize: "0.75rem",
          lineHeight: 2.66,
          letterSpacing: "0.08333em",
          textTransform: "uppercase",
        },
      },

      ...options,
    },
    esES
  );
}

export const convergTheme = {
  header: {
    background: "#ffffff",
    color: "#444444",
    fontSize: "0.8rem",
    elevation: 1,
    active: "#7979ff",
  },
  userMenu: {
    background: "#fff",
    color: "#444",
  },
  agentHeader: {
    background: "#444",
    color: "#fff",
    elevation: 1,
  },
  colores: {
    main: "#7979ff",
    contrast: "#ffffff",
    regular: "#2ea52e",
    body: "#ebebeb",
    irregular: "#f50057",
    acento: "#ff8b1e",
    botones: "#7979ff",
    botonesContraste: "#ffffff",
  },
  superficies: {
    elevation: 1,
    elevationHover: 2,
    border: "none",
    borderRadius: "4px",
  },
  sectionHeader: {
    color: "#444",
    borderBottom: "solid 2px #e9e9e9",
    fontWeight: 300,
  },
  botones: {
    borderRadius: 4,
    color: "#0574b9",
    colorContraste: "#ffffff",
  },
  tipografias: {
    primary: "Roboto",
  },
  estados: {
    borderLeft: "solid 0px",
    textColor: "#444",
    background: "#ebebeb",
    borderRadius: "4px",
    iniciado: "#42ffffa3",
    solicitado: "#ffe842a3",
    aprobado: "#79ff42a3",
    rechazado: "#ff242494",
  },
  palette: {
    primary: {
      main: "#0574b9",
    },
  },
  inputs: { variant: "standard" as "standard" },
};

export const corrTheme = {
  header: {
    background: "#0574b9",
    color: "#fff",
    fontSize: "0.875rem",
    elevation: 0,
    active: "#fff",
  },
  userMenu: {
    background: "#fff",
    color: "#444",
  },
  agentHeader: {
    background: "#444",
    color: "#fff",
    elevation: 0,
  },
  colores: {
    main: "#0574b9",
    contrast: "#ffffff",
    regular: "#2ea52e",
    body: "#fff",
    irregular: "#f50057",
    acento: "#ff8b1e",
    botones: "#7aac39",
    botonesContraste: "#ffffff",
  },
  superficies: {
    elevation: 0,
    elevationHover: 6,
    border: "1px solid #e0e0e0",
    borderRadius: "4px",
  },
  botones: {
    borderRadius: 50,
    color: "#7aac39",
    colorContraste: "#ffffff",
  },
  tipografias: {
    primary: "Roboto",
  },
  sectionHeader: {
    color: "#444",
    borderBottom: "solid 2px #e9e9e9",
    fontWeight: 900,
  },
  estados: {
    borderLeft: "solid 0px",
    textColor: "#444",
    background: "#ebebeb",

    borderRadius: "4px",
    iniciado: "#42ffffa3",
    solicitado: "#ffe842a3",
    aprobado: "#79ff42a3",
    rechazado: "#ff242494",
  },
  inputs: { variant: "outlined" as "outlined" },
};
