import React from 'react';
import { connect } from "react-redux";
import './App.css';
import Shell from "./components/Shell";
import { IntlProvider } from 'react-intl';
import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";

const messages = {
    'es': messages_es,
    'en': messages_en
}

declare global {
  interface Window { config: any; }
}
window.config = window.config || {};

const App = (props) => {
  return (<IntlProvider locale={props.locale} messages={messages[props.locale]}><Shell/></IntlProvider>);
}


const mapState = state => ({
  locale: state.auth.locale
})

export default connect(mapState,null)(App);
