import React from "react";
import {FormattedMessage} from "react-intl";
import ObjectView from "../Components/ObjectView";
import Skeleton from "@material-ui/lab/Skeleton";
import Paper from "@material-ui/core/Paper";
import Alert from "../../common/AlertsLabs";
import ObligationMapper from "../../MyTaxes/Taxes/ObligationMapper";
import {connect} from 'react-redux';

const PaymentsBody = (props) => {

    const skeletonView = () =>
        <Paper elevation={0} style={{padding: "20px", borderRadius: 0}}>
            <Skeleton variant="text" width={"40%"}/>
            <Skeleton variant="text" width={"30%"}/>
        </Paper>

    const renderResult = () => {
        if (props.objeto) {
            return <>
                <ObjectView objects={props.objeto}/>
                <ObligationMapper obligations={props.objeto.obligaciones} public={true}/>
            </>
        }
        if (props.objeto === null) {
            return <Alert
                severity="warning"
                title={
                    <FormattedMessage
                        id="public.payments.noObjectFound"
                        defaultMessage="No encontramos el objeto"
                    />
                }
                message={
                    <FormattedMessage
                        id="public.payments.noObjectFoundMessage"
                        defaultMessage="¿El identificador es correcto?"
                    />
                }
            />
        }
        return null
    }

    return props.isLoading ?
        skeletonView()
        : renderResult()
};

const mapDispatch = dispatch => ({
    setObligationStates: (states) => dispatch.payments.setObligationStates(states)
})
export default connect(null, mapDispatch)(PaymentsBody);
