import React, { useEffect, useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import NumberCurrency from '../../../common/NumberCurrency';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles({
  Plan: {
    margin: '10px'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',

  },
  check: {

    borderLeft: 'solid 1px #d6d6d6',
    display: 'flex',
    padding: '0 10px',
    background: '#F9F9F9',
    flexDirection: 'column',
    justifyContent: 'center',

  },
  cell: {
    flex: 1,
    borderRight: 'solid 1px #d6d6d638',
    display: 'flex',
    padding: '10px 20px 0',
    flexDirection: 'column',
    justifyContent: 'center',

  },
  cellPlan: {
    flex: 1,
    borderRight: 'solid 2px #d6d6d638',
    background: '#F9F9F9',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px 20px 0',
  },
  cellAnticipo: {
    flex: 1.5,
    borderRight: 'solid 1px #d6d6d638',
    display: 'flex',
    padding: '10px 20px 0',
    flexDirection: 'column',
    justifyContent: 'center',

  },
  gray: {
    color: '#757575'
  },
  Title: {

  },
  Info: {
    fontStyle: 'italic',
    fontWeight: 300,
    fontSize: '20px',

  }
});

const PlanToConfirm = (props) => {
  const classes = useStyles();
  return (

    <div className={classes.Plan}>
      <Paper elevation={1}>
        <Grid container className={classes.container}>
          <Grid item className={classes.cellPlan}>
            <h6 className={classes.Title}>Plan de Pago N° {props.plan.numeroPlan}</h6>
            <p className={classes.Info}>{props.plan.descripcionDecreto}</p>
          </Grid>
          <Grid item className={classes.cellAnticipo}>
            <h6 className={classes.Title}>Anticipo</h6>
            <p className={classes.Info}><NumberCurrency value={props.plan.anticipo} /> <span className={classes.gray}> vence </span> {props.plan.fechaAnticipo}</p>
          </Grid>

          <Grid item className={classes.cell}>
            <h6 className={classes.Title}>Cuotas</h6>
            <p className={classes.Info}>{props.plan.cantidadCuotas} <span className={classes.gray}> <FormattedMessage id={"paymentsPlan.Cuotas"} defaultMessage=" cuotas de " /> </span>  <NumberCurrency value={props.plan.valorCuota} /></p>
          </Grid>
          <Grid item className={classes.cell}>
            <h6 className={classes.Title}><FormattedMessage id={"paymentsPlan.Bonuses"} defaultMessage="Bonificaciones" /></h6>
            <p className={classes.Info}><NumberCurrency value={0} /></p>
          </Grid>
          <Grid item className={classes.cell}>
            <h6 className={classes.Title}>Total</h6>
            <p className={classes.Info}><NumberCurrency value={props.plan.totalPlan} /></p>
          </Grid>

        </Grid>
      </Paper>

    </div>)

}

export default PlanToConfirm