import React from "react";
import { Field } from "react-final-form";
import getType from "../../Inputs/InputsMapper";
import {
  validar,
  required,
  email,
  maxLength,
  maxLength15,
  minLength,
  minLength2,
  minValue,
  number,
  minValue13,
  phoneNumber,
  alphaNumeric,
  tooYoung,
} from "../../Inputs/Validations";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { AppContextConsumer } from "../../DynamicFormContext";
import Alert from "@material-ui/lab/Alert";

type Props = {
  values: any;
  group: any;
  inputs: any;
};
const useStyles = makeStyles((theme: Theme) => ({
  fieldInput: {
    margin: "10px 3px",
  },
}));
const RenderInputs = (props) => {
  const { values, group, inputs, name } = props;
  const classes = useStyles();
  const showldDisable = (appContext, input) => {
    return appContext.viewOnly || input.disabled;
  };

  return (
    <>
      {inputs && inputs.length > 0 ? (
        inputs.map((input, index) => (
          <AppContextConsumer key={index}>
            {(appContext) =>
              appContext && (
                <div className={classes.fieldInput} key={index}>
                  <Field
                    component={getType(input.type)}
                    label={input.label + (input.required ? "*" : "")}
                    inputelement={{
                      ...input,
                      label: input.label + (input.required ? "*" : ""),
                    }}
                    tramiteId={appContext.tramiteId}
                    // name={group ? `${group}.${input.name}` : input.name}
                    name={name ? `${name}.${input.name}` : input.name}
                    type="text"
                    loadingAsyncData={props.loadingAsyncData}
                    disabled={showldDisable(appContext, input)}
                    placeholder={input.placeholder}
                    values={values}
                    validate={validar(input)}
                    key={appContext.pasoId + input.name}
                    isLastPaso={appContext.lastPasoId === appContext.pasoId}
                    onFocus={() => {
                      appContext.setInputFocus(
                        name ? `${name}.${input.name}` : input.name
                      );
                    }}
                    onBlur={() => {
                      appContext.setInputFocus(null);
                    }}
                    appContext={appContext}
                  />
                </div>
              )
            }
          </AppContextConsumer>
        ))
      ) : (
        <Alert variant="outlined" severity="warning">
          El grupo no tiene ningun input
        </Alert>
      )}
    </>
  );
};

export default RenderInputs;
