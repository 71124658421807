export default {
  state: {
    formList: [],
    lastApplicationUpdated: {},
  },
  reducers: {
    setFormList(state, formList) {
      return Object.assign({}, { ...state, formList: formList });
    },
    setLastApplicationUpdated(state, lastApplicationUpdated) {
      return Object.assign(
        {},
        { ...state, lastApplicationUpdated: lastApplicationUpdated }
      );
    },
  },
  effects: {},
};
