import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LoadingComponent , {FalsoTexto}from "../../common/LoadingComponent";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import SeccionHeader from '../../common/SeccionHeader';
import { FormattedMessage } from 'react-intl';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
const useStyles = makeStyles({
  paymentMethod: {
    minHeight: "220px",
    
  },
  paymentMethodsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  load: { flex: 1, margin: "30px 20px" ,borderRadius:'10px',width: "30%",},
  table:{}
});

const PaymentMethodsLoading = (props) => {
  const classes = useStyles();
  return (
    <Container maxWidth="xl">
              <SeccionHeader title={<FormattedMessage id="paymentMethod.youArePaying" defaultMessage="Cargando metodos de pago habilitados" />}
      >
                            
                            </SeccionHeader>
      <div className={classes.paymentMethodsContainer}>
        <Card className={classes.load} elevation={6}>
          <LoadingComponent className={classes.load} isLoading>
            <div className={classes.paymentMethod}></div>
          </LoadingComponent>
        </Card>
        <Card className={classes.load} elevation={6}>
          <LoadingComponent className={classes.load} isLoading>
            <div className={classes.paymentMethod}></div>
          </LoadingComponent>
        </Card> <Card className={classes.load} elevation={6}>
          <LoadingComponent className={classes.load} isLoading>
            <div className={classes.paymentMethod}></div>
          </LoadingComponent>
        </Card>
      </div>

   
      <SeccionHeader title={<FormattedMessage id="paymentMethod.youArePaying" defaultMessage="Obligaciones a pagar" />}
      >
                            
                            </SeccionHeader>   <div className={classes.table}>
      <Card  elevation={1}>
      <LoadingComponent isLoading>
            <TableContainer>
            <Table>
              <TableHead  >
                <TableRow>
                  <TableCell><FalsoTexto/></TableCell>
                  <TableCell align="right"><FalsoTexto/></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {['a', 'b'].map((row, i) => (
                  <TableRow  key={i}>
                    <TableCell component="th" scope="row">
                    <FalsoTexto/>
                    </TableCell>
                  
                    <TableCell  align="right"><FalsoTexto/></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

            </LoadingComponent>
            </Card>
          </div>


    </Container>
  );
};
export default PaymentMethodsLoading;
