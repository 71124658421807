import { useField } from "react-final-form";
import React, { useEffect } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { useTheme } from "@material-ui/core/styles";
import { Button, Input } from "@material-ui/core";
import {
  getUrl,
  getDataFromResponse,
  applyDependencies,
  applyInitialDependencies,
  applySimpleCondition,
} from "./Operations";

import Axios, { Method } from "axios";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
const fullWidthOp = false;

const MoneyField = (props) => {
  const { component: Component, inputRef, ...other } = props;

  const fnSum = (values, dependencia, valor) => {
    const result =
      values[dependencia[0]].reduce((ac, item) => {
        if (item && item[dependencia[1]] !== undefined)
          return ac + parseInt(item[dependencia[1]]);
        else return ac;
      }, 0) ?? 0;

    valor += result;

    return valor;
  };

  const fnMult = (values, dependencia, valor) => {
    const result =
      values[dependencia[0]].reduce((ac, item) => {
        if (item && item[dependencia[1]] !== undefined)
          return ac * parseInt(item[dependencia[1]]);
        else return ac;
      }, 1) ?? 1;

    valor *= result;

    return valor;
  };

  const operations = { sum: fnSum, mult: fnMult };
  const operationsInitialValue = { sum: 0, mult: 1 };
  const operationsInitialRender = { sum: 0, mult: 0 };
  const theme = useTheme();
  const usarField = useField(`${"Periodo"}.${"periodo_declarar"}`, undefined);
  const [valores, setValores] = React.useState([]);
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    meta: { touched, invalid, error },
    name,
    input,
    values,
    ...custom
  } = props;
  const context = useContext(ctxts);
  useEffect(() => {
    //si es un
    if (inputelement.dependsOn) {
      if (values) {
        const dependencias = inputelement.dependsOn;
        let valor = operationsInitialValue[inputelement.operation];
        console.log("CRASHEA ACA ", input.name);
        dependencias.forEach((el) => {
          console.log(el);
          const dependencia = el.split(".");
          if (Array.isArray(values[dependencia[0]])) {
            if (
              values[dependencia[0]] &&
              values[dependencia[0]][0] !== undefined
            ) {
              console.log(values[dependencia[0]]);
              valor = operations[inputelement.operation](
                values,
                dependencia,
                valor
              );
              console.log("RESULT ", valor);
            }
          }
          // NO ES UN ARRAY
          else {
            if (values[dependencia[0]]) {
              if (values[dependencia[0]][dependencia[1]] !== undefined)
                valor += parseInt(values[dependencia[0]][dependencia[1]]) ?? 0;
            }
          }
        });
        input.onChange(valor);
      }
    }
  });

  useEffect(() => {
    //si es un
    if (inputelement.dependsOn) {
      input.onChange(operationsInitialRender[inputelement.operation]);
    }
  }, []);

  const [msj, setMsj] = React.useState({
    show: false,
    type: "error",
    msj: inputelement.asyncValidationConf?.errorMsg,
  });
  const validar = () => {
    let url = "";
    if (inputelement.asyncValidation && inputelement.asyncValidationConf) {
      url = getUrl(
        input.name,
        inputelement.asyncValidationConf.url,
        props.cuit,
        values,
        null,
        null,
        inputelement.asyncValidationConf.baseUrl,
        context.BaseUrls
      );
      Axios({
        method: inputelement.asyncValidationConf.method as Method,
        url: url,
      })
        .then((response) => {
          const res = response.data;
          const apiValue = getDataFromResponse(
            res,
            inputelement.asyncValidationConf?.propertyText
          );
          if (
            String(apiValue) ===
            (inputelement.asyncValidationConf?.propertyValue).toString()
          ) {
            setMsj({
              show: true,
              type: "success",
              msj: inputelement.asyncValidationConf.successMsg
                ? inputelement.asyncValidationConf.successMsg
                : "correcto",
            });
          } else {
            setMsj({
              show: true,
              type: "error",
              msj: inputelement.asyncValidationConf.errorMsg
                ? inputelement.asyncValidationConf.errorMsg
                : "Incorrecto",
            });
          }
        })
        .catch(function (error) {
          setMsj({ show: true, type: "error", msj: error.message });

          console.log(error.message);
        });
      //get data from api with newUrl
    }
  };

  const handleChange = (event) => {
    console.log("handleCHange" + event.target.value);
    //  setValue(event.target.value);
    setValores(event.target.value);
    input.onChange(event.target.value);
  };

  return (
    <div>
      <FormControl
        {...custom}
        error={touched && invalid}
        className={classes.formcontrol}
      >
        {" "}
        <div className="d-flex">
          <NumberFormat
            variant={theme.inputs.variant ? theme.inputs.variant : "standard"}
            {...props}
            {...input}
            {...custom}
            name={name}
            mask={""}
            customInput={TextField}
            prefix={"$"}
            type="text"
            thousandSeparator={"."}
            decimalSeparator={","}
            onValueChange={({ value: v }) =>
              handleChange({ target: { name, value: v } })
            }
          />{" "}
          {inputelement.asyncValidation ? (
            <Button onClick={validar}>
              {inputelement.asyncValidationConf?.label}
            </Button>
          ) : null}
        </div>
        {msj.show ? (
          <span
            style={msj.type === "error" ? { color: "red" } : { color: "green" }}
          >
            {msj.msj}
          </span>
        ) : null}
        {props.meta.touched && error && (
          <span style={{ color: "red" }}>{error}</span>
        )}
      </FormControl>
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(MoneyField);
