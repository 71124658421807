import React, { useEffect, useRef } from "react";
import { useStyles } from "./styles";
import { InputElement } from "../models/IDynamicForm";
import FormControl from "@material-ui/core/FormControl";
import { useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getUrl,
  applyDependencies,
  applyInitialDependencies,
  applySimpleCondition,
  getDependencies,
  getMask,
  validarDato,
  dependenciaCondicional,
  getAsyncData,
  getDataFromResponse,
} from "./Operations";
import _ from "lodash";
import FormGroup from "@material-ui/core/FormGroup";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import { useContext } from "react";
import { ctxts } from "../DynamicFormContext";
const fullWidthOp = false;
const SwitchField = (props) => {
  const classes = useStyles();
  const inputelement: InputElement = props.inputelement;
  const {
    label,
    input,
    meta: { touched, invalid, error },
    name,
    meta,
    values,
    ...customs
  } = props;
  const { tramiteId, loadingAsyncData, isTableView, isLastPaso, ...custom } =
    customs;
  const theme = useTheme();
  const posibleValues = {
    on: inputelement.onValue ? inputelement.onValue : "Si",
    off: inputelement.offValue ? inputelement.offValue : "No",
  };
  const context = useContext(ctxts);
  const mountedRef = useRef(true);
  //#region  //? Condicional  visible o no config
  const [visible, setVisible] = React.useState(
    inputelement.isConditional ? false : true
  );
  const [urlCondition, setUrlCondition] = React.useState("");
  useEffect(() => {
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      dependenciaCondicional(inputelement, urlCondition, setVisible, props);
    }
  }, [urlCondition]);
  //#endregion
  //#region  //? mascara config
  const [reg, setReg] = React.useState([]);
  useEffect(() => {
    setReg(getMask(inputelement));
  }, [inputelement.mask]);
  //#endregion
  //#region //! actualizar  urls
  //? con estas dependencias y useEffect recalculo las urls
  //? solo cuando cambien los valores de los inputs que la url requiere
  const [isLoading, setIsLoading] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const getArrayDependecies = () => {
    const dependencias = getDependencies(input, inputelement);
    return [
      dependencias.map((d) => {
        const dependenciaValue = _.get(values, d);
        return dependenciaValue ? dependenciaValue : "";
      }),
    ];
  };
  useEffect(() => {
    if (inputelement.asyncData) {
      setUrl(
        getUrl(
          input.name,
          inputelement.asyncConf.url,
          props.cuit,
          values,
          inputelement.asyncConf.JsonFieldsName,
          null,
          inputelement.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
    if (inputelement.isConditional && inputelement.conditional?.asyncData) {
      setUrlCondition(
        getUrl(
          input.name,
          inputelement.conditional.asyncConf.url,
          props.cuit,
          values,
          inputelement.conditional.asyncConf.JsonFieldsName,
          null,
          inputelement.conditional.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [...getArrayDependecies()]);
  //#endregion
  //#region //? validaciones asyncronas
  const [msj, setMsj] = React.useState({
    show: false,
    type: "error",
    msj: inputelement.asyncValidationConf?.errorMsg,
  });

  const validar = () =>
    validarDato(inputelement, input, props, values, setMsj, context.BaseUrls);
  //#endregion
  //#region  //? calculos entre campos
  useEffect(() => {
    applyDependencies(props);
    if (inputelement.conditional && !inputelement.conditional.asyncData) {
      setVisible(applySimpleCondition(props));
    }
  });
  //#endregion
  //#region //?traer datos asincronos
  const dependencias = getDependencies(input, inputelement);
  const focusedInput = props.appContext.inputFocus;
  useEffect(() => {
    if (!props.isLastPaso) return;
    if (
      inputelement.asyncData &&
      !dependencias.some((d) => d === props.appContext.inputFocus)
    ) {
      setIsLoading(true);
      const response = getAsyncData(inputelement, url, props, input);
      response.then((res) => {
        if (!mountedRef.current) return null;
        setIsLoading(false);
        if (res) {
          const ValueToSet = getDataFromResponse(
            res,
            inputelement.asyncConf.propertyText
          );
          input.onChange(ValueToSet ? posibleValues.on : posibleValues.off);
        }
      });
    }
  }, [url, focusedInput]); //se ejecuta solo si la url cambia

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion
  //#region //? inicializar el componente
  useEffect(() => {
    if (!_.get(values, input.name)) {
      input.onChange(posibleValues.off);
    }
    applyInitialDependencies(props);
  }, [
    inputelement.dependsOn ? _.get(values, inputelement.dependsOn[0]) : null,
  ]);
  //#endregion
  useEffect(() => {
    if (!_.get(values, input.name)) {
      input.onChange(posibleValues.off);
    }
  }, []);
  const toggleChecked = () => {
    if (_.get(values, input.name) === posibleValues.off) {
      input.onChange(posibleValues.on);
    } else {
      input.onChange(posibleValues.off);
    }
    // input.onChange(!input.value);
  };
  if (visible) {
    return (
      <div
        className={isLoading || props.loadingAsyncData ? classes.loading : null}
      >
        <FormGroup>
          <div className={classes.switchForm}>
            <label className={classes.switchLabel}>{label}</label>
            <Tooltip title={input.value.toString()}>
              <Switch
                color="primary"
                // disabled={props.disabled}
                size="small"
                checked={
                  input.value.toString().toLowerCase() ===
                  posibleValues.on.toString().toLowerCase()
                }
                onChange={
                  !props.disabled
                    ? toggleChecked
                    : () => {
                        return;
                      }
                }
              />
            </Tooltip>
          </div>
          {props.meta.touched && error && (
            <span style={{ color: "red" }}>{error}</span>
          )}
        </FormGroup>
      </div>
    );
  } else {
    return null;
  }
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(SwitchField);
