enum validatorsCode {
  "required",
}
const validatorName = {
  required: "required",
};

export type ExtraProps = {
  max?: number | string;
  min?: number | string;
  default?: any;
  decimales?: number;
};
export type Condition = {
  observed?: string;
  value?: any;
  asyncData?: boolean;
  asyncConf?: {
    method: string;
    url: string;
    propertyText: string;
    propertyValue: string;
    baseUrl?: string;
    JsonFieldsName: string;
  };
};
export type OptionSelect = {
  default?: boolean;
  disabled?: boolean;
  id?: string;
  text: string;
  value: any;
};

// inputelement.conditional.asyncConf.JsonFieldsName
// inputelement.asyncValidationConf.JsonFieldsName
export type InputElement = {
  name: string;
  type: typeOfInputs;
  target?: string;
  to?: string;
  label: string;
  offValue?: string;
  onValue?: string;
  hideInModalview?: boolean;
  placeHolder?: string;
  required?: boolean;
  asyncData?: boolean;
  collapse?: any;
  mask?: string;
  allowNewTag?: boolean;
  urlImg?: string;
  urlImgBaseUrl?: string;
  imgWidth?: number;
  linkBaseUrl?: string;
  optionsFromGroup?: boolean;
  customDateMax?: string;
  dateMax?: string;
  dateMin?: string;
  customDateMin?: string;
  validatorParams?: any;
  optionsConfig?: {
    groupName: string;
    propertyText: string;
    propertyValue: string;
  };
  asyncConf?: {
    method: string;
    url: string;
    baseNode?: string;
    propertyText: string;
    propertyValue: string;
    baseUrl?: string;
    JsonFieldsName?: [
      {
        keyName?: string;
        iputName?: string;
      }
    ];
  };
  asyncValidation?: boolean;
  asyncValidationConf?: {
    label: string;
    method: string;
    url: string;
    propertyText: string;
    propertyValue: string;
    errorMsg: string;
    successMsg: string;
    JsonFieldsName: string;
    baseUrl?: string;
  };

  disabled?: boolean;
  dependsOn?: Array<string>;
  operation?: typeOfOperations;
  mimeTypes?: Array<string>;
  maxSize?: any;
  maxFiles?: number;
  texto?: string;
  referencia?: string;
  seCalcula?: boolean;
  validators?: Array<string>;
  extraProps?: ExtraProps;

  isConditional?: boolean;
  conditional?: Condition;
  options?: Array<OptionSelect>;

  element?: any; // heredado
  text?: any; // heredado
  static?: any; // heredado
  bold?: any; // heredado

  italic?: any; // heredado
  content?: any; // heredado
  canHaveAnswer?: any; // heredado
  canHavePageBreakBefore?: any; // heredado
  canHaveAlternateForm?: any; // heredado
  canHaveDisplayHorizontal?: any; // heredado
  canHaveOptionCorrect?: any; // heredado
  canHaveOptionValue?: any; // heredado
  field_name?: any; // heredado
  dirty?: any; // heredado
  _href?: any; // heredado
  file_path?: any; // heredado
  readOnly?: any; // heredado
  defaultToday?: any; // heredado
  dateFormat?: any; // heredado
  timeFormat?: any; // heredado
  showTimeSelect?: any; // heredado
  showTimeSelectOnly?: any; // heredado
};
export type InputGroup = {
  name: string;
  iterable: boolean;
  addButtonText?: string;
  removeButtonText?: string;
  show: typeOfGroups;
  otherGroupValues?: string;
  inputs: Array<InputElement>;

  asyncData?: boolean;
  asyncConf?: {
    method: string;
    url: string;
    dataKey?: string;
    JsonFieldsName?: [
      {
        keyName?: string;
        iputName?: string;
      }
    ];
  };
  initialValuesInmutables?: boolean;
  isConditional?: boolean;
  condition?: { observed?: string; value?: any; operation?: string };
};

export type StepForm = {
  idStep: string;
  titleStep: string;
  inputGroups: Array<InputGroup>;
  isConditional?: boolean;
  condition?: { observed?: string; value?: any; operation?: string };
};

export type IDynamicForm = {
  id: any;
  name: string;
  requirements?: string;
  description?: string;
  imageDetail?: string;
  requirementsImage?: string;
  submit?: string;
  format?: typeOfForms;
  steps: Array<StepForm>;
};

export type IApplication = {
  id?: any;
  description?: string;
  requirements?: string;
  requirementsImage?: string;
  step?: StepForm[];
  submit?: string;
};

export enum inputType {
  link = "link",
  date = "date",
  dateTimeAudit = "dateTimeAudit",
  select = "select",
  selectMultiple = "selectMultiple",
  number = "number",
  money = "money",
  imageRender = "imageRender",
  paragraph = "paragraph",
  text = "text",
  password = "password",
  checkbox = "checkbox",
  radiobutton = "radiobutton",
  slider = "slider",
  switch = "switch",
  time = "time",
  dateTime = "dateTime",
  dateRange = "dateRange",
  file = "file",
  avatar = "avatar",
  tags = "tags",
  selectSearch = "selectSearch",
  textArea = "textArea",
  dropDown = "dropDown",
  modalInput = "modalInput",
  collapse = "collapse",
  date_viewOnly = "date_viewOnly",
  select_viewOnly = "select_viewOnly",
  selectMultiple_viewOnly = "selectMultiple_viewOnly",
  number_viewOnly = "number_viewOnly",
  text_viewOnly = "text_viewOnly",
  password_viewOnly = "password_viewOnly",
  checkbox_viewOnly = "checkbox_viewOnly",
  radiobutton_viewOnly = "radiobutton_viewOnly",
  slider_viewOnly = "slider_viewOnly",
  switch_viewOnly = "switch_viewOnly",
  time_viewOnly = "time_viewOnly",
  dateTime_viewOnly = "dateTime_viewOnly",
  dateRange_viewOnly = "dateRange_viewOnly",
  file_viewOnly = "file_viewOnly",
  avatar_viewOnly = "avatar_viewOnly",
  tags_viewOnly = "tags_viewOnly",
  selectSearch_viewOnly = "selectSearch_viewOnly",
  textArea_viewOnly = "textArea_viewOnly",
  button = "button",
}

type typeOfForms = "tabs" | "steps" | "horizontalSteps";
type typeOfOperations =
  | "sum"
  | "minus"
  | "minusRow"
  | "mult"
  | "multRow"
  | "sumRow"
  | "max"
  | "same"
  | "min";
type typeOfGroups = "tableRow" | "modalView" | "groupHorizontal" | "group";

export enum formType {
  tabs = "tabs",
  steps = "steps",
  horizontalSteps = "horizontalSteps",
}
export enum groupType {
  tableRow = "tableRow",
  modalView = "modalView",
  groupHorizontal = "groupHorizontal",
  group = "group",
}

type typeOfInputs =
  | "date"
  | "link"
  | "select"
  | "selectMultiple"
  | "number"
  | "money"
  | "text"
  | "password"
  | "checkbox"
  | "radiobutton"
  | "paragraph"
  | "slider"
  | "switch"
  | "time"
  | "dateTime"
  | "dateRange"
  | "file"
  | "avatar"
  | "tags"
  | "selectSearch"
  | "textArea"
  | "date_viewOnly"
  | "select_viewOnly"
  | "selectMultiple_viewOnly"
  | "number_viewOnly"
  | "text_viewOnly"
  | "password_viewOnly"
  | "checkbox_viewOnly"
  | "radiobutton_viewOnly"
  | "slider_viewOnly"
  | "switch_viewOnly"
  | "time_viewOnly"
  | "dateTime_viewOnly"
  | "dateRange_viewOnly"
  | "file_viewOnly"
  | "avatar_viewOnly"
  | "tags_viewOnly"
  | "selectSearch_viewOnly"
  | "textArea_viewOnly"
  | "button";
