import React, { useEffect, Children } from "react";
import Container from "@material-ui/core/Container";
import apiCMS from '../../../services/CMSServices';
import SeccionHeader from "../../common/SeccionHeader";
import { FormattedMessage } from "react-intl";
import Button from "../../../BaseComponents/Buttons";
import Vencimientos from "../Components/Vencimientos";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
const useStyles = makeStyles({
  root: { margin: "80px auto" },
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "5px auto",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  expand: {},
  title: {
    color: "#444",
    fontSize: "24px",
    fontFamily: "roboto",
    fontWeight: 300,
    textAlign: "center",
  },
  footer: { display: "flex", margin: "30px auto", justifyContent: "center" },
});
const SectionVencimientos = (props) => {
  const [showAll, setShowAll] = React.useState(false);
  const [expirations, setExpirations] = React.useState([]);

  useEffect(() => {
    apiCMS.ExpirationsServices.getExpirationsData()
        .then((response) => {
          setExpirations(response.data);
        });
}, []);

  const handleClick = () => {
    setShowAll(!showAll);
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <h4 className={classes.title}>
          <FormattedMessage
            id="public.expiration.title"
            defaultMessage="Vencimientos"
          />
        </h4>
        <div
          className={
            showAll
              ? classes.container + " " + classes.expand
              : classes.container
          }
        >
          {expirations
            .slice(0, showAll ? expirations.length : 3)
            .map((el, i) => (
              <Vencimientos
                obj={el}
                key={i}
                last={ (i+1 )% 3 === 0 || i === expirations.length - 1}
              />
            ))}
        </div>
        <div className={classes.footer}>
          <Button variant="outlined" onClick={handleClick} color="primary">
            {!showAll ? (
              <FormattedMessage
                id="public.expiration.button.showAll"
                defaultMessage="Todos los vencimientos"
              />
            ) : (
              <FormattedMessage
                id="public.expiration.button.showLess"
                defaultMessage="Ver menos"
              />
            )}
          </Button>
        </div>
      </Container>
    </div>
  );
};
export default SectionVencimientos;
