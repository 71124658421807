import React from "react";
import Container from "@material-ui/core/Container";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search';
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "5px auto",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  margin:{
      margin:'20px 0'
  },btn:{
    marginRight: '-13px',
    height: '54px',
    background: '#848d9c',
    borderRadius: '0 3px 3px 0',
  }
});
const PaymentSearch = (props) => {
  const classes = useStyles();
  const [values, setValues] = React.useState('');

  const handleChange = (event) => {
    setValues(event.target.value);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit =(event)=>{
    props.onClick(values)
    event.preventDefault();
  }
  return (
    <form onSubmit={handleSubmit}
> <FormControl fullWidth className={classes.margin} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-amount">{props.label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-amount"
        value={values}
        onChange={handleChange}
       
        endAdornment={
        <InputAdornment position='end'>
          <Button className={classes.btn} 
          onClick={handleSubmit} 
          color={'primary'} 
          startIcon={<SearchIcon />}
          variant='contained'>
            Buscar</Button></InputAdornment>} 
            labelWidth={60} placeholder={props.placeholder}
      />
    </FormControl></form>   
  );
};
export default PaymentSearch;
