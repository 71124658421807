import React from "react";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableCell from "@material-ui/core/TableCell";
import {FormattedMessage} from "react-intl";
import {EContact, IContact} from "../../../../models/IContact";
import {Order} from "../../../../constants/tables";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    tableCell: {
        padding: '5px 16px'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
});

interface HeadCell {
    id: keyof IContact;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
    default: string;
}

const headCells: HeadCell[] = [
    {id: EContact.fechaInicio, numeric: false, disablePadding: true, label: 'contacts.tableHead.date', default: "Fecha"},
    {id: EContact.tipo, numeric: false, disablePadding: false, label: 'contacts.tableHead.type', default: "Tipo"},
    {
        id: EContact.descripcion,
        numeric: false,
        disablePadding: false,
        label: 'contacts.tableHead.description',
        default: "Descripción"
    }
];

interface ContactTableHeadProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IContact) => void;
    order: Order;
    orderBy: string;
}

const ContactTableHead = (props: ContactTableHeadProps) => {
    const classes = useStyles();
    const {order, orderBy, onRequestSort} = props;
    const createSortHandler = (property: keyof IContact) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (

                    <TableCell
                        className={classes.tableCell}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}>

                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
                            <FormattedMessage id={headCell.label} defaultMessage={headCell.default}/>
                        </TableSortLabel>

                    </TableCell>
                ))}

                <TableCell align={'right'}><FormattedMessage id={'contacts.tableHead.detail'}
                                                             defaultMessage={"Detalle"}/>

                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default ContactTableHead