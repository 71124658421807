import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import styled from "styled-components";

import NumberCurrency from '../../common/NumberCurrency';
const useStyles = makeStyles((theme: Theme) => createStyles({
  BtnPayMethod: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  Links: {
    textDecoration:'none',
    '&:hover':{
      textDecoration:'none'

}},
  BtnPayMethodFocus: {

  }, buttonProgress: {
    color: 'green',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  BtnData: {

  },
  BtnTitle: {

  }, BtnSubtitle: {},
  BtnFooter: {}

}));

const BoxHover = styled.div`
  border-radius: 6px;
  box-shadow: 10px 20px 30px 0 rgba(51, 51, 51, 0.2);
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  justify-content: center;
  padding: 40px 30px;
  text-align: center;
  transition: all .2s ease-in-out;
  .subtext{
    color: #939393;
    font-weight: 100;
    font-size: 15px;
  }
  .boxImage {
    display:block;
    height: 100%;
    margin: auto;
    object-fit: contain;
    text-align: center;
    width: 80%;
  }
  .boxTitle, .boxPayment {
    font-size: 1.4rem;
    @media(max-width: 1199px) {
      font-size: 1.4rem;
    }
  }
  .boxTitle {
    color: #444;
    font-weight:500;
    margin: 0;
    transition: all .2s ease-in-out;
  }
  .boxPayment {
    color: #0574b9;
    margin: 0;
    span {
      font-size:1.4rem ;
    }
  }
  .boxDescription {
    color: #eee;
    font-size: 1rem;
    margin: 10px auto 0;
    width: 90%;
    p {
      margin: 0;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        color: #eee;
        font-size: .8rem;
        list-style: none;
        &::before {
          content: "- ";
        }
      }
    }
  }
  &:not(:first-of-type) {
    margin-left: 3%;
    @media(max-width: 991px) {
      margin-left: 0;
    }
  }
`;





const BoxButtonElectronic = (props) => {
  const classes = useStyles({});
  let btnProps = props.btnProps;
  btnProps.subtext = btnProps.descripcion;
  btnProps.image = btnProps.url_icono;
  btnProps.state = "enabled";

  return (
    <div className={'boxCol'}>
     
        <BoxHover 
          onClick = {props.onClickFc}
          focusRipple
          key={btnProps.nombre}
          focusVisibleClassName={classes.BtnPayMethodFocus}
          className="boxHover"
        >
          <div>
          
            <div className="sc-gzVnrw gNVUbC">
              {btnProps.image ? <img src={btnProps.image} alt={btnProps.nombre} width={"100%"} />: null}
              <h3 className="boxTitle">{btnProps.nombre}</h3>
              <div className="boxPayment"> {btnProps.modifier ?
               <p>
                <NumberCurrency value={(props.total * btnProps.modifier).toFixed(props.config.decimals)} /></p> : btnProps.subText}</div>
              <div className="boxDescription">
              {btnProps.subtext ? <p  className="subtext"> {btnProps.subtext }</p> : null}
                <p> {props.children}</p>
              </div>
             
            </div>
          </div>
        </BoxHover> 
        
    </div>);

}

//export default BoxButtons;

const mapState = state => ({
  config: state.auth.config
})

export default connect(mapState, null)(BoxButtonElectronic);

