import moment from 'moment';
export default {
    state: {
        simulation: {},
        plans:[]
    },
    reducers: {
        setSimulation(state, simulation){
            let plans = [];
            simulation.planes.forEach((plan)=>{
                plans[plan.numeroPlan] = {
                  numeroPlan:plan.numeroPlan, 
                  totalPlan:plan.totalPlan, 
                  decreto:plan.decretos[0].id,
                  anticipo:plan.decretos[0].porcentajesInteres[0].anticipo_minimo,
                  cantidadCuotas:1,
                  fechaAnticipo:moment().format("DD/MM/YYYY"),
                  descripcionDecreto: plan.decretos[0].decreto,
                  descripcionPlan: plan.descripcion
                };
              })
            return Object.assign({}, {...state, simulation:simulation, plans:plans})
        },
        updatePlans(state,plans){
            let updatedPlans = {...state.plans};
            plans.forEach((plan)=>{
              updatedPlans[plan.numeroPlan] = {...updatedPlans[plan.numeroPlan],...plan}
            })
            return Object.assign({},{...state,plans:updatedPlans});
          },
          clearSimulation(state){
            return Object.assign({},{...state,plans:[], simulation:{}});
          }
    },
    effects: {},
}