
import React from 'react';
import {
  createMuiTheme,
  createStyles,
  withStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import { Link } from "react-router-dom";



const CLink = withStyles((theme: Theme) => ({
    a: {
      textDecoration:'none',
      color:'inherit',
      '&:hover': {
        textDecoration:'none',
        color:'inherit',
      },
      '&:active': {
        textDecoration:'none',
        color:'inherit',
      },
      '&:focus': {
        textDecoration:'none',
        color:'inherit',
      }
    },
  }))(Link);


export default CLink;