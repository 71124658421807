import React from "react";
import {
  createMuiTheme,
  createStyles,
  withStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import BreadcrumbsCustom from "./BreadCrumbsCustom";
import { connect } from "react-redux";

const CBreadcrumbs = withStyles((theme: Theme) => ({
  root: {
    margin: "20px 0",
    color: theme.colores.main,
    "& a": {
      color: theme.colores.main,
    },
  },
}))(BreadcrumbsCustom);

export default CBreadcrumbs;

// const mapState = (state) => ({
//   inIframe: state.auth.inIframe,
// });

// export default connect(mapState, null)(CBreadcrumbs);
