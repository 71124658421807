
import React from 'react';
import {
  createMuiTheme,
  createStyles,
  withStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";


const CButton = withStyles((theme: Theme) => ({
    root: {
      borderRadius: theme.botones.borderRadius,
    },
    outlinedPrimary: {
      borderColor: theme.botones.color,
      color:  theme.botones.color,
      '&:hover': {
        borderColor:  theme.botones.color,
        color:  theme.botones.color,
        backgroundColor:  theme.botones.color + '33',
      },
    },
    textPrimary:{
        color: theme.botones.color,
    },
    containedPrimary: {
      backgroundColor: theme.botones.color,
      color: theme.botones.colorContraste,
      '&:hover': {
        backgroundColor:theme.botones.color,
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: theme.botones.color,
        },
      },
    }, 
  }))(Button);


export default CButton;