import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ObligationList from '../../ObligationList';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlanMsj from '../PlanMsj';
import PlanSlider from '../PlanSlider';
import PlanInfo from '../PlanInfo';
import { PlanDePagos } from '../../../../mock/data';
import {
    DatePicker,
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import {useIntl, FormattedMessage } from "react-intl";
interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}


const useStyles = makeStyles({
    root: {
        width: 250,
    },
    input: {
        width: 42,
    },
    datePicker: {
        marginLeft: '40px'
    },
    Incluidas:{
    borderTop: 'solid 1px #e4e4e4',
    borderRadius: '0 0 5px 5px',
    background: '#f9f9f9',
    marginTop:' 20px',
    },Padding24
    :{padding:'24px'}

});

const PlanTab = (props) => { 
    
    const { formatMessage } = useIntl();
    const vence = new Date();
    vence.setDate(vence.getDate()+15);
    const Dec = props.Decreto;
    const ObligacionesIncluidas = props.Incluidas;
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(
        new Date(),
    );


    const [anticipo, setAnticipo] = React.useState(Dec.porcentajesInteres[0].anticipo_minimo);
    const [anticipoMinimo, setAnticipoMinimo] = React.useState(Dec.porcentajesInteres[0].anticipo_minimo);
    const [porcentajeInteres, setPorcentajeInteres] = React.useState(Dec.porcentajesInteres[0].porcentaje);
    const [cuotas, setCuotas] = React.useState(Dec.cantMinCuotas);
    const [fechaAnticipo, setfechaAnticipo] = React.useState(Date());


    const handleAnticipo=(e)=>
    {
        setAnticipo(e);
        props.onMontoAnticipoChanged(e);

    }
    const handleCuotas=(e)=>
    {
        setCuotas(e);
        const rango = Dec.porcentajesInteres.filter(p => (e >= p.cuota_desde) && (e <= p.cuota_hasta))[0];
        setAnticipoMinimo(rango.anticipo_minimo);
        setPorcentajeInteres(rango.porcentaje);
        props.onCuotaChanged(e);


    }
    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
        props.onFechaAnticipoChanged(date);
    };
    const classes = useStyles();
   
    return (
     
        <Grid container spacing={0} alignItems="center">
              <Grid item xs={12}>
              <Grid container spacing={3} className={classes.Padding24} alignItems="center">
          {Dec.msj &&   <PlanMsj Msj={Dec.msj} />}
            <Grid item  xs={12} md={9} lg={9}>
                <Grid container spacing={2} alignItems="center">
                    <PlanSlider
                        onChange={e=>handleAnticipo(e)}
                        steps={50}
                        msjSlider={formatMessage({id:"paymentsPlan.obtain", defaultMessage:"obtenga "}) + Dec.cantMaxCuotas + formatMessage({id:"paymentsPlan.feesmessage", defaultMessage:" cuotas con un anticipo minimo de "}) + Dec.porcentajesInteres[0].anticipo_minimo}
                        title={<FormattedMessage
                        id="paymentsPlanTab.title.anticipo"
                        defaultMessage="¿Cuál será su anticipo de Plan?"
                    />}
                        min={anticipoMinimo}
                        labelMin={"$" + anticipoMinimo}
                        max={props.totalActualizado}
                        initial={anticipoMinimo}
                        labelMax={"$".concat(props.totalActualizado.toString())}
                    />
                    <PlanSlider
                       onChange={e=>handleCuotas(e)}
                        initial={Dec.cantMinCuotas}
                        steps={1}
                        msjSlider={''}
                        title={<FormattedMessage
                            id="paymentsPlanTab.title.cuotas"
                            defaultMessage="¿En cuantas cuotas?"
                        />}
                        min={Dec.cantMinCuotas}
                        labelMin={Dec.cantMinCuotas + formatMessage({id:"paymentsPlan.Cuota", defaultMessage:"Cuota "})}
                        max={Dec.cantMaxCuotas}
                        labelMax={Dec.cantMaxCuotas + formatMessage({id:"paymentsPlan.Cuotas", defaultMessage:"Cuotas "})} />
                    <Grid item xs={12}>
                        <div className={classes.datePicker}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"

                                    margin="normal"
                                    minDate={Date()}
                                    maxDate={vence}
                                    id="date-picker-in ine"
                                    label={<FormattedMessage
                                    id="paymentsPlanTab.anticipo.fecha"
                                    defaultMessage="Fecha anticipo"
                                />}
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            
            <Grid item xs={12} md={3} lg={3}>
                <PlanInfo Anticipo={anticipo}
                    Cuotas={cuotas}
                    ValorCuotas={typeof anticipo === 'number' && typeof cuotas === 'number' ? ((props.totalActualizado - anticipo) / cuotas) : 0}
                    Valor={props.totalActualizado}
                    Incluye={[formatMessage({id:"paymentsPlanTab.detail.includes", defaultMessage:"Incluye $ "})+ props.totalIntereses + formatMessage({id:"paymentsPlanTab.message.interests", defaultMessage:"de intereses"})]} />
            </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12}>
                <ExpansionPanel className={classes.Incluidas}>
                    <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Typography><FormattedMessage
                                    id="paymentsPlanTab.message.included"
                                    defaultMessage="Obligaciones incluidas"
                                /></Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ObligationList editable eliminable simulation obligationList={ObligacionesIncluidas} handleDelete={props.handleDelete}/>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        </Grid>
    );
}
export default PlanTab;


