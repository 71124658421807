import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
let icon = require("../../../assets/iconos/bottom-alert@1x.png");

interface msjProps {
  msj: string;
  type: string;
  subMsj?: string;
  action: [];
  link?: string;
}

interface EndMessageProps {
  msj: {
    msj: string;
    type: string;
    subMsj?: string;
    action?: [
      {
        action?: string;
        actionTxt?: string;
        type?: string;
      }
    ];
    link?: string;
  };
  loading: boolean;
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: "20px",
    },
  },
  Paper: {
    margin: "40px",
    padding: "40px",
  },
  HhCnter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    textAlign: "center",
  },
  vCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
  icon: {
    fontSize: "160px",
    color: "#32d859",
  },
  msj: {
    maxWidth: "80%",
    margin: "auto",
    color: "#444",
    padding: "50px",
  },
  header: {
    fontFamily: "roboto",
    fontWeight: 100,
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  btns: {
    margin: "5px",
  },
  lista:{
 
    margin:'auto'
  },
  listaElem:{
    display:'table',
    margin:'auto',
    textAlign: 'left',
    fontStyle: 'italic',
    color: '#949494',
  }
});

const EndMessage = (props) => {
  switch (props.msj.type) {
    case "success": {
      icon = require("../../../assets/iconos/bottom-success@1x.png");
      break;
    }
    case "alert": {
      icon = require("../../../assets/iconos/bottom-alert@1x.png");
      break;
    }
    case "error": {
      icon = require("../../../assets/iconos/bottom-error@1x.png");
      break;
    }
    default: {
      icon = require("../../../assets/iconos/bottom-alert@1x.png");
    }
  }

  const classes = useStyles();

  if (props.loading) {
    return (
      <Grid container className={classes.msj}>
        <Grid className={classes.HhCnter} item xs={12}>
          <div className={classes.vCenter}>
            <h1 className={classes.header}> {props.msj.msj}</h1>
            <br />
            <p>{props.msj.subMsj}
           
</p>
            <br />
          </div>
        </Grid>
        <Grid className={classes.HhCnter} item xs={12}>
          <div className={classes.root}>
            <LinearProgress />
          </div>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container className={classes.msj}>
        <Grid className={classes.HhCnter} item xs={12}>
          <div className={classes.vCenter + " " + classes.icon}>
            <img className={classes.icon} src={icon} alt="" />
          </div>
        </Grid>
        <Grid className={classes.HhCnter} item xs={12}>
          <div className={classes.vCenter}>
            <h1 className={classes.header}> {props.msj.msj}</h1>
            <br />
            <p>{props.msj.subMsj}
            {props.msj.confirmedObjects ? (
                props.msj.confirmedObjects.map((el) => (
                  <span className={classes.listaElem}>
                     {el}
                  </span>
                
                ))
            
            ) : null}</p>
            <br />
            <div className={classes.actions}>
              {props.msj.action.map((act) => (
                <Link className={classes.btns} to={act.action}>
                  <Button
                    variant={act.type == "primary" ? "contained" : "outlined"}
                    color="primary"
                  >
                    {act.actionTxt}
                  </Button>
                </Link>
              ))}
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default EndMessage;
