import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
let icon = require("../../../assets/iconos/center-error@1x.png");

const useStyles = makeStyles({
   
});

const AgentAction = (props) => {
    const classes = useStyles();
   
    return (
        <Alert icon={<img src={icon} />} variant="outlined" severity="error">
        <AlertTitle>
          <FormattedMessage
            id="agentDashboard.representation.ErrorMsj"
            defaultMessage="No encontramos el sujeto"
          />
        </AlertTitle>
        <FormattedMessage
          id="agentDashboard.representation.errSubmsj"
          defaultMessage="Intenta con otro parametro de busqueda"
        />
      </Alert>
    );
  };

  export default AgentAction;
