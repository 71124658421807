import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainRouter from "./MainRouter";
import AuthContext from "./AuthContext";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import defTheme from "../Theme/Theme";
import { corrTheme, convergTheme } from "../Theme/Theme";
import apiCMS from "../../services/CMSServices";

export default function Shell() {
  const [theme, setTheme] = useState(defTheme(convergTheme));

  useEffect(() => {
    apiCMS.ConfigServices.getThemeSelected()
      .then((res) => {
        setTheme(defTheme(res.data.json));
      })
      .catch((err) => console.log("Error al obtener estilos. " + err.message));
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <div
          style={{
            background: theme.colores.body,
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <AuthContext></AuthContext>
          </MuiPickersUtilsProvider>
        </div>
      </ThemeProvider>
    </Router>
  );
}
