export const vencimientos = {
  objetos: [
    {
      id: "110124257251",
      tipo: "I",
      categoria: "0",
      descripcion: "RAFAEL NUÑEZ 5400 X5000EMY CORDOBA - CAPITAL",
      estado: "ACTIVO",
      fecha_fin: null,
      fecha_inicio: null,
      saldo: 0,
      base_imponible: 1300000,
      etiquetas: "TEST ETIQUETA",
      id_externo: "123456",
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "20200000000001228887",
          capital: 1250,
          cuota: "7",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-08-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 1500,
          saldo: 1500,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "101",
          impuesto: "5",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "110124257251",
          objeto_tipo: "I",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 1500,
          descuento: 0,
        },
      ],
    },
    {
      id: "650369123",
      tipo: "E",
      categoria: "0",
      descripcion: "Contribuyente Local",
      estado: "ACTIVO",
      fecha_fin: null,
      fecha_inicio: null,
      saldo: 0,
      base_imponible: null,
      etiquetas: "PRUEBA ETIQUETA",
      id_externo: "220390",
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "20200000000009097623",
          capital: 1600,
          cuota: "7",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-08-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 2000,
          saldo: 2000,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "101",
          impuesto: "5",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "650369123",
          objeto_tipo: "E",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 2000,
          descuento: 0,
        },
      ],
    },
    {
      id: "A132FED",
      tipo: "A",
      categoria: "0",
      descripcion: "SUZUKI - MOTOCICLETA - VSTROM650",
      estado: "ACTIVO",
      fecha_fin: null,
      fecha_inicio: null,
      saldo: 0,
      base_imponible: 1200000,
      etiquetas: "Test",
      id_externo: "91248135",
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "20200000000009998887",
          capital: 1000,
          cuota: "7",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-08-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 1200,
          saldo: 1200,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "101",
          impuesto: "6",
          exenta: true,
          porcentaje_exencion: 80,
          sujeto_id: "20-35090403-5",
          objeto_id: "A132FED",
          objeto_tipo: "A",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 1200,
          descuento: 0,
        },
      ],
    },
    {
      id: "AA999AA",
      tipo: "A",
      categoria: "0",
      descripcion: "FORD - AUTOMOVIL - FOCUS 1.6 SE",
      estado: "ACTIVO",
      fecha_fin: null,
      fecha_inicio: null,
      saldo: 0,
      base_imponible: 800000,
      etiquetas: "CASA DE MIS VIEJOS",
      id_externo: "91248100",
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "20200000000001118887",
          capital: 1000,
          cuota: "7",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-08-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 1200,
          saldo: 1200,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "101",
          impuesto: "6",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "AA999AA",
          objeto_tipo: "A",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 1200,
          descuento: 0,
        },
      ],
    },
    {
      id: "AB888AB",
      tipo: "A",
      categoria: "0",
      descripcion: "FORD - ECOSPORT 1.6 SE",
      estado: "ACTIVO",
      fecha_fin: null,
      fecha_inicio: null,
      saldo: 0,
      base_imponible: 550000,
      etiquetas: null,
      id_externo: "333454",
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "20210000000001228887",
          capital: 500,
          cuota: "7",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-08-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 600,
          saldo: 600,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "101",
          impuesto: "6",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "AB888AB",
          objeto_tipo: "A",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 600,
          descuento: 0,
        },
      ],
    },
    {
      id: "AC123AC",
      tipo: "A",
      categoria: "0",
      descripcion: "HONDA - QUADBIKE - C600",
      estado: "ACTIVO",
      fecha_fin: null,
      fecha_inicio: null,
      saldo: 0,
      base_imponible: 350000,
      etiquetas: "Etiquetest",
      id_externo: "492782",
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "20220000000001228887",
          capital: 500,
          cuota: "7",
          estado: null,
          fiscalizada: "N",
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: null,
          prorroga: "2020-08-10T00:00:00.000Z",
          tipo: "tributaria",
          total: 600,
          saldo: 600,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "101",
          impuesto: "6",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "AC123AC",
          objeto_tipo: "A",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 600,
          descuento: 0,
        },
      ],
    },
    {
      id: "PP1591207525203",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-03T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "202015912075252952",
          capital: 350,
          cuota: "2",
          estado: null,
          fiscalizada: null,
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: "77da453e-028d-4e11-a15e-fcad8125f251",
          prorroga: "2020-08-15T00:00:00.000Z",
          tipo: null,
          total: 350,
          saldo: 350,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "999",
          impuesto: "9",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "PP1591207525203",
          objeto_tipo: "P",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 350,
          descuento: 0,
        },
      ],
    },
    {
      id: "PP1591207525208",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-03T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "202015912075252392",
          capital: 250,
          cuota: "2",
          estado: null,
          fiscalizada: null,
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: "05595d9e-d95a-49c5-beeb-48a9688d2eb8",
          prorroga: "2020-08-15T00:00:00.000Z",
          tipo: null,
          total: 250,
          saldo: 250,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "999",
          impuesto: "9",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "PP1591207525208",
          objeto_tipo: "P",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 250,
          descuento: 0,
        },
      ],
    },
    {
      id: "PP1591371406001",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-05T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "202015913714060272",
          capital: 250,
          cuota: "2",
          estado: null,
          fiscalizada: null,
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: "f0600697-0a7a-4ac8-a45a-9e5f9c808a20",
          prorroga: "2020-08-15T00:00:00.000Z",
          tipo: null,
          total: 250,
          saldo: 250,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "999",
          impuesto: "9",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "PP1591371406001",
          objeto_tipo: "P",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 250,
          descuento: 0,
        },
      ],
    },
    {
      id: "PP1591371845798",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-05T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "202015913718458162",
          capital: 89.82666778564453,
          cuota: "2",
          estado: null,
          fiscalizada: null,
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: "903b715f-ec2e-449d-a7a9-67e7ee11ba8e",
          prorroga: "2020-08-15T00:00:00.000Z",
          tipo: null,
          total: 89.82666778564453,
          saldo: 89.82666778564453,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "999",
          impuesto: "9",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "PP1591371845798",
          objeto_tipo: "P",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 89.82666778564453,
          descuento: 0,
        },
      ],
    },
    {
      id: "PP1591371845799",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-05T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "202015913718458202",
          capital: 504.5733337402344,
          cuota: "2",
          estado: null,
          fiscalizada: null,
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: "4dc91a29-46fd-4455-a064-c53ab23014f0",
          prorroga: "2020-08-15T00:00:00.000Z",
          tipo: null,
          total: 504.5733337402344,
          saldo: 504.5733337402344,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "999",
          impuesto: "9",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "PP1591371845799",
          objeto_tipo: "P",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 504.5733337402344,
          descuento: 0,
        },
      ],
    },
    {
      id: "PP1591372995428",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-05T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [],
    },
    {
      id: "PP1591373208015",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-05T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [],
    },
    {
      id: "PP1591373208016",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-05T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [],
    },
    {
      id: "PP1591384522195",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-05T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "202015913845222672",
          capital: 95,
          cuota: "2",
          estado: null,
          fiscalizada: null,
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: "1dec4eeb-c49b-4e42-ad93-ca80646a4604",
          prorroga: "2020-08-15T00:00:00.000Z",
          tipo: null,
          total: 95,
          saldo: 95,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "999",
          impuesto: "9",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "PP1591384522195",
          objeto_tipo: "P",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 95,
          descuento: 0,
        },
      ],
    },
    {
      id: "PP1591384522196",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-05T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [
        {
          id: "202015913845222182",
          capital: 415.385009765625,
          cuota: "2",
          estado: null,
          fiscalizada: null,
          indice_interes_punitorio: null,
          indice_interes_resarcitorio: null,
          interes_punitorio: null,
          interes_resarcitorio: null,
          juicio_id: null,
          periodo: "2020",
          plan_id: "a9362f5b-f3f5-4811-a79b-7617ca92794a",
          prorroga: "2020-08-15T00:00:00.000Z",
          tipo: null,
          total: 415.385009765625,
          saldo: 415.385009765625,
          vencimiento: "2020-08-10T00:00:00.000Z",
          concepto: "999",
          impuesto: "9",
          exenta: null,
          porcentaje_exencion: null,
          sujeto_id: "20-35090403-5",
          objeto_id: "PP1591384522196",
          objeto_tipo: "P",
          vencida: false,
          interesRecargo: 0,
          saldoConRecargo: 415.385009765625,
          descuento: 0,
        },
      ],
    },
    {
      id: "PP1591385829615",
      tipo: "P",
      categoria: null,
      descripcion: "Plan de Pago",
      estado: null,
      fecha_fin: null,
      fecha_inicio: "2020-06-05T00:00:00.000Z",
      saldo: null,
      base_imponible: null,
      etiquetas: null,
      id_externo: null,
      datos_adicionales: null,
      sujeto_id: "20-35090403-5",
      obligaciones: [],
    },
  ],
};
