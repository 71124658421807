import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import arrayMutators from "final-form-arrays";
import { Form, FormSpy } from "react-final-form";
import Steps from "./FormComponents/Steps";
import TabsView from "./FormComponents/Steps/TabsView";
import HorizontalSteps from "./FormComponents/Steps/HorizontalSteps";
import Paper from "@material-ui/core/Paper";
import { AppContextInterface, AppContextProvider } from "./DynamicFormContext";

import Footer from "./FormComponents/Footer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";

//#region Estilos
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    titulodelForm: {
      padding: "10px 10px",
      textTransform: "capitalize",
      color: "#6b6b6b",
      borderBottom: "solid 1px #444",
    },
    contForm: {
      [theme.breakpoints.down("sm")]: {
        padding: "0",
      },
    },
    superficie: {
      border: theme.superficies.border,
      borderRadius: theme.superficies.borderRadius,
    },
  })
);
//#endregion

const DynamicForm = (props: any) => {
  const classes = useStyles();
  const formulario = props.jsonform;
  const [inputFocus, setInputFocus] = React.useState(null);

  let inputfoc = null;
  const setInptFoc = (n) => {
    inputfoc = n;
    DynamicContext.inputFocus = n;
  };

  const DynamicContext: AppContextInterface = {
    viewOnly: props.viewOnly,
    initialSnapshot: props.initial,
    tramiteId: props.tramiteId,
    pasoId: props.pasoId,
    lastPasoId: props.lastPaso,
    inputFocus: inputfoc,
    setInputFocus: setInptFoc,
    BaseUrls: formulario.BaseUrl,
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppContextProvider value={DynamicContext}>
      <Paper
        className={classes.superficie}
        elevation={theme.superficies.elevation}
      >
        <Typography variant="h6" className={classes.titulodelForm}>
          {props.title}{" "}
          <small>
            {props.tramiteId
              ? "# " + props.tramiteId.slice(-6, props.tramiteId.length)
              : null}
          </small>
        </Typography>

        <div className={classes.contForm}>
          <Form
            onSubmit={() => {}}
            mutators={{ ...arrayMutators }}
            initialValues={
              props.initial
                ? {
                    paramTramite: {
                      id: props.paramTramiteId,
                      nombre: props.title,
                      tipo: props.paramTramiteTipo,
                    },
                    ...props.initial,
                  }
                : {
                    paramTramite: {
                      id: props.paramTramiteId,
                      nombre: props.title,
                      tipo: props.paramTramiteTipo,
                    },
                  }
            }
            render={({
              handleSubmit,
              form: {
                mutators: { push, pop }, // injected from final-form-arrays above
              },
              hasValidationErrors,
            }) => (
              <FormSpy>
                {({ values, errors, ...rest }) => (
                  <form onSubmit={handleSubmit} className={classes.root}>
                    {formulario.format === "horizontalSteps" && !mobile ? ( //HorizontalSteps renderizo grupo segun tipo de formulario
                      <HorizontalSteps
                        push={push}
                        pop={pop}
                        values={values}
                        steps={formulario.steps}
                        rest={rest}
                        errors={errors}
                        tramiteId={props.tramiteId}
                        Footer={
                          <Footer
                            disabledFooter={props.disabledFooter}
                            values={values}
                            formulario={formulario}
                            errors={errors}
                            hasValidationErrors={hasValidationErrors}
                            handleSubmit={handleSubmit}
                            viewOnly={props.viewOnly}
                            tramiteId={props.tramiteId}
                            paramTramiteId={props.paramTramiteId}
                            handleVolver={props.handleVolver}
                            enriquecer={props.enriquecer}
                            pasoId={props.pasoId}
                            title={props.title}
                            paramTramiteTipo={props.paramTramiteTipo}
                          />
                        }
                      />
                    ) : formulario.format === "steps" ||
                      (formulario.format === "horizontalSteps" && mobile) ? (
                      <Steps //steps
                        push={push}
                        pop={pop}
                        values={values}
                        rest={rest}
                        errors={errors}
                        tramiteId={props.tramiteId}
                        steps={formulario.steps}
                        Footer={
                          <Footer
                            disabledFooter={props.disabledFooter}
                            values={values}
                            formulario={formulario}
                            errors={errors}
                            hasValidationErrors={hasValidationErrors}
                            handleSubmit={handleSubmit}
                            viewOnly={props.viewOnly}
                            tramiteId={props.tramiteId}
                            paramTramiteId={props.paramTramiteId}
                            handleVolver={props.handleVolver}
                            enriquecer={props.enriquecer}
                            pasoId={props.pasoId}
                            title={props.title}
                            paramTramiteTipo={props.paramTramiteTipo}
                          />
                        }
                      />
                    ) : (
                      <TabsView //tabs
                        push={push}
                        pop={pop}
                        mobile={mobile}
                        rest={rest}
                        errors={errors}
                        values={values}
                        steps={formulario.steps}
                        tramiteId={props.tramiteId}
                        Footer={
                          <Footer
                            disabledFooter={props.disabledFooter}
                            values={values}
                            formulario={formulario}
                            errors={errors}
                            hasValidationErrors={hasValidationErrors}
                            handleSubmit={handleSubmit}
                            viewOnly={props.viewOnly}
                            tramiteId={props.tramiteId}
                            paramTramiteId={props.paramTramiteId}
                            handleVolver={props.handleVolver}
                            enriquecer={props.enriquecer}
                            pasoId={props.pasoId}
                            title={props.title}
                            paramTramiteTipo={props.paramTramiteTipo}
                          />
                        }
                      />
                    )}
                    {/* <Footer
                      values={values}
                      hasValidationErrors={hasValidationErrors}
                      handleSubmit={handleSubmit}
                      viewOnly={props.viewOnly}
                      tramiteId={props.tramiteId}
                      paramTramiteId={props.paramTramiteId}
                      handleVolver={props.handleVolver}
                      enriquecer={props.enriquecer}
                      pasoId={props.pasoId}
                    /> */}
                  </form>
                )}
              </FormSpy>
            )}
          />
        </div>
      </Paper>
    </AppContextProvider>
  );
};

export default DynamicForm;
