import React, { useEffect } from "react";
import Contacts from "../../components/MyContacts/";
import Breadcrumbs from "../../components/common/MaterialComponents/Breadcrumbs";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Widgets as mock } from "../../mock/Widgets.mock";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useLocation, useHistory } from "react-router-dom";

import IFrame from "./IFrame";
const useStyles = makeStyles((theme) =>
  createStyles({
    widgetContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",

      gridGap: "10px",
      padding: "0 0 90px",
    },
    iframe: {
      width: "100%",
      border: "none",
    },
    title: {
      paddingLeft: "10px",
    },
    widget: {},
    widgetSuperf: {
      padding: "15px 0px",
      width: "100%",

      height: "100%",
      boxShadow: theme.shadows[theme.superficies.elevation],
      border: theme.superficies.border,
    },
    ifContainer: {
      height: "calc(100% - 40px)",
      // marging: "10px",
      padding: "0 5px",
    },
  })
);
const WidgetDashboard = (props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [width, setWidth] = React.useState(0);
  const classes = useStyles(width);
  const ref = React.useRef(null);
  const [Widgets, setWidgets] = React.useState([]);
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    console.log(ref.current ? ref.current.offsetWidth : "ni");
    setWidth(ref.current ? ref.current.offsetWidth : 0);
  }, [ref.current]);

  useEffect(() => {
    if (!location.state) {
      // TODO: define an error
      console.error("error en el objeto widgets");
      history.goBack();
    }
    if (location.state && location.state["widgets"]) {
      // setWidgets(mock);

      setWidgets(location.state["widgets"]);
    }
  });

  const getWidth = (w) => {
    return `${(w * width) / 12}px`;
  };

  const getHeight = (h) => {
    return `${(h * width) / 12}px`;
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/dashboard">Inicio</Link>

        <Typography color="textPrimary"> Gráficas</Typography>
      </Breadcrumbs>
      <div
        className={classes.widgetContainer}
        ref={ref}
        style={{ gridTemplateRows: `repeat(20,${width / 12}px)` }}
      >
        {Widgets.map((widget) => (
          <div
            className={classes.widget}
            style={{
              gridColumn: `auto / span ${mobile ? 12 : widget.width}`,

              gridRow: `auto / span ${
                mobile ? (widget.height * 12) / widget.width : widget.height
              }`,
            }}
          >
            <Paper className={classes.widgetSuperf}>
              <Typography
                className={classes.title}
                gutterBottom
                variant="h5"
                component="h2"
              >
                {widget.title}
              </Typography>
              <div className={classes.ifContainer}>
                <iframe
                  id="iframepage"
                  className={classes.iframe}
                  title="estado de cuenta"
                  width="100%"
                  height="100%"
                  src={widget.url}
                ></iframe>
              </div>
            </Paper>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WidgetDashboard;
