import React from 'react';
import {FormattedMessage} from "react-intl";
import moment from 'moment';
import 'moment-timezone';

const ObligationExpire = (props) => {

    const Dias = props.expirationDate.diff(moment(), 'days');

    if (Dias < 31 && Dias > 0) {
        return (
            <p><FormattedMessage id="myTaxes.expiresIn" defaultMessage="Vence en"/> {Dias.toString()} {Dias > 1 ?
                <FormattedMessage id="myTaxes.days" defaultMessage="dias"/> :
                <FormattedMessage id="myTaxes.day" defaultMessage="dia"/>} - {props.expirationDate.format('DD/MM/YYYY')}
            </p>)
    }

    if (Dias < 0) {
        return (<p><FormattedMessage id="myTaxes.alredyExpired"
                                     defaultMessage="La obligacion vencio el"/> {props.expirationDate.format('DD/MM/YYYY')}
        </p>);
    }

    if (Dias === 0) {
        return (<p><FormattedMessage id="myTaxes.expiresToday"
                                     defaultMessage="La obligacion vence hoy"/> - {props.expirationDate.format('DD/MM/YYYY')}
        </p>);
    }

    return (<p><FormattedMessage id="myTaxes.expiresOn"
                                     defaultMessage="Vence el"/> {props.expirationDate.format('DD/MM/YYYY')}</p>);
}

export default ObligationExpire;