import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import './styles.css';

function JuicioTags(props) {
    const renderTags = () => {
        const tagsToRender = [];
        tagsToRender.push(<span key={'Judicial'} className={"estadoCuota"} style={{background: "red"}}>Judicial</span>);
        return tagsToRender;
    }

    return(<div>{renderTags()}</div>);
}

const mapState = state => ({
    config: state.auth.config,
    tags: state.tags.tags,
    locale: state.auth.locale,
})

export default connect(mapState, null)(JuicioTags);