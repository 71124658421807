import axios, { Method } from "axios";


/**
 * Receive an array and an N value, return the first N elements of the array
 * @param any array
 * @param number n
 */
export const first =  function(array: any[], n: number) {
    if (array == null)
        return void 0;
    if (n == null)
        return array[0];
    if (n < 0)
        return [];
    return array.slice(0, n);
};

export const replaceUrlParams = function(url: string, params: any) {
    for(var propertyName in params) {
        var re = new RegExp('{' + propertyName + '}', 'gm');
        url = url.replace(re, params[propertyName]);
    }
    return url;
  }

export const mapData = (from : any, to : any) => 
  {
      let parse = {};
      
      if(to === null || to === undefined)
        return parse;
      
      if(from === null || to === undefined)
        return parse;

      const keys = Object.keys(to);
      keys.forEach(key => { 
          if(typeof to[key] === 'string' && to[key].indexOf('{') >= 0 )
          {
              parse[key] = from[to[key].replace(/{/g,'').replace(/}/g,'')];
          }
          else
          {
              parse[key] = to[key];
          }
      });

      return parse;
  }

  export type InvokeAPIConfig = {
    url: string;
    sendAuthHeader: boolean;
    method: string;
    jsonRequest?: object;
    jsonResponse?: object;
  };
  
export const invokeAPI = (config:InvokeAPIConfig, data:object, jwt:string) =>{
    return new Promise((resolve, reject) => {
        axios(
            {
            method: config.method as Method,
            url: replaceUrlParams(config.url, data),
            headers: config.sendAuthHeader? {'Authorization': `Bearer ${jwt}`} : {},
            data: mapData(data, config.jsonRequest)
            }
        ).then((res) => {
            let parsedResponse : any = mapData(res.data, config.jsonResponse);
            parsedResponse = { ...parsedResponse, ...data};
            resolve(parsedResponse);
        })
        .catch((err) => reject(err));
    })
}