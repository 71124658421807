import React, { Component } from 'react';
import styled from "styled-components";
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';

const StyledSup = styled.sup`
    font-size: 60%;
    margin-left: 0.2em;
    letter-spacing: 0.02em;
`;

const NumberCurrency = (props) => {
    const formatCurrency = (value) => {
        const value_parts = value.split('.');
        return <span>{value_parts[0]}<StyledSup>{value_parts[1]}</StyledSup></span>;
    }

    return(
        <CurrencyFormat prefix={props.config.currencySymbol} value={props.value} renderText={formatCurrency} displayType={'text'} decimalSeparator={'.'} thousandSeparator={","} />
        );
}

const mapState = state => ({
    config: state.auth.config,
})

export default connect(mapState, null)(NumberCurrency);