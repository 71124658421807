import React from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import AddMoreButton from "../../PaymentMethods/AddMoreButton";
import Button from "../MaterialComponents/Button";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
interface SeccionHeaderProps {
  title: String;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Header: {
      marginTop: "40px",
      marginBottom: "10px",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: theme.sectionHeader.borderBottom,
    },
    Title: {
      color: theme.sectionHeader.color,
      fontSize: "2rem",

      fontWeight: theme.sectionHeader.fontWeight,
    },
    right: {
      textAlign: "right",
    },
  })
);

const SeccionHeader = (props) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.Header}>
      <Grid item>
        <Typography variant="h4" className={classes.Title}>
          {props.leftIcon ? props.leftIcon : null}
          {props.title}{" "}
          {props.refresh ? (
            <IconButton
              disabled={props.refreshing}
              aria-label="actualizar"
              component="span"
              onClick={props.refresh}
            >
              <Refresh />
            </IconButton>
          ) : null}
        </Typography>
      </Grid>
      <Grid item className={classes.right} md={4}>
        {props.action && (
          <Link to={props.actionTo} onClick={props.handleClick}>
            <Button variant="contained" size="small" color="primary">
              {props.actionLabel}
            </Button>
          </Link>
        )}
        {props.children}
      </Grid>
    </Grid>
  );
};
export default SeccionHeader;
