import React, {useEffect} from 'react';
import {FormattedMessage} from "react-intl";
import SeccionHeader from '../common/SeccionHeader';
import {makeStyles} from '@material-ui/core/styles';
import ContactsDetail from './ContactsDetail';
import ContactsMsj from './ContactsMsj'
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import TableSkeleton from "../common/TableSkeleton";
import ContactTabs from "./ContactTabs";
import apiBUC from '../../services/BUCServices';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        marginBottom: '50px'
    },
    tableCell: {
        padding: '5px 16px'
    },
    tableHead: {
        background: '#efefef'
    },
    falsoTexto: {

        width: '63%',
        height: '15px',
        borderRadius: '15px',
        display: 'inline-block',
        background: '#dadada',

    },
    tabs: {
        background: 'white',
        flexDirection: 'row',
        '& span': {
            flexDirection: 'row',
        }
    },
    tableRow: {
        cursor: 'pointer',
        '&:hover': {
            background: '#f3f3f3'
        }
    }
    , tabsContainer: {
        boxShadow: ' inset 3px -1px 2px 0px #dddddd8c',
        background: ' #f7f7f7'
    }
    , buscar: {
        marginTop: '-16px',
        marginBottom: '16px',
    }

});


const Contacts = (props) => {

    const [loadingApplications, setLoadingApplications] = React.useState(true);
    const [contactList, setContactList] = React.useState([]);
    const [contacto, setContacto] = React.useState();
    const [isOpen, setisOpen] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const [loadingText, setLoadingText] = React.useState(<FormattedMessage id={'contacts.loadingText'}
                                                                           defaultMessage={"Cargando..."}/>);

    const classes = useStyles();

    const handleClose = () => {
        setisOpen(false)
    }

    const searchItems = (data) => {
        return data.filter((el) =>
            el.tipo.toLowerCase().indexOf(filter.toLowerCase()) > -1
            ||
            el.descripcion.toLowerCase().indexOf(filter.toLowerCase()) > -1
        );
    }
    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    };

    useEffect(() => {
        apiBUC.SubjectServices.getContacts()
            .then((response) => {
                setContactList(response.data);
                setLoadingApplications(false);
            });
    }, []);


    if (loadingApplications) {
        return (
            <div>
                <SeccionHeader title={<FormattedMessage id="contacts.title" defaultMessage="Mis contactos"/>}>
                </SeccionHeader>
                <TableSkeleton
                    loadingApplications={loadingApplications}
                    loadingText={loadingText}
                    cantCols={5}
                    cantRows={3}
                />
            </div>
        );
    } else {
        return (
            <div className={classes.container}>

                <SeccionHeader title={<FormattedMessage id="contacts.title" defaultMessage="Mis contactos"/>}>
                    <TextField
                        id="searchbar"
                        label={<FormattedMessage id="contacts.tabs.Find" defaultMessage="Buscar" />}
                        type="text"
                        onChange={handleFilter}
                        className={classes.buscar}
                    />
                </SeccionHeader>

                {contactList.length > 0 ?
                    <ContactTabs contactList={searchItems(contactList)}/>
                    :
                    <ContactsMsj icon={'alert'} msj={<FormattedMessage id="contacts.NoContacts" defaultMessage="No se genero ningun contacto" />}/>}
                {isOpen && <ContactsDetail data={contacto} isOpen={isOpen} handleClose={handleClose}/>}
            </div>
        );
    }
}

const mapState = state => ({
    cuit: state.auth.cuit,
    jwt: state.auth.jwt
});

const mapDispatch = dispatch => ({})

export default connect(mapState, mapDispatch)(Contacts);






