import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Button from "../common/MaterialComponents/Button";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import UserAplications from "../Applications/UserApplications";
import Spinner from "../../components/common/Spinner";
import LoadingAccordion from "../MyTaxes/Taxes/LoadingAccordion";
import SeccionHeader from "../common/SeccionHeader";
import apiBUC from "../../services/BUCServices";
import apiApplication from "../../services/ApplicationServices";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "../../components/common/MaterialComponents/Breadcrumbs";
import TableSkeleton from "../common/TableSkeleton";

export interface IContent {
  text: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Link: {
      "&:hover": {
        color: "white",
      },
      color: "white",
    },
    Msj: {
      width: "100%",
      textAlign: "center",
      padding: "10px",
      marginTop: "15px",
      // background: "#c9c9c9",
    },
    container: {
      marginBottom: "50px",
    },
  })
);

export interface ITodo {
  userId: number;
  id: number;
  title: string;
  completed: boolean;
}

const MainPage = (props) => {
  const [loadingText, setLoadingText] = React.useState(
    <FormattedMessage
      id={"mainPage.loadingProcedures"}
      defaultMessage="Estamos cargando tus tramites..."
    />
  );
  const [loadingApplications, setLoadingApplications] = React.useState(true);
  const [userProcedures, setUserProcedures] = React.useState([]);

  useEffect(() => {
    apiBUC.SubjectServices.getSubjectTramites(props.cuit)
      .then((response) => {
        setUserProcedures(response.data.reverse());
        // if (props.formList.length == 0) {
        apiApplication.services.getApplications().then((fields) => {
          props.setFormList(fields.data);
          setLoadingApplications(false);
        });
        // } else {
        //   setLoadingApplications(false);
        // }
      })
      .catch((err) => {
        setLoadingText(
          <FormattedMessage
            id={"mainPage.notFoundProcedure"}
            defaultMessage="No se encontraron trámites iniciados"
          />
        );
        setLoadingApplications(false);
      });
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/dashboard">Inicio</Link>

        <Typography color="textPrimary"> Mis trámites</Typography>
      </Breadcrumbs>
      <SeccionHeader
        title={
          <FormattedMessage id={props.title} defaultMessage="Mis tramites" />
        }
      >
        <Link to={"nuevo-tramite"} className={classes.Link}>
          <Button variant="contained" color="primary">
            <FormattedMessage
              id={"mainPage.newProcedure"}
              defaultMessage="Nuevo Tramite"
            />
          </Button>
        </Link>
      </SeccionHeader>
      {loadingApplications ? (
        <TableSkeleton
          loadingApplications={loadingApplications}
          loadingText={loadingText}
          cantCols={5}
          cantRows={3}
        />
      ) : (
        <>
          {userProcedures.length > 0 ? (
            <UserAplications procedures={userProcedures} />
          ) : (
            <Typography className={classes.Msj}>
              <FormattedMessage
                id={"mainPage.noProcedure"}
                defaultMessage="No iniciaste ningun tramite"
              />
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  formList: state.applications.formList,
});

const mapDispatch = (dispatch) => ({
  setFormList: (formList) => dispatch.applications.setFormList(formList),
});

export default connect(mapState, mapDispatch)(MainPage);
