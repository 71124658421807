import React from "react";
import { makeStyles ,createStyles } from "@material-ui/core/styles";
import Expiration from "../Expiration";
import { FormattedMessage } from "react-intl";
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme) =>
  createStyles({
  ExpirationList: {
    justifyContent: "center",
    margin: "50px auto",
    padding: "0 70px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('md')]: {
      padding: "0 20px",
    },
    [theme.breakpoints.down('sm')]: {
      padding: "0 0",
    },
    
  },
  title: {
    color: "#444",
    fontSize: "1.5rem",
    fontWeight: 300,
    textAlign: "center",
  },
})
);
const SectionExpirations = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.ExpirationList}>
      <Typography variant='h4' className={classes.title}>
        {props.terceros ?  <FormattedMessage
          id="public.expiration.thirdtitle"
          defaultMessage="Vencimientos de terceros"
        />:
        <FormattedMessage
          id="public.expiration.title"
          defaultMessage="Vencimientos"
        />}
      </Typography>
      {props.objects.map((objeto, idx) => (
        <Expiration key={idx} terceros={props.terceros} object={objeto}></Expiration>
      ))}
    </div>
  );
};

export default SectionExpirations;
