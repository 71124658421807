import React from 'react';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import Input from '@material-ui/core/Input';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        width: 250,
    },
    input: {
        width: 50,
    },
    msjSlider:{
        color:'blue'
    },
    Container:{
        padding: '8px 80px 8px 60px',
    },
    seccion:{
      
    }


});

const PlanSlider = (props) => {
 const classes = useStyles();
 const minimo =props.min;
 const maximo =props.max;
 const  labelMin = props.labelMin;
 const labelMax = props.labelMax;
 const pasos = props.steps;
    const [slider, setSlider] = React.useState<number | string | Array<number | string>>(props.initial);

    const handleSliderChange = (event: any, newValue: number | number[]) => {
        setSlider(newValue);
        props.onChange(newValue);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSlider(event.target.value === '' ? '' : Number(event.target.value));
        props.onChange(Number(event.target.value));
    };

    const handleBlur = () => {
        if (slider < 0) {
            setSlider(0);
        } else if (slider > maximo) {
            setSlider(maximo);
        }
    };
   

    return (
        <Grid className={classes.seccion} item xs={12}>
            <Grid className={classes.Container}  container spacing={2} alignItems="center">
                <Grid item xs={11}>
                <h6>{props.title}</h6></Grid>
                <Grid item xs={1}>
                    <Input
                     disabled={props.disabled}
                        className={classes.input}
                        value={slider}
                        margin="dense"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: pasos,
                            min:minimo,
                            max: maximo,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    /> </Grid>
                <Grid item xs={12}>
                    <Slider
                      disabled={props.disabled}
                        value={typeof slider === 'number' ? slider : 0}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        valueLabelDisplay="auto"
                       
                        min={minimo}
                        max={maximo}
                        marks={[{
                            value: minimo,
                            label:labelMin,
                        },
                        {
                            value: maximo,
                            label:labelMax,
                        }]}
                    />     </Grid>
                <div>
                <div className={classes.msjSlider}> {props.msjSlider}</div> 
                                </div>
            </Grid>
        </Grid>);

}
export default PlanSlider;


