import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import apiCMS from '../../../services/CMSServices';
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    // maxWidth: 400,
    flexGrow: 1,
  },
  buttons: {
    position: "relative",
    display: "flex",
    flexDirection: "row",

    justifyContent: "space-between",
  },
  buttonsColumn: {
    top: "50%",
    width: "100%",
    display: "flex",
    position: "absolute",
    flexDirection: "column",
  
    transform: "translate(0px, -50%)",
    justifyContent: "center",
  },
  header: {
    display: "flex",
    alignItems: "center",

    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    display: "block",
    maxWidth: "100%",
    overflow: "hidden",
    width: "100%",
  },
  slide: {
    [theme.breakpoints.up("xs")]: {
      minHeight: "120px",
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "200px",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "300px",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "400px",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
  },
  button: {
    fontSize: "40px",
    color: "white",
  },

  imgBkg: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    flex: 1,
  },
  GridContent: {
    flexGrow: 1,
    background: "red",
  },
  Content: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    padding: "20px 40px",
  },
  dots: {
    justifyContent: "center",
    background: "transparent",
  },
}));
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Carousel = (props) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = React.useState(0);
  
  const [autoplay, setAutoplay] = React.useState(true);
  const [carrouselTabs, setCarrouselTabs] = React.useState([]);

  useEffect(() => {
    apiCMS.CarrouselTabsServices.getCarrouselTabs()
        .then((response) => {
          setCarrouselTabs(response.data);
        });
}, []);

  const maxSteps = carrouselTabs.length;

  const handleNext = () => {
    setAutoplay(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setAutoplay(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };
  if(carrouselTabs.length <= 0){return null};
  return (
    <div className={classes.root}>
      <AutoPlaySwipeableViews
        autoplay={autoplay}
        axis={"x"}
        index={activeStep}
        interval={6000}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {carrouselTabs.map((step, index) => (
          <Link to={step.action?step.action:'/home'} key={index}>
            <div className={classes.slide} >

                <div
    className={classes.imgBkg}
    style={{backgroundImage: `url(${window.config.apiBase.cms}${step.img.url})`}}
    />

            </div>
          </Link>
        ))}
      </AutoPlaySwipeableViews>
      <div className={classes.buttonsColumn}>
        <div className={classes.buttons}>
          <Button
            className={classes.button}
            size="small"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            {<KeyboardArrowLeft fontSize={"inherit"} />}
          </Button>
          <Button
            className={classes.button}
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {<KeyboardArrowRight fontSize={"inherit"} />}
          </Button>
        </div>
      </div>
      <MobileStepper
        className={classes.dots}
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton={null}
        backButton={null}
      />
    </div>
  );
};
export default Carousel;
