import React from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Markdown } from 'react-showdown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      backgroundColor: "transparent",
      margin: "5px 10px",

      "&:hover": {
        textDecoration: "none",
      },
      "&[data-active=true]": {
        '&  $Paper':{
          borderBottom: "solid 3px #75c4c6",  
        }

        
      },
    },
    Btn: {},
    Paper: {
      display: "flex",
      minWidth: '231px',
      minHeight: '130px',
      padding: '5px'  ,
      textAlign: "center",
      flexDirection: "column",
      flex: "1",
      justifyContent: "center",
      margin: "auto",
    },
    icon: {
      fontSize: "40px",
      margin: "0 auto 20px",
    },
    title: { color:'#555'// theme.palette.primary.main
    , fontWeight: 400 },
    text: {
        '&[data-adeudada=true]':{
            color:theme.palette.error.dark
        },
        color:theme.palette.success.dark,

        fontSize:'15px',
      
    },
  })
);

const AffidavitObject = (props) => {
  const classes = useStyles();
  const [elevation, setElevation] = React.useState(1);
  const _onMouseOver = () => {
    setElevation(10);
  };
  const _onMouseOut = () => {
    setElevation(1);
  };
  return (
    <div
      onMouseOver={_onMouseOver}
      onMouseOut={_onMouseOut}
      data-active={props.active}
      className={classes.link}
    
    >
      <ButtonBase focusRipple onClick={()=>props.onChange(props.obj)} className={classes.Btn}>
        <Paper elevation={elevation} className={classes.Paper}>
          <h5 className={classes.title}><Markdown color={"default"}  markup={ props.obj['richtext_' + props.locale.toUpperCase()] } /></h5>
          <p className={classes.text} data-adeudada={props.adeudadas > 0}>
            {props.adeudadas > 0
              ? props.adeudadas + " DDJJ Adeudadas"
              : "Estas al día"}
          </p>
        </Paper>
      </ButtonBase>
    </div>
  );
};

const mapState = state => ({
  locale: state.auth.locale,
})

export default connect(mapState,null)(AffidavitObject);
