import React, { useEffect } from "react";
import MaskedInput from "react-text-mask";
function InputMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={other.reg?.length > 0 ? other.reg : false}
      placeholderChar={"_"}
      showMask
      guide={false}
    />
  );
}

export default InputMask;
