import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import StepButton from "@material-ui/core/StepButton";
import Typography from "@material-ui/core/Typography";
import Grupos from "../Grupo/Grupos";
import { StepForm } from "../../models/IDynamicForm";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import { LogicalOperationsArray } from "../../Inputs/Operations";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    completed: {
      display: "inline-block",
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    danger: {
      color: "red",
    },
  })
);

const getSteps = (pasos) => {
  const steps = pasos ? pasos.map((step) => step.tituloPaso) : "";
  return steps;
};

const GetStepContent = (props) => {
  const { values, step, push, pop } = props;

  return <div></div>;
};

const HorizontalSteps = (props) => {
  const { push, pop, values, steps } = props;
  const classes = useStyles();
  const { submitFailed } = props.rest;

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set<number>());
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const stepses = getSteps(steps);

  const totalSteps = () => {
    return stepses.length;
  };

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () => {
    return skipped.size;
  };

  const completedSteps = () => {
    return completed.size;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
          // find the first step that has been completed
          steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== totalSteps() - skippedSteps()) {
      handleNext();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted(new Set<number>());
    setSkipped(new Set<number>());
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  function isStepComplete(step: number) {
    return completed.has(step);
  }
  const hasError = (step) => {
    const b = step.inputGroups.find((group) =>
      props.errors[group.name] ? true : false
    );
    return b && submitFailed ? true : false; //foreach get name
  };
  return (
    <div className={classes.root}>
      {steps && steps?.length > 1 && (
        <Stepper alternativeLabel nonLinear activeStep={activeStep}>
          {steps.map((stp, index) => {
            const stepProps: { completed?: boolean } = {};
            const buttonProps: { optional?: React.ReactNode } = {};
            if (isStepOptional(index)) {
              buttonProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return !stp.isConditional ? (
              <Step key={stp.idStep} {...stepProps}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={isStepComplete(index)}
                  {...buttonProps}
                >
                  {stp.titleStep}
                </StepButton>
              </Step>
            ) : stp.isConditional &&
              LogicalOperationsArray[
                stp.condition?.operation ? stp.condition?.operation : "same"
              ]?.(
                stp.condition?.value,
                _.get(values, stp.condition?.observed)
              ) ? (
              <Step key={stp.idStep} {...stepProps}>
                <StepButton
                  onClick={handleStep(index)}
                  completed={isStepComplete(index)}
                  {...buttonProps}
                >
                  <span className={hasError(stp) && classes.danger}>
                    {hasError(stp) && "! "} {stp.titleStep}
                  </span>
                </StepButton>
              </Step>
            ) : (
              <></>
            );
          })}
        </Stepper>
      )}
      {steps && steps?.length > 0 ? (
        <div>
          {allStepsCompleted() ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
              {props.Footer}
            </div>
          ) : (
            <div>
              <Grupos
                values={values}
                groups={steps[activeStep].inputGroups}
                push={push}
                pop={pop}
              />

              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                >
                  Next
                </Button>
                {isStepOptional(activeStep) && !completed.has(activeStep) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSkip}
                    className={classes.button}
                  >
                    Skip
                  </Button>
                )}
                {activeStep !== steps?.length &&
                  (completed.has(activeStep) ? (
                    <Typography variant="caption" className={classes.completed}>
                      Step {activeStep + 1} already completed
                    </Typography>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleComplete}
                    >
                      {completedSteps() === totalSteps() - 1
                        ? "Finish"
                        : "Complete Step"}
                    </Button>
                  ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Alert variant="outlined" severity="warning">
          El formulario no tiene ningun paso
        </Alert>
      )}
    </div>
  );
};

export default HorizontalSteps;
