export interface IRepresented {
  denominacion: string;
  username: string;
  cuit: string;
}

export const RepresentedType = {
  20: "personIcon",
  23: "personIcon",
  24: "personIcon",
  27: "personIcon",
  30: "domainIcon",
  33: "domainIcon",
  34: "domainIcon",
  55: "domainIcon",
};

export const RepresentedColor = {
    20: "#7574ce",
    23: "#7574ce",
    24: "#7574ce",
    27: "#7574ce",
    30: "#5cafb7",
    33: "#5cafb7",
    34: "#5cafb7",
    55: "#5cafb7",
  };
  