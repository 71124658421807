import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import NumberCurrency from "../../../common/NumberCurrency";
import { connect } from "react-redux";

function PayButton(props) {
  return (
    <Button
      style={{ backgroundColor: "white" }}
      variant="outlined"
      size="small"
      color="primary"
    >
      <Link to={props.linkTo}>
        <FormattedMessage id="myTaxes.payButton.link" defaultMessage="Pagar " />
        <NumberCurrency value={props.amount.toFixed(props.config.decimals)} />
      </Link>
    </Button>
  );
}

const mapState = (state) => ({
  config: state.auth.config,
});

export default connect(mapState, null)(PayButton);
