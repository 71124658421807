import React, { useEffect } from "react";
import { FieldArray } from "react-final-form-arrays";
import RenderInputs from "../RenderInputs";
import { InputGroup } from "../../models/IDynamicForm";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import _ from "lodash";
import {
  getUrl,
  getDependenciasByUrl,
  getValuesFromOtherGroup,
} from "../../Inputs/Operations";
import { connect } from "react-redux";
import { useField } from "react-final-form";
import Axios, { Method } from "axios";
import { AppContextConsumer } from "../../DynamicFormContext";
import { useContext } from "react";
import { ctxts } from "../../DynamicFormContext";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupH: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
    groupV: {
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      "& .field-input": {
        margin: "15px",
      },
    },
    buttonsContainer: {},
    button: {
      margin: "10px",
    },
  })
);

type Props = {
  values: any;
  group: InputGroup;
  push: any;
  pop: any;
};
const SimpleView = (props) => {
  const classes = useStyles();
  const { values, group, push, pop } = props;
  const context = useContext(ctxts);
  const [url, setUrl] = React.useState("");
  const field = useField(group.name);
  const [isLoading, setIsLoading] = React.useState(false);
  let dependencias = [];
  if (group.asyncData)
    getDependenciasByUrl(group.name, group.asyncConf.url, null, dependencias);

  useEffect(() => {
    if (group.asyncData) {
      setUrl(
        getUrl(
          group.name,
          group.asyncConf.url,
          props.cuit,
          values,
          group.asyncConf.JsonFieldsName,
          null,
          group.asyncConf.baseUrl,
          context.BaseUrls
        )
      );
    }
  }, [
    ...dependencias.map((d) => {
      const dependenciaValue = _.get(values, d);
      return dependenciaValue ? dependenciaValue : "";
    }),
  ]);

  const [initialValue, setInitialValue] = React.useState(values[group.name]);
  // let initialValue = null;
  //!TODO GET VALUES FROM OTHER GROUP
  useEffect(() => {
    getValuesFromOtherGroup(group, values, field);
  }, [_.get(values, group.otherGroupValues)]);

  //use efect para traer datos asincronos
  useEffect(() => {
    // TIENE DEPENDENCIA EN URL
    if (!props.isLastPaso) return;
    if (group.asyncData && url && !(url.includes("[") || url.includes("{"))) {
      setIsLoading(true);
      Axios({
        method: group.asyncConf.method as Method,
        url: url,
        headers: { Authorization: `Bearer ${props.jwt}` },
      })
        .then((response) => {
          field.input.onChange(_.get(response, group.asyncConf.dataKey));
          setIsLoading(false);
          // setInitialValue(response.data);
        })
        .catch(function (error) {
          setIsLoading(false);
          console.log(error.message);
        });
    }
  }, [url]); //se ejecuta solo si la url cambia
  const requiredArray = (value) =>
    value && value.length > 0 ? undefined : "Required";

  const isRequired = (group) => {
    return group.inputs.some((i) => i.required);
  };
  return (
    <div>
      <FieldArray
        name={group.name}
        validate={isRequired(group) ? requiredArray : null}
      >
        {({ fields }) =>
          fields.map((name, index) => (
            <div
              key={index}
              className={
                group.show === "groupHorizontal"
                  ? classes.groupH
                  : classes.groupV
              }
            >
              <RenderInputs
                values={values}
                key={index}
                name={name}
                loadingAsyncData={isLoading}
                group={group}
                inputs={group.inputs}
              />
            </div>
          ))
        }
      </FieldArray>
      <AppContextConsumer>
        {(appContext) =>
          appContext &&
          !appContext.viewOnly &&
          !group.initialValuesInmutables && (
            <div className={classes.buttonsContainer}>
              <Button
                type="button"
                className={classes.button}
                variant={"outlined"}
                color={"primary"}
                onClick={() => pop(group.name)}
              >
                {group.removeButtonText || "remove"}
              </Button>
              <Button
                type="button"
                className={classes.button}
                variant={"contained"}
                color={"primary"}
                onClick={() => push(group.name, undefined)}
              >
                {group.addButtonText || "add"}
              </Button>
            </div>
          )
        }
      </AppContextConsumer>
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

const mapDispatch = (dispatch) => ({});

export default connect(mapState, mapDispatch)(SimpleView);
