import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Container, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import {
  format,
  formatDistance,
  formatRelative,
  subDays,
  isAfter,
} from "date-fns";
import { es } from "date-fns/locale";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { objectColor, EobjectType, objectIcon } from "../../../models/IObjects";
import ExpirationRightDetail from "../ExpirationRightDetail";

const useStyles = makeStyles((theme) =>
  createStyles({
    expirationContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      background: "white",
      position: "relative",
      borderBottom: "solid 1px #ddd",
      "&:hover $expirationActions": {
        display: "flex",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    expirationContainerFocused: {
      "& $expirationActions": {
        display: "flex",
      },
    },
    expirationDate: {},

    expirationDescription: {
      display: "flex",
      flex: "1",
      paddingLeft: "20px",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "5px",
        paddingRight: "10px",
      },
    },
    expirationActions: {
      display: "none",
      flex: "1",
      flexDirection: "row",
      justifyContent: "flex-end",
      height: "100%",
      paddingTop: "18px",
      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        right: "10px",
        top: 0,
        bottom: 0,
        justifyContent: 'center',
    paddingTop: 0,
        display: "flex",
        flexDirection:'column',
        background: "white",
        textAlign: "right",
      },
    },
    link: {
      textDecoration: "none",
      backgroundColor: "transparent",
      margin: "5px 10px",
      "&:hover": {
        textDecoration: "none",
      },
    },
    Btn: {},
    Paper: {
      display: "flex",
      minHeight: "180px",
      minWidth: "182px",
      textAlign: "center",
      flexDirection: "column",
      flex: "1",
      justifyContent: "center",
      margin: "auto",
    },
    icon: {
      fontSize: "40px",
      margin: "0 auto 20px",
    },
    mes: {
      fontSize: "15px",
      textAlign: "left",
      display: "block",
      lineHeight: "18px",
      margin: "6px 5px 0",

      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    fecha: {
      fontSize: "50px",
      textAlign: "right",
      display: "block",
      color: theme.colores.acento,
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "32px",
        lineHeight: "1",
      },
    },
    vcenter: {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",

        width: "100px",

        padding: "5px",
      },
    },
    detalle: {
      textAlign: "center",
      fontSize: "15px",
      fontWeight: 100,
      margin: "0 auto",
      maxWidth: "270px",
    },
    centered: {
      justifyContent: "center",
      marginRight: "10px",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        fontSize: "0.8rem",
      },
    },
    hideOnMobile: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    dateInfo: {
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
    expirationIcon: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    cuota: { paddingTop: "3px", paddingLeft: "2px" },
  })
);
const Expiration = (props) => {
  const classes = useStyles();

  const objeto = {
    tipo: props.terceros ? props.object.tipo_objeto : props.object.tipo,
    id: props.terceros ? props.object.objeto_id : props.object.id,
    descripcion: props.terceros
      ? props.object.descripcion
      : props.object.descripcion,
    obligaciones: props.terceros
      ? props.object.obligacionTercero
      : props.object.obligaciones,
  };

  const obligacion =
    objeto.obligaciones.length > 0 ? objeto.obligaciones[0] : null;
  const vencimiento = obligacion && new Date(obligacion.vencimiento);
  const prorroga = obligacion && new Date(obligacion.prorroga);
  const [focus, setFocus] = React.useState(false);
  const dt = isAfter(vencimiento, prorroga) ? vencimiento : prorroga;

  const obligationState = obligacion
    ? props.obligationStates.find((state) =>
        obligacion.estado
          ? state.code === obligacion.estado
          : state.code === "NONE"
      )
    : props.obligationStates.find((state) => state.code === "NONE");

  return (
    <>
      {!obligacion ? (
        <></>
      ) : (
        <Grid
          container
          className={`${classes.expirationContainer} ${
            focus ? classes.expirationContainerFocused : null
          }`}
        >
          <Grid item sm={2} className={classes.vcenter}>
            <Grid container className={classes.centered}>
              <Grid item sm={6}>
                <Typography className={classes.fecha}>
                  {dt.getDate()}
                </Typography>
              </Grid>
              <Grid item sm={6} className={classes.dateInfo}>
                <Typography className={classes.mes}>
                  {format(dt, "iiii", { locale: es })}
                  <br />
                  <strong>{format(dt, "MMMM", { locale: es })}</strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <Typography className={classes.expirationDescription}>
              <span className={classes.expirationIcon}>
                <Icon style={{ color: objectColor[EobjectType[objeto.tipo]] }}>
                  {objectIcon[EobjectType[objeto.tipo]]}
                </Icon>
                <span>
                  {objeto.id}{" "}
                  <small className={classes.cuota}>
                    (cuota {obligacion.cuota})
                  </small>
                </span>
                <br className={classes.hideOnMobile} />
              </span>
              <i className={classes.hideOnMobile}>{objeto.descripcion}</i>
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <div
              onClick={() => setFocus(!focus)}
              className={classes.expirationActions}
            >
              {obligacion ? (
                <ExpirationRightDetail
                  obligation={obligacion}
                  state={obligationState}
                  config={props.config}
                />
              ) : null}
            </div>
          </Grid>
        </Grid>
      )}{" "}
    </>
  );
};

// export default Expiration;
const mapState = (state) => ({
  config: state.auth.config,
  obligationStates: state.payments.obligationStates,
});

export default connect(mapState, null)(Expiration);
