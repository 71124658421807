import React, {useEffect} from 'react';

import {makeStyles, createStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) =>
    createStyles({
        Clock: {
            padding: "4px 12px",
            margin: '5px 0px',
            [theme.breakpoints.down('sm')]: {
                margin: '0',
                textAlign: 'revert',
                display: 'flex',
                justifyContent: 'flex-end',
                whiteSpace: 'nowrap'
            }
        },
    })
);
const millisToMinutesAndSeconds = (millis) => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return seconds === "60"
        ? minutes + 1 + ":00 min"
        : minutes + ":" + (seconds.length < 2 ? "0" : "") + seconds + " min";
};


const Clock = (props) => {
    const classes = useStyles()
    const [tiempoAtencion, setTiempoAtencion] = React.useState(0);
    const tick = () => {
        setTiempoAtencion(Date.now() - props.atencion.horaInicio);
    };
    useEffect(() => {
        let timerID;
        if (props.atencion) {
            timerID = setInterval(() => tick(), 1000);
        }

        return () => {
            clearInterval(timerID);
        };
    }, [props.atencion]);

    if (!props.bold) {
        return <div className={classes.Clock}>
            {millisToMinutesAndSeconds(tiempoAtencion)}
        </div>
    } else {
        return <b className={props.classes.Clock}>
            {millisToMinutesAndSeconds(tiempoAtencion)}
        </b>
    }
}

export default Clock;