import React from "react";
import { makeStyles ,createStyles} from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import { connect } from "react-redux";
import Fab from "@material-ui/core/Fab";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) =>
  createStyles({
  circuloCentral: {
    left: "50%",
    color: theme.colores.contrast,
    width: "300px",
    height: "300px",
    top: "0",
    display: "flex",
    padding: "10px",
    position: "absolute",
    borderRadius: "50%",
    transform: "translate(-50%, 0px)",
    background: theme.colores.main,
    textAlign: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  fabbtn: {
    background: "none",
    boxShadow: "none",
    marginBottom: "-30px",
  },
  link: { textDecoration: "none" },

  icon: { fontSize: "220px", lineHeight: "80px", marginBottom: "-20px" },
  userName: { fontSize: "22px", paddingBottom: "30px", lineHeight: "22px" },
  userCuit: { fontSize: "18px" },
})
);

const UserCircle = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.circuloCentral}>
      <span className={classes.icon}>
        <PersonIcon fontSize={"inherit"} />
      </span>
      <Typography className={classes.userName}>
        {props.denominacion} <br />
        <span className={classes.userCuit}>{props.cuit}</span>
        <br />
        <Link className={classes.link} to={"/definir-representado"}>
          <Fab
            className={classes.fabbtn}
            size="small"
            color="primary"
            aria-label="Cambiar representado"
          >
            <SyncAltIcon />
          </Fab>
        </Link>
      </Typography>
    </div>
  );
};

const mapState = (state) => ({
  denominacion: state.auth.denominacion,
  cuit: state.auth.cuit,
});

export default connect(mapState, null)(UserCircle);
