import React from 'react';
import Clock from "../Clock";
import Button from "@material-ui/core/Button";
import {Icon} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import apiBUC from "../../../../services/BUCServices";
import apiCMS from "../../../../services/CMSServices";
import {useHistory} from "react-router-dom";
import { connect } from "react-redux";

const StopAppointmentButton = (props: any) => {
    const history = useHistory();

    const stopAtencion = () => {
        props.stopAtencion()
        props.resetAtencion();
        history.push("/agent-dashboard");
    };

    return (
        <>
            <Clock {...props}></Clock>
            <Button
                className={props.classes.BtnDetener}
                onClick={() => stopAtencion()}
                startIcon={<Icon>stop</Icon>}
                variant="text"
            >
                <FormattedMessage id='agentHeader.stop' defaultMessage={'Detener'}/>
            </Button>
        </>
    )
}

const mapState = (state) => ({
    jwt: state.auth.jwt,
    appointmentsConfig: state.appointments.appointmentsConfig,
});

const mapDispatch = (dispatch) => ({
    setAppointmentsConfig: (config) => dispatch.appointments.setAppointmentsConfig(config),
    stopAtencion: () => dispatch.appointments.stopAtencion(),
});

export default connect(mapState, mapDispatch)(StopAppointmentButton);
