import React from 'react';
import ObligationCheckbox from "./ObligationCheckbox";
import { connect } from 'react-redux';
//import './styles.css';
import moment from 'moment';
import 'moment-timezone';
import { FormattedMessage } from 'react-intl';

const Obligation = (props) => {
    const expirationDate = moment(props.obligation.vencimiento).add(3, 'hours');
    const isExpired = moment(expirationDate.format('YYYY-MM-DD')).isBefore(moment().format('YYYY-MM-DD'));
    const Dias = expirationDate.diff(moment(), 'days');
    let totalToPay = 0;
    if(props.obligation.exenta){
        totalToPay = (props.obligation.saldoConRecargo * (100 - props.obligation.porcentaje_exencion)) / 100;
    } else {
        totalToPay = props.obligation.saldoConRecargo;
    }
    
    const Expire = () => {
        if (Dias < 31 && Dias > 0) {
        return(<p><FormattedMessage id="myTaxes.expiresIn" defaultMessage="Vence en" /> {Dias.toString()} {Dias > 1 ? <FormattedMessage id="myTaxes.days" defaultMessage="dias" /> : <FormattedMessage id="myTaxes.day" defaultMessage="dia" />} - {expirationDate.format('DD/MM/YYYY')}</p>)
        } else if (Dias < 0) {
            return (<p><FormattedMessage id="myTaxes.alredyExpired" defaultMessage="La obligacion vencio el" /> {expirationDate.format('DD/MM/YYYY')}</p>);
        }else if (Dias === 0) {
            return (<p><FormattedMessage id="myTaxes.expiresToday" defaultMessage="La obligacion vence hoy" /> - {expirationDate.format('DD/MM/YYYY')}</p>);
        } 
        else {
            return (<p><FormattedMessage id="myTaxes.expiresOn" defaultMessage="Vence el" /> {expirationDate.format('DD/MM/YYYY')}</p>);
        }
    }

    if(props.obligation.cuota == 0 && isExpired){
        return (<div></div>)
    } else {
        return (
            <div className="row justify-content-end Obligacion" >
                <div className="col">
                    <div className="row">
                        <FormattedMessage id="myTaxes.installment" defaultMessage="Cuota" /> {props.obligation.cuota}<span style={{ display: 'inline-block', width: '1%' }}></span><br />
                    </div>
                    <div className="row">
                        <small>{Expire()}</small>
                    </div>
                </div>
                <div className="col-auto">
                    {totalToPay > 0 ? <b>{totalToPay} </b> : null}
                    <span style={{ display: 'block', height: '1%' }}></span>
                    {props.showCapitalDebt ? <small>{props.obligation.saldo} </small> : null}
                </div>
                <div className="col-auto">
                    <ObligationCheckbox obligation={props.obligation} />
                </div>
            </div>
        )
    }
}


export default Obligation;