import React from "react";
import Button from "../../Inputs/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
const ModalEnd = (props) => {
  const handleClose = () => {
    props.handleClose(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      color={props.type === "error" ? "danger" : "primary"}
      maxWidth="sm"
      fullWidth
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        {props.cerrando ? (
          <>
            {" "}
            <CircularProgress size={24} />
            <span style={{ marginLeft: "15px", paddingLeft: "15px" }}>
              {props.user.isBackOffice
                ? "Volvemos a la lista de tareas"
                : "Te redireccionaremos al detalle del tramite..."}
            </span>
          </>
        ) : (
          props.text
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant={"contained"}
          autoFocus
          disabled={props.cerrando}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalEnd;
