import React from 'react';
import {Button} from '@material-ui/core';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import { FormattedMessage } from 'react-intl';

export function GoBackButton(props){
    return(
        <div className="d-flex justify-content-center">
            <Button size="small" color="primary" onClick={ _ => props.clearObligations()}><Link to={props.to}><FormattedMessage id="paymentMethod.goBack" defaultMessage="Volver" /></Link></Button>
        </div>
        )
}


const mapDispatch = dispatch => ({
    clearObligations: () => dispatch.payments.clearObligations()
})

export default connect(null, mapDispatch)(GoBackButton);