import React, { useState, useEffect } from "react";
import Taxes from "../../components/MyTaxes/Taxes";
import PayButton from "../../components/MyTaxes/Taxes/PayButton";
import Switch from "@material-ui/core/Switch";
import { FormattedMessage } from "react-intl";
import PayAllButton from "../../components/MyTaxes/Taxes/PayAllButton";
import { connect } from "react-redux";
import SearchBox from "../../components/MyTaxes/Taxes/SearchBox";
import AddThirdPartObjects from "../../components/MyTaxes/Taxes/AddThirdPartObjects";
import apiCMS from "../../services/CMSServices";
import NumberCurrency from "../../components/common/NumberCurrency";
import SeccionHeader from "../../components/common/SeccionHeader";
import { Card, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ThirdPartTaxesForm from "../../components/MyTaxes/Taxes/ThirdPartTaxesForm";
import ThirdTaxes from "../../components/MyTaxes/Taxes/ThirdTaxes";
import apiBUC from "../../services/BUCServices";
import apiApplication from "../../services/ApplicationServices";

const useStyles = makeStyles({
  taxesScreen: {
    marginTop: "40px",
    background: "white",
    padding: "0px 20px",
    borderRadius: "10px",
    boxShadow: "0 0 13px 0 rgba(82,63,105,.05)",
    marginBottom: "140px",
  },
  container: { marginBottom: "140px" },
  taxesTituloMiga: {
    color: "#555",
    margin: "10px 0 10px",
  },
  verdeudalabel: {
    textAlign: "right",
    padding: "9px",
  },
  taxesGrillaTitulo: {
    margin: "0 -40px -20px",
  },
  search: {
    marginTop: "40px",

    display: "flex",
    flexDirection: "row",
    padding: "0",

    borderRadius: "6px",
  },
  taxesSearch: {
    border: "none",
  },
  title: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  taxesConteiner: {
    padding: "30px",
    borderRadius: "5px",
    boxShadow: "1px 2px 3px 2px #00000017",
    background: "white",
  },

  taxesHeader: {
    padding: "10px 0",
  },

  taxesBody: {
    margin: "10px 0",
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "5px",
  },

  taxesFooter: {
    color: "white",
    position: "fixed",
    borderBottom: "solid 20px #4c4c4c",
    bottom: "0",
    width: "100%",
    left: "0",
    padding: "30px",
    background: "#0584ce",
  },
  searchComp: {
    flex: 1,
  },
});

const MyTaxes = (props) => {
  const classes = useStyles();
  const [isOpen, setisOpen] = useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const [loadingText, setLoadingText] = React.useState(
    "Estamos cargando tus impuestos..."
  );
  const [loadingThirdPartData, setLoadingThirdPartData] = React.useState(true);
  const [loadingThirdPartText, setLoadingThirdPartText] = React.useState(
    "Estamos cargando tus impuestos..."
  );

  const loadTaxes = (obligationStates) => {
    const excludedStates = obligationStates
      .filter((state) => !state.show)
      .map((ob) => ob.code);
    props.setThirdPartObjects([]);
    props.setObligationStates(obligationStates);

    const taxes = props.taxesTypes;
    const excludedTaxes = taxes.filter((tax) => !tax.active).map((t) => t.code);
    let activeTypes = taxes.filter((t) => t.active).map((t) => t.code);

    apiBUC.SubjectServices.getSubjectData(
      props.cuit,
      excludedStates.join(","),
      excludedTaxes.join(",")
    )
      .then((response) => {
        const col = response.data.objetos.filter(
          (object) => object.obligaciones.length > 0
        );
        const aux = col.map((o) => {
          o.obligaciones = o.obligaciones.filter(
            (obligation) => obligation.saldo > 0
          );
          return o;
        });
        const activeObjects = aux.filter((o) => activeTypes.includes(o.tipo));
        props.setAllObjects(activeObjects);
        props.setLoadingObjects(false);
        props.setRenderedObjects(activeObjects);
        let thirdPartObjectsAux = null;

        if (response.data.objetosTerceros) {
          const colThird = response.data.objetosTerceros.filter(
            (object) => object.obligacionTercero.length > 0
          );
          const auxThird = colThird.map((o) => {
            o.obligaciones = o.obligacionTercero.filter(
              (obligation) => obligation.saldo > 0
            );
            return o;
          });
          const activeObjectsThirdPart = auxThird.filter((o) =>
            activeTypes.includes(o.tipo_objeto)
          );
          let jsonString = JSON.stringify(activeObjectsThirdPart);
          jsonString = jsonString.replace(/objeto_id/g, "id");
          jsonString = jsonString.replace(/tipo_objeto/g, "tipo");
          thirdPartObjectsAux = JSON.parse(jsonString);
          props.setThirdPartObjects(JSON.parse(jsonString));
          props.setRenderedThirdPartObjects(JSON.parse(jsonString));
        } else {
          props.setThirdPartObjects([]);
        }
        setLoadingData(false);
        setLoadingThirdPartData(false);

        let tagsInUse = [];
        if (activeObjects) {
          activeObjects.forEach((object) => {
            if (object.etiquetas) {
              const tags = object.etiquetas.split(",");
              tags.forEach((tag) => {
                tagsInUse.push(tag);
              });
            }
          });
        }
        if (thirdPartObjectsAux) {
          thirdPartObjectsAux.forEach((object) => {
            if (object.etiquetas) {
              const tags = object.etiquetas.split(",");
              tags.forEach((tag) => {
                tagsInUse.push(tag);
              });
            }
          });
        }

        props.setTagsInUse(tagsInUse);
      })
      .catch((err) => {
        props.setLoadingObjects(false);
        setLoadingText("No se encontraron impuestos asociados");
        setLoadingData(false);
      });

    apiBUC.SubjectServices.getSubjectJudgments(props.cuit).then((response) => {
      response.data.forEach((judgment) => {
        props.addJudgment(judgment);
      });
    });
  };

  useEffect(() => {
    apiBUC.SubjectServices.getSubjectSituation()
      .then((response) => {
        props.setDebtExist(response.data);
      })
      .catch((err) => {
        console.error(
          "No existen objetos asociados para el sujeto. ",
          err.message
        );
      });
    props.setLoadingObjects(true);

    // if (props.formList.length == 0) {
    apiApplication.services
      .getApplications()
      .then((fields) => props.setFormList(fields.data))
      .catch((err) => {
        setLoadingText("No se encontraron campos asociados");
        console.error("error al cargar tramites: ", err.message);
      });
    // }
    apiCMS.TaxesServices.getObligationStates()
      .then((states) => {
        apiCMS.TaxesServices.getObligationStatesActions().then(
          (stateActions) => {
            props.setObligationStateActions(stateActions.data);
            apiCMS.TaxesServices.getTrialsConfig().then((config) => {
              props.setTrialsConfig(config.data[0]);
              loadTaxes(states.data);
            });
          }
        );
      })
      .catch(() => {
        loadTaxes([]);
      });
  }, []);

  const totalDebt = (objects) => {
    let total = 0;
    objects.forEach((object) => {
      object.obligaciones.forEach((obligation) => {
        if (obligation.cuota != "0") {
          if (obligation.exenta) {
            total =
              total +
              (obligation.saldoConRecargo *
                (100 - obligation.porcentaje_exencion)) /
                100;
          } else {
            total = total + obligation.saldoConRecargo;
          }
        }
      });
    });
    return total;
  };

  const stickyFotter = () => {
    if (props.showPayAll) {
      return (
        <div className="row justify-content-between">
          <div
            className="row justify-content-start"
            style={{ marginLeft: "25px" }}
          >
            <b>
              <FormattedMessage
                id="myTaxes.totalDebt"
                defaultMessage="Deuda total"
              />
              :
            </b>
            <div style={{ paddingLeft: "5px" }}>
              <NumberCurrency
                value={totalDebt(props.allObjects).toFixed(
                  props.config.decimals
                )}
              />
            </div>
          </div>
          <div
            className="row justify-content-end"
            style={{ marginRight: "10px" }}
          >
            <div className="col-auto">
              {props.totalToPay > 0 ? (
                <PayButton
                  linkTo="/payment-methods"
                  amount={props.totalToPay}
                />
              ) : null}
            </div>
            <div className="col-auto">
              <PayAllButton
                linkTo="/payment-methods"
                size="small"
                buttonText={
                  <FormattedMessage
                    id="myTaxes.payAllButton.link"
                    defaultMessage="Pagar todas las obligaciones"
                  />
                }
              />
            </div>
          </div>
        </div>
      );
    }
  };
  const handleClick = () => {
    setisOpen(true);
  };
  const handleClose = () => {
    setisOpen(false);
  };
  const handleAdd = () => {
    setisOpen(false);
  };
  return (
    <Grid className={classes.container} container>
      <Grid item sm={12}>
        <div className="row">
          <div className={"col " + classes.search}>
            <div className={classes.searchComp}>
              <SearchBox />
            </div>
            <div className={classes.verdeudalabel}>
              <span>
                <FormattedMessage
                  id="myTaxes.showOriginalDebt"
                  defaultMessage="Ver deuda original"
                />
              </span>
              <Switch
                checked={props.showCapitalDebt}
                onChange={props.toggleShowCapitalDebt()}
                value={props.showCapitalDebt}
                color="primary"
              />
            </div>
          </div>
        </div>

        <div className={classes.taxesHeader}>
          <div className="row">
            <div className={"col " + classes.title}>
              <SeccionHeader
                title={
                  <FormattedMessage
                    id="myTaxes.title"
                    defaultMessage="Mis impuestos"
                  />
                }
              ></SeccionHeader>
            </div>
          </div>
        </div>
        {/* <Card elevation={1}> */}
        <Taxes loadingData={loadingData} loadingText={loadingText} />
        {/* </Card> */}
        <div className={classes.taxesHeader}>
          <div className="row">
            <div className={"col " + classes.title}>
              <SeccionHeader
                handleClick={handleClick}
                title={
                  <FormattedMessage
                    id="myTaxes.ThirdPartTitle"
                    defaultMessage="Impuestos de terceros"
                  />
                }
                action={props.thirdPartObjects.length > 0}
                actionTo={"/mis-impuestos"}
                actionLabel={
                  <FormattedMessage
                    id="myTaxes.thridPart.Add"
                    defaultMessage="Agregar Impuestos"
                  />
                }
              ></SeccionHeader>
            </div>
          </div>
        </div>

        {props.thirdPartObjects.length > 0 || loadingThirdPartData ? (
          <Card elevation={1}>
            <ThirdTaxes
              loadingData={loadingThirdPartData}
              loadingText={loadingThirdPartText}
            />
          </Card>
        ) : (
          <AddThirdPartObjects
            msj={
              <FormattedMessage
                id="myTaxes.thridPart.Nothing"
                defaultMessage="No hay nada aqui..."
              />
            }
            icon={"alert"}
            lblBtn={
              <FormattedMessage
                id="myTaxes.thridPart.Add"
                defaultMessage="Agreagar Impuestos"
              />
            }
            handleAdd={handleClick}
          />
        )}
        {isOpen && (
          <ThirdPartTaxesForm
            isOpen={isOpen}
            handleAdd={handleAdd}
            handleClose={handleClose}
          />
        )}
      </Grid>

      {/* <Grid item lg={2}><Card color={'red'} elevation={1}>
Cuenta

    </Card></Grid>*/}
      <div className={classes.taxesFooter}>{stickyFotter()}</div>
    </Grid>
  );
};

const mapState = (state) => ({
  showAlert: state.payments.showAlert,
  totalToPay: state.payments.totalToPay,
  showPayAll: state.payments.showPayAll,
  showCapitalDebt: state.payments.showCapitalDebt,
  allObjects: state.payments.allObjects,
  config: state.auth.config,
  thirdPartObjects: state.payments.thirdPartObjects,
  cuit: state.auth.cuit,
  renderedObjects: state.payments.renderedObjects,
  allDeclarations: state.payments.allDeclarations,
  taxesTypes: state.taxes.taxesTypes,
  filteringObjects: state.payments.filteringObjects,
  formList: state.applications.formList,
});

const mapDispatch = (dispatch) => ({
  toogleShowAlert: () => dispatch.payments.toogleShowAlert,
  toggleShowCapitalDebt: () => dispatch.payments.toggleShowCapitalDebt,
  addThirdPartObject: (object) => dispatch.payments.addThirdPartObject(object),
  setLoadingObjects: (loading) => dispatch.payments.setLoadingObjects(loading),
  setAllObjects: (objectList) => dispatch.payments.setAllObjects(objectList),
  setRenderedObjects: (arrayObjects) =>
    dispatch.payments.setRenderedObjects(arrayObjects),
  setRenderedThirdPartObjects: (arrayObjects) =>
    dispatch.payments.setRenderedThirdPartObjects(arrayObjects),
  toogleShowPayAll: (value) => dispatch.payments.toogleShowPayAll(value),
  addJudgment: (judgment) => dispatch.payments.addJudgment(judgment),
  setAllDeclarations: (declarationList) =>
    dispatch.payments.setAllDeclarations(declarationList),
  setDebtExist: (debtExist) => dispatch.userInfo.setDebtExist(debtExist),
  setThirdPartObjects: (objects) =>
    dispatch.payments.setThirdPartObjects(objects),
  setFilteringObjects: (bool) => dispatch.payments.setFilteringObjects(bool),
  setObligationStates: (states) =>
    dispatch.payments.setObligationStates(states),
  setObligationStateActions: (statesActions) =>
    dispatch.payments.setObligationStateActions(statesActions),
  setFormList: (formList) => dispatch.applications.setFormList(formList),
  setTagsInUse: (tags) => dispatch.payments.setTagsInUse(tags),
  setTrialsConfig: (config) => dispatch.payments.setTrialsConfig(config),
});

export default connect(mapState, mapDispatch)(MyTaxes);
