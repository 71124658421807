
export const tramiteType = {
    1: 'Automotor',
    2: 'Embarcaciones',
    3: 'Ingresos Brutos',
    4: 'Inmobiliario'
}
export const tramiteIcon = {
    1:"directions_car",
    2:"directions_boat",
    3:"person",
    4:"homeIcon"

}
export const tramiteColor = {
    1:"#6ebe64",
    2:"#75c4c6",
    3:"#b478b4",
    4:"#e67864"

}



export enum EContactType {
    A=1,
    EM=2,
    IB=3,
    I = 4,

}

