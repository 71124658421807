import axios from 'axios';

const PaymentPlansServices = () => {
    const simulate = (obligations: any, jwt: any) => axios.post(window.config.apiBase.planes + 'param-plans/simular', obligations, { headers: {"Authorization" : `Bearer ${jwt}`}});
    const confirm = (plans: any, jwt: any) => axios.post(window.config.apiBase.planes + 'planes-pagos/confirmar', plans, { headers: {"Authorization" : `Bearer ${jwt}`}});
    const getAvailable = (cuit: any, obligations: any, jwt: any) => axios.post(window.config.apiBase.planes + 'sujetos/' + cuit + '/obligaciones/carrito', obligations, { headers: {"Authorization" : `Bearer ${jwt}`}});;

    return {
        simulate,
        confirm,
        getAvailable
    };
};
export default PaymentPlansServices;
