import { IRepresented } from "../models/IRepresented";

export default {
  state: {
    jwt: null,
    urlToGoBack: null,
    accessToken: null,
    username: "",
    name: "",
    inIframe: false,
    denominacion: "",
    lastName: "",
    config: {},
    menu: [],
    userMenu: [],
    cuit: null,
    id: null,
    taxRole: null,
    agentActions: null,
    agentLinks: null,
    atencion: null,
    locale: "es",
    publicLogin: true,
    representedList: new Array<IRepresented>(),
    authenticatedUser: {
      username: "",
      cuit: "",
      denominacion: "",
      jwt: "",
      accessToken: null,
    },
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    loginSuccess(
      state,
      username,
      { jwt, cuit, name, lastName, id, taxRole, accessToken }
    ) {
      return Object.assign(
        {},
        {
          ...state,
          username: username,
          authToken: accessToken,
          jwt: jwt,
          cuit: cuit,
          name: name,
          lastName: lastName,
          denominacion: name + " " + lastName,
          id: id,
          taxRole: taxRole,
        }
      );
    },
    setAuthenticatedUser(
      state,
      jwt,
      { username, cuit, denominacion, accessToken }
    ) {
      return Object.assign(
        {},
        {
          ...state,
          authenticatedUser: {
            jwt: jwt,
            username: username,
            cuit: cuit,
            denominacion: denominacion,
            accessToken: accessToken,
          },
        }
      );
    },
    impersonateUser(state, jwt, user: { username; cuit; denominacion }) {
      return Object.assign(
        {},
        {
          ...state,
          jwt: jwt,
          username: user.username,
          cuit: user.cuit,
          denominacion: user.denominacion,
        }
      );
    },
    setConfig(state, config) {
      if (config.ApiClientSituation == "") {
        config.ApiClientSituation = window.config.apiBase.buc;
      }
      return Object.assign({}, { ...state, config: config });
    },
    setMenu(state, menu) {
      return Object.assign({}, { ...state, menu: menu });
    },
    setUrlToGoBack(state, urlToGoBack) {
      return Object.assign({}, { ...state, urlToGoBack: urlToGoBack });
    },
    setInIframe(state, inIframe) {
      return Object.assign({}, { ...state, inIframe: inIframe });
    },
    setUserMenu(state, userMenu) {
      return Object.assign({}, { ...state, userMenu: userMenu });
    },
    setAgentActions(state, agentActions) {
      return Object.assign({}, { ...state, agentActions: agentActions });
    },
    setPublicLogin(state, publicLogin) {
      return Object.assign({}, { ...state, publicLogin: publicLogin });
    },
    setAgentLinks(state, agentLinks) {
      return Object.assign({}, { ...state, agentLinks: agentLinks });
    },
    clearAuth(state) {
      return Object.assign(
        {},
        {
          ...state,
          jwt: null,
          urlToGoBack: null,
          username: "",
          config: null,
          menu: [],
          cuit: null,
          id: null,
        }
      );
    },
    setLocale(state, locale) {
      return Object.assign({}, { ...state, locale: locale });
    },
    setRepresentedList(state, representedList) {
      return Object.assign({}, { ...state, representedList: representedList });
    },
    setAtencion(state, atencion) {
      return Object.assign({}, { ...state, atencion: atencion });
    },
    setAccessToken(state, accessToken) {
      return Object.assign({}, { ...state, accessToken: accessToken });
    },
    resetAtencion(state) {
      return Object.assign(
        {},
        {
          ...state,
          atencion: null,
          jwt: state.authenticatedUser.jwt,
          cuit: state.authenticatedUser.cuit,
          username: state.authenticatedUser.username,
          denominacion: state.authenticatedUser.denominacion,
        }
      );
    },
  },
  effects: {},
};
