import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { init } from "@rematch/core";
import * as models from "./redux";
import { Provider } from "react-redux";
import createRematchPersist from "@rematch/persist";
import { getPersistor } from "@rematch/persist";
import { default as pplugin } from "@rematch/persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import storage from "redux-persist/lib/storage";
const persistPlugin = createRematchPersist({
  whitelist: [
    "auth",
    "payments",
    "contacts",
    "languages",
    "tags",
    "objectsActions",
    "paymentPlans",
    "taxes",
  ],
  throttle: 1000,
  version: 1,
});
const pconfig = {
  key: "root",
  storage,
};

const store = init({
  models,
  plugins: [pplugin(pconfig)], //persistPlugin
});

const persistor = getPersistor();
const Root = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
