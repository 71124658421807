import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../common/MaterialComponents/Button";
import ApplicationMoves from "../ApplicationMoves";
import Moment from "react-moment";
import "moment/locale/es";
import ApplicationState from "../ApplicationState";
import { FormattedMessage } from "react-intl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles({
  Link: {
    "&:hover": {
      color: "white",
    },
    color: "white",
  },
  row: {
    "&:hover": {
      background: "#eee",
      cursor: "pointer",
    },
  },
  cell: {
    background: "transparent",
  },
  table: {
    minWidth: 650,
  },
  header: {
    background: "#ddd",
  },
  DialogActions: {
    padding: "10px 40px",
  },
  dialogHeader: {
    background: "#ddd",
  },
});

export default function ApplicationDetail(props) {
  const app = props.applicationSelected;
  const tipoTable = true;
  const classes = useStyles();
  // TODO: Review this line, the state should always have at least one state... this if condition is because in development the state might not exist
  const getLastState = (estado) => {
    return estado &&
      estado.length > 0 &&
      estado !== "undefined" &&
      estado[estado.length - 1]
      ? estado[estado.length - 1].proceso.replace(/_/g, " ")
      : null;
  };
  return (
    <Dialog
      open={props.open}
      fullWidth={true}
      maxWidth={"md"}
      onClose={props.onClose}
    >
      <DialogTitle
        className={classes.dialogHeader}
        id="responsive-dialog-title"
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography>
              {" "}
              <FormattedMessage
                id={"userApplication.detail.title"}
                defaultMessage="Tramite"
              />{" "}
              #{app.id.split("-")[0]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
            <Typography>
              <b>
                <FormattedMessage
                  id={"userApplication.detail.state"}
                  defaultMessage="Estado"
                />
                :
              </b>{" "}
            </Typography>
            {!app.draft && app.estado.length === 0 ? (
              <ApplicationState estado={"Procesando"} />
            ) : app.draft ? (
              <ApplicationState estado={"Borrador"} />
            ) : (
              <ApplicationState estado={getLastState(app.estado)} />
            )}
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item md={6}>
            <Typography>
              <b>
                <FormattedMessage
                  id={"userApplication.detail.started"}
                  defaultMessage="Inicio"
                />
                :
              </b>
              <Moment locale="es" format="LLL">
                {app.fecha_solicitud}
              </Moment>
            </Typography>
            <br />
            {app.estado.length > 0 ? (
              <Typography>
                <b>
                  <FormattedMessage
                    id={"userApplication.detail.lastMove"}
                    defaultMessage="Último Movimiento"
                  />
                  :{" "}
                </b>
                {app.estado[app.estado.length - 1] ? (
                  <Moment locale="es" format="LLL">
                    {app.estado[app.estado.length - 1]}
                  </Moment>
                ) : null}
              </Typography>
            ) : null}
            {/*<br/>*/}
            {/*<b>Requiere comunicacion institucional: </b>{app.requiere_comunicacion_institucional ? "si" : "no"} <br/>*/}
            <br />
            <br />
          </Grid>
        </Grid>
        {props.applicationSelected.estado.length > 1 &&
        props.applicationSelected.estado !== "undefined" ? (
          <ApplicationMoves
            moves={props.applicationSelected.estado}
            table={tipoTable}
            app={app}
          />
        ) : null}
      </DialogContent>

      <DialogActions className={classes.DialogActions}>
        <div className="col" style={{ textAlign: "right" }}>
          <Button
            onClick={props.onClose}
            variant="contained"
            color="primary"
            autoFocus
          >
            <FormattedMessage
              id={"userApplication.detail.accept"}
              defaultMessage="Aceptar"
            />
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
