import React, { useEffect } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grupos from "../Grupo/Grupos";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import { LogicalOperationsArray } from "../../Inputs/Operations";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      // className={value !== index && "d-none"}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {/* {value === index && ( */}
      <Box p={3}>
        <div>{children}</div>
      </Box>
      {/* )} */}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  danger: {
    color: "red",
  },
}));

const TabsView = (props) => {
  const { push, pop, values, steps } = props;
  const { submitFailed } = props.rest;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    setValue(0);
  }, [steps]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const shouldShowStep = (values, step) => {
    return (
      _.get(values, step.condition?.observed) &&
      _.get(values, step.condition.observed).toString() ===
        step.condition.value.toString()
    );
  };

  const hasError = (step) => {
    const b = step.inputGroups?.find((group) =>
      props.errors[group.name] ? true : false
    );
    return b && submitFailed ? true : false; //foreach get name
  };

  return (
    <div className={classes.root}>
      {steps && steps?.length > 1 && (
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {steps.map(
              (step, index) =>
                !step.isConditional ||
                (step.isConditional &&
                  LogicalOperationsArray[
                    step.condition?.operation
                      ? step.condition?.operation
                      : "same"
                  ]?.(
                    _.get(values, step.condition?.observed),
                    step.condition?.value
                  )) ? (
                  <Tab //mapeo los steps en nombre de tabs
                    key={"steps" + index}
                    label={
                      <span className={hasError(step) ? classes.danger : null}>
                        {hasError(step) && "! "} {step.titleStep}
                      </span>
                    }
                    {...a11yProps(index)}
                  />
                ) : (
                  <Tab //mapeo los steps en nombre de tabs
                    key={"steps" + index}
                    className={"d-none"}
                  />
                ) // <span key={"steps" + index}></span>
              //  null
            )}
          </Tabs>
        </AppBar>
      )}
      {steps && steps?.length > 0 ? (
        steps.map(
          (step, index) =>
            !step.isConditional ||
            (step.isConditional &&
              LogicalOperationsArray[
                step.condition?.operation ? step.condition?.operation : "same"
              ]?.(
                _.get(values, step.condition?.observed),
                step.condition?.value
              )) ? (
              <TabPanel key={"panel-" + index} value={value} index={index}>
                <Grupos //armo los grupos adentro de cada tab
                  values={values}
                  groups={step.inputGroups}
                  push={push}
                  pop={pop}
                />
                {value >= steps?.length - 1 && props.Footer}
              </TabPanel>
            ) : (
              <TabPanel
                key={"panel-" + index}
                value={value}
                index={index}
              ></TabPanel>
            ) // <span key={"panel-" + index}></span>
        )
      ) : (
        <Alert variant="outlined" severity="warning">
          El formulario no tiene ningun paso
        </Alert>
      )}
    </div>
  );
};

export default TabsView;
