import React, { useEffect } from "react";
import NumberCurrency from "../../common/NumberCurrency";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios, { Method } from "axios";
import { format } from "date-fns";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) =>
  createStyles({
    btnLateral: {
      width: "100%",
      border: "solid #e9e9e9 1px",
      display: "flex",
      padding: "10px 30px",
      minHeight: "96px",
      margin: "3px",
      background: "#fff",
      transition: "all 300ms",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        minHeight: "auto",
        margin: "3px",
      },
      "&:hover": {
        margin: "3px -10px",
        transition: "all 300ms",
        textDecoration: "none",
        [theme.breakpoints.down("sm")]: {
          margin: "3px",
          transform: "scale(1.03)",
        },
      },
    },
    btnLateralDer: {
      padding: "10px 30px 10px 170px",
      transition: "all 300ms",
      "&:hover": {
        margin: "3px -10px 3px 10px",
        transition: "all 300ms",
        textDecoration: "none",
        [theme.breakpoints.down("sm")]: {
          margin: "0",
          transform: "scale(1.03)",
        },
      },
      [theme.breakpoints.down("sm")]: {
        padding: "10px 30px",
      },
    },
    noCentered: {
      padding: "20px 30px",
      background: "white",
      margin: "5px",
      maxWidth: "30%",
      display: "flex",
      border: "solid #e9e9e9 1px",
      flexDirection: "column",
      justifyContent: "center",
      transition: "all 300ms",
      "&:hover": {
        transform: "scale(1.04)",
        boxShadow: "0px 0px 30px 0px #3333331a",
        transition: "all 300ms",
        textDecoration: "none",
      },
      "& $txtBig": {
        fontSize: "20px",
        lineHeight: "initial",
      },
    },
    txtSmall: {
      fontSize: "11px",
      color: "#6b6b6b",
    },
    txtBig: {
      fontSize: "34px",
      color: theme.colores.main,
      lineHeight: "36px",
    },
    txtMd: {
      color: "#545454",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "21px",
    },
  })
);
const getUrl = (url, cuit) => {
  url = url.replace("{{cuit}}", cuit);
  return url;
};
const getIndex = (name) => {
  const exp = /\d+(?!\.)/i;
  const index = exp.exec(name);
  if (index) {
    return index[0];
  } else {
    return null;
  }
};
const getDataFromResponse = (response, name) => {
  let indx;
  return name.split(".").reduce(function (a, b) {
    if (Array.isArray(a)) {
      const nameIdx = getIndex(b);
      const name = b.replace(`[${nameIdx}]`, "");
      const data = (a = a[indx][name]);
      indx = nameIdx;
      return data;
    } else {
      indx = getIndex(b);
      const name = b.replace(`[${indx}]`, "");
      return (a = a[name]);
    }
  }, response);
};
const UserCenteredLink = (props) => {
  const classes = useStyles();
  const capitalToPay = 7200000;
  const { element } = props;
  const [url, setUrl] = React.useState(
    element.asyncData ? getUrl(element.asyncConf.url, props.cuit) : ""
  );
  const [valueToShow, setValueToShow] = React.useState("");

  useEffect(() => {
    // TIENE DEPENDENCIA EN URL
    if (element.asyncData) {
      Axios({
        method: element.asyncConf.method as Method,
        url: url,
      })
        .then((response) => {
          const res = response;
          const ValueToSet = getDataFromResponse(
            res,
            element.asyncConf.dataTreeNode
          );
          setValueToShow(ValueToSet);
        })
        .catch(function (error) {
          console.log(error.message);
        });
    }
  }, [url]);

  useEffect(() => {
    if (!element.asyncData && element.reduxStateDataTreeNode) {
      const ValueToSet = getDataFromResponse(
        props.state,
        element.reduxStateDataTreeNode
      );
      setValueToShow(ValueToSet);
    }
  }, []);

  const GetFormatedValue = (value, datatype) => {
    switch (datatype) {
      case "money":
        return <NumberCurrency value={value.toFixed(props.config.decimals)} />;
        break;
      case "date":
        return format(new Date(value), "dd/MM/yyyy");
        break;
      default:
        return value;
    }
  };
  const GetTextWithValue = (originalText, value, dataType) => {
    const classes = useStyles();
    const text = originalText.split("{{value}}");
    return (
      <span>
        {text[0]}
        {value && (
          <span className={classes.txtBig}>
            {GetFormatedValue(value, dataType)}
          </span>
        )}
        {text[1]}
      </span>
    );
  };
  const clases = !props.noCentered
    ? props.right
      ? classes.btnLateral + " " + classes.btnLateralDer
      : classes.btnLateral
    : classes.noCentered;
  return (
    <Link to={element.to} className={clases}>
      <Typography className={classes.txtSmall}>
        {element["smallTop_" + props.locale.toUpperCase()]}{" "}
      </Typography>
      <Typography className={classes.txtMd}>
        {GetTextWithValue(
          element["middleValue_" + props.locale.toUpperCase()],
          valueToShow,
          element.dataType
        )}
      </Typography>
      <Typography className={classes.txtSmall}>
        {element["smallBottom_" + props.locale.toUpperCase()]}
      </Typography>
    </Link>
  );
};

const mapState = (state) => ({
  config: state.auth.config,
  cuit: state.auth.cuit,
  locale: state.auth.locale,
  state: state,
});

export default connect(mapState, null)(UserCenteredLink);
