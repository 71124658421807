import React from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      width: 250,
    },
    fullList: {
      width: "auto",
    },
    paper: {
      width: "100%",
      background: theme.agentHeader.background,
    },
    iconButton: {},
    AgentToolbar: {
      width: "100%",
      background: theme.agentHeader.background,
      minHeight: "auto !important",
    },
    AgentToolbarAtendiendo: {
      width: "100%",
      minHeight: "auto !important",
      background: theme.agentHeader.background,
      // opacity: ".3",
      color: theme.agentHeader.color,
      transition: "all 300ms",
      "&:hover": {
        opacity: "1",
        //padding: "10px 24px",
        transition: "all 300ms",
      },
    },
    btns: {
      position: "absolute",
      top: 0,
    },
  })
);

const AgentHeaderMobile = (props) => {
  const classes = useStyles();
  return (
    <>
      {props.mobile ? (
        <>
          <Drawer
            anchor={"top"}
            PaperProps={{ className: classes.paper }}
            open={props.open}
            onClick={props.onClose}
            onClose={props.onClose}
          >
            {props.children}
            <div></div>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.onClose()}
            >
              <HighlightOffIcon />
            </IconButton>
          </Drawer>

          <Toolbar
            className={
              props.atencion
                ? classes.AgentToolbarAtendiendo
                : classes.AgentToolbar
            }
          >
 

          </Toolbar>
        </>
      ) : (
        props.children
      )}
    </>
  );
};

export default AgentHeaderMobile;
