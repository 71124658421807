export const objectColor = {
    1:"#e67864",
    2:"#6ebe64",
    3:"#b478b4",
    4:"#75c4c6",
    5:'#d3ba40',
    6:'#706f7f',
}
export const objectIcon = {
    1:"home",
    2:"drive_eta",
    3:"monetization_on",
    4:"directions_bo",
    5:'home',
    6:'home',
}


export enum EobjectType {
    I=1,
    A=2,
    E=3,
    EMq = 4,
    M=5,
    P=6

}
