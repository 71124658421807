import React from "react";
import {  makeStyles ,createStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";
import LoadingComponent from "../../../common/LoadingComponent";
import Typography from '@material-ui/core/Typography';
const useStyles = makeStyles((theme) =>
  createStyles({
    regular: {
     height: 'fit-content',
      display: 'flex',
      padding: '5px 15px',
      background: theme.colores.regular,
      borderRadius: '4px',
      flexDirection: 'column',
      textTransform: 'uppercase',
      justifyContent: 'center',
      margin: '10px 15px',
    },
    irregular: {
      height: 'fit-content',
      display: 'flex',
      padding: '5px 15px',
      background:  theme.colores.irregular,
      borderRadius: '4px',
      flexDirection: 'column',
      textTransform: 'uppercase',
      justifyContent: 'center',
      margin: '10px 15px',
    },
    infoLoading: {
      height: 'fit-content',
      display: 'flex',
      padding: '5px 15px',
      background:  theme.colores.gris,
      borderRadius: '4px',
      flexDirection: 'column',
      textTransform: 'uppercase',
      justifyContent: 'center',
   
    },
    btntext:{

    }
  })
  );
const StateButton = (props) => {
    const classes = useStyles();
    return (
      <>
        {props.isLoading ? (
          <LoadingComponent isLoading>
            <div className={classes.infoLoading}>
              <FormattedMessage
                id="userInfo.loadingState"
                defaultMessage="Cargando"
              />
            </div>
          </LoadingComponent>
        ) : (
          <div className={!props.debtExist ? classes.regular : classes.irregular}>
            <Typography className={ classes.btntext}>
            {props.debtExist ? (
              <FormattedMessage
                id="userInfo.irregularState"
                defaultMessage="Irregular"
              />
            ) : (
              <FormattedMessage
                id="userInfo.regularState"
                defaultMessage="Regular"
              />
              
            )}</Typography>
          </div>
        )}
      </>
    );
  };
  

  export default StateButton