import axios from 'axios';

const FileServices = () => {
    
    const parseData = (object : any, data : any) => 
    {
        let parse = {};
        const keys = Object.keys(data);
        keys.forEach(key => { parse[key] = object[data[key]]});

        return parse;
    }

    const postFile = (action : any, object : any, locale : any, jwt : any) => {
        axios(
            {
            method: action.method,
            url: action.url,
            headers: {'Authorization': `Bearer ${jwt}`},
            data: parseData(object, action.json)
            }
        ).then((response) => {
            const linkSource = "data:application/pdf;base64," + response.data;
            const downloadLink = document.createElement("a");
            const fileName = action['name_' + locale.toUpperCase()] + ".pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        });
    }

    return {
        postFile
    };
};
export default FileServices;
 