import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import DefineRepresentedList from "../../components/DefineRepresented/DefineRepresentedList";
import { IRepresented } from "../../models/IRepresented";
import { connect } from "react-redux";
import SeccionHeader from "../../components/common/SeccionHeader";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ListIcon from "@material-ui/icons/List";
import { makeStyles } from "@material-ui/core/styles";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
const useStyles = makeStyles({
  buscar: {
    marginTop: "-16px",
    marginBottom: "16px",
  },
  toggleView: {
    marginTop: "-10px",
    marginBottom: "10px",
    color: "#444",
  },
});

const DefineRepresented = (props) => {
  const [filter, setFilter] = React.useState("");
  const [Table, setTable] = useState(true);
  const classes = useStyles();

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const searchItems = (data) => {
    return data.filter(
      (el) =>
        JSON.stringify(el).toLowerCase().indexOf(filter.toLowerCase()) > -1
    );
  };

  const setView = () => {
    setTable(!Table);
  };

  return (
    <div>
      <SeccionHeader
        title={
          <FormattedMessage
            id={"representedList.title"}
            defaultMessage={"Seleccionar un representado"}
          />
        }
      >
        <div>
          <TextField
            id="searchbar"
            label={
              <FormattedMessage
                id="representedList.search"
                defaultMessage="Buscar"
              />
            }
            type="text"
            onChange={handleFilter}
            className={classes.buscar}
          />
          <IconButton
            className={classes.toggleView}
            onClick={setView}
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            {Table ? <ViewModuleIcon /> : <ListIcon />}
          </IconButton>
        </div>
      </SeccionHeader>
      <DefineRepresentedList
        TableView={Table}
      />
     
    </div>
  );
};

const mapDispatch = (dispatch) => ({
});

const mapState = state => ({})

export default connect(mapState, mapDispatch)(DefineRepresented);
