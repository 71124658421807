export const TurnosListMock = [
  {
    "estado": "En espera",
    "fecha": "2020-06-24T17:46:00.000Z",
    "cuit": "20-35609265-5",
    "razonSocial": "Ayala, Fernando Ariel",
    "telefono": "3794526523",
    "email": "fernando@gmail.com",
    "delegacion": "Delegacion corrientes - CIAC",
    "motivo": "Otros tramites",
  },
  {
    "estado": "Programado",
    "fecha": "2020-06-24T17:46:00.000Z",
    "cuit": "20-35609265-5",
    "razonSocial": "Ayala, Fernando Ariel",
    "telefono": "3794526523",
    "email": "fernando@gmail.com",
    "delegacion": "Delegacion corrientes - CIAC",
    "motivo": "Otros tramites",
  },
  {
    "estado": "Programado",
    "fecha": "2020-06-24T17:46:00.000Z",
    "cuit": "20-35609265-5",
    "razonSocial": "Ayala, Fernando Ariel",
    "telefono": "3794526523",
    "email": "fernando@gmail.com",
    "delegacion": "Delegacion corrientes - CIAC",
    "motivo": "Otros tramites",
  },
  {
    "estado": "Programado",
    "fecha": "2020-06-24T17:46:00.000Z",
    "cuit": "20-35609265-5",
    "razonSocial": "Ayala, Fernando Ariel",
    "telefono": "3794526523",
    "email": "fernando@gmail.com",
    "delegacion": "Delegacion corrientes - CIAC",
    "motivo": "Otros tramites",
  },
  {
    "estado": "Programado",
    "fecha": "2020-06-24T17:46:00.000Z",
    "cuit": "20-35609265-5",
    "razonSocial": "Ayala, Fernando Ariel",
    "telefono": "3794526523",
    "email": "fernando@gmail.com",
    "delegacion": "Delegacion corrientes - CIAC",
    "motivo": "Otros tramites",
  },
];

export const TurnosConfigMock = {
  urlListaDeturnos: "https://run.mocky.io/v3/870547a1-4259-4e39-8cec-84ac5591f2e4",
  urlNextTurno: "https://run.mocky.io/v3/7a5eb350-31d8-4a12-85fc-1631550de099",
  urlStopTurno: "https://run.mocky.io/v3/a2c4fa14-b7e4-4ad7-8f0a-abb7380fe330",
  columnas: [
    {
      columnaLabel: "estado",
      columnaValue: "estado",
      columnaTypo: "state",
    },
    {
      columnaLabel: "fecha",
      columnaValue: "fecha",
      columnaTypo: "date",
    },
    {
      columnaLabel: "cuit",
      columnaValue: "cuit",
      columnaTypo: "string",
    },
    {
      columnaLabel: "razonSocial",
      columnaValue: "razonSocial",
      columnaTypo: "string",
    },
   
    {
      columnaLabel: "delegacion",
      columnaValue: "delegacion",
      columnaTypo: "string",
    },
    {
      columnaLabel: "motivo",
      columnaValue: "motivo",
      columnaTypo: "string",
    },
  ],
};
