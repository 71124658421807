import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { FormattedMessage } from "react-intl";

import { connect } from "react-redux";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ContactTabPanel from "../ContactTabPanel";
import ContactTable from "../ContactTable";
import { makeStyles } from "@material-ui/core/styles";
import { contactType, EContactType } from "../../../models/IContact";
import Badge from "../Badge";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    container: {
        marginBottom: '50px'
    },
    tableCell: {
        padding: '5px 16px'
    },
    tableHead: {
        background: '#efefef'
    },
    falsoTexto: {

        width: '63%',
        height: '15px',
        borderRadius: '15px',
        display: 'inline-block',
        background: '#dadada',

    },
    tabs: {
        background: 'white',
        flexDirection: 'row',
        '& span': {
            flexDirection: 'row',
        }
    },
    tableRow: {
        cursor: 'pointer',
        '&:hover': {
            background: '#f3f3f3'
        }
    }
    , tabsContainer: {
        boxShadow: ' inset 3px -1px 2px 0px #dddddd8c',
        background: ' #f7f7f7'
    }
    , buscar: {
        marginTop: '-16px',
        marginBottom: '16px',
    }

});

const ContactTabs = (props) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(0);
    const [filter, setFilter] = React.useState('');
    const [cantMsj, setCantMsj] = React.useState(0);
    const [cantNoti, setCantNoti] = React.useState(0);
    const [cantDFE, setCantDFE] = React.useState(0);


    const filterItems = (data, param) => data.filter((el) => el.tipo.toLowerCase().indexOf(param.toLowerCase()) > -1);
     
    const countDosentRead = (data) => data.filter(x => !x.fecha_interaccion).length;

    const searchItems = (data) => {
        return data.filter((el) =>
            el.tipo.toLowerCase().indexOf(filter.toLowerCase()) > -1
            ||
            el.descripcion.toLowerCase().indexOf(filter.toLowerCase()) > -1
        );
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
        refreshRead()
    };

    const refreshRead = () => {
        setCantMsj(+countDosentRead(filterItems(props.contactList, contactType[EContactType.mensaje])))
        setCantNoti(+countDosentRead(filterItems(props.contactList, contactType[EContactType.notificacion])))
        setCantDFE(+countDosentRead(filterItems(props.contactList, contactType[EContactType.DFE])))
    }



    useEffect(() => refreshRead(), [])
    console.log(props.contactTypes);

    return (
        <TableContainer component={Paper}>
            <Tabs className={classes.tabsContainer}
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                >
                    {props.contactTypes.filter(contactType => contactType.showInTab).map( (contactType, index) => <Tab value={index} className={classes.tabs}
                        label={contactType['name_' + props.locale.toUpperCase()]}
                    />)}
                    <Tab value={20} className={classes.tabs}
                    label={<FormattedMessage id="contacts.tabs.All" defaultMessage="Todos" />} />
            </Tabs>

            {props.contactTypes.filter(contactType => contactType.showInTab).map( (contactType, index) => <ContactTabPanel value={value} index={index}>
                <ContactTable contactList={searchItems(filterItems(props.contactList, contactType.code))}
                    refreshRead={refreshRead}
                    msjEmpty={<FormattedMessage id="contacts.NoResult"
                        defaultMessage="No hay contactos con ese criterio de búsqueda" />} />
                </ContactTabPanel>
            )}
            <ContactTabPanel value={value} index={20}>
                <ContactTable contactList={searchItems(props.contactList)}
                    refreshRead={refreshRead}
                    msjEmpty={<FormattedMessage id="contacts.NoResult"
                        defaultMessage="No hay contactos con ese criterio de búsqueda" />} />
            </ContactTabPanel>
        </TableContainer>
    );
}

const mapState = (state) => ({
    contactTypes: state.contacts.contactTypes,
    locale: state.auth.locale
  });
  
export default connect(mapState, null)(ContactTabs);
