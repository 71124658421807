import React from 'react';
import Obligation from '../Obligation/Obligation';
import Juicio from '../Juicio/Juicio';
import {connect} from 'react-redux';

const JuicioMapper = (props): any => {
    const distinct = (value, index, self) => self.indexOf(value) === index;
    const obsToRender = props.obligations.filter( ob => ob.estado == 'JUDICIAL' );
    let juicios = [];
    obsToRender.forEach( ob => juicios.push(ob.juicio_id));
    const juiciosToRender = juicios.filter(distinct);

    if(juiciosToRender.length > 0){
        return (juiciosToRender.map((juicio) => <Juicio key={juicio} juicio={juicio} obligations={obsToRender}/>))
    } else {
        return (<div></div>)
    }
    
    //return (props.obligations.map((ob) => ob.estado !== 'JUDICIAL' ? <Obligation key={ob.id} obligation={ob}/> : null))
}

export default connect(null, null)(JuicioMapper);
