import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import useLoginForm from "./LoginHooks";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import apiCMS from "../../services/CMSServices";
import { LoginProviders } from "../../constants/loginProviders";
import { mockRepresented } from "../../mock/DefineRepresentedMock";
import apiBUC from "../../services/BUCServices";
import apiPayment from "../../services/PaymentServices";
import apiApplication from "../../services/ApplicationServices";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props) => {
  const classes = useStyles({});
  const publicUser = {
    jwt:
      "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTEsImN1aXQiOiIyMC0zNTA5MDQwMy01IiwiY3VpdFJlcHJlc2VudGFkbyI6IjIwLTM1MDkwNDAzLTUiLCJpc0JhY2tPZmZpY2UiOmZhbHNlLCJncnVwbyI6WyJDb250cmlidXllbnRlIl0sImlhdCI6MTYwMDI1OTM1MSwiZXhwIjoxNjAyODUxMzUxfQ.g8AHtOx3O1OlwHH-aVTCSk30hUpvmQF-oi5_MzX-gn1MUu65ciEgY8PJIKUXW8tHc1OoA9U36yQp4Dipt6PogR-sNshSQjf3mExAqY4KAGmTonBsEfoJTHqtGaxzN-HXwop-BmSjpLYN48jGyP9nKWkbZMrxoBwSRiRxmyO9JB9BA5OGE0iyg391UukeFKhrTC7KTjqXazrOuYExmZgF7zgbO-XTN-42acqloF8eddMGlyB9mYDanzuJeCV6iTjpdEzwYz9mbBk93FvYJ8l_KNhY2TkqHKTQbmcdq6QqWPcl6KqwSc24n-_F2Ga_I3JbThZGf9lpumFky2AtV2X8Yw",
    user: {
      username: "public",
      id: 99999,
      tax_role: {
        id: 8,
      },
      cuit: props.allObjects[0].sujeto_id,
      name: "Persona",
      lastName: "Publica",
    },
  };

  useEffect(() => {
    apiCMS.AuthServices.getProvider()
      .then((res) => {
        handleLogin(res.data);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  const handleLogin = (provider) => {
    switch (provider.type) {
      case LoginProviders.JWT:
        // apiCMS.AuthServices.login(inputs.username, inputs.password)

        loginCallback(
          publicUser.user.username,
          publicUser.jwt,
          publicUser.user.cuit,
          publicUser.user.tax_role.id,
          publicUser.user.name,
          publicUser.user.lastName,
          publicUser.user.id
        );
        break;
      case LoginProviders.TDR:
        // apiCMS.AuthServices.loginTDR(inputs.username, inputs.password, provider.url, provider.data)
        const token = publicUser.jwt;
        const datos = parseJwt(token);
        apiCMS.setToken(token);

        apiCMS.AuthServices.getTaxRoleByCode(datos.usuarioTipoId);
        loginCallback(
          datos.username,
          token,
          datos.cuitRepresentado,
          publicUser.user.id,
          datos.nombre,
          datos.apellido,
          datos.userId
        );
        break;
    }
  };

  const b64DecodeUnicode = (str) => {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(b64DecodeUnicode(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const loginCallback = (username, jwt, cuit, taxRole, name, lastName, id, accessToken = '') => {
    props.loginSuccess(username, jwt, cuit, name, lastName, id, taxRole, accessToken);

    apiCMS.UserMenuServices.getUserMenu(taxRole)
      .then((res) =>
        props.setUserMenu(res.data.map((item) => item["user_menu"]))
      )
      .catch((err) =>
        console.log(
          "No se pudo obtener el menú del usuario. ERROR: ",
          err.message
        )
      );

    props.setAuthenticatedUser(jwt, username, cuit, name + " " + lastName, accessToken);
    props.setPublicLogin(true);
    apiCMS.setToken(jwt);
    apiBUC.setToken(jwt);
    apiPayment.setToken(jwt);
    apiApplication.setToken(props.jwt);

    apiCMS.AuthServices.getMenuItems(taxRole)
      .then((res) => {
        const orderedMenu = res.data
          .map((item) => {
            return item.action;
          })
          .sort((a, b) => (a.order > b.order ? 1 : -1));

        const defaultAction = orderedMenu[0].link;
        props.setMenu(orderedMenu);

        apiCMS.ConfigServices.getConfigs()
          .then((response) => {
            props.setConfig(response.data.find((t) => t.id === 1));

            apiCMS.LanguagesServices.getLanguages().then((response) => {
              props.setLanguages(response.data);
              props.history.push(defaultAction);
            });
          })
          .catch((err) => {
            alert(err);
          });
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: 1,
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h3>Login publico. Estamos cargando la informacion, por favor aguarde</h3>
      <LinearProgress />
    </div>
  );
};

const mapState = (state) => ({
  allObjects: state.payments.allObjects,
});

const mapDispatch = (dispatch) => ({
  loginSuccess: (username, jwt, cuit, name, lastName, id, taxRole, accessToken) =>
    dispatch.auth.loginSuccess(username, {
      jwt,
      cuit,
      name,
      lastName,
      id,
      taxRole,
      accessToken
    }),
  setConfig: (config) => dispatch.auth.setConfig(config),
  setMenu: (menu) => dispatch.auth.setMenu(menu),
  setUserMenu: (menu) => dispatch.auth.setUserMenu(menu),
  setLanguages: (languagesList) =>
    dispatch.languages.setLanguages(languagesList),
  setTags: (tagsList) => dispatch.tags.setTags(tagsList),
  setAuthenticatedUser: (jwt, username, cuit, denominacion, accessToken) =>
    dispatch.auth.setAuthenticatedUser(jwt, { username, cuit, denominacion, accessToken }),
  setPublicLogin: (publicLogin) => dispatch.auth.setPublicLogin(publicLogin),
});

export default withRouter(connect(mapState, mapDispatch)(Login));
