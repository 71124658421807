import React, { useEffect, useState } from "react";
import ObligationList from "../../components/PaymentMethods/ObligationList";
import { Divider, Paper } from "@material-ui/core";
import GoBackButton from "../../components/PaymentMethods/GoBackButton";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import EndMessage from "../../components/PaymentMethods/EndMessage";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import SeccionHeader from "../../components/common/SeccionHeader";
import PaymentMethodsLoading from "../../components/PaymentMethods/PaymentMethodsLoading";
import { Container } from "@material-ui/core/";

import BoxButton from "../../components/PaymentMethods/BoxButtons";
import BoxButtonElectronic from "../../components/PaymentMethods/BoxButtonElectronic";
import styled from "styled-components";

import { CashMethods } from "../../mock/data";
import { useHistory } from "react-router-dom";
import PaymentPlansServices from "../../services/PaymentPlansServices";
import _ from "lodash";
import apiBUC from "../../services/BUCServices";
import apiPayment from "../../services/PaymentServices";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: theme.spacing(2),
    },
  })
);

const BoxContainer = styled(Container)`
  transition: all 0.2s ease-in-out;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  flex-wrap: wrap;

  .boxCol {
    position: relative;
    padding: 15px;
    flex: 1;
    min-width: 25%;
    max-width: 30%;
    margin: 0 auto;
    transition: all 0.2s ease-in-out;
    @media (max-width: 991px) {
      &:not(:last-of-type) {
        margin: 0 0 20px;
      }
    }
    &.disabled:not(.disabledImage) {
      .boxHover {
        background: transparent;
        border: solid 1px #eee;
        box-shadow: none !important;
        transform: none !important;
        pointer-events: none;
        .boxTitle {
          color: #ddd !important;
        }
        .boxDescription {
          color: #ddd;
          li {
            color: #ddd;
          }
        }
      }
    }
    &.disabledImage {
      .boxHover {
        pointer-events: none;
        transform: none !important;
        .boxImage {
          filter: grayscale(100%);
        }
      }
    }
  }
  &:hover {
    .boxCol {
      .boxHover {
        box-shadow: none;
      }
      &:hover {
        opacity: 1;
        .boxHover:not(.disabled) {
          box-shadow: 10px 20px 30px 0 rgba(51, 51, 51, 0.2);
          cursor: pointer;
          transform: scale(1.1, 1.1);
        }
      }
    }
  }
`;

const PaymentMethods = (props) => {
  const [TiposOnline, setTiposOnline] = React.useState(null);
  const [ClienteId, setClienteId] = React.useState("30709110078");
  const [TiposManual, setTiposManual] = React.useState(null);
  const [FormasDePago, setFormasDePago] = React.useState(null);
  const [Boleta, setBoleta] = React.useState(null);
  const [Reporte, setReporte] = React.useState(null);
  const [Estado, setEstado] = React.useState("");
  const [Loading, setLoading] = React.useState(false);
  const [ReadyToPay, setReadyToPay] = React.useState(false);
  const [Editable, setEditable] = React.useState(true);
  const [Mensaje, setMensaje] = React.useState({
    msj: "Estamos preparando el pago",
    subMsj: `Sera redireccionado a la pagina seleccionada`,
    type: "loading",
    action: [],

    link: null,
  });
  let history = useHistory();
  const classes = useStyles({});
  let pdfBase = "";
  let vecesBajado = 0;

  useEffect(() => {
    let jsonClaves = {
      claves: [],
    };

    props.data.forEach((obl) => {
      let claveToPush = "";
      claveToPush = obl.concepto.toString();
      claveToPush = claveToPush + obl.impuesto.toString();
      if (jsonClaves.claves.includes(claveToPush)) {
      } else {
        jsonClaves.claves.push(claveToPush);
      }
    });
    let FormasDePagoToSet = [];

    apiPayment.services
      .getPaymentTypes(ClienteId, jsonClaves)
      .then((response) => {
        _.orderBy(response.data, ["nombre"], ["asc"]).forEach((method) => {
          if (method.nombre == "online") {
            FormasDePagoToSet.push({
              title: "Online",
              modifier: 1,
              action: "/e-payments",
              disabled: false,
            });
            setTiposOnline(method.medios);
          }
          if (method.nombre == "manual") {
            FormasDePagoToSet.push({
              title: (
                <FormattedMessage
                  id="paymentsPlanTab.title.cash"
                  defaultMessage="Efectivo"
                />
              ),
              modifier: 0.9,
              action: "/cash-payments",
              disabled: false,
            });
            setTiposManual(method.medios);
          }
        });
        if (!props.publicLogin) {
          calculatePaymentsPlan(FormasDePagoToSet);
        } else {
          setFormasDePago(FormasDePagoToSet);
          setReadyToPay(true);
        }
      });
  }, []);

  const calculatePaymentsPlan = (FormasDePagoToSet) => {
    PaymentPlansServices()
      .simulate(
        props.data.map((o) => {
          let saldo = o.saldo;
          let saldoConRecargo = o.saldoConRecargo;
          if (o.exenta) {
            saldo = (o.saldo * (100 - o.porcentaje_exencion)) / 100;
            saldoConRecargo =
              (o.saldoConRecargo * (100 - o.porcentaje_exencion)) / 100;
          }

          return {
            periodo: o.periodo,
            cuota: o.cuota,
            impuesto: o.impuesto,
            concepto: o.concepto,
            vencimiento: o.vencimiento,
            prorroga: o.prorroga,
            saldo,
            saldoConRecargo,
            id: o.id,
            sujeto_id: o.sujeto_id,
            objeto_id: o.objeto_id,
            objeto_tipo: o.objeto_tipo,
          };
        }),
        props.jwt
      )
      .then((response) => {
        props.setSimulation(response.data);
        let disabled = true;
        let msgPlanes = (
          <FormattedMessage
            id="paymentsPlanTab.title.unavailablePlans"
            defaultMessage="No hay planes disponibles."
          />
        );
        const cantPlanes = response.data.planes.length;
        switch (cantPlanes) {
          case 0:
            msgPlanes = (
              <FormattedMessage
                id="paymentsPlanTab.title.unavailablePlans"
                defaultMessage="No hay planes disponibles."
              />
            );
            break;
          case 1:
            msgPlanes = (
              <FormattedMessage
                id="paymentsPlanTab.title.availablePlan"
                defaultMessage="1 plan disponible."
              />
            );
            disabled = false;
            break;
          default:
            msgPlanes = (
              <FormattedMessage
                id="paymentsPlanTab.title.availablePlans"
                defaultMessage={cantPlanes + " planes disponibles."}
              />
            );
            disabled = false;
            break;
        }

        FormasDePagoToSet.push({
          title: (
            <FormattedMessage
              id="paymentsPlanTab.title.formas"
              defaultMessage="Plan de pago"
            />
          ),
          modifier: 0,
          subtext: msgPlanes,
          action: "/plan-payments",
          disabled: disabled,
        });
        setFormasDePago(FormasDePagoToSet);
        setReadyToPay(true);
      });
  };

  const downloadReporte = (): void => {
    const linkSource = "data:application/pdf;base64," + pdfBase;
    const downloadLink = document.createElement("a");
    const fileName = "reporte.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    vecesBajado++;
    //chequea si action en downloadReporte ya fue ejecutado
    if (vecesBajado == 1) {
      downloadLink.click();
      vecesBajado++;
    }
    if (vecesBajado > 2) {
      vecesBajado = 0;
    }
  };

  //#region BTNS MEDIOS DE PAGO Y SET MENSAJE
  const handleClick = (e) => {
    setEstado(e.action);
    setEditable(false);
    if (e.action == "/plan-payments") {
      history.push(e.action);
      setMensaje({
        msj: "Estamos generando el cupon de pago",
        subMsj: `Puede abonarlo en ${[...CashMethods]}`,
        type: "loading",
        action: [],
        link: null,
      });
    } else if (e.action == "/cash-payments") {
      setLoading(true);
      setEditable(false);
      setMensaje({
        msj: "Estamos generando el cupon de pago",
        subMsj: `Puede abonarlo en ${[...CashMethods]}`,
        type: "loading",
        action: [],
        link: null,
      });

      let oblJson = {};
      oblJson["sujeto_id"] = props.cuit;
      oblJson["medio_pago"] = "EF1";
      oblJson["obligaciones"] = [];
      props.data.forEach((obl) => {
        let oblToPush = {};
        oblToPush["id"] = obl.id;
        oblToPush["sujeto_id"] = obl.sujeto_id;
        oblToPush["cuota"] = obl.cuota;
        oblToPush["periodo"] = obl.periodo;
        oblToPush["concepto"] = obl.concepto;
        oblToPush["impuesto"] = obl.impuesto;
        oblToPush["objeto_id"] = obl.objeto_id;
        oblToPush["vencimiento"] = obl.vencimiento;

        oblJson["obligaciones"].push(oblToPush);
      });

      apiBUC.SubjectServices.generateBoleta(oblJson).then((response) => {
        // Llamar a Reportes
        let jsonReporte = {
          id: response.data.id,
          language: props.locale,
        };

        apiPayment.services
          .generateReporte(jsonReporte, props.jwt)
          .then((response) => {
            setReporte(response.data);
            pdfBase = response.data;
            setMensaje({
              msj: "Se genero el cupon de pago",
              subMsj: `Puede abonarlo en ${[...CashMethods]}`,
              type: "success",
              action: [
                {
                  action: () => downloadReporte(),
                  actionTxt: <FormattedMessage id={"paymentMethod.download"} defaultMessage="Descargar"/>,
                  type: "primary",
                },
                {
                  action: "/mis-impuestos",
                  actionTxt: <FormattedMessage id={"paymentMethod.goBack"} defaultMessage="Volver"/>,
                  type: "secondary",
                },
              ],
              link: null,
            });
            setLoading(false);
            // window.open(e.link, "_blank")
            setBoleta(response.data);
          });
      });
    } else {
      if (e.action == "/e-payments") {
        let oblJson = {};
        oblJson["sujeto_id"] = props.cuit;
        oblJson["obligaciones"] = [];
        props.data.forEach((obl) => {
          let oblToPush = {};
          oblToPush["id"] = obl.id;
          oblToPush["sujeto_id"] = obl.sujeto_id;
          oblToPush["cuota"] = obl.cuota;
          oblToPush["periodo"] = obl.periodo;
          oblToPush["concepto"] = obl.concepto;
          oblToPush["impuesto"] = obl.impuesto;
          oblToPush["objeto_id"] = obl.objeto_id;
          oblToPush["vencimiento"] = obl.vencimiento;

          oblJson["obligaciones"].push(oblToPush);
        });

        apiBUC.SubjectServices.generateBoleta(oblJson).then((response) => {
          setBoleta(response.data);
        });
      }
    }
  };
  const handleClickElectronic = (e) => {
    setLoading(true);
    setEstado("message");
    setEditable(false);
    setMensaje({
      msj: "Estamos preparando el pago",
      subMsj: `Sera redireccionado a la pagina de  ${e.nombre}`,
      type: "loading",
      action: [],
      link: null,
    });

    apiPayment.services
      .confirmPaymentType(Boleta.id, {
        detalle: Boleta.obligaciones,
        referencia_id: Boleta.id,
        importe: Boleta.importe,
        sujeto_id: Boleta.sujeto_id,
        medio_pago: e.medio,
        cliente_id: ClienteId,
      })
      .then((response) => {
        setTimeout(() => {
          setMensaje({
            msj: `Te redireccionamos a la pagina de  ${e.nombre}`,
            subMsj: ``,
            type: "success",
            action: [
              {
                action: "/mis-impuestos",
                actionTxt: "Volver a mis impuestos",
                type: "secondary",
              },
            ],

            link: `${response.data.checkout_url}`,
          });
          setLoading(false);

          window.open(response.data.response_gateway.checkout_url, "_blank");
        }, 2000);
      });
  };
  //#endregion
  //#region calcular total a pagar
  let total = 0;
  props.data.forEach((obl) => {
    if (obl.exenta) {
      total += (obl.saldoConRecargo * (100 - obl.porcentaje_exencion)) / 100;
    } else {
      total += obl.saldoConRecargo;
    }
  });
  //#endregion

  const getObligationsToPay = () => {
    let obligationsList = [];
    props.allObjects.forEach(
      (object) =>
        (obligationsList = [...obligationsList, ...object.obligaciones])
    );
    return obligationsList;
  };

  // NO SELECCIONO NINGUNA OBLIGACION
  if (props.data === null || props.data.length === 0) {
    return (
      <div>
        <p></p>
        <h3>No has seleccionado ninguna obligacion para pagar</h3>
        <p></p>
        <h4>
          <small>
            Vuelve a "Mis Impuestos" para seleccionar al menos una obligacion
          </small>
        </h4>
        <p></p>
        <GoBackButton to="mis-impuestos" />
      </div>
    );
  } else {
    if (ReadyToPay) {
      return (
        <div style={{ marginBottom: "30px" }}>
          {!(Estado == "message" || Estado == "/cash-payments") && (
            <SeccionHeader
              title={
                <FormattedMessage
                  id="paymentMethod.select"
                  defaultMessage="Seleccioná el medio de pago"
                />
              }
            ></SeccionHeader>
          )}

          <div className="boxHoverLinks">
            {Estado == "/e-payments" ? (
              <BoxContainer>
                {TiposOnline.map((elem, i) => (
                  <BoxButtonElectronic
                    key={i}
                    elevation={2}
                    onClickFc={() => handleClickElectronic(elem)}
                    btnProps={elem}
                    total={total}
                  ></BoxButtonElectronic>
                ))}
              </BoxContainer>
            ) : Estado == "message" || Estado == "/cash-payments" ? (
              <EndMessage msj={Mensaje} loading={Loading} />
            ) : (
              <BoxContainer>
                {FormasDePago.map((elem, i) => (
                  <BoxButton
                    key={i}
                    elevation={2}
                    onClickFc={() => handleClick(elem)}
                    btnProps={elem}
                    total={total}
                  />
                ))}
              </BoxContainer>
            )}
          </div>
          {/*   <PaymentMethodBox obligationList={props.data} />*/}

          {!(Estado == "message" || Estado == "/cash-payments") ? (
            <div className="taxesScreen">
              <SeccionHeader
                title={
                  <FormattedMessage
                    id="paymentMethod.youArePaying"
                    defaultMessage="Obligaciones a pagar"
                  />
                }
                action={props.data.length === getObligationsToPay().length}
                actionLabel={"Agregar Obligaciones"}
                actionTo={"mis-impuestos"}
              ></SeccionHeader>
              <Paper elevation={1}>
                <ObligationList
                  eliminable={Editable}
                  obligationList={props.data}
                />
              </Paper>

              <GoBackButton to="mis-impuestos" />
            </div>
          ) : null}
        </div>
      );
    } else {
      return (
        <div style={{ marginBottom: "30px" }}>
          <PaymentMethodsLoading></PaymentMethodsLoading>

          <GoBackButton to="mis-impuestos" />
        </div>
      );
    }
  }
};

const mapDispatch = (dispatch) => ({
  clearObligations: () => dispatch.payments.clearObligations(),
  setSimulation: (simulation) =>
    dispatch.paymentPlans.setSimulation(simulation),
});

const mapState = (state) => ({
  config: state.auth.config,
  publicLogin: state.auth.publicLogin,
  jwt: state.auth.jwt,
  data: state.payments.selectedObligations,
  allObjects: state.payments.allObjects,
  cuit: state.auth.cuit,
  denominacion: state.auth.denominacion,
  locale: state.auth.locale,
  simulation: state.paymentPlans.simulation,
});

export default connect(mapState, mapDispatch)(PaymentMethods);
