import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import SeccionHeader from "../../components/common/SeccionHeader";
import { useLocation, useHistory } from "react-router-dom";
import { replaceUrlParams } from "../../utils/Tools";
import { connect } from "react-redux";

const useStyles = makeStyles({
  iframe: {
    height: "69vh",
    border: "none",
  },
  container: {
    marginBottom: "50px",
  },
  paper: {
    padding: "20px",
  },
});

const Embedded = (props) => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [url, setUrl] = React.useState("");
  const [title, setTitle] = React.useState(" ");
  const [titleLanguageId, setTitleLanguageId] = React.useState(
    "Embedded.Title"
  );

  useEffect(() => {
    if (!location.state) {
      // TODO: define an error
      console.error("Id form is invalid");
      history.goBack();
    }
    if (location.state && location.state["url"]) {
      const params = { jwt: props.jwt };

      setUrl(replaceUrlParams(location.state["url"], params));

      if (location.state["title"]) {
        setTitle(location.state["title"]);
        setTitleLanguageId(location.state["titleLanguageId"]);
      }
    }
  });

  return (
    <div className={classes.container}>
      <SeccionHeader
        title={<FormattedMessage id={titleLanguageId} defaultMessage={title} />}
      ></SeccionHeader>
      <Paper className={classes.paper}>
        <iframe
          id="iframepage"
          className={classes.iframe}
          title="estado de cuenta"
          width="100%"
          height="100vh"
          src={url}
        ></iframe>
      </Paper>
    </div>
  );
};

const mapState = (state) => ({
  jwt: state.auth.jwt,
});

export default connect(mapState, null)(Embedded);
