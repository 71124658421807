import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) =>
  createStyles({
    state: {
      padding: "3px 7px",
      background: theme.estados.background,
      display: "inline-block",
      color: theme.estados.textColor,
      borderRadius: theme.estados.borderRadius,
      borderLeft: theme.estados.borderLeft,
      width: "100%",
      textAlign: "center",
    },
    iniciado: {
      background: theme.estados.iniciado,
    },
    solicitado: {
      background: theme.estados.solicitado,
    },
    aprobado: {
      background: theme.estados.aprobado,
    },
    rechazado: {
      background: theme.estados.rechazado,
    },
  })
);

export default function ApplicationState(props) {
  const classes = useStyles();

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const switchStyle = () => {
    switch (props.estado) {
      case "iniciado":
        return classes.state + " " + classes.iniciado;
        break;
      case "rechazado":
        return classes.state + " " + classes.rechazado;
        break;
      case "aprobado":
        return classes.state + " " + classes.aprobado;
        break;
      case "finalizado":
        return classes.state + " " + classes.aprobado;
        break;
      case "borrador":
        return classes.state + " " + classes.solicitado;
        break;
      case "solicitado":
        return classes.state + " " + classes.solicitado;
        break;
      default:
        return classes.state;
        break;
    }
  };
  return props.estado ? (
    <div className={switchStyle()}>
      <Typography> {capitalize(props.estado.replace("_", " "))}</Typography>
    </div>
  ) : null;
}
