import React, { useEffect } from "react";
import apiApplication from "../../services/ApplicationServices";
import Button from "@material-ui/core/Button";

import { connect } from "react-redux";

const DummyWeb = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("data");
  const [posting, setPosting] = React.useState(false);

  const handlePresentar = (values) => {
    // props.handleSubmit()
    // if(!props.hasValidationErrors){
    setPosting(true);
    apiApplication.services
      .postTramite(
        props.cuit,
        "presentar",
        {
          datosdelbien: { impuesto: "Automotor" },
          datoscomprador: {
            nombre: "Leandro",
            apellido: "Canavery",
            telefono: "",
            email: "gmail.com",
          },
          paramTramiteId: "24ec380d-4def-488f-8a5e-b7e592cfe702",
        },
        "24ec380d-4def-488f-8a5e-b7e592cfe702",
        // props.paramTramiteId,
        null
        // props.tramiteId
      )
      .then((response) => {
        setPosting(false);
        // history.goBack();
        // history.push("/mis-tramites");
      })
      .catch((err) => {
        setPosting(false);
        alert(err);
      });
    // }
  };

  return (
    <div>
      Hola mundo
      <div className="d-block">
        <pre>
          <code>{myParam}</code>
        </pre>
      </div>
      <iframe
        scrolling="yes"
        src="http://localhost:3000/mis-tareas?sso=eyJhbGciOiJSUzI1NiJ9.eyJpZCI6InZuTkZhSEVhOVV2NlhleWtUNHBXR0RQV3NHWkdMZkVrIiwiY2xpZW50SWQiOiI5MTEyYzUxY2Y1YjM0Y2UxN2Y1NDMyNGRkODA5NDk0NiIsInVzZXJJZCI6MzkwOCwidXNlcm5hbWUiOiJNQVJDQVMwMSIsImN1aXRSZXByZXNlbnRhZG8iOm51bGwsImFwZWxsaWRvIjoiUFJVRUJBMSIsIm5vbWJyZSI6Ik1BUkNBUyIsImlkQ29udHJpYnV5ZW50ZSI6MCwiZXN0YWJsZWNpbWllbnRvIjowLCJsb3RlSWQiOm51bGwsImNyZWF0ZWRBdCI6IjIwMjEtMDktMjFUMTU6NDc6NTguMzkzWiIsInVzdWFyaW9UaXBvSWQiOjIsImV4cCI6MTYzMjI4MjQ3OCwiaXNCYWNrT2ZmaWNlIjp0cnVlLCJncnVwbyI6WyJNYXJjYXMgeSBTZcOxYWxlcyJdfQ.HGmmUl9cfg402A6O0mg9h3Fq16-UtD_XPQgl_bp5rMl4cMhU3KtATw8I38CQI8npKsZC35i5T3JTv9sX2F_GYIzM8zOP3NdLYJr_M4jy0YVSo4RLJ1u_sdxskMZWGO4o_aOeXRTnSVx0DX7xGg6qsrfdvrDI7mTl4PfP85n2I7rkyvshFL-h3jhrDCJPodpaPZhm4OI_de2L7PRCfbAIlq5hlAfRNX9J2w2aGVxwAUvn1ZRGvdA7vId8hgf1l__SZ8WVYKxkbqvXLh6q5LMsdUReqsn8L0M4tUFG0TQD2GzzUa5Qo6Nn8yUbrXThntAEXKBooO9cenu2ldO-8gj77g&amp;access_token=vnNFaHEa9Uv6XeykT4pWGDPWsGZGLfEk"
        title="Some Content"
      ></iframe>
      <Button
        disabled={posting}
        variant={"outlined"}
        color="primary"
        onClick={handlePresentar}
      >
        {posting ? "Guardando" : "Guardar"}
      </Button>
    </div>
  );
};

const mapState = (state) => ({
  cuit: state.auth.cuit,
  jwt: state.auth.jwt,
});

export default connect(mapState, null)(DummyWeb);
