import React from "react";
import { connect } from "react-redux";
import NumberCurrency from "../../common/NumberCurrency";
import { FormattedMessage } from "react-intl";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import InfoIcon from "@material-ui/icons/Info";
import Checkbox from "@material-ui/core/Checkbox";
import ObligationCheckbox from "../../MyTaxes/Taxes/ObligationCheckbox";

// import ObligationRightDetail from "../../MyTaxes/Taxes/ObligationRightDetail";

const useStyles = makeStyles({
  Link: {
    "&:hover": {
      color: "white",
    },
    color: "white",
  },
  row: {
    "&:hover": {
      background: "#eee",
    },
  },
  deleted: {
    // textDecoration: 'line-through',
    boxShadow: "inset 1px 1px 7px 1px #ddd",
    background: "#f3f3f3",
  },
  cell: {
    padding: "7px 24px 7px 16px",
    background: "transparent",
  },
  cellAlert: { textAlign: "center" },
  cellLarge: {
    width: "75%",
  },
  table: {
    margin: "auto",
    width: "70%",
    background: "#f9f9f9",
  },
  header: {
    background: "#ddd",
  },
  stepsStyle: {
    fontWeight: 400,
    textAlign: "center",
    fontSize: "12px",
  },
  cellDeleted: {},
  icon: {
    color: "#b9b900",
  },
  cellBorrar: {
    textAlign: "right",
    minWidth: "120px",
  },
  btns: {
    padding: 0,
  },
  saldo: {
    display: "flex",
    flexDirection: "column",
  },
  originalDebt: {
    fontSize: "11px",
  },
});

const ObligationListElement = (props) => {
  let expirationDate = new Date(props.obligation.vencimiento);
  const [deleted, setDeleted] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  
  let honorarioLogic;
  if(props.trialsConfig.partialPay && props.trialsConfig.includeFees){
    honorarioLogic = props.trialsConfig.feesTaxConcept.feesTaxConcept.includes(parseInt(props.obligation.impuesto.toString().concat(props.obligation.concepto.toString())))
  } else {
    honorarioLogic = false;
  }

  let isInTrial;
  if(props.obligation.estado == 'JUDICIAL' || props.obligation.objeto_tipo == 'J') {
    if(!props.trialsConfig.partialPay) {
      isInTrial = true
    } else {
      if(props.trialsConfig.includeFees) {
        isInTrial = true
      } else {
        isInTrial = false
      }
    }
  } else {
    isInTrial = false
  }

  

  let totalToPay = 0;
  if (props.obligation.exenta) {
    totalToPay =
      (props.obligation.saldoConRecargo *
        (100 - props.obligation.porcentaje_exencion)) /
      100;
  } else {
    if (props.obligation.importe) {
      totalToPay = parseFloat(props.obligation.importe);
    } else {
      if (props.obligation.saldoConRecargo) {
        totalToPay = props.obligation.saldoConRecargo;
      } else {
        totalToPay = props.obligation.saldoConRecargo;
      }
    }
  }
  //   const obligationState = props.obligation
  //   ? props.obligationStates.find((state) =>
  //       props.obligation.estado
  //         ? state.code === props.obligation.estado
  //         : state.code === "NONE"
  //     )
  //   : props.obligationStates.find((state) => state.code === "NONE");
  const classes = useStyles();
  const borrar = () => {
    setDeleting(!deleting);
    props.onDelete(props.obligation);
  };
  /*const waitToConfirm = () => {
        setTimeout(() => {
            // if(!deleting){clearTimeout()}
            //    props.onDelete()
        }, 5000);
    }*/
  if (deleted) {
    return <span></span>;
  }
  if (deleting) {
    return (
      <TableRow
        className={deleting ? classes.row + " " + classes.deleted : classes.row}
        key={props.id}
      >
        <TableCell colSpan={5} className={classes.cellAlert}>
          <InfoIcon className={classes.icon} />
          <FormattedMessage
            id="payments.youDeleted"
            defaultMessage="Usted ha eliminado la "
          />
          <b>
            <FormattedMessage id="myTaxes.installment" defaultMessage="Cuota" />{" "}
            {props.obligation.cuota}
          </b>
          {" - "}
          <FormattedMessage id="payments.Object" defaultMessage="Objeto" />
          <b>{props.obligation.objeto_id}</b>
        </TableCell>

        <TableCell className={classes.cellBorrar}>
          <Button className={classes.btns} color="primary" onClick={borrar}>
            Deshacer
          </Button>
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow
        className={deleting ? classes.row + " " + classes.deleted : classes.row}
        key={props.id}
      >
        <TableCell className={classes.cell}>
          {props.obligation.objeto_id}
        </TableCell>
        <TableCell className={classes.cell}>
          {props.obligation.periodo}
        </TableCell>
        <TableCell className={classes.cell}>
          {!isNaN(props.obligation.cuota) ? <FormattedMessage id="myTaxes.installment" defaultMessage="Cuota" /> :null}
          {!isNaN(props.obligation.cuota) ? " " : null}
          {props.obligation.cuota}
        </TableCell>
        <TableCell className={classes.cell}>
          {expirationDate.getDate()}/{expirationDate.getMonth()}/
          {expirationDate.getFullYear()}
        </TableCell>
        <TableCell className={classes.cell}>
          <span className={classes.saldo}>
            <b>
              <NumberCurrency
                value={totalToPay.toFixed(props.config.decimals)}
              />
            </b>
            {props.deudaOriginal ? (
              <span className={classes.originalDebt}>
                <NumberCurrency
                  value={props.obligation.saldo.toFixed(props.config.decimals)}
                />{" "}
              </span>
            ) : null}
          </span>
        </TableCell>
        {props.agregable ? props.juicios ? honorarioLogic ?
          <TableCell>
          </TableCell>
        : (
          <TableCell>
            {" "}
            <ObligationCheckbox obligation={props.obligation}/>
          </TableCell>
        )
        : (
          <TableCell>
            {" "}
            <Checkbox color={"primary"} onChange={e => props.setObligationsForSimulation(props.obligation, e.target.checked)}/>
          </TableCell>
        ) : null}
        {props.editable && !isInTrial ? (
          <TableCell className={classes.cellBorrar}>
            <IconButton
              className={classes.btns}
              onClick={borrar}
              aria-label="delete"
            >
              {deleting ? (
                <AddCircleIcon fontSize="small" />
              ) : (
                <DeleteForeverIcon fontSize="small" />
              )}
            </IconButton>
          </TableCell>
        ) : <TableCell></TableCell>}
      </TableRow>
    );
  }
};

const mapState = (state) => ({
  config: state.auth.config,
  obligationStates: state.payments.obligationStates,
  obligationsForSimulation: state.payments.obligationsForSimulation,
  trialsConfig: state.payments.trialsConfig
});

const mapDispatch = dispatch => ({
  setObligationsForSimulation: (objects, isChecked) => dispatch.payments.setObligationsForSimulation(objects, isChecked)
})

export default connect(mapState, mapDispatch)(ObligationListElement);
